import React from "react"
import { Link } from "react-router-dom"

export const NavSoloItem = ({ content, active = false }) => {
  return (
    <li className="metismenu-item">
      <Link to={content.path} className={`metismenu-link ${active ? "active" : ""}`}>
        <i className={`metismenu-icon ${content.icon}`}></i>
        {content.label}
      </Link>
    </li>
  )
}
