import {
  BASE_MEDIAS_VIDEO_THUMBNAIL_URL,
  BASE_MEDIAS_PLAYLIST_THUMBNAIL_URL,
  BASE_MEDIAS_WEBCONTENT_THUMBNAIL_URL,
  BASE_API_URL,
  BASE_MEDIAS_ORGANIZATION_LOGO_URL,
  BASE_MEDIAS_USER_AVATAR_URL,
  BASE_MEDIAS_SCREEN_MEDIA_URL
} from "config/api"

const getMediaSrc = (element, type) => {
  if (type.toLowerCase() === "video") return BASE_MEDIAS_VIDEO_THUMBNAIL_URL + element
  if (type.toLowerCase() === "playlist") return BASE_MEDIAS_PLAYLIST_THUMBNAIL_URL + element
  if (type.toLowerCase() === "webContent") return BASE_MEDIAS_WEBCONTENT_THUMBNAIL_URL + element
  if (type.toLowerCase() === "screen") return BASE_MEDIAS_SCREEN_MEDIA_URL + element
  if (type.toLowerCase() === "organization") return BASE_MEDIAS_ORGANIZATION_LOGO_URL + element
  if (type.toLowerCase() === "user") return BASE_MEDIAS_USER_AVATAR_URL + element

  return BASE_API_URL
}
export default getMediaSrc
