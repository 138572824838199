import React, { Component } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Col, Row } from "reactstrap"
import i18n from "utils/i18n"
import _ from "lodash"
import DraggableListVideo from "./DraggableListVideo"
import DraggableListPlaylist from "./DraggableListPlaylist"
import ProgramContent from "./ProgramContent"

export class ProgramHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      grid: 8,
      hasChanged: false,
      videos: [],
      playlists: [],
      selected: [],
      activeTab: 0
    }
  }

  componentDidMount() {
    this.initItems()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.videos.data.length !== this.props.videos.data.length ||
      prevProps.playlists.data.length !== this.props.playlists.data.length ||
      prevProps.content.length !== this.props.content.length
    )
      this.initItems()
  }

  initItems = () => {
    this.setState(
      {
        videos: this.props.videos.data
          .map(video => ({
            type: "VIDEO",
            id: video.id,
            thumbnail: video.thumbnail,
            order: null,
            startDate: null,
            endDate: null,
            contentId: null,
            data: { ...video }
          })) // @ts-ignore
          .sort((a, b) => new Date(b.data.uploadedAt) - new Date(a.data.uploadedAt)),
        playlists: this.props.playlists.data
          .map(playlist => ({
            id: playlist.id,
            thumbnail: playlist.thumbnail,
            type: "PLAYLIST",
            order: null,
            startDate: null,
            endDate: null,
            contentId: null,
            data: { ...playlist }
          }))
          // @ts-ignore
          .sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt)),
        selected: this.props.content.map(content => ({
          id: content.type === "PLAYLIST" ? content.playlist.id : content.video.id,
          thumbnail: content.type === "PLAYLIST" ? content.playlist.thumbnail : content.video.thumbnail,
          type: content.type,
          order: content.order,
          startDate: content.startDate,
          endDate: content.endDate,
          contentId: content.id,
          data: content.type === "PLAYLIST" ? content.playlist : content.video
        }))
      },
      () => {
        this.props.setCurrentSelectedContent(this.state.selected)
      }
    )
  }

  editProgramContentDates = (selectedId, _startDate, _endDate) => {
    const selected = _.clone(this.state.selected)
    const index = selected.findIndex(i => i.id === selectedId)

    selected[index].startDate = _startDate
    selected[index].endDate = _endDate

    this.setState(
      () => ({
        selected
      }),
      () => {
        this.props.setCurrentSelectedContent(this.state.selected)
      }
    )
    this.props.setHasChanged(true)
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = droppableSource.droppableId === "programContent" ? sourceClone : source
    result[droppableDestination.droppableId] =
      droppableDestination.droppableId === "programContent" ? destClone : destination

    return result
  }

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: this.state.grid,
    width: 250
  })

  id2List = {
    droppableVideos: "videos",
    droppablePlaylists: "playlists",
    programContent: "selected"
  }

  getList = id => this.state[this.id2List[id]]

  onDoubleClick = (item, index, draggableId) => {
    const result = {
      combine: null,
      destination: {
        index: this.state.selected.length,
        droppableId: "programContent"
      },
      draggableId,
      mode: "FLUID",
      reason: "DROP",
      source: { index, droppableId: item.type === "PLAYLIST" ? "droppablePlaylists" : "droppableVideos" },
      type: "DEFAULT"
    }

    this.onDragEnd(result)
  }

  deleteContent = (content, index) => {
    const result = {
      combine: null,
      destination: {
        index: 0,
        droppableId: content.type === "PLAYLIST" ? "droppablePlaylists" : "droppableVideos"
      },
      draggableId: "3-programContent",
      mode: "FLUID",
      reason: "DROP",
      source: { index, droppableId: "programContent" },
      type: "DEFAULT"
    }

    this.onDragEnd(result)
  }

  onDragEnd = _result => {
    const { source, destination } = _result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(this.getList(source.droppableId), source.index, destination.index)

      let tmpState
      tmpState = { items }

      if (source.droppableId === "programContent") {
        tmpState = { selected: items }
      }

      this.setState(tmpState)
      this.props.setCurrentSelectedContent(items)
      this.props.setHasChanged(true)
    } else {
      const result = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )

      this.setState(
        prevState => ({
          videos: result.droppableVideos || prevState.videos,
          playlists: result.droppablePlaylists || prevState.playlists,
          selected: result.programContent || prevState.programContent
        }),
        () => {
          this.props.setCurrentSelectedContent(this.state.selected)
        }
      )
      this.props.setHasChanged(true)
    }
  }

  update = async () => {
    this.props.update(this.state.selected)

    this.props.setHasChanged(false)
  }

  _changeActiveTab = index => {
    this.setState({ activeTab: index })
  }

  render() {
    const { hasChanged, setHasChanged, userCanEdit, categories, organizations } = this.props
    const { videos, playlists, selected, activeTab } = this.state

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {userCanEdit && (
          <Row>
            <Col xs={12}>
              <div className="RRT__tabs body-tabs body-tabs-layout no-margin-right">
                <div
                  className={`RRT__tab draggable-tab RRT__tab--first ${activeTab === 0 ? "RRT__tab--selected" : ""}`}
                  onClick={() => this._changeActiveTab(0)}
                >
                  {i18n.t("entities.plural.playlist")}
                </div>
                <div
                  className={`RRT__tab draggable-tab ${activeTab === 1 ? "RRT__tab--selected" : ""}`}
                  onClick={() => this._changeActiveTab(1)}
                >
                  {i18n.t("entities.plural.video")}
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row
          style={{
            display: "flex",
            overflow: "hidden",
            flex: "1 1",
            height: "100%",
            paddingTop: 20,
            paddingBottom: 10
          }}
        >
          {userCanEdit && (
            <Col
              xs={6}
              style={{
                display: "flex",
                overflow: "hidden",
                flex: "1 1",
                height: "100%",
                flexDirection: "column",
                paddingRight: 10
              }}
            >
              {activeTab === 0 && (
                <DraggableListPlaylist
                  items={playlists}
                  organizations={organizations}
                  dblClickHandler={this.onDoubleClick}
                  myOrganization={this.props.myOrganization}
                />
              )}
              {activeTab === 1 && (
                <DraggableListVideo
                  items={videos}
                  organizations={organizations}
                  dblClickHandler={this.onDoubleClick}
                  categories={categories}
                  myOrganization={this.props.myOrganization}
                />
              )}
            </Col>
          )}

          <Col xs={userCanEdit ? 6 : 12} style={{ display: "flex", overflow: "hidden", flex: "1 1", height: "100%" }}>
            <ProgramContent
              selected={selected}
              editProgramContentDates={this.editProgramContentDates}
              deleteContent={this.deleteContent}
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              update={this.update}
              userCanEdit={userCanEdit}
            />
          </Col>
        </Row>
      </DragDropContext>
    )
  }
}

export default ProgramHandler
