import React, { Fragment } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import Textarea from "components/Form/Textarea"
import i18n from "utils/i18n"

const GeneralForm = ({ userCanEdit }) => {
  return (
    <Fragment>
      <Field
        type="text"
        name="title"
        label={i18n.t("entities.attributes.title")}
        editable={userCanEdit}
        component={ClassicInput}
        required
      />
      <Field
        component={Textarea}
        editable={userCanEdit}
        label={i18n.t("entities.attributes.description")}
        required={false}
        name="description"
      />
    </Fragment>
  )
}

export default GeneralForm
