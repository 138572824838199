import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Col, Row, Button } from "reactstrap"
import i18n from "utils/i18n"
import _ from "lodash"
import {
  apiGetOrganizations,
  apiGetOrganization,
  resetFocusOrga,
  apiGetOrganizationSharingRules
} from "services/organizations"
import { apiPostSharingRule, apiPatchSharingRule, apiDeleteSharingRule } from "services/sharingRules"
import MainContainer from "components/PageStructure/MainContainer"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import Select from "react-select"
import ListLoader from "components/List/ListLoader"
import { Formik, Form } from "formik"
import initFormValues from "utils/formik/initFormValues"
import RuleLine from "./RuleLine"
import initialValues from "./Form/initialValues"
import validationSchema from "./Form/validationSchema"
import SharingRuleForm from "./Form"

class SharingRules extends Component {
  constructor(props) {
    super(props)

    this.state = {
      organizationSelected: null,
      form: {
        init: false,
        loading: false
      }
    }
  }

  componentDidMount() {
    this.props.apiGetOrganizations()
  }

  componentWillUnmount() {
    this.props.resetFocusOrga()
  }

  onOrganizationSelectChange = value => {
    this.setState({
      organizationSelected: value
    })

    console.log(value)

    this.props.apiGetOrganizationSharingRules(value.value)
  }

  onToggleDownloadRule = (_rule, field) => {
    const rule = _.clone(_rule)
    rule[field] = !rule[field]

    this.props.apiPatchSharingRule(rule.id, rule)
  }

  onRuleDelete = rule => {
    this.props.apiDeleteSharingRule(rule.id)
  }

  cancelForm = () => {
    this.setState({ form: { loading: false, init: false } })
  }

  submitForm = async values => {
    this.setState({ form: { loading: true, init: true } })

    const data = _.clone(values)
    data.sharingOrganization = values.sharingOrganization.value
    data.canDownload = values.canDownload.value
    data.onlyShared = values.onlyShared.value
    data.grantedOrganization = this.props.organizations.focus.data.id

    const response = await this.props.apiPostSharingRule(data)

    if (response.type === "API_POST_SHARINGRULE_FAIL") this.setState({ form: { loading: false, init: true } })
    else this.setState({ form: { loading: false, init: false } })
  }

  render() {
    const { organizationSelected, form } = this.state
    const { organizations } = this.props

    const sharingRules = {
      accessTo: this.props.organizations.focus.data.accessTo,
      sharingTo: this.props.organizations.focus.data.sharingTo
    }

    const possibleOrganizations = organizations.list.data.filter(
      elt =>
        !sharingRules.accessTo.map(e => e.sharingOrganizationId).includes(elt.id) &&
        elt.id !== organizations.focus.data.id
    )

    const getOrganizationFromList = id => {
      return organizations.list.data.concat(organizations.accessible.data).find(i => i.id === id)
    }

    return (
      <PageContainer>
        <PageTitle heading={i18n.t("organizations.pageTitle.sharingRules")} icon="pe-7s-flag folder-color-CHANNEL" />

        <Row>
          <Col xs={12}>
            <MainContainer title={i18n.t("entities.singular.organization")}>
              <Select
                value={organizationSelected}
                disabled={false}
                onChange={this.onOrganizationSelectChange}
                name="role"
                options={generateMultiSelectOptions(organizations.list.data, "id", "name")}
                placeholder={i18n.t("form.actions.chooseOrganization")}
                className="basic-multi-select"
                classNamePrefix="tagHandler"
              />
            </MainContainer>
          </Col>
        </Row>

        {organizations.focus.loading && <ListLoader />}

        {organizationSelected !== null && !organizations.focus.loading && organizations.focus.data.id !== null && (
          <Fragment>
            {!form.init && (
              <Row>
                <Col xs={3}>
                  <Button
                    className="btn-icon"
                    color="primary"
                    outline
                    size="md"
                    onClick={() => this.setState({ form: { init: true, loading: false } })}
                  >
                    <i className="pe-7s-plus btn-icon-wrapper"> </i>
                    {i18n.t("actions.add")}
                  </Button>
                </Col>
              </Row>
            )}

            {form.init && (
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, {}, true)}
                validationSchema={validationSchema}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors }) => {
                  return (
                    <Form>
                      <SharingRuleForm
                        possibleOrganizations={possibleOrganizations}
                        errors={errors}
                        loading={form.loading}
                        onCancel={this.cancelForm}
                      />
                    </Form>
                  )
                }}
              </Formik>
            )}
            <br />
            <Row className="main-list-container">
              <Col xs={12}>
                <div className="sharingRules-title">{i18n.t("organizations.pageTitle.accessToAll")}</div>

                {sharingRules.accessTo
                  .filter(i => i.onlyShared === false)
                  .map(rule => (
                    <RuleLine
                      rule={rule}
                      organization={getOrganizationFromList(rule.sharingOrganizationId)}
                      onDelete={this.onRuleDelete}
                      onToggleDownloadRule={this.onToggleDownloadRule}
                    />
                  ))}

                <br />
                <div className="sharingRules-title">{i18n.t("organizations.pageTitle.accessToOnlyShared")}</div>

                {sharingRules.accessTo
                  .filter(i => i.onlyShared === true)
                  .map(rule => (
                    <RuleLine
                      rule={rule}
                      organization={getOrganizationFromList(rule.sharingOrganizationId)}
                      onDelete={this.onRuleDelete}
                      onToggleDownloadRule={this.onToggleDownloadRule}
                    />
                  ))}
              </Col>
            </Row>
          </Fragment>
        )}
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations
  }
}

const mapDispatchToProps = {
  apiGetOrganizations,
  apiGetOrganization,
  apiGetOrganizationSharingRules,
  resetFocusOrga,
  apiPostSharingRule,
  apiPatchSharingRule,
  apiDeleteSharingRule
}

export default connect(mapStateToProps, mapDispatchToProps)(SharingRules)
