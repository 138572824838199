import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  monday: Yup.boolean().required(i18n.t("form.requiredField")),
  tuesday: Yup.boolean().required(i18n.t("form.requiredField")),
  wednesday: Yup.boolean().required(i18n.t("form.requiredField")),
  thursday: Yup.boolean().required(i18n.t("form.requiredField")),
  friday: Yup.boolean().required(i18n.t("form.requiredField")),
  saturday: Yup.boolean().required(i18n.t("form.requiredField")),
  sunday: Yup.boolean().required(i18n.t("form.requiredField")),
  startHour: Yup.string().required(i18n.t("form.requiredField")),
  endHour: Yup.string().required(i18n.t("form.requiredField")),
  source: Yup.string().required(i18n.t("form.requiredField")),
  volume: Yup.number().min(0).max(100).required(i18n.t("form.requiredField"))
})

export default validationSchema
