import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { Button } from "reactstrap"
import i18n from "utils/i18n"

const DropzoneVideo = ({ onChange }) => {
  const onDrop = useCallback(acceptedFiles => {
    onChange(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className="dropzone-video">
      <input {...getInputProps()} />
      <div className="dropzone-text">
        <i className="pe-7s-video btn-icon-wrapper" style={{ fontSize: "4em" }}>
          {" "}
        </i>

        {i18n.t("videos.video.dragAndDropHere")}
        <br />
        <br />
        {i18n.t("form.or")}
        <br />
        <br />
        <Button className="mb-3 btn-icon" color="warning" type="button" size="md">
          <i className="pe-7s-plus btn-icon-wrapper"> </i>
          {i18n.t("videos.video.add")}
        </Button>
      </div>
    </div>
  )
}

export default DropzoneVideo
