import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Button } from "reactstrap"
import { apiGetPrograms } from "services/programs"
import ListContainer from "components/List/ListContainer"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"

class Programs extends Component {
  state = {
    listLength: null
  }

  componentDidMount() {
    this.props.apiGetPrograms()
  }

  render() {
    const { programs, me } = this.props
    const { listLength } = this.state

    return (
      <PageContainer>
        <PageTitle
          heading={i18n.t("programs.pageTitle.heading")}
          icon="pe-7s-film folder-color-PROGRAM"
          number={listLength}
        />

        <div className="page-top-buttons">
          {me.role === "ADMIN" && (
            <Link to="/programs/new" className="new-element-button">
              <Button className="btn-icon" color="primary" outline size="md">
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {i18n.t("programs.actions.add")}
              </Button>
            </Link>
          )}
        </div>

        <ListContainer
          type="PROGRAM"
          lineHeight="lg"
          elements={{ data: programs.list.data }}
          sortField="title"
          filtersOn
          filters={{ search: ["title"], select: ["owner", "groups"] }}
          defaultLayout="list"
          listLengthSetter={nb => this.setState({ listLength: nb })}
          multipleLayouts={false}
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    programs: state.programs,
    myOrganization: state.organizations.me.data,
    me: state.users.me.data
  }
}

const mapDispatchToProps = {
  apiGetPrograms: () => apiGetPrograms()
}

export default connect(mapStateToProps, mapDispatchToProps)(Programs)
