import React, { Component, Fragment } from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import i18n from "utils/i18n"
import moment from "moment"
import ValidateButton from "components/Buttons/ValidateButton"
import getContentDuration from "utils/getContentDuration"
import InputRange from "react-input-range"
import { Row, Button } from "reactstrap"
import { BASE_API_URL } from "config/api"
import LineContentItem from "./LineContentItem"
import GridContentItem from "./GridContentItem"

export class PlaylistContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gridSpace: 2,
      layout: "list"
    }
  }

  getItemStyle = (isDragging, draggableStyle, snapshot) => {
    const style = {
      userSelect: "none",
      padding: 2,
      width: this.state.layout === "grid" ? `${100 / (12 / this.state.gridSpace)}%` : "100%",
      height: this.state.layout === "list" ? 40 : "unset",

      // styles we need to apply on draggables
      ...draggableStyle
    }
    if (!snapshot.isDragging) return style

    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`
    }
  }

  onLayoutChange = _layout => this.setState({ layout: _layout })

  changeGridSpace = _space => this.setState({ gridSpace: _space })

  render() {
    const {
      selected,
      deleteContent,
      hasChanged,
      update,
      playlistId,
      userCanEdit,
      ownerId,
      replaceContent,
      addAfterContent
    } = this.props
    const { layout, gridSpace } = this.state

    return (
      <Droppable droppableId="playlistContent">
        {provided => (
          <Fragment>
            <div ref={provided.innerRef} className="droppable-list-content">
              <div className="droppable-content-header">
                <div className="main-container-title">
                  {i18n.t("playlists.pageTitle.content")}{" "}
                  <div className="video-time" style={{ marginLeft: "1em", marginRight: "1em" }}>
                    {moment.utc(getContentDuration(selected)).format("mm:ss")}
                  </div>
                  {hasChanged && (
                    <ValidateButton
                      disabled={false}
                      text={i18n.t("actions.save")}
                      type="button"
                      onClick={() => update()}
                    />
                  )}
                  {!hasChanged && (
                    <a href={`${BASE_API_URL}player/${ownerId}/playlist/${playlistId}`} target="_blank">
                      <Button
                        color="success"
                        className="btn-icon"
                        size="md"
                        type="button"
                        outline
                        disabled={hasChanged || selected.length === 0}
                      >
                        <i className="pe-7s-glasses btn-icon-wrapper"> </i>
                        {i18n.t("playlists.seePlaylist")}
                      </Button>
                    </a>
                  )}
                </div>

                <div className="filters-layout-choice">
                  <Fragment>
                    {layout === "grid" && (
                      <InputRange
                        formatLabel={() => ""}
                        maxValue={4}
                        minValue={1}
                        value={gridSpace}
                        onChange={value => this.changeGridSpace(value)}
                      />
                    )}
                    {false && (
                      <i
                        onClick={() => this.onLayoutChange("grid")}
                        style={{ fontSize: "26px", marginRight: "10px" }}
                        className={`pe-7s-keypad btn-icon-wrapper layout-choice-icon ${
                          layout === "grid" ? "color-gray-dark" : "color-light-gray"
                        }`}
                      >
                        {" "}
                      </i>
                    )}
                    <i
                      onClick={() => this.onLayoutChange("list")}
                      className={`pe-7s-menu btn-icon-wrapper layout-choice-icon ${
                        layout === "list" ? "color-gray-dark" : "color-light-gray"
                      }`}
                    >
                      {" "}
                    </i>
                  </Fragment>
                </div>
              </div>
              {selected.length === 0 && (
                <div className="line-element-container-helper">{i18n.t("playlists.helpInit")}</div>
              )}

              <div className="main-list-container">
                <Row style={{ marginBottom: 60 }}>
                  {selected.map((item, index) => (
                    <Draggable
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${item.type}-${item.id}-${index}`}
                      draggableId={`${item.id}-playlistContent-${index}`}
                      index={index}
                      isDragDisabled={!userCanEdit}
                    >
                      {(provided2, snapshot2) => (
                        <Fragment>
                          {layout === "grid" && (
                            <GridContentItem
                              item={item}
                              provided={provided2}
                              style={this.getItemStyle(snapshot2.isDragging, provided2.draggableProps.style, snapshot2)}
                              deleteContent={deleteContent}
                              userCanEdit={userCanEdit}
                              index={index}
                            />
                          )}
                          {layout === "list" && (
                            <LineContentItem
                              item={item}
                              provided={provided2}
                              style={this.getItemStyle(snapshot2.isDragging, provided2.draggableProps.style, snapshot2)}
                              deleteContent={deleteContent}
                              userCanEdit={userCanEdit}
                              index={index}
                              replaceContent={replaceContent}
                              addAfterContent={addAfterContent}
                            />
                          )}
                        </Fragment>
                      )}
                    </Draggable>
                  ))}
                </Row>
              </div>

              {provided.placeholder}
            </div>
          </Fragment>
        )}
      </Droppable>
    )
  }
}

export default PlaylistContent
