let baseUrl
if (process.env.NODE_ENV === "production") {
  baseUrl = "https://api.pepstream.fr"
} else {
  baseUrl = "https://api.pepstream.fr"
}

export const BASE_URL = baseUrl
export const BASE_API_URL = `${BASE_URL}/`
export const BASE_PLAYER_VIDEO_URL = `${BASE_URL}/player/video/`
export const BASE_PLAYER_IFRAME_URL = `${BASE_URL}/player/iframe/`
export const BASE_PLAYER_PLAYLIST_URL = `${BASE_URL}/player/playlist/`
export const BASE_PLAYER_CHANNEL_URL = `${BASE_URL}/player/streaming/`
export const BASE_PLAYER_WEBTV_URL = `${BASE_URL}/player/webTV/`
export const BASE_MEDIAS_VIDEO_URL = `${BASE_URL}/medias/video/`
export const BASE_MEDIAS_VIDEO_DOWNLOAD_URL = `${BASE_URL}/medias/video/download/`
export const BASE_MEDIAS_VIDEO_THUMBNAIL_URL = `${BASE_URL}/medias/thumbnail/video/`
export const BASE_MEDIAS_ORGANIZATION_LOGO_URL = `${BASE_URL}/medias/organization/`
export const BASE_MEDIAS_USER_AVATAR_URL = `${BASE_URL}/medias/user/`
export const BASE_MEDIAS_PLAYLIST_THUMBNAIL_URL = `${BASE_URL}/medias/thumbnail/playlist/`
export const BASE_MEDIAS_WEBCONTENT_THUMBNAIL_URL = `${BASE_URL}/medias/thumbnail/webContent/`
export const BASE_MEDIAS_SCREEN_MEDIA_URL = `${BASE_URL}/medias/screen/`
