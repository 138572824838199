import React from "react"
import { Col, Row } from "reactstrap"
import moment from "moment"
import getThumbnailSrc from "utils/getThumbnailSrc"
import { BASE_PLAYER_VIDEO_URL } from "config/api"
import MultipleTagDisplayer from "components/Tags/MultipleTagDisplayer"
import GroupDisplayer from "components/Groups/GroupDisplayer"
import i18n from "utils/i18n"

const Collapsible = ({ element }) => {
  return (
    <div className="collapsible-container">
      <Row className="collapsible-first-row">
        <Col xs={2} className="collapsible-thumbnail-container">
          <img src={getThumbnailSrc(element.thumbnail, "video")} alt="Thumbnail" className="collapsible-thumbnail" />
        </Col>
        <Col xs={4} className="collapsible-title-container">
          <div className="line">
            <div className="collapsible-element-info">
              <div className="content element-title">{element.title}</div>
              <div className="video-time mt-video-time">{moment.utc(element.duration * 1000).format("mm:ss")}</div>
            </div>
          </div>
          <div className="line">{/* <MultipleTagDisplayer tags={element.ownerTags} type="global" /> */}</div>
        </Col>
        <Col xs={2}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.owner")}</div>
            <div className="content text-ellipsis">{element.owner.name}</div>
          </div>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.uploadedAt")}</div>
            <div className="content">{moment(element.uploadedAt).format("DD/MM/YYYY, HH:mm")}</div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.urlStreamingLink")}</div>
            <div className="link-content">
              <a href={BASE_PLAYER_VIDEO_URL + element.id} target="_blank">
                {BASE_PLAYER_VIDEO_URL + element.id}
              </a>
            </div>
          </div>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.urlStreamingView")}</div>
            <div className="link-content">
              <a href={`${window.location.protocol}//${window.location.host}/#/videos/${element.id}`} target="_blank">
                {`${window.location.protocol}//${window.location.host}/#/videos/${element.id}`}
              </a>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.description")}</div>
            <div className="content scrollable">{element.description}</div>
          </div>
        </Col>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <Col xs={2}></Col>
        <Col xs={6} className="collapsible-actions-container">
          <Row className="collapsible-actions-row">
            <Col xs={4}>
              <div className="collapsible-element-info">
                <div className="label">{i18n.t("entities.plural.tag")}</div>
                <div className="content tags-container">
                  <MultipleTagDisplayer tags={element.localTags} type="local" />
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="collapsible-element-info">
                <div className="label">{i18n.t("entities.singular.group")}</div>
                <div className="content">
                  <GroupDisplayer groups={element.groups} />
                </div>
              </div>
            </Col>
            <Col xs={4}>
              <div className="collapsible-element-info">
                <div className="label">{i18n.t("entities.attributes.videoShared")}</div>
                <div className="content">
                  {element.shared
                    ? i18n.t("entities.attributesValues.shared.yes")
                    : i18n.t("entities.attributesValues.shared.no")}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Collapsible
