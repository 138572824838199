import React, { Component, Fragment } from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { Row, Col } from "reactstrap"
import GridItemPlaylist from "features/Playlists/List/Items/GridItem"
import produce from "immer"
import {
  createOrganizationSearchFilter,
  createOrganizationSelectFilter,
  createOrganizationSelectFilterForGroups
} from "utils/filters/createFilters"
import Filters from "components/List/Filters/Filters"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import moment from "moment"
import getContentDuration from "utils/getContentDuration"
import PlaylistThumbnail from "components/Users/PlaylistThumbnail"

class DraggableListVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gridSpace: 2,
      filtersLoaded: false,
      filters: {
        search: {
          value: ""
        },
        select: []
      },
      layout: "grid"
    }
  }

  componentDidMount() {
    this.initFilters()
  }

  changeGridSpace = _space => this.setState({ gridSpace: _space })

  initFilters = () => {
    const listFilters = {
      search: ["title"],
      select: ["owner", "groups"]
    }
    const type = "PLAYLIST"

    const { organizations } = this.props

    this.setState({
      filters: {
        search: createOrganizationSearchFilter(listFilters.search),
        select: listFilters.select.map(filter => {
          if (filter === "owner")
            return createOrganizationSelectFilter(
              {
                data: [
                  this.props.myOrganization,
                  ...this.props.myOrganization.accessTo
                    .map(rule => ({
                      name: organizations.list.data
                        .concat(organizations.accessible.data)
                        .find(i => i.id === rule.sharingOrganizationId).name,
                      id: rule.sharingOrganizationId
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
                ]
              },
              i18n.t("entities.attributes.owner"),
              "culture",
              "data",
              "owner"
            )
          else if (filter === "groups") {
            return createOrganizationSelectFilterForGroups(
              this.props.myOrganization,
              i18n.t("entities.singular.group"),
              "localGroups",
              "groups",
              type
            )
          } else if (filter === "localTags") {
            return createOrganizationSelectFilter(
              this.props.myOrganization,
              i18n.t("entities.singular.tag"),
              "ticket",
              "localTags",
              "localTags"
            )
          }

          return {}
        })
      },
      filtersLoaded: true
    })
  }

  onFilterChange = (_filterType, _filter, _value) => {
    switch (_filterType) {
      case "select":
        this.setState(
          produce(draft => {
            const oldFilter = draft.filters.select.find(filter => filter.name === _filter)
            if (_value === null) {
              oldFilter.value = []
            } else if (oldFilter.value.includes(_value))
              oldFilter.value.splice(
                oldFilter.value.findIndex(i => i === _value),
                1
              )
            else {
              oldFilter.value.push(_value)
            }
          })
        )
        break
      case "search":
        this.setState(
          produce(draft => {
            // eslint-disable-next-line no-param-reassign
            draft.filters.search.value = _value
          })
        )
        break
      default:
      // console.log("not possible")
    }
  }

  onLayoutChange = _layout => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.layout = _layout
      })
    )
  }

  applyFilters = (filters, _elt) => {
    const elt = _elt.data

    if (filters === null) return true
    let result = true

    // Search filter
    if (filters.search.value !== "") {
      filters.search.fields.forEach(field => {
        if (elt[field] === undefined) result = false
        else if (elt[field].toLowerCase().search(filters.search.value.toLowerCase()) === -1) result = false
      })
    }

    // Select filters
    filters.select.forEach(selectFilter => {
      switch (selectFilter.eltField) {
        case "groups":
          if (selectFilter.value.length !== 0) {
            if (elt[selectFilter.eltField][0] === undefined) result = false
            else if (!selectFilter.value.includes(elt[selectFilter.eltField][0].id)) result = false
          }
          break
        case "owner":
          if (selectFilter.value.length !== 0) {
            if (!selectFilter.value.includes(elt[selectFilter.eltField].id)) result = false
          }
          break
        case "localTags":
          if (selectFilter.value.length !== 0) {
            if (!elt[selectFilter.eltField].some(localTag => selectFilter.value.includes(localTag.id))) result = false
          }

          break
        default:
        // Nothing happens
      }
    })

    return result
  }

  getItemStyle = (isDragging, draggableStyle, snapshot) => {
    const style = {
      userSelect: "none",
      padding: this.state.layout === "grid" ? 6 : 0,
      width: this.state.layout === "grid" ? `${100 / (12 / this.state.gridSpace)}%` : "100%",
      height: this.state.layout === "list" ? 40 : "unset",

      // styles we need to apply on draggables
      ...draggableStyle
    }
    if (!snapshot.isDragging) return style

    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`
    }
  }

  render() {
    const { items, dblClickHandler } = this.props
    const { gridSpace, filters, layout, filtersLoaded } = this.state

    const activeItems = items.filter(elt => this.applyFilters(filters, elt)).map(i => i.id)

    return (
      <Droppable droppableId="droppablePlaylists" isDropDisabled>
        {provided => (
          <Fragment>
            {filtersLoaded && (
              <Filters
                filters={filters}
                onFilterChange={this.onFilterChange}
                layout={layout}
                onlyLogo
                multipleLayouts
                onLayoutChange={this.onLayoutChange}
                gridSpace={gridSpace}
                onGridSpaceChange={this.changeGridSpace}
              />
            )}
            <div className="main-list-container">
              <div ref={provided.innerRef} className="droppable-list-elements">
                <Row style={{ margin: 0 }}>
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={`${item.id}-playlist`} index={index}>
                      {(provided2, snapshot2) => (
                        <Fragment>
                          {layout === "grid" && (
                            <div
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}
                              style={this.getItemStyle(snapshot2.isDragging, provided2.draggableProps.style, snapshot2)}
                              onDoubleClick={() => dblClickHandler(item, index, `${item.id}-playlist`)}
                              className={`grid-element-col box-shadow ${
                                activeItems.includes(item.id) ? "" : "hiddenDraggable"
                              }`}
                            >
                              <GridItemPlaylist
                                element={item.data}
                                linkActivated={false}
                                handlers={{ role: "ADMIN" }}
                                actionsActivated={false}
                                smallText
                              />
                            </div>
                          )}
                          {layout === "list" && (
                            <div
                              ref={provided2.innerRef}
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}
                              style={this.getItemStyle(snapshot2.isDragging, provided2.draggableProps.style, snapshot2)}
                              onDoubleClick={() => dblClickHandler(item, index, `${item.id}-playlist`)}
                              className={`line-element-container ${
                                activeItems.includes(item.id) ? "" : "hiddenDraggable"
                              }`}
                            >
                              <Row className="line-element-row">
                                <Col xs={1} className="list-thumbnail-playlist">
                                  <PlaylistThumbnail playlist={item.data} />
                                </Col>
                                <Col xs={5}>
                                  <div className="element-title text-ellipsis">{item.data.title}</div>
                                </Col>
                                <Col xs={4}>
                                  <div className="line-element-info">
                                    <div className="content">
                                      <OrganizationWithLogoDisplayer
                                        organization={item.data.owner}
                                        style={{ height: 20 }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={2}>
                                  <div className="video-time" style={{ float: "right" }}>
                                    {moment.utc(getContentDuration(item.data.content, true)).format("mm:ss")}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Fragment>
                      )}
                    </Draggable>
                  ))}
                </Row>

                <span
                  style={{
                    display: "none"
                  }}
                >
                  {provided.placeholder}
                </span>
              </div>
            </div>
          </Fragment>
        )}
      </Droppable>
    )
  }
}

export default DraggableListVideo
