import React from "react"
import Select from "react-select"

const SelectAutoComplete = ({
  options = [],
  placeholder,
  label = null,
  required = false,
  editable = true,
  disabled = false,
  onChange = null,
  field: { name, value, onBlur },
  form: { touched, setFieldValue }
}) => {
  const _onChange = _value => {
    setFieldValue(name, _value)
    if (onChange !== null) onChange(_value)
  }

  return (
    <div className="form-input-container">
      <div className="form-label">
        {label !== null && <span>{label}</span>}
        {label !== null && required && <span className="form-cross-required">*</span>}
      </div>

      <Select
        value={value}
        isDisabled={!editable || disabled}
        onChange={_onChange}
        onBlur={onBlur}
        name={name}
        options={options}
        placeholder={placeholder}
        className="basic-multi-select"
        classNamePrefix="tagHandler"
      />

      {touched[name] && required && value.value === "" && <div className="form-error-style">Requis</div>}
    </div>
  )
}

export default SelectAutoComplete
