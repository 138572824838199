import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import { apiPatchOrganization, apiPatchLogoOrganization, getMineOrganization } from "services/organizations"
import { Row, Col } from "reactstrap"
import MainContainer from "components/PageStructure/MainContainer"
import { Formik, Form, Field } from "formik"
import _ from "lodash"
import produce from "immer"
import FormSaveButton from "components/Buttons/FormSaveButton"
import initFormValues from "utils/formik/initFormValues"
import i18n from "utils/i18n"
import ClassicInput from "components/Form/ClassicInput"
import Tabs from "react-responsive-tabs"
import validationSchema from "features/Organization/Form/validationSchema"
import initialValues from "features/Organization/Form/initialValues"
import Users from "features/Organization/Users"
import LocalGroups from "features/Organization/LocalGroups"
import LocalTags from "features/Organization/LocalTags"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import OrganizationLogoHandler from "components/Organization/OrganizationLogoHandler"
import { BASE_MEDIAS_ORGANIZATION_LOGO_URL } from "config/api"
import ListLoader from "components/List/ListLoader"
import BroadcastTags from "./BroadcastTags"
import Categories from "./Categories"
import PageTitle from "components/PageStructure/PageTitle"

export class Organization extends Component {
  state = {
    title: "organizations.pageTitle.heading",
    logoHandler: {
      src: null,
      error: null
    },
    isInit: false,
    activeTab: 0
  }

  componentDidMount() {
    this.initComponent()
    this.props.getMineOrganization()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      switch (this.props.location.pathname) {
        case "/organization":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 0 })
          break
        case "/organization/members":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 0 })
          break
        case "/organization/groups":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 1 })
          break
        case "/organization/tags":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 2 })
          break
        case "/organization/broadcastTags":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 3 })
          break
        case "/organization/categories":
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 4 })
          break
        default:
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ activeTab: 0 })
      }
    }
  }

  initComponent = async () => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.isInit = true
        // eslint-disable-next-line no-param-reassign
        draft.logoHandler = {
          error: null,
          src: `${BASE_MEDIAS_ORGANIZATION_LOGO_URL + this.props.organization.data.logo}`
        }
      })
    )
  }

  handleLogoChange = async (src, file) => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.logoHandler.error = null
      })
    )
    const supportedTypes = ["image/png", "image/jpeg"]

    if (!supportedTypes.includes(file.type)) {
      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.logoHandler.error = i18n.t("form.image.formatError")
        })
      )
    } else {
      const formData = new FormData()
      formData.append("logo", file)

      await this.props.apiPatchLogoOrganization(this.props.organization.data.id, formData)

      this.setState(prevState => ({ ...prevState, logoHandler: { src, error: null } }))
    }
  }

  submitForm = async values => {
    this.props.apiPatchOrganization(this.props.organization.data.id, values)
  }

  render() {
    const { title, activeTab, logoHandler, isInit } = this.state
    const { organization, categories } = this.props
    const { data } = organization

    if (organization.loading) return <ListLoader />

    if (!organization.loading && organization.data !== undefined && isInit) {
      const tabItems = [
        {
          title: i18n.t("organizations.tabs.members"),
          content: <Users organization={data} />,
          tabClassName: "draggable-tab"
        },
        {
          title: i18n.t("organizations.tabs.groups"),
          content: <LocalGroups organization={data} />,
          tabClassName: "draggable-tab"
        },
        {
          title: i18n.t("organizations.tabs.broadcastTags"),
          content: <BroadcastTags organization={data} />,
          tabClassName: "draggable-tab"
        }
      ]

      if (organization.data.level === 0)
        tabItems.push({
          title: i18n.t("organizations.tabs.categories"),
          content: <Categories organization={data} categories={categories.data} />,
          tabClassName: "draggable-tab"
        })

      return (
        <PageContainer>
          <PageTitle
            heading={i18n.t("organizations.pageTitle.heading")}
            icon="pe-7s-culture folder-color-ORGANIZATION"
          />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, false)}
                validationSchema={validationSchema}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors }) => {
                  return (
                    <Form>
                      <Row>
                        <Col xs={6}>
                          <FormSaveButton text={i18n.t("actions.save")} type="submit" disabled={!_.isEmpty(errors)} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <MainContainer>
                            <Row>
                              <Col xs={1}>
                                <OrganizationLogoHandler
                                  organization={data}
                                  logoHandler={logoHandler}
                                  onChange={this.handleLogoChange}
                                />
                              </Col>
                              <Col xs={6}>
                                <Field
                                  type="text"
                                  name="name"
                                  label={i18n.t("entities.attributes.name")}
                                  component={ClassicInput}
                                  required
                                />
                              </Col>
                              {data.superOrga !== null && (
                                <Col xs={5}>
                                  <div>
                                    <div className="form-label">{i18n.t("entities.attributes.superOrga")}</div>
                                  </div>{" "}
                                  <OrganizationWithLogoDisplayer organization={data.superOrga} />
                                </Col>
                              )}
                            </Row>
                          </MainContainer>
                        </Col>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          <Tabs
            tabsWrapperClass="body-tabs body-tabs-layout"
            transform={false}
            selectedTabKey={activeTab}
            showInkBar
            items={tabItems}
          />
        </PageContainer>
      )
    }

    return <div>coucou</div>
  }
}

const mapStateToProps = state => ({
  organization: state.organizations.me,
  user: state.users.me.data,
  categories: state.categories.list
})

const mapDispatchToProps = {
  apiPatchOrganization,
  apiPatchLogoOrganization,
  getMineOrganization
}

export default connect(mapStateToProps, mapDispatchToProps)(Organization)
