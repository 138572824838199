import React from "react"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import DeviceStatus from "components/Device/DeviceStatus"
import bytesToGo from "utils/bytesToGo"
import DeviceActionButton from "components/Buttons/DeviceActionButton"
import getDeviceThumbnail from "utils/getDeviceThumbnail"
import ListActionButton from "components/Buttons/ListActionButton"

const ItemLine = ({ element, handlers }) => {
  let sourceText

  switch (element.source) {
    case "HDMI1":
      sourceText = "HDMI1"
      break
    case "HDMI2":
      sourceText = "HDMI2"
      break
    case "HDMI3":
      sourceText = "HDMI3"
      break
    case "PepstreamApp":
      sourceText = "PEPSTREAM"
      break
    case "SamsungHome":
      sourceText = "Samsung Home"
      break
    case "WebBrowser":
      sourceText = "Web Browser"
      break
    default:
      sourceText = "Samsung Home"
  }

  const diskSpace =
    element.magicInfo !== null
      ? `${Math.round(bytesToGo(element.magicInfo?.diskSpaceRepository) * 100) / 100}Go`
      : "Inconnu"

  return (
    <Link to={`/devices/${element.id}`} className="row-link">
      <Row className="line-element-row">
        <Col xs={1} style={{ backgroundImage: `url(${getDeviceThumbnail(element)})` }} className="list-thumbnail">
          &nbsp;
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="element-title">{element.name}</div>

            <div className="content">
              <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
            </div>
          </div>
        </Col>
        <Col xs={1}>
          <div className="line-element-info">
            <DeviceStatus status={element.status} />
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("devices.attributes.source")}</div>
            <div className="content">{sourceText}</div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("devices.attributes.diskSpace")}</div>
            <div className="content">{diskSpace}</div>
          </div>
        </Col>

        <Col xs={3}>
          <div className="list-action-menu">
            <DeviceActionButton
              text={i18n.t("devices.actions.on")}
              onClick={() => handlers.setDeviceOn(element.id)}
              disabled={element.status === "ON" || element.status === "DISCONNECTED"}
            />
            <DeviceActionButton
              text={i18n.t("devices.actions.off")}
              onClick={() => handlers.setDeviceOff(element.id)}
              disabled={element.status === "OFF" || element.status === "DISCONNECTED"}
            />
            <DeviceActionButton
              text={i18n.t("devices.actions.clearStorage")}
              onClick={() => handlers.clearDeviceStorage(element.id)}
              disabled={element.status === "DISCONNECTED"}
            />
          </div>
        </Col>
        <Col xs={1}>
          <ListActionButton icon="" label="Accès rapide" onClick={() => handlers.openQuickAccess(element.id)} />
        </Col>
      </Row>
    </Link>
  )
}

export default ItemLine
