import React, { Component, Fragment } from "react"
import { Row, Col } from "reactstrap"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import i18n from "utils/i18n"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import Switch from "components/Form/Switch"
import ImageHandler from "./ImageHandler"
import TextEditor from "./TextEditor"

export class DisplayParameters extends Component {
  render() {
    const { activeTab, values, onImageUpload, onImageDelete, onTextChange } = this.props

    const imgPositionOptions = [
      { value: "TOPLEFT", label: "TOPLEFT" },
      { value: "TOP", label: "TOP" },
      { value: "TOPRIGHT", label: "TOPRIGHT" },
      { value: "RIGHT", label: "RIGHT" },
      { value: "BOTTOMRIGHT", label: "BOTTOMRIGHT" },
      { value: "BOTTOM", label: "BOTTOM" },
      { value: "BOTTOMLEFT", label: "BOTTOMLEFT" },
      { value: "LEFT", label: "LEFT" }
    ]

    const sourceOptions = [
      { value: null, label: "Aucune" },
      { value: "URL", label: "URL" }
    ]

    return (
      <Fragment>
        {activeTab === 0 && (
          <Fragment>
            <Row>
              <Col xs={6}>
                <ImageHandler
                  image={values.displayParameters.player_img}
                  loading={false}
                  onChange={onImageUpload}
                  onImageDelete={onImageDelete}
                />
              </Col>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  required
                  disabled={values.displayParameters.player_img === ""}
                  name="displayParameters.player_imgPosition"
                  label={i18n.t("devices.content.imagePosition")}
                  options={[
                    { value: "TOPLEFT", label: "TOPLEFT" },
                    { value: "TOPRIGHT", label: "TOPRIGHT" },
                    { value: "BOTTOMRIGHT", label: "BOTTOMRIGHT" },
                    { value: "BOTTOMLEFT", label: "BOTTOMLEFT" }
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  required
                  name="displayParameters.player_text_defilement"
                  label={i18n.t("devices.content.text_defilement")}
                  options={[
                    { value: "FIX", label: "FIX" },
                    { value: "SLOW", label: "SLOW" },
                    { value: "MEDIUM", label: "MEDIUM" },
                    { value: "FAST", label: "FAST" }
                  ]}
                />
              </Col>
              <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
                <Field
                  component={Switch}
                  editable
                  style={{ marginBottom: 0 }}
                  name="displayParameters.player_text_with_background"
                  label={i18n.t("devices.content.text_defilement_background")}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TextEditor onChange={onTextChange} value={values.displayParameters.player_text} />
              </Col>
            </Row>
          </Fragment>
        )}
        {activeTab === 1 && (
          <Fragment>
            <Row>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  required
                  disabled={false}
                  name="displayParameters.bloc1_source"
                  label={i18n.t("devices.content.source")}
                  options={sourceOptions}
                />
              </Col>
              <Col xs={6}>
                {values.displayParameters.bloc1_source.value === "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc1_url"
                    label={i18n.t("devices.content.sourceUrl")}
                    placeholder="https://..."
                  />
                )}

                {values.displayParameters.bloc1_source.value !== "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc1_backgroundColor"
                    label={i18n.t("devices.content.backgroundColor")}
                  />
                )}
              </Col>
            </Row>
            {values.displayParameters.bloc1_source.value !== "URL" && (
              <Fragment>
                <Row>
                  <Col xs={6}>
                    <ImageHandler
                      image={values.displayParameters.bloc1_img}
                      loading={false}
                      onChange={onImageUpload}
                      onImageDelete={onImageDelete}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Field
                      component={SelectAutoComplete}
                      required
                      name="displayParameters.bloc1_text_defilement"
                      label={i18n.t("devices.content.text_defilement")}
                      options={[
                        { value: "FIX", label: "FIX" },
                        { value: "SLOW", label: "SLOW" },
                        { value: "MEDIUM", label: "MEDIUM" },
                        { value: "FAST", label: "FAST" }
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <TextEditor onChange={onTextChange} value={values.displayParameters.bloc1_text} />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
        {activeTab === 2 && (
          <Fragment>
            <Row>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  required
                  disabled={false}
                  name="displayParameters.bloc2_source"
                  label={i18n.t("devices.content.source")}
                  options={sourceOptions}
                />
              </Col>
              <Col xs={6}>
                {values.displayParameters.bloc2_source.value === "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc2_url"
                    label={i18n.t("devices.content.sourceUrl")}
                    placeholder="https://..."
                  />
                )}

                {values.displayParameters.bloc2_source.value !== "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc2_backgroundColor"
                    label={i18n.t("devices.content.backgroundColor")}
                  />
                )}
              </Col>
            </Row>
            {values.displayParameters.bloc2_source.value !== "URL" && (
              <Fragment>
                <Row>
                  <Col xs={6}>
                    <ImageHandler
                      image={values.displayParameters.bloc2_img}
                      loading={false}
                      onChange={onImageUpload}
                      onImageDelete={onImageDelete}
                    />
                  </Col>
                  <Col xs={6}>
                    <Field
                      component={SelectAutoComplete}
                      required
                      disabled={values.displayParameters.bloc2_img === ""}
                      name="displayParameters.bloc2_imgPosition"
                      label={i18n.t("devices.content.imagePosition")}
                      options={imgPositionOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <TextEditor onChange={onTextChange} value={values.displayParameters.bloc2_text} />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
        {activeTab === 3 && (
          <Fragment>
            <Row>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  required
                  disabled={false}
                  name="displayParameters.bloc3_source"
                  label={i18n.t("devices.content.source")}
                  options={sourceOptions}
                />
              </Col>
              <Col xs={6}>
                {values.displayParameters.bloc3_source.value === "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc3_url"
                    label={i18n.t("devices.content.sourceUrl")}
                    placeholder="https://..."
                  />
                )}

                {values.displayParameters.bloc3_source.value !== "URL" && (
                  <Field
                    component={ClassicInput}
                    required
                    name="displayParameters.bloc3_backgroundColor"
                    label={i18n.t("devices.content.backgroundColor")}
                  />
                )}
              </Col>
            </Row>
            {values.displayParameters.bloc3_source.value !== "URL" && (
              <Fragment>
                <Row>
                  <Col xs={6}>
                    <ImageHandler
                      image={values.displayParameters.bloc3_img}
                      loading={false}
                      onChange={onImageUpload}
                      onImageDelete={onImageDelete}
                    />
                  </Col>
                  <Col xs={6}>
                    <Field
                      component={SelectAutoComplete}
                      required
                      disabled={values.displayParameters.bloc3_img === ""}
                      name="displayParameters.bloc3_imgPosition"
                      label={i18n.t("devices.content.imagePosition")}
                      options={imgPositionOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <TextEditor onChange={onTextChange} value={values.displayParameters.bloc3_text} />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default DisplayParameters
