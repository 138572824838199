import React from "react"
import { Col, Row } from "reactstrap"
import Status from "components/Status"
import { Link } from "react-router-dom"
import OrganizationLogo from "components/Organization/OrganizationLogo"

const ItemLine = ({ element }) => {
  return (
    <Link to={`/subOrgas/${element.id}`} className="row-link">
      <Row className="line-element-row">
        <Col xs={1} className="list-user-avatar">
          <OrganizationLogo organization={element} />
        </Col>
        <Col xs={6}>
          <div className="element-title">{element.name}</div>
        </Col>
        <Col xs={2}>
          <Status status={element.status} />
        </Col>
      </Row>
    </Link>
  )
}

export default ItemLine
