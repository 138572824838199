import React from "react"
import { Button } from "reactstrap"
import RemoteIcon from "assets/icons/remote_icon.png"

const DeviceRemoteButton = ({ onClick, style = {} }) => {
  return (
    <Button
      className="btn-icon device-remote-button"
      color="light"
      outline
      size="md"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
      style={{ ...style }}
    >
      <img src={RemoteIcon} alt="" style={{ width: 8 }} />
    </Button>
  )
}

export default DeviceRemoteButton
