// eslint-disable-next-line max-classes-per-file
import React, { Component, PureComponent, Fragment } from "react"
import { connect } from "react-redux"
import { apiGetDevices } from "services/devices"
import MapGL, { Marker } from "react-map-gl"
import MAP_STYLE from "assets/mapbox-style/style.json"
import MAPBOX_TOKEN from "config/MAPBOX_TOKEN"

import PinUnpaired from "assets/dashboard-markers/pin_unpaired.png"
import PinDisconnected from "assets/dashboard-markers/pin_disconnected.png"
import PinOff from "assets/dashboard-markers/pin_off.png"
import PinOn from "assets/dashboard-markers/pin_on.png"
import QuickAccess from "features/Devices/QuickAccess"

// PureComponent ensures that the markers are only rerendered when data changes
class Markers extends PureComponent {
  render() {
    const { data, openQuickAccess } = this.props
    return data.map(device => (
      <Marker key={device.id} longitude={device.lng} latitude={device.lat}>
        {device.status === "UNPAIRED" && (
          <img src={PinUnpaired} alt="D" className="pin-dashboard" onClick={() => openQuickAccess(device.id)} />
        )}
        {device.status === "DISCONNECTED" && (
          <img src={PinDisconnected} alt="D" className="pin-dashboard" onClick={() => openQuickAccess(device.id)} />
        )}
        {device.status === "OFF" && (
          <img src={PinOff} alt="D" className="pin-dashboard" onClick={() => openQuickAccess(device.id)} />
        )}
        {device.status === "ON" && (
          <img src={PinOn} alt="D" className="pin-dashboard" onClick={() => openQuickAccess(device.id)} />
        )}
      </Marker>
    ))
  }
}

const DashboardBlock = function ({ nb, subtitle, type }) {
  return (
    <div className={`dashboard-block ${type}`}>
      <div className="dashboard-number">{nb}</div>
      <div className="dashboard-subtitle">{subtitle}</div>
    </div>
  )
}

export class index extends Component {
  state = {
    mapStyle: "",
    viewport: {
      latitude: 46,
      longitude: 2,
      zoom: 5,
      bearing: 0,
      pitch: 0
    },
    interactiveLayerIds: [],
    quickAccessVisible: false,
    quickAccessDeviceId: null
  }

  componentDidMount() {
    this.props.apiGetDevices()
  }

  _onViewportChange = viewport => this.setState({ viewport })

  _onInteractiveLayersChange = layerFilter => {
    this.setState({
      interactiveLayerIds: MAP_STYLE.layers.map(layer => layer.id).filter(layerFilter)
    })
  }

  _onClick = event => {
    const feature = event.features && event.features[0]

    if (feature) {
      window.alert(`Clicked layer ${feature.layer.id}`) // eslint-disable-line no-alert
    }
  }

  _getCursor = ({ isHovering, isDragging }) => {
    return isHovering ? "pointer" : "default"
  }

  openQuickAccess = id => {
    this.setState({ quickAccessDeviceId: id, quickAccessVisible: true })
  }

  closeQuickAccess = () => {
    this.setState({ quickAccessDeviceId: null, quickAccessVisible: false })
  }

  render() {
    const { viewport, interactiveLayerIds, quickAccessVisible, quickAccessDeviceId } = this.state
    const { devices } = this.props

    const markers = devices.list.data
      .filter(i => i.lat !== null && i.lng !== null)
      .map(i => ({ id: i.id, lat: i.lat, lng: i.lng, status: i.status }))

    const unlocatedDevices = devices.list.data.filter(i => i.lat === null && i.lng === null).length

    return (
      <Fragment>
        <MapGL
          {...viewport}
          width="100%"
          height="100%"
          mapStyle={MAP_STYLE}
          clickRadius={2}
          onClick={this._onClick}
          getCursor={this._getCursor}
          interactiveLayerIds={interactiveLayerIds}
          onViewportChange={this._onViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <Markers data={markers} openQuickAccess={this.openQuickAccess} />

          <div className="dashboard-resume">
            <DashboardBlock
              nb={devices.list.data.filter(i => i.status === "ON").length}
              subtitle="En marche"
              type="on"
            />
            <DashboardBlock nb={devices.list.data.filter(i => i.status === "OFF").length} subtitle="Arrêt" type="off" />
            <DashboardBlock
              nb={devices.list.data.filter(i => i.status === "DISCONNECTED").length}
              subtitle="Déconnecté"
              type="disconnected"
            />
            <DashboardBlock
              nb={devices.list.data.filter(i => i.status === "UNPAIRED").length}
              subtitle="Non appairé"
              type="unpaired"
            />
          </div>

          {unlocatedDevices > 0 && (
            <div className="unlocated-devices">
              ⚠️ {unlocatedDevices} écran{unlocatedDevices > 1 && "s"} sans localisation
            </div>
          )}
        </MapGL>

        <QuickAccess visible={quickAccessVisible} deviceId={quickAccessDeviceId} close={this.closeQuickAccess} />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  devices: state.devices
})

const mapDispatchToProps = {
  apiGetDevices
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
