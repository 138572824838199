/* eslint-disable indent */
import _ from "lodash"

const initFormValues = (_initialValues, data, isNewElement) => {
  const result = _initialValues.reduce((acc, item) => {
    switch (item.type) {
      case "text":
        acc[item.name] = isNewElement && data[item.name] === "" ? "" : data[item.name] || ""
        break
      case "number":
        acc[item.name] = isNewElement && data[item.name] === "" ? "" : data[item.name] || 0
        break
      case "password":
        acc[item.name] = isNewElement ? "" : data[item.name] || ""
        break
      case "multiSelect":
        acc[item.name] = isNewElement
          ? []
          : data[item.name].map(opt => ({
              value: opt[item.options.valueField],
              label: opt[item.options.labelField],
              color: item.options.withColor ? opt.color : "#000"
            }))
        break
      case "selectAutoComplete-Multi":
        if (_.isEmpty(data[item.name])) acc[item.name] = { value: "", label: "" }
        else {
          acc[item.name] = isNewElement
            ? {
                value: "",
                label: ""
              }
            : {
                value: data[item.name][0][item.options.valueField],
                label: data[item.name][0][item.options.labelField],
                color: "#000"
              }
        }

        break
      case "selectAutoComplete-Single":
        if (_.isEmpty(data[item.name])) acc[item.name] = { value: "", label: "" }
        else {
          acc[item.name] =
            isNewElement && _.isEmpty(data[item.name])
              ? {
                  value: "",
                  label: ""
                }
              : {
                  value: data[item.name][item.options.valueField],
                  label: data[item.name][item.options.labelField],
                  color: "#000"
                }
        }
        break
      case "boolean":
        if (data[item.name] === null || data[item.name] === undefined) acc[item.name] = false
        else acc[item.name] = data[item.name]

        break

      case "select":
        acc[item.name] =
          isNewElement && data[item.name] === ""
            ? { value: "", label: "" }
            : { value: data[item.name], label: data[item.name] } || { value: "", label: "" }
        break
      case "nestedObject":
        acc[item.name] = item.elements.reduce((acc2, item2) => {
          const accu = acc2

          if (item2.type === "text")
            accu[item2.name] =
              isNewElement && data[item.name][item2.name] === "" ? "" : data[item.name][item2.name] || ""
          else if (item2.type === "select")
            accu[item2.name] =
              isNewElement && data[item.name][item2.name] === ""
                ? { value: "", label: "" }
                : { value: data[item.name][item2.name], label: data[item.name][item2.name] } || { value: "", label: "" }
          else if (item2.type === "boolean") accu[item2.name] = isNewElement ? false : data[item.name][item2.name]

          return accu
        }, {})

        break
      default:
        return acc
    }

    return acc
  }, {})

  return result
}

export default initFormValues
