/* eslint-disable react/jsx-closing-tag-location */
import React, { Fragment } from "react"
import TreeView from "react-treeview"
import i18n from "utils/i18n"
import { Link, withRouter } from "react-router-dom"
import { Dropdown } from "semantic-ui-react"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"

class DeviceGroupsTree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: this.props.deviceGroup.id !== "-1",
      onEdit: null,
      editValue: props.deviceGroup.name
    }
  }

  handleClick = () => {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }))
  }

  toggleDeviceGroupEdit = () => {
    this.setState(prevState => ({ onEdit: !prevState.onEdit }))
  }

  onEditValueChange = event => {
    this.setState({ editValue: event.target.value })
  }

  onDeviceGroupUpdate = () => {
    const data = _.clone(this.props.deviceGroup)

    if (this.state.editValue !== data.name) {
      data.name = this.state.editValue
      this.props.onDeviceGroupUpdate(data)
    }

    this.toggleDeviceGroupEdit()
  }

  toggleSelected = device => {
    if (this.props.selected.map(i => i.id).includes(device.id)) this.props.onElementDelete(device)
    else this.props.onElementAdd(device)
  }

  render() {
    const { deviceGroup, selected, program } = this.props
    const { collapsed, onEdit, editValue } = this.state

    const label = (
      <Fragment>
        {!onEdit && (
          <span className="node-deviceGroup" onClick={() => this.handleClick()}>
            {deviceGroup.id !== "-1" && <Fragment>{deviceGroup.name}</Fragment>}
          </span>
        )}
        {onEdit && (
          <span className="node-deviceGroup" style={{ display: "flex", justifyContent: "space-between" }}>
            <input type="text" className="deviceGroup-new-input" value={editValue} onChange={this.onEditValueChange} />{" "}
            <FormSaveButton
              type="button"
              disabled={false}
              onClick={() => {
                if (editValue !== null && editValue !== "") this.onDeviceGroupUpdate()
                else this.toggleDeviceGroupEdit()
              }}
              text={editValue === "" ? "X" : "OK"}
            />
          </span>
        )}
      </Fragment>
    )
    const focusID = this.props.focus !== null && this.props.focus.id

    console.log(deviceGroup)
    return (
      <TreeView
        key={`deviceGroup-${deviceGroup.id}`}
        nodeLabel={label}
        collapsed={collapsed}
        itemClassName={`tree-view_item deviceGroup-tree ${deviceGroup.id === "-1" ? "hidden" : ""}`}
        onClick={() => this.handleClick()}
      >
        {deviceGroup.devices.length > 0 &&
          deviceGroup.devices.map(device => (
            <div
              className={`device-view-item ${selected.map(i => i.id).includes(device.id) ? "selected" : undefined}`}
              onClick={() => this.toggleSelected(device)}
              key={`device-${device.id}`}
            >
              <i
                className="pe-7s-airplay btn-icon-wrapper"
                style={{ marginRight: "0.5em", color: focusID === device.id ? "#fff" : "#3d4f60" }}
                // eslint-disable-next-line react/jsx-closing-tag-location
              ></i>
              {device.name}

              {selected.map(i => i.id).includes(device.id) &&
                device.program !== undefined &&
                device.program !== null &&
                device.program.id !== program.id && (
                  <i
                    className="pe-7s-attention btn-icon-wrapper"
                    style={{
                      position: "absolute",
                      right: 35,
                      "font-size": "1.6em",
                      "font-weight": "600",
                      color: "rgb(208, 16, 16)"
                    }}
                    // eslint-disable-next-line react/jsx-closing-tag-location
                  ></i>
                )}
              {device.program !== null && (
                <i
                  className="pe-7s-film btn-icon-wrapper"
                  style={{
                    position: "absolute",
                    right: 10,
                    "font-size": "2em",
                    color: focusID === device.id ? "#fff" : "#3d4f60"
                  }}
                  // eslint-disable-next-line react/jsx-closing-tag-location
                ></i>
              )}
            </div>
          ))}
        {deviceGroup.devices.length === 0 && (
          <div className="device-view-empty">
            <i>{i18n.t("devices.none")}</i>
          </div>
        )}
      </TreeView>
    )
  }
}

export default withRouter(DeviceGroupsTree)
