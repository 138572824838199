import React, { Component } from "react"
import { Provider } from "react-redux"
import { Route, Switch, HashRouter } from "react-router-dom"

import { PersistGate } from "redux-persist/integration/react"

import Login from "./features/Login"
import Home from "./features"

import configureStore from "./config/configureStore"
import history from "./config/history"
import "styles/index.css"
import "styles/base.css"
import "styles/custom.css"
import "semantic-ui-css/semantic.min.css"
import "react-input-range/lib/css/index.css"
import "video-react/dist/video-react.css"
import "react-treeview/react-treeview.css"

export default class App extends Component {
  constructor(props) {
    super(props)

    const { store, persistor } = configureStore()

    this.state = {
      isReady: false,
      store,
      persistor
    }
  }

  componentDidMount() {
    this.setState({ isReady: true })
  }

  render() {
    if (!this.state.isReady) {
      return true
    }

    return (
      <Provider store={this.state.store}>
        <PersistGate loading={null} persistor={this.state.persistor}>
          <HashRouter
            // @ts-ignore
            history={history}
          >
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route path="/" component={Home} />
            </Switch>
          </HashRouter>
        </PersistGate>
      </Provider>
    )
  }
}
