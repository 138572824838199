import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(i18n.t("form.requiredField")),
  lastName: Yup.string().required(i18n.t("form.requiredField")),
  email: Yup.string().email(i18n.t("form.incorrectEmail")).required(i18n.t("form.requiredField")),
  role: Yup.string().required(i18n.t("form.requiredField")),
  password: Yup.string().min(4, i18n.t("form.passwordLength")).required(i18n.t("form.requiredField"))
})

export default validationSchema
