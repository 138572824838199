import React from "react"
import { Col, Row } from "reactstrap"
import Status from "components/Status"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import ReleaseStatusProgressBar from "components/ProgressBar"
import { Icon } from "semantic-ui-react"

const ItemLine = ({ element, collapseElt, isCollapsed }) => {
  const collapse = e => {
    e.preventDefault()
    e.stopPropagation()

    collapseElt()
  }

  return (
    <Link to={`/programs/${element.id}`} className="row-link">
      <Row className="line-element-row">
        <Col xs={1}>
          <i className="pe-7s-film folder-color-PROGRAM" style={{ fontSize: "3em" }}></i>
        </Col>
        <Col xs={3}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.name")}</div>
            <div className="content">{element.title}</div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.owner")}</div>
            <div className="content">
              <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
            </div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.nbElements")}</div>
            <div className="content">{element.content.length}</div>
          </div>
        </Col>
        {element.releaseStatus.nbDevices > 0 && (
          <Col xs={2} onClick={collapse}>
            <div className="line-element-info">
              <div className="label">{i18n.t("entities.attributes.releaseStatus")}</div>
              <div className="content" style={{ position: "relative" }}>
                <ReleaseStatusProgressBar
                  nb={element.releaseStatus.nbDevicesSynced}
                  total={element.releaseStatus.nbDevices}
                />
                {isCollapsed && (
                  <Icon size="small" name="chevron up" style={{ position: "absolute", top: 6, right: "-20px" }} />
                )}
                {!isCollapsed && (
                  <Icon size="small" name="chevron down" style={{ position: "absolute", top: 6, right: "-20px" }} />
                )}
              </div>
            </div>
          </Col>
        )}

        <Col xs={2}>
          <Status status={element.status} />
        </Col>
      </Row>
    </Link>
  )
}

export default ItemLine
