import React, { Component } from "react"
import { Sidebar } from "semantic-ui-react"
import Autocomplete from "react-autocomplete"
import _ from "lodash"
import i18n from "utils/i18n"
import PS7Icon from "components/Icons/PS7Icon"
import ValidateButton from "components/Buttons/ValidateButton"
import SidebarCloseButton from "components/SidebarCloseButton"

export class ChannelsSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initSelected: this.props.selected,
      selected: this.props.selected,
      autocompleteValue: ""
    }
  }

  createDataForAutocomplete = () => {
    const result = this.props.elements
      .filter(device => {
        return this.state.selected.findIndex(elt => elt.id === device.id) <= -1
      })
      .map(i => ({
        label: i.title,
        value: i.title,
        data: i
      }))

    return result
  }

  onElementAdd = elt => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = _.clone(this.state.selected)
    data.push(elt)

    this.setState({ selected: data })

    this.setState({ autocompleteValue: "" })
  }

  onElementDelete = elt => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = _.clone(this.state.selected)
    data.splice(
      data.findIndex(i => i.id === elt.id),
      1
    )

    this.setState({ selected: data })
  }

  onCancel = () => {
    this.props.onCancel()

    this.setState(prevState => ({
      selected: prevState.initSelected
    }))
  }

  render() {
    const { visible, onSubmit } = this.props
    const { selected, autocompleteValue } = this.state

    return (
      <Sidebar as="div" animation="overlay" direction="right" visible={visible} className="sidebar-editor">
        <div className="quickaccess-container">
          <div className="quickaccess-sidebar-title" style={{ padding: "1em 0", marginBottom: "1em" }}>
            {i18n.t("entities.plural.channel")}
          </div>

          <SidebarCloseButton onClick={this.onCancel} style={{ position: "absolute", top: "25px", right: "1em" }} />

          <Autocomplete
            items={this.createDataForAutocomplete()}
            shouldItemRender={(item, value) => {
              return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
            }}
            getItemValue={item => item.label}
            renderItem={(item, highlighted) => (
              <div key={item.id} style={{ backgroundColor: highlighted ? "#eee" : "transparent", padding: 5 }}>
                {item.label}
              </div>
            )}
            wrapperStyle={{ width: "100%", position: "relative", marginBottom: "2em" }}
            // eslint-disable-next-line react/no-children-prop
            renderMenu={items => <div className="autocomplete-menu" children={items} />}
            renderInput={props => (
              <div className="autocomplete-input-container">
                {autocompleteValue === "" && <i className="pe-7s-search color-gray search-icon-input"></i>}

                {autocompleteValue !== "" && (
                  <i
                    className="pe-7s-close color-gray search-icon-input"
                    onClick={() => this.setState({ autocompleteValue: "" })}
                  >
                    {" "}
                  </i>
                )}
                <input type="text" className="autocomplete-input" {...props} placeholder="Rechercher" />
              </div>
            )}
            value={autocompleteValue}
            onChange={e => this.setState({ autocompleteValue: e.target.value })}
            onSelect={(value, item) => {
              this.onElementAdd(item.data)
            }}
          />

          {selected.map(channel => (
            <div className="autocomplete-item" key={`channel-${channel.id}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PS7Icon
                  icon="airplay"
                  className="btn-icon-wrapper"
                  style={{ marginRight: "0.5em", color: "#3d4f60", fontSize: "1.5em" }}
                />
                {channel.title}
              </div>

              <PS7Icon
                icon="trash"
                className="color-red autocomplete-item-remove"
                onClick={() => this.onElementDelete(channel)}
              />
            </div>
          ))}

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3em" }}>
            <ValidateButton text={i18n.t("actions.validate")} type="button" onClick={() => onSubmit(selected)} />
          </div>
        </div>
      </Sidebar>
    )
  }
}

export default ChannelsSidebar
