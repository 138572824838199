import Devices from "features/Devices/List"
import Device from "features/Devices/One"

const devicesRoutes = [
  {
    name: "menu.categories.devices",
    elements: [
      {
        path: "/devices",
        exact: true,
        component: Devices,
        children: []
      },
      {
        path: "/devices/:id",
        exact: true,
        component: Device,
        children: []
      }
    ]
  }
]

export default devicesRoutes
