import React from "react"
import { Button } from "reactstrap"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"

const MiniPageTitle = ({ heading, backButton = null, icon = null }) => {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          {icon !== null && (
            <div className="mini-page-title-icon">
              <i className={icon} />
            </div>
          )}
          {backButton !== null && (
            <Link to={backButton}>
              <Button className="btn-icon" color="light" outline size="md">
                <i className="pe-7s-left-arrow btn-icon-wrapper"> </i>
                {i18n.t("menu.backButton")}
              </Button>
            </Link>
          )}

          <div style={{ marginLeft: "1em" }}>{heading}</div>
        </div>
      </div>
    </div>
  )
}

export default MiniPageTitle
