import React from "react"
import { Progress } from "reactstrap"
import i18n from "utils/i18n"

const UploadProgressBar = ({ percentage }) => {
  return (
    <div className="dropzone-video">
      <div className="dropzone-text">
        {i18n.t("videos.uploadInProgress")}
        <br />
        <br />
        <Progress color="upload-progress-bar-color" className="upload-progress-bar" value={percentage}>
          {percentage}%
        </Progress>
      </div>
    </div>
  )
}

export default UploadProgressBar
