import React, { Fragment } from "react"
import { Field } from "formik"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import { Row, Col } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import i18n from "utils/i18n"
import _ from "lodash"
import MainContainer from "components/PageStructure/MainContainer"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import ClassicInput from "components/Form/ClassicInput"
import CancelButton from "components/Buttons/CancelButton"
import ColorPicker from "components/Form/ColorPicker"

const index = ({ errors, loading, onCancel }) => {
  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <FormSaveButton
            isLoading={loading}
            text={i18n.t("actions.save")}
            type="submit"
            disabled={!_.isEmpty(errors)}
          />
          <CancelButton onClick={() => onCancel()} style={{ marginLeft: 5 }} />
        </Col>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <Col xs={6}></Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <MainContainer withBorder>
            <Row>
              <Col xs={12} md={4}>
                <Field
                  type="text"
                  name="name"
                  label={i18n.t("entities.attributes.name")}
                  component={ClassicInput}
                  required
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  component={SelectAutoComplete}
                  required
                  name="type"
                  label={i18n.t("entities.attributes.type")}
                  placeholder={i18n.t("form.actions.chooseGroupType")}
                  options={generateMultiSelectOptions(
                    [
                      { value: "VIDEO", name: i18n.t("entities.plural.video") },
                      { value: "WEBCONTENT", name: i18n.t("entities.plural.webContent") }
                    ],
                    "value",
                    "name"
                  )}
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  component={ColorPicker}
                  required
                  editable
                  name="color"
                  label={i18n.t("entities.attributes.color")}
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
      </Row>
    </Fragment>
  )
}

export default index
