/* eslint-disable no-loop-func */
import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import ListContainer from "components/List/ListContainer"
import { apiGetDevices, apiSetDeviceOn, apiSetDeviceOff, apiClearDeviceStorage } from "services/devices"

class DevicesList extends Component {
  componentDidMount() {}

  setDeviceOn = id => {
    this.props.apiSetDeviceOn(id)
  }

  setDeviceOff = id => {
    this.props.apiSetDeviceOff(id)
  }

  clearDeviceStorage = id => {
    this.props.apiClearDeviceStorage(id)
  }

  // À refaire totalement
  findSubOrgasIds(id, _orgas, acc = []) {
    if (_orgas.length === 0) return []
    const result = acc
    result.push(id)

    const subOrgas = _orgas.filter(i => i.superOrgaId === id).map(i => i.id)

    if (subOrgas.length > 0) {
      subOrgas.forEach(subOrga => {
        const subOrgaResult = this.findSubOrgasIds(subOrga, _orgas, result)

        result.concat(subOrgaResult)
      })
    }

    result.concat(subOrgas)

    return result
  }

  applyListFilter = list => {
    const { listFilter } = this.props

    if (listFilter.orga !== null && listFilter.group === null) {
      // Display devices from the orga and its sub orgas
      const subOrgasIds = this.findSubOrgasIds(listFilter.orga, this.props.organizations.list.data)
      const orgasToDisplay = [listFilter.orga, ...subOrgasIds]

      return list.filter(i => orgasToDisplay.includes(i.owner.id))
    }

    if (listFilter.group !== null) return list.filter(i => i.group !== null && i.group.id === listFilter.group)

    return list
  }

  render() {
    const { devices, openQuickAccess, refreshing } = this.props

    return (
      <Fragment>
        <ListContainer
          type="DEVICE"
          lineHeight="lg"
          elements={{ data: this.applyListFilter(devices.list.data) }}
          sortField="name"
          filtersOn
          loading={devices.list.loading}
          handlers={{
            setDeviceOn: this.setDeviceOn,
            setDeviceOff: this.setDeviceOff,
            clearDeviceStorage: this.clearDeviceStorage,
            refreshing,
            openQuickAccess
          }}
          filters={{ search: ["name"], select: [], selectSingle: ["status"] }}
          defaultLayout="grid"
          multipleLayouts
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    devices: state.devices,
    organizations: state.organizations,
    myOrganization: state.organizations.me.data,
    me: state.users.me.data
  }
}

const mapDispatchToProps = {
  apiGetDevices,
  apiSetDeviceOn,
  apiSetDeviceOff,
  apiClearDeviceStorage
}

export default connect(mapStateToProps, mapDispatchToProps)(DevicesList)
