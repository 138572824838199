/* eslint-disable react/jsx-closing-tag-location */
import React, { Fragment } from "react"
import TreeView from "react-treeview"
import i18n from "utils/i18n"
import { Link, withRouter } from "react-router-dom"
import { Dropdown } from "semantic-ui-react"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"

class DeviceGroupsTree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: this.props.deviceGroup.id !== "-1",
      onEdit: null,
      editValue: props.deviceGroup.name
    }
  }

  handleClick = groupId => {
    this.props.changeListFilter(this.props.orga.id, groupId)
    this.setState(prevState => ({ collapsed: !prevState.collapsed }))
  }

  toggleDeviceGroupEdit = () => {
    this.setState(prevState => ({ onEdit: !prevState.onEdit }))
  }

  onEditValueChange = event => {
    this.setState({ editValue: event.target.value })
  }

  onDeviceGroupUpdate = () => {
    const data = _.clone(this.props.deviceGroup)

    if (this.state.editValue !== data.name) {
      data.name = this.state.editValue
      this.props.onDeviceGroupUpdate(data)
    }

    this.toggleDeviceGroupEdit()
  }

  render() {
    const { deviceGroup, onDeviceGroupDelete, listFilter, changeListFilter } = this.props
    const { collapsed, onEdit, editValue } = this.state

    const label = (
      <Fragment>
        {!onEdit && (
          <span className="node-deviceGroup" onClick={() => this.handleClick(deviceGroup.id)}>
            {deviceGroup.id !== "-1" && (
              <Fragment>
                <i
                  className="pe-7s-folder btn-icon-wrapper deviceGroup-icon"
                  style={{ marginRight: "0.5em", color: "#9a9a9a", fontSize: "1.6em" }}
                ></i>{" "}
                <Dropdown
                  className="deviceGroup-handler"
                  icon={null}
                  floating
                  direction="right"
                  trigger={
                    <i
                      className="pe-7s-more btn-icon-wrapper"
                      style={{ marginRight: "0.5em", color: "#777777", fontSize: "1.6em" }}
                    ></i>
                  }
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="deviceGroup-handler-item"
                      text="Renommer"
                      onClick={() => this.toggleDeviceGroupEdit()}
                    />
                    {deviceGroup.devices.length === 0 && (
                      <Dropdown.Item
                        className="deviceGroup-handler-item"
                        text="Supprimer"
                        onClick={() => onDeviceGroupDelete(deviceGroup.id)}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {deviceGroup.name}
              </Fragment>
            )}
          </span>
        )}
        {onEdit && (
          <span className="node-deviceGroup" style={{ display: "flex", justifyContent: "space-between" }}>
            <input type="text" className="deviceGroup-new-input" value={editValue} onChange={this.onEditValueChange} />{" "}
            <FormSaveButton
              type="button"
              disabled={false}
              onClick={() => {
                if (editValue !== null && editValue !== "") this.onDeviceGroupUpdate()
                else this.toggleDeviceGroupEdit()
              }}
              text={editValue === "" ? "X" : "OK"}
            />
          </span>
        )}
      </Fragment>
    )
    const focusID = this.props.focus !== null && this.props.focus.id

    return (
      <TreeView
        key={`deviceGroup-${deviceGroup.id}`}
        nodeLabel={label}
        collapsed={collapsed}
        itemClassName={`tree-view_item deviceGroup-tree ${deviceGroup.id === "-1" ? "hidden" : ""} ${
          listFilter.group === deviceGroup.id ? "active" : ""
        } `}
        onClick={() => this.handleClick(deviceGroup.id)}
      >
        {deviceGroup.devices.length > 0 &&
          deviceGroup.devices.map(device => (
            <Link to={`/devices/${device.id}`} onClick={() => changeListFilter(null, null)} key={`device-${device.id}`}>
              <div className={`device-view-item ${focusID === device.id ? "active" : undefined}`}>
                <i
                  className="pe-7s-airplay btn-icon-wrapper"
                  style={{ marginRight: "0.5em", color: focusID === device.id ? "#fff" : "#3d4f60" }}
                  // eslint-disable-next-line react/jsx-closing-tag-location
                ></i>
                {device.name}
              </div>
            </Link>
          ))}
        {deviceGroup.devices.length === 0 && (
          <div className="device-view-empty">
            <i>{i18n.t("devices.none")}</i>
          </div>
        )}
      </TreeView>
    )
  }
}

export default withRouter(DeviceGroupsTree)
