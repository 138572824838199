import React, { Fragment } from "react"
import { Col, Row } from "reactstrap"
import i18n from "utils/i18n"
import CollapsibleScreenTimer from "./Collapsible"

const ItemLine = ({ element, onSave, collapseElt, isCollapsed, sourceOptions, index }) => {
  const _days = [
    element.monday ? i18n.t("devices.daysShort.monday") : null,
    element.tuesday ? i18n.t("devices.daysShort.tuesday") : null,
    element.wednesday ? i18n.t("devices.daysShort.wednesday") : null,
    element.thursday ? i18n.t("devices.daysShort.thursday") : null,
    element.friday ? i18n.t("devices.daysShort.friday") : null,
    element.saturday ? i18n.t("devices.daysShort.saturday") : null,
    element.sunday ? i18n.t("devices.daysShort.sunday") : null
  ].filter(i => i !== null)

  return (
    <Fragment>
      <div className="line-element-container cursor-pointer md" onClick={() => collapseElt(index)}>
        <Row className="line-element-row">
          <Col xs={1}>
            <div className={`screenTimer-title text-ellipsis ${isCollapsed ? "active" : ""}`}>
              {i18n.t("devices.planning.screenTimer")} {index}
            </div>
          </Col>
          <Col xs={3}>
            <div className="line-element-info">
              {element.status && <div className="content">{_days.join(", ")}</div>}
              {!element.status && <div className="content">-</div>}
            </div>
          </Col>
          <Col xs={2}>
            <div className="line-element-info">
              {element.status && (
                <div className="content">
                  {element.startHour} - {element.endHour}
                </div>
              )}
              {!element.status && <div className="content">-</div>}
            </div>
          </Col>
          <Col xs={2}>
            <div className="line-element-info">
              <div className="label">{i18n.t("devices.attributes.source")}</div>
              {element.status && <div className="content">{element.source}</div>}
              {!element.status && <div className="content">-</div>}
            </div>
          </Col>
          <Col xs={2}>
            <div className="line-element-info">
              <div className="label">{i18n.t("devices.attributes.volume")}</div>
              {element.status && <div className="content">{element.volume}</div>}
              {!element.status && <div className="content">-</div>}
            </div>
          </Col>
        </Row>
      </div>

      <div className={`line-element-content ${isCollapsed ? "collapsed" : ""}`}>
        <CollapsibleScreenTimer element={element} onSave={onSave} sourceOptions={sourceOptions} />
      </div>
    </Fragment>
  )
}

export default ItemLine
