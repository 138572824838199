export const API_GET_ALL_CHANNEL = "API_GET_ALL_CHANNEL"
export const API_GET_ONE_CHANNEL = "API_GET_ONE_CHANNEL"
export const API_POST_CHANNEL = "API_POST_CHANNEL"
export const API_PATCH_CHANNEL = "API_PATCH_CHANNEL"
export const API_DELETE_CHANNEL = "API_DELETE_CHANNEL"
export const GET_ONE_CHANNEL = "GET_ONE_CHANNEL"
export const RESET_FOCUS_CHANNEL = "RESET_FOCUS_CHANNEL"
export const INIT_CHANNEL = "INIT_CHANNEL"
export const API_UPLOAD_IMAGE_CHANNEL = "API_UPLOAD_IMAGE_CHANNEL"
export const API_DELETE_IMAGE_CHANNEL = "API_DELETE_IMAGE_CHANNEL"
