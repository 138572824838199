import * as React from "react"

function PepstreamLogoHorizontalWhite(props) {
  return (
    <svg data-name="Calque 1" viewBox="0 0 591 250.61" {...props}>
      <defs>
        <linearGradient id="prefix__a" x1={2} y1={214.96} x2={593} y2={214.96} gradientUnits="userSpaceOnUse">
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient id="prefix__c" x1={274.04} y1={69.67} x2={317.31} y2={69.67} xlinkHref="#prefix__a" />
        <linearGradient id="prefix__b" x1={268.44} y1={36.24} x2={355.54} y2={36.24} gradientUnits="userSpaceOnUse">
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.63} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient id="prefix__d" x1={268.44} y1={103.15} x2={355.54} y2={103.15} xlinkHref="#prefix__b" />
        <linearGradient id="prefix__e" x1={239.46} y1={69.68} x2={268.44} y2={69.68} gradientUnits="userSpaceOnUse">
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
      </defs>
      <path
        d="M277 180.88h-26.64v-3.31h57v3.31H280.6v71.5H277zm97.85 71.5h-4.6l-24.36-32h-26.5v32h-3.64v-74.81h30.89c9.51 0 16.77 2.67 21.37 7.16a19.64 19.64 0 015.45 13.89v.22c0 12.61-9.73 20-23.51 21.16zm-5-53.33v-.21c0-11.22-8.65-18-23.4-18h-27v36.23h26.28c15.27.04 24.06-7.44 24.06-18.02zM516 252.38h-4l-10.15-21.81h-46.24l-10.25 21.81h-3.74L477.09 177h3.42zm-15.71-25.12l-21.59-45.95-21.48 45.95zM523 252.38h3.53v-68.5l31.31 44.88h.22l31.31-44.88v68.5H593v-74.81h-3.42l-31.52 45.52-31.64-45.52H523zm-96.9-39.2h-42.36v3.32h42.36zm8.41-35.74h-50.77v3.31h50.77zm.54 71.49h-51.31v3.31h51.31zm-403.6-71.31c17.39 0 28.38 9.92 28.38 25v.21c0 16.75-13.44 25.5-29.87 25.5H15.12v24H2v-74.71h29.45M46.5 202.9c0-8.74-6.3-13.33-16.12-13.33H15.12v26.89h15.26c9.93 0 16.12-5.55 16.12-13.34zm63.84 5.87H68v11.65h42.36zm8.31-31.15H68v11.73h50.67zm.54 62.95H68v11.74h51.21zm39.73-62.95c17.39 0 28.38 9.92 28.38 25v.21c0 16.75-13.45 25.5-29.88 25.5h-14.83v24h-13.12v-74.71h29.45m15 25.28c0-8.74-6.29-13.33-16.11-13.33h-15.22v26.89h15.26c9.92 0 16.11-5.55 16.11-13.34zm15.95 38.52a46 46 0 0031.16 12c15.68 0 26.67-8.32 26.67-22.3v-.21c0-12.38-8.22-18-24-21.87-14.3-3.42-17.71-6-17.71-11.74v-.3c0-4.91 4.48-8.86 12.16-8.86 6.83 0 13.56 2.67 20.28 7.68l7-9.92a41.42 41.42 0 00-27.1-9.39c-14.83 0-25.5 8.86-25.5 21.66v.21c0 13.77 9 18.46 24.86 22.3 13.87 3.2 16.86 6.09 16.86 11.42v.21c0 5.66-5.13 9.39-13.23 9.39-9.29 0-16.44-3.52-23.59-9.71l-7.89 9.39"
        transform="translate(-2 -2.77)"
        fill="url(#prefix__a)"
      />
      <path
        d="M349.4 76.91l-16.93-10-11.26 6.63c-.26.17-.51.35-.79.5l-54 31.76v19.68a7.92 7.92 0 004.15 7.25 9.19 9.19 0 004.14 1 8 8 0 004.15-1.16l70.54-41.4a8.21 8.21 0 000-14.26z"
        opacity={0.8}
        fill="#fff"
      />
      <path
        d="M321.11 60.23l11.36 6.68 16.93-9.95a7.67 7.67 0 004.14-7.13 7.65 7.65 0 00-4.14-7.13L278.88 1.23a7.78 7.78 0 00-8.29-.13 7.93 7.93 0 00-4.15 7.26v19.72l54 31.75c.22.14.44.29.67.4z"
        opacity={0.5}
        fill="#fff"
      />
      <path
        d="M266.44 42.56V28.08l-16.54-9.73a7.76 7.76 0 00-8.29-.13 7.93 7.93 0 00-4.15 7.26v83a7.93 7.93 0 004.15 7.26 9.19 9.19 0 004.14 1 7.93 7.93 0 004.15-1.17l16.54-9.72V42.56z"
        opacity={0.7}
        fill="#fff"
      />
      <path
        d="M317.31 69.71a5.65 5.65 0 00-2.57-5L283.3 46.24a5.81 5.81 0 00-6.18-.1 5.92 5.92 0 00-3.12 5.41v36.32a5.92 5.92 0 003.08 5.41 6.92 6.92 0 003.09.77 5.92 5.92 0 003.09-.87l31.51-18.54a5.62 5.62 0 002.54-4.93z"
        transform="translate(-2 -2.77)"
        fill="url(#prefix__c)"
      />
      <path
        d="M323.11 63l11.36 6.68 16.93-9.95a7.67 7.67 0 004.14-7.13 7.65 7.65 0 00-4.14-7.13L280.88 4a7.78 7.78 0 00-8.29-.13 7.93 7.93 0 00-4.15 7.26v19.72l54 31.75c.22.14.44.29.67.4z"
        transform="translate(-2 -2.77)"
        fill="url(#prefix__b)"
      />
      <path
        d="M351.4 79.68l-16.93-10-11.26 6.63c-.26.17-.51.35-.79.5l-54 31.76v19.68a7.92 7.92 0 004.15 7.25 9.19 9.19 0 004.14 1 8 8 0 004.15-1.16l70.54-41.4a8.21 8.21 0 000-14.26z"
        transform="translate(-2 -2.77)"
        fill="url(#prefix__d)"
      />
      <path
        d="M268.44 45.33V30.85l-16.54-9.73a7.76 7.76 0 00-8.29-.13 7.93 7.93 0 00-4.15 7.26v83a7.93 7.93 0 004.15 7.26 9.19 9.19 0 004.14 1 7.93 7.93 0 004.15-1.17l16.54-9.72V45.33z"
        transform="translate(-2 -2.77)"
        fill="url(#prefix__e)"
      />
    </svg>
  )
}

const MemoPepstreamLogoHorizontal = React.memo(PepstreamLogoHorizontalWhite)
export default MemoPepstreamLogoHorizontal
