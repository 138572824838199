import React, { Fragment, Component } from "react"
import i18n from "utils/i18n"
import ListLoader from "components/List/ListLoader"
import LineItemScreenTimer from "./Items/LineItem"

class DevicePlanning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapseId: null
    }
  }

  collapseElt = collapseId => {
    this.setState(prevState => ({ collapseId: prevState.collapseId === collapseId ? null : collapseId }))
  }

  onTimerSave = (index, values) => {
    this.props.handlers.onSave(index, values)
    this.setState({ collapseId: null })
  }

  render() {
    const { device, loading } = this.props
    const { collapseId } = this.state
    let sourceOptions = []

    if (device.magicInfoId === null)
      return <div className="list-no-result">{i18n.t("devices.magicInfoPairingNeeded")}</div>

    if (device.time !== undefined && device.time !== null) {
      sourceOptions = device.time.inputSourceList.map(i => ({ value: i.text, label: i.text }))
    }

    return (
      <Fragment>
        {loading && <ListLoader />}

        {!loading && device.time !== null && (
          <Fragment>
            <LineItemScreenTimer
              key="screenTimer-1"
              element={device.time.timerConfTimer1}
              onSave={values => this.onTimerSave(1, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 1}
              sourceOptions={sourceOptions}
              index={1}
            />

            <LineItemScreenTimer
              key="screenTimer-2"
              element={device.time.timerConfTimer2}
              onSave={values => this.onTimerSave(2, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 2}
              sourceOptions={sourceOptions}
              index={2}
            />

            <LineItemScreenTimer
              key="screenTimer-3"
              element={device.time.timerConfTimer3}
              onSave={values => this.onTimerSave(3, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 3}
              sourceOptions={sourceOptions}
              index={3}
            />

            <LineItemScreenTimer
              key="screenTimer-4"
              element={device.time.timerConfTimer4}
              onSave={values => this.onTimerSave(4, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 4}
              sourceOptions={sourceOptions}
              index={4}
            />

            <LineItemScreenTimer
              key="screenTimer-5"
              element={device.time.timerConfTimer5}
              onSave={values => this.onTimerSave(5, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 5}
              sourceOptions={sourceOptions}
              index={5}
            />

            <LineItemScreenTimer
              key="screenTimer-6"
              element={device.time.timerConfTimer6}
              onSave={values => this.onTimerSave(6, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 6}
              sourceOptions={sourceOptions}
              index={6}
            />

            <LineItemScreenTimer
              key="screenTimer-7"
              element={device.time.timerConfTimer7}
              onSave={values => this.onTimerSave(7, values)}
              collapseElt={this.collapseElt}
              isCollapsed={collapseId === 7}
              sourceOptions={sourceOptions}
              index={7}
            />
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default DevicePlanning
