import React, { Component } from "react"
import { TwitterPicker } from "react-color"

class ColorPicker extends Component {
  state = {
    displayColorPicker: false
  }

  onColorChange = color => {
    this.setState({ displayColorPicker: false })
    this.props.form.setFieldValue(this.props.field.name, color.hex)
  }

  render() {
    const { field, form, label, editable } = this.props
    const { name, value } = field
    const { errors, touched } = form
    const { displayColorPicker } = this.state

    return (
      <div className="form-input-container">
        <div className="form-label">
          {label !== null && <span>{label}</span>}
          {label !== null && <span className="form-cross-required">*</span>}
        </div>

        <div
          className="color-picker-result"
          style={{
            backgroundColor: value
          }}
          onClick={() => {
            if (editable) this.setState({ displayColorPicker: true })
          }}
        />
        {displayColorPicker && (
          <div className="colorpicker-popover">
            <div onClick={() => this.setState({ displayColorPicker: false })} className="colorpicker-cover" />
            <TwitterPicker color={value} onChange={this.onColorChange} />
          </div>
        )}

        {touched[name] && errors[name] && <div className="form-error-style">{errors[name]}</div>}
      </div>
    )
  }
}

export default ColorPicker
