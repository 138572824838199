import React, { Fragment } from "react"
import i18n from "utils/i18n"

const DroppableContentLabel = ({ content, className = "", style = {} }) => {
  return (
    <Fragment>
      {content.type === "VIDEO" && (
        <div className={`droppable-content-label video ${className}`} style={{ ...style }}>
          {i18n.t("entities.singular.video")}
        </div>
      )}
      {content.type === "WEBCONTENT" && (
        <div className={`droppable-content-label webContent ${className}`} style={{ ...style }}>
          {i18n.t("entities.singular.webContent")}
        </div>
      )}
      {content.type === "PLAYLIST" && (
        <div className={`droppable-content-label playlist ${className}`} style={{ ...style }}>
          {i18n.t("entities.singular.playlist")}
        </div>
      )}
    </Fragment>
  )
}

export default DroppableContentLabel
