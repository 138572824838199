import React from "react"
import { Checkbox as SemCheckbox } from "semantic-ui-react"

// Checkbox input
const SharedSwitch = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  label = null,
  editable = true,
  style = {}
}) => {
  return (
    <div className="form-switch-sharedswitch" style={{ ...style }}>
      <div className="label-switch">{label !== null && <span>{label}</span>}</div>

      <SemCheckbox
        toggle
        disabled={!editable}
        name={name}
        type="checkbox"
        id={name}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className="sharedSwitch"
      />

      {touched[name] && errors[name] && <div className="form-error-style">{errors[name]}</div>}
    </div>
  )
}

export default SharedSwitch
