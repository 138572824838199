import React, { Fragment } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import i18n from "utils/i18n"
import { Row, Col, Button } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import MainContainer from "components/PageStructure/MainContainer"
import _ from "lodash"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SharedSwitch from "components/Form/SharedSwitch"

const CustomForm = ({
  organizations,
  userCanEdit,
  myOrganization,
  element,
  errors,
  loading,
  isNew,
  openConfirmDeleteModalOpen,
  openDevicesSidebar,
  openChannelsSidebar
}) => {
  // const getOrganizationFromList = organization => {
  //   return organizations.list.data.find(i => i.id === organization.value)
  // }

  return (
    <Fragment>
      {userCanEdit && (
        <Row>
          <Col xs={6}>
            <FormSaveButton
              isLoading={loading}
              text={i18n.t("actions.save")}
              type="submit"
              disabled={!_.isEmpty(errors)}
            />
          </Col>
          <Col xs={6}>
            {!isNew && (
              <FormDeleteButton
                text={i18n.t("actions.delete")}
                disabled={false}
                type="button"
                className="form-delete-button-right"
                onClick={() => openConfirmDeleteModalOpen()}
              />
            )}
          </Col>
        </Row>
      )}
      <MainContainer title={i18n.t("programs.pageTitle.owner")}>
        <Row>
          <Col xs={6}>
            {isNew && (
              <Field
                component={SelectAutoComplete}
                required
                disabled={!isNew}
                name="owner"
                placeholder={i18n.t("form.actions.chooseOrganization")}
                options={generateMultiSelectOptions(
                  [
                    { id: myOrganization.id, name: `${myOrganization.name} (${i18n.t("form.me")})` },
                    ...organizations.list.data.filter(e => e.id !== myOrganization.id)
                  ],
                  "id",
                  "name"
                )}
              />
            )}
            {!isNew && <OrganizationWithLogoDisplayer organization={element.owner} />}
          </Col>
          <Col xs={6} style={{ display: "flex" }}>
            <Field
              component={SharedSwitch}
              editable={userCanEdit}
              name="shared"
              style={{ marginLeft: 5, position: "relative" }}
              label={i18n.t("entities.attributes.sharing")}
              required
            />
          </Col>
        </Row>
      </MainContainer>
      <Row>
        <Col xs={6}>
          <MainContainer title={i18n.t("programs.pageTitle.informations")}>
            <Row>
              <Col xs={6}>
                <Field
                  type="text"
                  name="title"
                  editable={userCanEdit}
                  label={i18n.t("entities.attributes.title")}
                  component={ClassicInput}
                  required
                />
              </Col>
              <Col xs={6}>
                <Field
                  component={SelectAutoComplete}
                  label={i18n.t("entities.singular.group")}
                  required={false}
                  editable
                  name="groups"
                  placeholder={i18n.t("form.actions.chooseGroup")}
                  options={[{ value: "", label: "Aucun" }].concat(
                    generateMultiSelectOptions(myOrganization.localGroups, "id", "name", "PROGRAM")
                  )}
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
        <Col xs={6}>
          {!isNew && (
            <MainContainer title={i18n.t("programs.pageTitle.publication")}>
              <Row>
                <Col xs={6}>
                  <Button
                    className="btn-icon program-publication-button"
                    color="primary"
                    size="md"
                    outline
                    onClick={() => {
                      if (userCanEdit) openDevicesSidebar()
                    }}
                    type="button"
                  >
                    <i className="pe-7s-monitor btn-icon-wrapper" /> Écrans &nbsp; <b>{element.devices.length}</b>
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    className="btn-icon program-publication-button"
                    color="primary"
                    size="md"
                    outline
                    onClick={() => {
                      if (userCanEdit) openChannelsSidebar()
                    }}
                    type="button"
                  >
                    <i className="pe-7s-airplay btn-icon-wrapper" /> Chaînes &nbsp; <b>{element.channels.length}</b>
                  </Button>
                </Col>
              </Row>
            </MainContainer>
          )}
        </Col>
      </Row>
    </Fragment>
  )
}

export default CustomForm
