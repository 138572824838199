import routes from "./routes"
import devicesRoutes from "./devicesRoutes"

export const getRoutingConfig = () => {
  const items = []

  routes.forEach(category => {
    if (category.elements.length !== 0) {
      category.elements.forEach(elt => {
        if (elt.component !== undefined) {
          items.push({
            path: elt.path,
            exact: elt.exact,
            component: elt.component,
            icon: elt.icon,
            inSidebar: elt.inSidebar,
            label: elt.label,
            isOwnerOnly: elt.isOwnerOnly || category.isOwnerOnly,
            authorizedRoles: elt.authorizedRoles || category.authorizedRoles
          })
        }

        if (elt.children.length !== 0) {
          elt.children.forEach(subelt => {
            if (subelt.component !== undefined) {
              items.push({
                path: subelt.path,
                exact: subelt.exact,
                component: subelt.component,
                icon: subelt.icon,
                inSidebar: subelt.inSidebar,
                label: subelt.label,
                isOwnerOnly: subelt.isOwnerOnly || elt.isOwnerOnly || category.isOwnerOnly,
                authorizedRoles: subelt.authorizedRoles || elt.authorizedRoles || category.authorizedRoles
              })
            }
          })
        }
      })
    }
  })
  return items
}

export const getDevicesRoutingConfig = () => {
  const items = []

  devicesRoutes.forEach(category => {
    if (category.elements.length !== 0) {
      category.elements.forEach(elt => {
        if (elt.component !== undefined) {
          items.push({
            path: elt.path,
            exact: elt.exact,
            component: elt.component,
            icon: elt.icon,
            inSidebar: elt.inSidebar,
            label: elt.label
          })
        }

        if (elt.children.length !== 0) {
          elt.children.forEach(subelt => {
            if (subelt.component !== undefined) {
              items.push({
                path: subelt.path,
                exact: subelt.exact,
                component: subelt.component,
                icon: subelt.icon,
                inSidebar: subelt.inSidebar,
                label: subelt.label
              })
            }
          })
        }
      })
    }
  })
  return items
}

export const getSidebarConfig = () => routes
