import i18n from "i18n-js"
import moment from "moment"

export const supportedLanguagues = ["gb", "fr"]

/* eslint-disable */
i18n.translations = supportedLanguagues.reduce((parent, child) => {
  parent[child] = require(`./${child}`).default
  return parent
}, {})
/* eslint-enable */

i18n.fallbacks = true
i18n.locales.no = "en"

i18n.t = (scope, options) => {
  const opts = options || { count: 0 }
  const locale = (() => {
    if (window.localStorage.getItem("locale") != null) {
      const language = window.localStorage.getItem("locale")
      return language
    }
    return "fr"
  })()
  // @ts-ignore
  return i18n.translate(scope, { locale, ...opts })
}

i18n.createProvider = (prefix, suffix) => (key, params) =>
  i18n.t(`${prefix}.${key}${suffix ? `.${suffix}` : ""}`, params)

i18n.labelize = (scope, params) => {
  const translation = i18n.t(`attributes.${scope}`, params)
  return `${translation}:`
}

i18n.timeFormat = date => {
  const locale = (() => {
    if (window.localStorage.getItem("locale") != null) {
      const language = window.localStorage.getItem("locale")
      return language
    }
    return "fr"
  })()

  return i18n.translations[locale].timeUtils(date, moment)
}

export default i18n
