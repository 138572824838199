import * as React from "react"

function ScreenOne(props) {
  return (
    <svg
      id="prefix__Calque_1"
      x={0}
      y={0}
      viewBox="0 0 150 85"
      xmlSpace="preserve"
      style={{ width: "100%", height: "100%", cursor: "pointer" }}
      className={props.active ? "screen-display-active" : ""}
    >
      <style>{".prefix__st1{fill:#fff}"}</style>
      <path fill="#e8e8e8" d="M0 0h150v85H0z" />
      <path
        className="prefix__st1"
        d="M47.5 30.5c1.1 0 2-.9 2-2v-8.7l13.1 13.1c.4.4.9.6 1.4.6s1-.2 1.4-.6c.8-.8.8-2.1 0-2.9l-13-13.1h8.7c1.1 0 2-.9 2-2s-.9-2-2-2H47.5c-1.1 0-2 .9-2 2v13.6c0 1.1.9 2 2 2zM101.9 53.6c-1.1 0-2 .9-2 2v8.7L86.4 50.8c-.8-.8-2.1-.8-2.9 0s-.8 2.1 0 2.9L97 67.2h-8.7c-1.1 0-2 .9-2 2s.9 2 2 2h13.6c1.1 0 2-.9 2-2V55.7c.1-1.2-.8-2.1-2-2.1zM63.1 50.8L49.5 64.3v-8.7c0-1.1-.9-2-2-2s-2 .9-2 2v13.6c0 1.1.9 2 2 2h13.6c1.1 0 2-.9 2-2s-.9-2-2-2h-8.7L66 53.7c.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0zM101.9 12.8H88.3c-1.1 0-2 .9-2 2s.9 2 2 2H97L83.9 30c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.4.6s1-.2 1.4-.6l13.2-13.1v8.7c0 1.1.9 2 2 2s2-.9 2-2V14.8c.1-1.1-.8-2-2-2z"
      />
      <g>
        <path d="M21.6 37.6h4.9v1.5h-3.1v1.8h2.9v1.5h-2.9v3.2h-1.8v-8zM29.7 37.6v4.7c0 1.4.5 2.1 1.5 2.1s1.5-.7 1.5-2.1v-4.7h1.8v4.5c0 2.5-1.3 3.7-3.4 3.7-2 0-3.2-1.1-3.2-3.7v-4.5h1.8zM36.1 37.6h1.8v6.5h3.2v1.5h-5v-8zM42.2 37.6H44v6.5h3.2v1.5h-5v-8zM50.8 43.8c.5.3 1.2.5 2 .5s1.3-.3 1.3-.9c0-.5-.4-.8-1.4-1.1-1.3-.5-2.2-1.2-2.2-2.4 0-1.4 1.2-2.4 3.1-2.4.9 0 1.6.2 2.1.4l-.4 1.5c-.3-.2-.9-.4-1.7-.4s-1.2.4-1.2.8c0 .5.5.7 1.5 1.1 1.4.5 2.1 1.3 2.1 2.4 0 1.4-1 2.5-3.3 2.5-.9 0-1.8-.2-2.3-.5l.4-1.5zM63.2 45.5c-.3.2-1.1.3-2.1.3-2.8 0-4.2-1.7-4.2-4 0-2.8 2-4.3 4.4-4.3.9 0 1.7.2 2 .4l-.4 1.5c-.4-.2-.9-.3-1.5-.3-1.5 0-2.6.9-2.6 2.7 0 1.6 1 2.6 2.6 2.6.6 0 1.2-.1 1.5-.3l.3 1.4zM64.4 37.7c.6-.1 1.5-.2 2.4-.2 1.2 0 2 .2 2.6.6.5.4.7.9.7 1.7 0 1-.7 1.7-1.4 2 .6.2.9.8 1.1 1.5.3.9.5 2 .7 2.3h-1.9c-.1-.2-.3-.9-.6-1.9-.2-1-.6-1.3-1.3-1.3h-.6v3.2h-1.8v-7.9h.1zm1.8 3.5h.7c.9 0 1.5-.5 1.5-1.2s-.5-1.1-1.3-1.1c-.4 0-.7 0-.8.1l-.1 2.2zM76.3 42.3h-3v1.9h3.3v1.5h-5.2v-8.1h5v1.5h-3.2v1.7h3l.1 1.5zM82.7 42.3h-3v1.9H83v1.5h-5.2v-8.1h5v1.5h-3.2v1.7h3l.1 1.5zM84.3 45.7v-8.1h2.1l1.7 3c.5.9 1 1.9 1.3 2.8-.1-1.1-.2-2.2-.2-3.4v-2.4H91v8.1h-1.9l-1.7-3.1c-.5-.9-1-1.9-1.4-2.9 0 1.1.1 2.2.1 3.5v2.4l-1.8.1zM96.5 45.7l-2.6-8.1h2l1 3.4c.3 1 .5 1.9.7 2.9.2-1 .5-1.9.7-2.9l1-3.5h1.9l-2.7 8.1-2 .1zM104.1 37.6v8.1h-1.8v-8.1h1.8zM105.6 37.7c.7-.1 1.5-.2 2.5-.2 1.5 0 2.5.3 3.3.9.8.6 1.4 1.6 1.4 3 0 1.5-.6 2.6-1.3 3.3-.9.7-2.1 1-3.7 1-.9 0-1.6-.1-2.1-.1v-7.9h-.1zm1.9 6.6h.6c1.7 0 2.7-.9 2.7-2.8 0-1.7-1-2.6-2.5-2.6-.4 0-.7 0-.8.1v5.3zM118.8 42.3h-3v1.9h3.3v1.5H114v-8.1h5v1.5h-3.2v1.7h3v1.5zM127.8 41.6c0 2.7-1.6 4.3-4 4.3s-3.8-1.8-3.8-4.1c0-2.4 1.5-4.2 3.9-4.2 2.5-.1 3.9 1.7 3.9 4zm-5.8.1c0 1.6.7 2.7 2 2.7 1.2 0 1.9-1.2 1.9-2.7s-.7-2.7-2-2.7c-1.2-.1-1.9 1.1-1.9 2.7z" />
      </g>
    </svg>
  )
}

const MemoScreenFive = React.memo(ScreenOne)
export default MemoScreenFive
