import { toFailure, toSuccess } from "actions"
import { API_GET_ALL_CATEGORY, API_POST_CATEGORY, API_PATCH_CATEGORY, API_DELETE_CATEGORY } from "actions/categories"
import { createReducer } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import i18n from "utils/i18n"

const initialState = {
  list: {
    loading: false,
    error: false,
    data: []
  }
}

const categories = createReducer(initialState, {
  [API_GET_ALL_CATEGORY]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_CATEGORY)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_CATEGORY)]: state => {
    state.list.loading = false
    state.list.error = true
  },
  [API_POST_CATEGORY]: state => {
    state.list.loading = true
  },
  [toSuccess(API_POST_CATEGORY)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data.push(action.payload.data)

    toast.success(i18n.t("categories.api.created"))
  },
  [toFailure(API_POST_CATEGORY)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_PATCH_CATEGORY]: state => {
    state.list.loading = true
  },
  [toSuccess(API_PATCH_CATEGORY)]: (state, action) => {
    state.list.loading = false
    state.list.error = false

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("categories.api.patched"))
  },
  [toFailure(API_PATCH_CATEGORY)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_DELETE_CATEGORY]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_CATEGORY)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_CATEGORY)]: (state, action) => {
    state.list.loading = false
    console.log(action.payload.data.id)
    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)

    state.list.data.splice(index, 1)

    toast.success(i18n.t("categories.api.deleted"))
  }
})

export default categories
