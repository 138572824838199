import {
  API_GET_ALL_ORGANIZATION,
  API_GET_ONE_ORGANIZATION,
  API_GET_MY_STORAGE_ORGANIZATION,
  API_POST_ORGANIZATION,
  API_PATCH_ORGANIZATION,
  API_PATCH_LOGO_ORGANIZATION,
  API_DELETE_ORGANIZATION,
  GET_ONE_SUBORGA,
  RESET_FOCUS_SUBORGA,
  RESET_FOCUS_ORGA,
  API_INIT_MINE_ORGANIZATION,
  API_GET_MINE_USERS_ORGANIZATION,
  API_GET_MINE_SUBORGAS_ORGANIZATION,
  API_GET_MINE_ORGANIZATION,
  API_GET_ORGANIZATION_SHARING_RULES
} from "actions/organizations"
import { API_GET_ORGANIZATION_DEVICE } from "actions/devices"

export function getSubOrgaFromStore(id) {
  return {
    type: GET_ONE_SUBORGA,
    payload: {
      id
    }
  }
}

export function apiGetOrganizations() {
  return {
    type: API_GET_ALL_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations`
      }
    }
  }
}

export function apiGetOrganization(id) {
  return {
    type: API_GET_ONE_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/${id}`
      }
    }
  }
}

export function apiGetOrganizationSharingRules(id) {
  return {
    type: API_GET_ORGANIZATION_SHARING_RULES,
    payload: {
      request: {
        url: `/organizations/${id}/sharingRules`
      }
    }
  }
}

export function apiGetDeviceOrganization(id) {
  return {
    type: API_GET_ORGANIZATION_DEVICE,
    payload: {
      request: {
        url: `/organizations/${id}`
      }
    }
  }
}

export function apiPostOrganization(_data) {
  return {
    type: API_POST_ORGANIZATION,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/organizations`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchOrganization(id, mine) {
  return {
    type: API_PATCH_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/${id}`,
        method: "PATCH",
        data: mine
      }
    }
  }
}

export function apiPatchLogoOrganization(id, _data) {
  return {
    type: API_PATCH_LOGO_ORGANIZATION,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/organizations/${id}/logo`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiDeleteOrganization(id) {
  return {
    type: API_DELETE_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function getMyOrganizationStorage() {
  return {
    type: API_GET_MY_STORAGE_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/me/storage`
      }
    }
  }
}

export function getMineOrganization() {
  return {
    type: API_GET_MINE_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/me`
      }
    }
  }
}

export function initMineOrganization() {
  return {
    type: API_INIT_MINE_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/me/init`
      }
    }
  }
}

export function apiGetMyOrganizationUsers() {
  return {
    type: API_GET_MINE_USERS_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/me/users`
      }
    }
  }
}

export function apiGetMyOrganizationSubOrgas() {
  return {
    type: API_GET_MINE_SUBORGAS_ORGANIZATION,
    payload: {
      request: {
        url: `/organizations/me/subOrgas`
      }
    }
  }
}

export function resetFocusSubOrga() {
  return {
    type: RESET_FOCUS_SUBORGA
  }
}

export function resetFocusOrga() {
  return {
    type: RESET_FOCUS_ORGA
  }
}
