import users from "utils/i18n/gb/users.json"

// let i = 0
export default {
  users,
  timeUtils: (date, moment) => {
    const dateFormat = (options, onSameTimeZone = true) => {
      if (onSameTimeZone) {
        return new Intl.DateTimeFormat("en-GB", options).format(new Date(date))
      }
      return new Intl.DateTimeFormat("en-GB", options).format(moment(new Date(date)).local())
    }

    const minimalOptions = {
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }

    const dateNow = new Date()
    const isAtLeastOneWeekBefore = moment(new Date(date)).isSameOrBefore(moment(dateNow).subtract(1, "week"))
    const isOnSameTimeZone = new Date(date).getTimezoneOffset() === dateNow.getTimezoneOffset()
    const isThisYear = moment(new Date(date)).isSame(moment(dateNow), "year")

    if (!isThisYear) minimalOptions.year = "numeric"
    if (!isAtLeastOneWeekBefore) minimalOptions.weekday = "short"

    // if (i < 11){
    //   console.log('moment date now', moment(dateNow))
    //   console.log('moment date', moment(new Date(date)))
    //   console.log({rawDate: new Date(date), minimalOptions, dateFormat: dateFormat(minimalOptions)})
    //   console.log('----------------')
    //   i++
    // }
    return dateFormat(minimalOptions, isOnSameTimeZone)
  }
}
