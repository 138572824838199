import React, { Fragment } from "react"
import i18n from "utils/i18n"

const DeviceStatus = ({ status, className = "", style = {} }) => {
  return (
    <Fragment>
      {status === "ON" && (
        <div className={`status-label success ${className}`} style={{ ...style }}>
          {i18n.t("devices.status.on")}
        </div>
      )}
      {status === "OFF" && (
        <div className={`status-label warning ${className}`} style={{ ...style }}>
          {i18n.t("devices.status.off")}
        </div>
      )}
      {status === "UNPAIRED" && (
        <div className={`status-label unpaired ${className}`} style={{ ...style }}>
          {i18n.t("devices.status.unpaired")}
        </div>
      )}
      {status === "DISCONNECTED" && (
        <div className={`status-label error ${className}`} style={{ ...style }}>
          {i18n.t("devices.status.disconnected")}
        </div>
      )}
    </Fragment>
  )
}

export default DeviceStatus
