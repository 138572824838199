import {
  API_GET_MY_STORAGE_ORGANIZATION,
  API_POST_ORGANIZATION,
  API_PATCH_ORGANIZATION,
  GET_ONE_SUBORGA,
  RESET_FOCUS_ORGA,
  RESET_FOCUS_SUBORGA,
  API_PATCH_LOGO_ORGANIZATION,
  API_GET_ONE_ORGANIZATION,
  API_GET_ALL_ORGANIZATION,
  API_INIT_MINE_ORGANIZATION,
  API_GET_MINE_USERS_ORGANIZATION,
  API_GET_MINE_SUBORGAS_ORGANIZATION,
  API_GET_MINE_ORGANIZATION,
  API_GET_ORGANIZATION_SHARING_RULES
} from "actions/organizations"
import { API_POST_DEVICE } from "actions/devices"
import { toSuccess, toFailure } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { API_POST_USER, API_DELETE_USER, API_PATCH_USER, LOGOUT_USER } from "actions/users"
import { API_POST_LOCALGROUP, API_PATCH_LOCALGROUP, API_DELETE_LOCALGROUP } from "actions/localGroups"
import { API_PATCH_LOCALTAG, API_POST_LOCALTAG, API_DELETE_LOCALTAG } from "actions/localTags"
import { toast } from "react-toastify"
import i18n from "utils/i18n"
import { API_POST_DEVICEGROUP, API_DELETE_DEVICEGROUP, API_PATCH_DEVICEGROUP } from "actions/deviceGroups"
import { API_PATCH_SHARINGRULE, API_POST_SHARINGRULE, API_DELETE_SHARINGRULE } from "actions/sharingRules"
import _ from "lodash"
import { API_POST_BROADCASTTAG, API_DELETE_BROADCASTTAG, API_PATCH_BROADCASTTAG } from "actions/broadcastTags"

const initialState = {
  focus: {
    data: {
      id: null,
      accessTo: [],
      sharingTo: [],
      users: [],
      broadcastTags: [],
      deviceGroups: [],
      programs: []
    },
    loading: false,
    error: null
  },
  list: {
    data: [],
    loading: false,
    loaded: false,
    error: null
  },
  accessible: {
    data: [],
    loading: false,
    loaded: false,
    error: null
  },
  me: {
    data: {
      id: null,
      users: [],
      subOrgas: []
    },
    loading: false,
    error: false,
    isInit: false,
    usersFetched: false,
    subOrgasFetched: false
  }
}

const organizations = createReducer(initialState, {
  [API_GET_ALL_ORGANIZATION]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_ORGANIZATION)]: (state, action) => {
    state.list.loading = false
    state.list.loaded = true
    state.list.error = false
    state.list.data = action.payload.data.list
    state.accessible.data = action.payload.data.accessible
  },
  [toFailure(API_GET_ALL_ORGANIZATION)]: state => {
    state.list.loading = false
    state.list.loaded = false
    state.list.error = true

    state.accessible.loading = false
    state.accessible.loaded = false
    state.accessible.error = true
  },
  [API_GET_MINE_ORGANIZATION]: state => {
    state.me.loading = true
  },
  [toSuccess(API_GET_MINE_ORGANIZATION)]: (state, action) => {
    state.me.loading = false
    state.me.error = false
    state.me.data = _.merge(state.me.data, action.payload.data)
  },
  [toFailure(API_GET_MINE_ORGANIZATION)]: state => {
    state.me.loading = false
    state.me.error = true
  },
  [API_INIT_MINE_ORGANIZATION]: state => {
    state.me.loading = true
    state.me.isInit = false
  },
  [toSuccess(API_INIT_MINE_ORGANIZATION)]: (state, action) => {
    state.me.loading = false
    state.me.error = false
    state.me.isInit = true
    state.me.data = action.payload.data
  },
  [toFailure(API_INIT_MINE_ORGANIZATION)]: state => {
    state.me.loading = false
    state.me.error = true
  },

  [API_GET_MINE_USERS_ORGANIZATION]: state => {
    state.me.loading = true
  },
  [toSuccess(API_GET_MINE_USERS_ORGANIZATION)]: (state, action) => {
    state.me.loading = false
    state.me.error = false
    state.me.data.users = action.payload.data
    state.me.usersFetched = true
  },
  [toFailure(API_GET_MINE_USERS_ORGANIZATION)]: state => {
    state.me.loading = false
    state.me.error = true
  },

  [API_GET_MINE_SUBORGAS_ORGANIZATION]: state => {
    state.me.loading = true
  },
  [toSuccess(API_GET_MINE_SUBORGAS_ORGANIZATION)]: (state, action) => {
    state.me.loading = false
    state.me.error = false
    state.me.data.subOrgas = action.payload.data
    state.me.subOrgasFetched = true
  },
  [toFailure(API_GET_MINE_SUBORGAS_ORGANIZATION)]: state => {
    state.me.loading = false
    state.me.error = true
  },

  [API_GET_ONE_ORGANIZATION]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ONE_ORGANIZATION)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ONE_ORGANIZATION)]: state => {
    state.focus.loading = false
    state.focus.error = true
    state.focus.data = null
  },

  [toSuccess(API_GET_MY_STORAGE_ORGANIZATION)]: (state, action) => {
    if (state.me.data !== null) {
      state.me.data.storage = action.payload.data
    }
  },

  [GET_ONE_SUBORGA]: (state, action) => {
    state.focus.data = state.me.data.subOrgas.find(e => e.id === Number(action.payload.id))
  },

  [API_POST_ORGANIZATION]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_POST_ORGANIZATION)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data

    state.list.data.push(action.payload.data)

    toast.success(i18n.t("subOrgas.api.created"))
  },
  [toFailure(API_POST_ORGANIZATION)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_PATCH_ORGANIZATION]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_PATCH_ORGANIZATION)]: (state, action) => {
    const organizationId = Number(action.payload.data.id)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data = action.payload.data
      state.me.error = false
      state.me.loading = false
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data = action.payload.data
      state.focus.error = false
      state.focus.loading = false
    }

    // Patch list
    const index = state.list.data.findIndex(i => i.id === organizationId)
    if (index > -1) state.list.data[index] = action.payload.data

    toast.success(i18n.t("organizations.api.patched"))
  },
  [toFailure(API_PATCH_ORGANIZATION)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [toSuccess(API_PATCH_LOGO_ORGANIZATION)]: (state, action) => {
    const organizationId = Number(action.payload.data.id)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.logo = action.payload.data.logo
      state.me.error = false
      state.me.loading = false
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data.logo = action.payload.data.logo
      state.focus.error = false
      state.focus.loading = false
    }

    // Patch list
    const index = state.list.data.findIndex(i => i.id === organizationId)
    if (index > -1) state.list.data[index].logo = action.payload.data.logo

    toast.success(i18n.t("organizations.api.patched"))
  },

  /* Organization & subOrgas users */

  [toSuccess(API_POST_USER)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization)
    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.users.push(action.payload.data)
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data.users.push(action.payload.data)
    }

    toast.success(i18n.t("users.api.created"))
  },

  [toSuccess(API_PATCH_USER)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      const index = state.me.data.users.findIndex(i => i.id === action.payload.data.id)
      state.me.data.users[index] = action.payload.data
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.users.findIndex(i => i.id === action.payload.data.id)
      state.focus.data.users[index] = action.payload.data
    }

    toast.success(i18n.t("users.api.patched"))
  },

  [toSuccess(API_DELETE_USER)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.users.splice(
        state.me.data.users.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch focus
    if (state.focus.data.id !== null) {
      state.focus.data.users.splice(
        state.focus.data.users.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    toast.success(i18n.t("users.api.deleted"))
  },

  /** LOCALGROUP */
  [toSuccess(API_POST_LOCALGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.localGroups.push(action.payload.data)
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data.localGroups.push(action.payload.data)
    }

    // Patch list
    const index = state.list.data.findIndex(i => i.id === organizationId)
    if (index > -1) state.list.data[index].localGroups.push(action.payload.data)
    toast.success(i18n.t("localGroups.api.created"))
  },

  [toSuccess(API_PATCH_LOCALGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      const index = state.me.data.localGroups.findIndex(i => i.id === action.payload.data.id)
      state.me.data.localGroups[index] = action.payload.data
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.localGroups.findIndex(i => i.id === action.payload.data.id)
      state.focus.data.localGroups[index] = action.payload.data
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].localGroups.findIndex(i => i.id === action.payload.data.id)
      if (index > -1) state.list.data[orgaIndex].localGroups[index] = action.payload.data
    }

    toast.success(i18n.t("localGroups.api.updated"))
  },

  [toSuccess(API_DELETE_LOCALGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.localGroups.splice(
        state.me.data.localGroups.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch focus
    if (state.focus.data.id !== null) {
      state.focus.data.localGroups.splice(
        state.focus.data.localGroups.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].localGroups.findIndex(i => i.id === action.payload.data.id)
      if (index > -1)
        state.list.data[orgaIndex].localGroups.splice(
          state.list.data[orgaIndex].localGroups.findIndex(i => i.id === Number(action.payload.data.id)),
          1
        )
    }
    toast.success(i18n.t("localGroups.api.deleted"))
  },

  /** LOCALTAG */
  [toSuccess(API_POST_LOCALTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.localTags.push(action.payload.data)
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data.localTags.push(action.payload.data)
    }

    // Patch list
    const index = state.list.data.findIndex(i => i.id === organizationId)
    if (index > -1) state.list.data[index].localTags.push(action.payload.data)
    toast.success(i18n.t("localTags.api.created"))
  },

  [toSuccess(API_PATCH_LOCALTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      const index = state.me.data.localTags.findIndex(i => i.id === action.payload.data.id)
      state.me.data.localTags[index] = action.payload.data
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.localTags.findIndex(i => i.id === action.payload.data.id)
      state.focus.data.localTags[index] = action.payload.data
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].localTags.findIndex(i => i.id === action.payload.data.id)
      if (index > -1) state.list.data[orgaIndex].localTags[index] = action.payload.data
    }

    toast.success(i18n.t("localTags.api.updated"))
  },

  [toSuccess(API_DELETE_LOCALTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.localTags.splice(
        state.me.data.localTags.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch focus
    if (state.focus.data.id !== null) {
      state.focus.data.localTags.splice(
        state.focus.data.localTags.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].localTags.findIndex(i => i.id === action.payload.data.id)
      if (index > -1)
        state.list.data[orgaIndex].localTags.splice(
          state.list.data[orgaIndex].localTags.findIndex(i => i.id === Number(action.payload.data.id)),
          1
        )
    }
    toast.success(i18n.t("localTags.api.deleted"))
  },

  /** BROADCAST TAGS */
  [toSuccess(API_POST_BROADCASTTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)

    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.broadcastTags.push(action.payload.data)
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      state.focus.data.broadcastTags.push(action.payload.data)
    }

    // Patch list
    const index = state.list.data.findIndex(i => i.id === organizationId)
    if (index > -1) state.list.data[index].broadcastTags.push(action.payload.data)
    toast.success(i18n.t("broadcastTags.api.created"))
  },

  [toSuccess(API_PATCH_BROADCASTTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      const index = state.me.data.broadcastTags.findIndex(i => i.id === action.payload.data.id)
      state.me.data.broadcastTags[index] = action.payload.data
    }

    // Patch focus
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.broadcastTags.findIndex(i => i.id === action.payload.data.id)
      state.focus.data.broadcastTags[index] = action.payload.data
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].broadcastTags.findIndex(i => i.id === action.payload.data.id)
      if (index > -1) state.list.data[orgaIndex].broadcastTags[index] = action.payload.data
    }

    toast.success(i18n.t("broadcastTags.api.updated"))
  },

  [toSuccess(API_DELETE_BROADCASTTAG)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)
    // Patch me
    if (state.me.data.id === organizationId) {
      state.me.data.broadcastTags.splice(
        state.me.data.broadcastTags.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch focus
    if (state.focus.data.id !== null) {
      state.focus.data.broadcastTags.splice(
        state.focus.data.broadcastTags.findIndex(i => i.id === Number(action.payload.data.id)),
        1
      )
    }

    // Patch list
    const orgaIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaIndex > -1) {
      const index = state.list.data[orgaIndex].broadcastTags.findIndex(i => i.id === action.payload.data.id)
      if (index > -1)
        state.list.data[orgaIndex].broadcastTags.splice(
          state.list.data[orgaIndex].broadcastTags.findIndex(i => i.id === Number(action.payload.data.id)),
          1
        )
    }
    toast.success(i18n.t("broadcastTags.api.deleted"))
  },

  [RESET_FOCUS_SUBORGA]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loading = false
  },

  [RESET_FOCUS_ORGA]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loading = false
  },

  /* DEVICE GROUP */
  [toSuccess(API_POST_DEVICEGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)

    if (state.me.data.id === organizationId) {
      state.me.data.deviceGroups.push(action.payload.data)
    }
    if (state.focus.data.id === organizationId) {
      state.focus.data.deviceGroups.push(action.payload.data)
    }

    const orgaListIndex = state.list.data.findIndex(i => i.id === organizationId)
    if (orgaListIndex > -1) state.list.data[orgaListIndex].deviceGroups.push(action.payload.data)

    toast.success(i18n.t("deviceGroups.api.created"))
  },

  [toSuccess(API_PATCH_DEVICEGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.owner.id)
    const elementId = Number(action.payload.data.id)

    if (state.me.data.id === organizationId) {
      const index = state.me.data.deviceGroups.findIndex(i => i.id === elementId)
      state.me.data.deviceGroups[index] = action.payload.data
    }
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.deviceGroups.findIndex(i => i.id === elementId)
      state.focus.data.deviceGroups[index] = action.payload.data
    }

    const index2 = state.list.data.findIndex(i => i.id === organizationId)
    if (index2 > -1) {
      const index3 = state.list.data[index2].deviceGroups.findIndex(i => i.id === elementId)
      if (index3 > -1) state.list.data[index2].deviceGroups[index3] = action.payload.data
    }

    toast.success(i18n.t("deviceGroups.api.updated"))
  },

  [toSuccess(API_DELETE_DEVICEGROUP)]: (state, action) => {
    const organizationId = Number(action.payload.data.organization.id)
    const elementId = Number(action.payload.data.id)

    if (state.me.data.id === organizationId) {
      const index = state.me.data.deviceGroups.findIndex(i => i.id === elementId)
      state.me.data.deviceGroups.splice(index, 1)
    }
    if (state.focus.data.id === organizationId) {
      const index = state.focus.data.deviceGroups.findIndex(i => i.id === elementId)
      state.focus.data.deviceGroups.splice(index, 1)
    }

    const index2 = state.list.data.findIndex(i => i.id === organizationId)
    if (index2 > -1) {
      const index3 = state.list.data[index2].deviceGroups.findIndex(i => i.id === elementId)
      if (index3 > -1) state.list.data[index2].deviceGroups.splice(index3, 1)
    }

    toast.success(i18n.t("deviceGroups.api.deleted"))
  },

  /** SHARING RULES */

  [API_GET_ORGANIZATION_SHARING_RULES]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loading = true
    state.focus.error = false
  },
  [toSuccess(API_GET_ORGANIZATION_SHARING_RULES)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ORGANIZATION_SHARING_RULES)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [toSuccess(API_POST_SHARINGRULE)]: (state, action) => {
    if (state.focus.data.id === action.payload.data.grantedOrganization.id) {
      state.focus.data.accessTo.push(action.payload.data)
    }

    if (state.focus.data.id === action.payload.data.sharingOrganization.id) {
      state.focus.data.sharingTo.push(action.payload.data)
    }

    toast.success(i18n.t("organizations.api.sharingRulesUpdated"))
  },

  [toSuccess(API_PATCH_SHARINGRULE)]: (state, action) => {
    let index
    index = state.focus.data.accessTo.findIndex(i => i.id === action.payload.data.id)
    if (index > -1) state.focus.data.accessTo[index] = action.payload.data

    index = state.focus.data.sharingTo.findIndex(i => i.id === action.payload.data.id)
    if (index > -1) state.focus.data.sharingTo[index] = action.payload.data

    toast.success(i18n.t("organizations.api.sharingRulesUpdated"))
  },

  [toSuccess(API_DELETE_SHARINGRULE)]: (state, action) => {
    let index

    index = state.focus.data.accessTo.findIndex(i => i.id === action.payload.data.id)
    if (index > -1) {
      state.focus.data.accessTo.splice(index, 1)
    }

    index = state.focus.data.sharingTo.findIndex(i => i.id === action.payload.data.id)
    if (index > -1) {
      state.focus.data.accessTo.splice(index, 1)
    }

    toast.success(i18n.t("organizations.api.sharingRulesUpdated"))
  },

  [toSuccess(API_POST_DEVICE)]: (state, action) => {
    if (Number(action.payload.data.device.owner.id) === Number(state.me.data.id)) {
      state.me.data.broadcastTags.push(action.payload.data.broadcastTag)
    }
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default organizations
