import * as React from "react"

function BigScreenFour(props) {
  return (
    <svg id="prefix__Calque_1" data-name="Calque 1" viewBox="0 0 600 353" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-4,.prefix__cls-5{fill:#fff}.prefix__cls-5{font-size:16px;font-family:inherit;font-weight:600}.prefix__cls-6{letter-spacing:-.04em}.prefix__cls-7{letter-spacing:0}"
          }
        </style>
      </defs>
      <path fill="#e8e8e8" d="M0 0h497v280H0z" />
      <path fill="#0184df" d="M0 280.03h600v73H0z" />
      <path fill="#30d4ee" d="M497 0h103v280H497z" />
      <path
        className="prefix__cls-4"
        d="M276 140.67a27.67 27.67 0 00-26.73-26.73 27.65 27.65 0 00-28.53 28.53 27.67 27.67 0 0026.73 26.73h.9A27.65 27.65 0 00276 140.67zm-12 16.08a21.64 21.64 0 01-15.68 6.65h-.72a21.83 21.83 0 01.72-43.64h.72a21.82 21.82 0 0115 37z"
      />
      <path
        className="prefix__cls-4"
        d="M257.39 140l-12.26-8.79a2 2 0 00-3.13 1.61v17.58a2 2 0 003.13 1.61l12.26-8.79a2 2 0 000-3.22z"
      />
      <text className="prefix__cls-5" transform="translate(223.41 323.22)">
        B
        <tspan className="prefix__cls-6" x={9.66} y={0}>
          L
        </tspan>
        <tspan className="prefix__cls-7" x={17.23} y={0}>
          OC 1
        </tspan>
      </text>
      <text className="prefix__cls-5" transform="translate(524.91 147.22)">
        B
        <tspan className="prefix__cls-6" x={9.66} y={0}>
          L
        </tspan>
        <tspan className="prefix__cls-7" x={17.23} y={0}>
          OC 2
        </tspan>
      </text>
    </svg>
  )
}

const MemoBigScreenFour = React.memo(BigScreenFour)
export default MemoBigScreenFour
