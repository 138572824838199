import React, { Fragment, Component } from "react"
import { Col, Button, Row } from "reactstrap"
import i18n from "utils/i18n"
import { Loader, Input } from "semantic-ui-react"
import _ from "lodash"
import ValidateButton from "components/Buttons/ValidateButton"

class ItemLine extends Component {
  state = {
    onEdit: false,
    loading: false,
    name: this.props.element.name
  }

  toggleEdit = () => {
    this.setState(prevState => ({ onEdit: !prevState.onEdit }))
  }

  onChange = event => {
    this.setState({ name: event.target.value })
  }

  submit = async () => {
    const { element, handlers } = this.props
    this.setState({ loading: true })

    const data = _.clone(element)
    data.name = this.state.name

    await handlers.patch(data)
    this.setState({ loading: false, onEdit: false })
  }

  render() {
    const { element, handlers } = this.props
    const { onEdit, loading, name } = this.state

    return (
      <Row className="line-element-row">
        <Col xs={1}>
          <i className={`pe-7s-folder folder-color-${element.type}`} color="primary" style={{ fontSize: "3em" }}></i>
        </Col>
        <Col xs={3}>
          <div className="line-element-info-editable">
            {!onEdit && (
              <div className="line-editable-content">
                <div className="label">{i18n.t("entities.attributes.name")}</div>
                <div className="content">{element.name}</div>
              </div>
            )}

            {onEdit && (
              <Fragment>
                <Input
                  fluid
                  placeholder={i18n.t("entities.attributes.name")}
                  required
                  onChange={this.onChange}
                  name="name"
                  className="inputText line-edit-input"
                  value={name}
                />
                <ValidateButton
                  text={i18n.t("actions.validate")}
                  type="button"
                  onClick={() => this.submit()}
                  disabled={false}
                />
              </Fragment>
            )}

            {loading && <Loader size="mini" active inline />}
            <div className="line-editable-actions">
              <i className="pe-7s-edit icon-only btn-icon-wrapper edit-line-item" onClick={() => this.toggleEdit()}></i>
            </div>
          </div>
        </Col>
        <Col xs={3}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.type")}</div>
            <div className="content">{i18n.t(`entities.plural.${element.type.toLowerCase()}`)}</div>
          </div>
        </Col>
        <Col xs={5}>
          <div className="list-action-menu">
            <Button className="btn-icon" color="danger" outline size="sm" onClick={() => handlers.delete(element.id)}>
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
              {i18n.t("actions.delete")}
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default ItemLine
