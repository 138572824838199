import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  owner: Yup.object().required(i18n.t("form.requiredField")),
  title: Yup.string().required(i18n.t("form.requiredField")),
  description: Yup.string()
})

export default validationSchema
