import React, { Component } from "react"
import ThumbnailViewer from "components/Thumbnail/ThumbnailViewer"
import i18n from "utils/i18n"
import FormSaveButton from "components/Buttons/FormSaveButton"

class VideoPlayerInline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      thumbnailActive: false
    }
  }

  componentDidMount() {}

  render() {
    const { video, thumbnail, thumbnailTipActive, hideThumbnailTip } = this.props
    const { thumbnailActive } = this.state

    return (
      <div style={{ position: "relative" }}>
        {video && (
          <video
            controls
            controlsList="nodownload"
            style={{ width: "100%", position: "relative" }}
            poster={thumbnail.src}
            id="videoPlayer"
            onPlay={() => this.setState({ thumbnailActive: false })}
            onPause={() => this.setState({ thumbnailActive: true })}
          >
            {video.src === null && <div className="no-video-overlay">{i18n.t("videos.video.none")}</div>}

            {video.src !== null && <source src={video.src} />}
          </video>
        )}
        {thumbnailTipActive && (
          <div className="thumbnail-tip-overlay">
            <span>Positionnez la vidéo sur l&apos;image que vous voulez utiliser</span>
            <FormSaveButton
              text="OK"
              type="type"
              onClick={() => hideThumbnailTip()}
              style={{ color: "#fff", backgroundColor: "#30b1ff", borderColor: "#30b1ff", marginTop: "1em" }}
            />
          </div>
        )}
        {thumbnailActive && <ThumbnailViewer thumbnail={thumbnail.src} inVideo />}
        <canvas id="canvas" style={{ overflow: "auto" }}></canvas>
      </div>
    )
  }
}

export default VideoPlayerInline
