import React from "react"
import { Image } from "semantic-ui-react"
import getOrganizationLogoSrc from "utils/getOrganizationLogoSrc"

const OrganizationWithLogoDisplayer = ({ organization, style = undefined }) => {
  return (
    <div className="organization-with-logo-displayer" style={{ ...style }}>
      <Image
        src={getOrganizationLogoSrc(organization)}
        circular
        className="user-avatar orga-logo"
        style={{ minWidth: 20, minHeight: "100%" }}
      />
      <div className="orga-name">{organization.name}</div>
    </div>
  )
}

export default OrganizationWithLogoDisplayer
