import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("form.requiredField")),
  owner: Yup.object().required(i18n.t("form.requiredField")),
  program: Yup.object(),
  group: Yup.object(),
  pepstreamImageMode: Yup.number().min(1).max(5).required(i18n.t("form.requiredField"))
})

export default validationSchema
