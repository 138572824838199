const initialValues = [
  {
    name: "title",
    type: "text"
  },
  {
    name: "shared",
    type: "boolean"
  },
  {
    name: "owner",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "groups",
    type: "selectAutoComplete-Multi",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  }
]

export default initialValues
