import React, { Fragment } from "react"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import DeviceStatus from "components/Device/DeviceStatus"
import getDeviceThumbnail from "utils/getDeviceThumbnail"
import DeviceRemoteButton from "features/Devices/DeviceRemoteButton"

const GridLink = ({ element, linkActivated, children }) => {
  if (linkActivated)
    return (
      <Link to={`/devices/${element.id}`} className="grid-link">
        {children}
      </Link>
    )

  return <Fragment>{children}</Fragment>
}

const GridItemDevice = ({
  element,
  handlers = { openQuickAccess: _id => _id },
  linkActivated = true,
  actionsActivated = true
}) => {
  return (
    <GridLink element={element} linkActivated={linkActivated}>
      <DeviceStatus status={element.status} style={{ position: "absolute", top: 5, left: 5 }} />
      <div className="grid-element-top">
        <img src={getDeviceThumbnail(element)} alt="" className="grid-thumbnail" />
      </div>
      <div className="grid-element-subcontainer">
        <Row>
          <Col xs={12} className="grid-element-firstrow">
            <div className="element-title text-ellipsis">{element.name}</div>
          </Col>
        </Row>
        <Row className="grid-element-second-row">
          <Col xs={9} className="grid-element">
            <div className="line-element-info">
              <div className="content">
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
          {actionsActivated && (
            <Col xs={3}>
              <DeviceRemoteButton onClick={() => handlers.openQuickAccess(element.id)} />
            </Col>
          )}
        </Row>
      </div>
    </GridLink>
  )
}

export default GridItemDevice
