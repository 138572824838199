import React, { Component } from "react"
import i18n from "utils/i18n"

class UserAvatarHandler extends Component {
  inputClick = () => {
    document.getElementById("inputFile-logo").click()
  }

  onLibraryImport = e => {
    const file = e.target.files[0]
    this.props.onChange(URL.createObjectURL(file), file)
  }

  render() {
    const { avatarHandler } = this.props

    return (
      <div className="organization-changeable-container" onClick={() => this.inputClick()}>
        <input
          type="file"
          name="inputFile-logo"
          id="inputFile-logo"
          className="form-video-input"
          onChange={this.onLibraryImport}
        />

        <div className={`organization-changeable-overlay ${avatarHandler.src === null && "logo-overlay-active"}`}>
          {i18n.t("actions.edit")}
        </div>
        <img src={avatarHandler.src} alt="" className="organization-changeable-logo" />
        {avatarHandler.error !== null && <div className="form-error-style">{avatarHandler.error}</div>}
      </div>
    )
  }
}

export default UserAvatarHandler
