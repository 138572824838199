import { combineReducers } from "redux"
import users from "reducers/users"
import devices from "reducers/devices"
import ThemeOptions from "reducers/ThemeOptions"
import metisMenuReducer from "react-metismenu/lib/reducers"
import videos from "reducers/videos"
import organizations from "reducers/organizations"
import channels from "reducers/channels"
import playlists from "reducers/playlists"
import programs from "reducers/programs"
import webContents from "reducers/webcontents"
import auth from "reducers/auth"
import categories from "reducers/categories"
import { LOGOUT_USER } from "actions/users"

const appReducer = combineReducers({
  users,
  devices,
  videos,
  organizations,
  channels,
  playlists,
  programs,
  webContents,
  ThemeOptions,
  auth,
  categories,
  metisMenuStore: metisMenuReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // for all keys defined in your persistConfig(s)
    window.localStorage.removeItem("persist:root")
    // storage.removeItem('persist:otherKey')

    // eslint-disable-next-line no-param-reassign
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
