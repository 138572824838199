import React from "react"
import { Button } from "reactstrap"

const ListActionButton = ({ label = null, icon, onClick, style = {} }) => {
  return (
    <Button
      className="btn-icon"
      color="light"
      outline
      size="md"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
      style={{ marginRight: 5, ...style }}
    >
      <i className={`pe-7s-${icon} btn-icon-wrapper`}> </i>
      {label}
    </Button>
  )
}

export default ListActionButton
