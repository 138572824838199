import React, { Fragment } from "react"
import i18n from "utils/i18n"
import FormSaveButton from "components/Buttons/FormSaveButton"
import { toast } from "react-toastify"
import DeviceGroupsTree from "./DeviceGroupsTree"

class OrgaTree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addGroupInputVisible: false,
      newGroupInput: ""
    }
  }

  toggleNewGroupInput = () => {
    this.setState(prevState => ({ addGroupInputVisible: !prevState.addGroupInputVisible }))
  }

  onNewGroupInputChange = event => {
    this.setState({ newGroupInput: event.target.value })
  }

  onNewGroupSubmit = async orga => {
    if (this.state.newGroupInput === "") {
      toast.error(i18n.t("deviceGroups.form.notNull"))
    } else {
      const data = {
        owner: orga.id,
        name: this.state.newGroupInput
      }

      await this.props.onDeviceGroupSubmit(data)

      this.setState({ newGroupInput: "", addGroupInputVisible: false })
    }
  }

  render() {
    const { addGroupInputVisible, newGroupInput } = this.state
    const { orga, focus, onDeviceGroupUpdate, onDeviceGroupDelete, listFilter, changeListFilter } = this.props

    return (
      <Fragment>
        {orga.deviceGroups.map(deviceGroup => (
          <DeviceGroupsTree
            focus={focus}
            deviceGroup={deviceGroup}
            orga={orga}
            key={`deviceGroup-${deviceGroup.id}`}
            onDeviceGroupUpdate={onDeviceGroupUpdate}
            onDeviceGroupDelete={onDeviceGroupDelete}
            listFilter={listFilter}
            changeListFilter={changeListFilter}
          />
        ))}

        {!addGroupInputVisible && (
          <div className="tree-view_item tree-view_item deviceGroup-new-tree">
            <span className="node-deviceGroup" onClick={() => this.toggleNewGroupInput()}>
              <i className="pe-7s-plus btn-icon-wrapper" style={{ marginRight: "0.5em", color: "#9a9a9a" }}></i>{" "}
              {i18n.t("deviceGroups.actions.new")}
            </span>
          </div>
        )}
        {addGroupInputVisible && (
          <div
            className="tree-view_item tree-view_item deviceGroup-new-tree"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <input
              type="text"
              className="deviceGroup-new-input"
              value={newGroupInput}
              onChange={this.onNewGroupInputChange}
            />{" "}
            <FormSaveButton
              type="button"
              disabled={false}
              onClick={() => {
                if (newGroupInput === "") this.toggleNewGroupInput()
                else this.onNewGroupSubmit(orga)
              }}
              // @ts-ignore
              value={newGroupInput}
              text={newGroupInput === "" ? "X" : "OK"}
            />
          </div>
        )}
      </Fragment>
    )
  }
}

export default OrgaTree
