import React from "react"

const PageTitle = ({ heading, icon, number = null }) => {
  return (
    <div className="app-page-title">
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className="page-title-icon">
            <i className={icon} />
          </div>
          <div>{heading}</div>
          {number !== null && <div className="list-nb-elements">{number}</div>}
        </div>
        <div className="page-title-actions"></div>
      </div>
    </div>
  )
}

export default PageTitle
