import { API_POST_CATEGORY, API_DELETE_CATEGORY, API_PATCH_CATEGORY, API_GET_ALL_CATEGORY } from "actions/categories"

export function apiGetAllCategories() {
  return {
    type: API_GET_ALL_CATEGORY,
    payload: {
      request: {
        url: `/categories`,
        method: "GET"
      }
    }
  }
}

export function apiPostCategory(_data) {
  return {
    type: API_POST_CATEGORY,
    payload: {
      request: {
        url: `/categories`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchCategory(_data) {
  return {
    type: API_PATCH_CATEGORY,
    payload: {
      request: {
        url: `/categories/${_data.id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteCategory(id) {
  return {
    type: API_DELETE_CATEGORY,
    payload: {
      request: {
        url: `/categories/${id}`,
        method: "DELETE"
      }
    }
  }
}
