import {
  API_GET_ALL_PLAYLIST,
  API_GET_ONE_PLAYLIST,
  API_POST_PLAYLIST,
  API_DELETE_PLAYLIST,
  GET_ONE_PLAYLIST,
  INIT_PLAYLIST,
  RESET_FOCUS_PLAYLIST,
  API_PATCH_PLAYLIST_CONTENT,
  API_PATCH_PLAYLIST,
  API_COPY_PLAYLIST,
  API_REPLACE_IN_PLAYLIST,
  API_ADDAFTER_IN_PLAYLIST,
  API_GET_ONE_FOR_COPY_PLAYLIST
} from "actions/playlists"

export function getPlaylistFromStore(id) {
  return {
    type: GET_ONE_PLAYLIST,
    payload: {
      id
    }
  }
}

export function apiGetPlaylists() {
  return {
    type: API_GET_ALL_PLAYLIST,
    payload: {
      request: {
        url: `/playlists`
      }
    }
  }
}

export function apiGetPlaylist(id) {
  return {
    type: API_GET_ONE_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/${id}`
      }
    }
  }
}

export function apiGetPlaylistForCopy(id) {
  return {
    type: API_GET_ONE_FOR_COPY_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/${id}`
      }
    }
  }
}

export function apiPostPlaylist(_data) {
  return {
    type: API_POST_PLAYLIST,
    payload: {
      request: {
        url: `/playlists`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiReplaceInPlaylists(_data) {
  return {
    type: API_REPLACE_IN_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/replace`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiAddAfterInPlaylists(_data) {
  return {
    type: API_ADDAFTER_IN_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/addAfter`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiCopyPlaylist(id, _data) {
  return {
    type: API_COPY_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/${id}/duplicate`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchPlaylist(id, _data) {
  return {
    type: API_PATCH_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiPatchPlaylistContent(id, _data) {
  return {
    type: API_PATCH_PLAYLIST_CONTENT,
    payload: {
      request: {
        url: `/playlists/content/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeletePlaylist(id) {
  return {
    type: API_DELETE_PLAYLIST,
    payload: {
      request: {
        url: `/playlists/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function initPlaylist() {
  return {
    type: INIT_PLAYLIST
  }
}

export function resetFocusPlaylist() {
  return {
    type: RESET_FOCUS_PLAYLIST
  }
}
