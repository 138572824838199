import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  sharingOrganization: Yup.object().required(i18n.t("form.requiredField")),
  canDownload: Yup.object().required(i18n.t("form.requiredField")),
  onlyShared: Yup.object().required(i18n.t("form.requiredField"))
})

export default validationSchema
