const initialValues = [
  {
    name: "magicinfoServerUrl",
    type: "text"
  },
  {
    name: "urlLauncherTimeout",
    type: "number"
  },
  {
    name: "urlLauncherAddress",
    type: "text"
  }
]

export default initialValues
