import React from "react"
import { Row, Col, Button } from "reactstrap"
import getThumbnailSrc from "utils/getThumbnailSrc"
import moment from "moment"
import DroppableContentLabel from "components/DroppableContentLabel"
import { Dropdown } from "semantic-ui-react"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"

const LineContentItem = ({
  item,
  provided,
  style,
  deleteContent,
  userCanEdit,
  index,
  replaceContent,
  addAfterContent
}) => {
  const element = item.data

  return (
    <div
      className="content-line md"
      style={{ ...style }}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <Row className="line-element-row">
        {userCanEdit && (
          <Col xs={1} style={{ padding: 0 }}>
            <i
              onClick={() => deleteContent(item, index)}
              className="pe-7s-close btn-icon-wrapper color-red cursor-pointer"
              style={{ float: "left", fontSize: "2em" }}
            />
          </Col>
        )}
        <Col
          xs={1}
          style={{ backgroundImage: `url(${getThumbnailSrc(item.thumbnail, item.type)})` }}
          className="list-thumbnail"
        >
          &nbsp;
        </Col>
        <Col xs={userCanEdit ? 4 : 5}>
          <div className="element-title text-ellipsis">{element.title}</div>
          <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
        </Col>
        <Col xs={3}>
          <DroppableContentLabel content={item} />
        </Col>
        <Col xs={1}>
          {userCanEdit && (
            <Dropdown
              icon={null}
              floating
              direction="left"
              onClick={e => {
                e.preventDefault()
              }}
              trigger={
                <Button className="pe-7s-more btn-icon-wrapper" size="sm" color="light">
                  {" "}
                </Button>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  className="deviceGroup-handler-item"
                  text={i18n.t("playlists.content.replace")}
                  onClick={() => replaceContent(item.type, item.id)}
                />
                <Dropdown.Item
                  className="deviceGroup-handler-item"
                  text={i18n.t("playlists.content.addAfter")}
                  onClick={() => addAfterContent(item.type, item.id)}
                />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
        <Col xs={2}>
          <div className="video-time" style={{ float: "right" }}>
            {moment.utc(element.duration * 1000).format("mm:ss")}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LineContentItem
