import React from "react"
import { Link } from "react-router-dom"

export const NavMultipleItem = ({ item, pathname, opened, onClick }) => {
  const isActive = pathname === item.path || item.children.map(child => child.path).includes(pathname)

  // Pour le moment, tout le temps à false car toujours un lien sur l'entete
  const _opened = opened

  return (
    <li className="metismenu-item">
      <Link to={item.path} className={`metismenu-link ${isActive ? "active" : ""}`} onClick={() => onClick()}>
        <i className={`metismenu-icon ${item.icon}`}></i> {item.label}
        <i
          className={`metismenu-state-icon pe-7s-angle-down caret-left ${isActive || _opened ? "rotate-minus-90" : ""}`}
          // eslint-disable-next-line react/jsx-closing-tag-location
        ></i>
      </Link>
      <ul className={`metismenu-container ${isActive || _opened ? "visible" : ""}`}>
        {item.children.map(
          child =>
            child.inSidebar && (
              <li className="metismenu-item" key={`sidebar-child-${child.path}`}>
                <Link to={child.path} className={`metismenu-link ${pathname === child.path ? "active" : ""}`}>
                  <i className="metismenu-icon"></i> {child.label}
                </Link>
              </li>
            )
        )}
      </ul>
    </li>
  )
}
