import React, { Fragment } from "react"
import { Row, Col } from "reactstrap"
import MainContainer from "components/PageStructure/MainContainer"
import i18n from "utils/i18n"
import { Field, Formik } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import initFormValues from "utils/formik/initFormValues"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"
import initialValues from "./initialValues"
import validationSchema from "./validationSchema"

const DeviceManagement = ({ device, loading, onSave }) => {
  let data = {}

  if (device.magicInfoId === null)
    return <div className="list-no-result">{i18n.t("devices.magicInfoPairingNeeded")}</div>

  if (device.setup !== null && device.setup !== undefined) {
    data = {
      magicinfoServerUrl: device.setup.magicinfoServerUrl,
      urlLauncherAddress: device.setup.urlLauncherAddress,
      urlLauncherTimeout: device.setup.urlLauncherTimeout
    }
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={initFormValues(initialValues, data, false)}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({ errors, values }) => {
          return (
            <Fragment>
              <Row>
                <Col xs={12}>
                  <FormSaveButton
                    text={i18n.t("actions.save")}
                    type="button"
                    disabled={!_.isEmpty(errors)}
                    onClick={() => onSave(values)}
                  />
                </Col>
              </Row>
              <MainContainer>
                {device.status !== "UNPAIRED" && !loading && device.setup !== null && (
                  <Row>
                    <Col xs={6}>
                      <Field
                        type="text"
                        name="magicinfoServerUrl"
                        label={i18n.t("devices.attributes.magicinfoServerUrl")}
                        component={ClassicInput}
                        disabled
                        required
                      />
                      <Field
                        type="text"
                        name="urlLauncherAddress"
                        label={i18n.t("devices.attributes.urlLauncherAddress")}
                        component={ClassicInput}
                        disabled
                        required
                      />

                      <Field
                        type="number"
                        name="urlLauncherTimeout"
                        label={i18n.t("devices.attributes.urlLauncherTimeout")}
                        component={ClassicInput}
                        required
                        disabled
                      />
                    </Col>
                  </Row>
                )}
              </MainContainer>
            </Fragment>
          )
        }}
      </Formik>
    </Fragment>
  )
}

export default DeviceManagement
