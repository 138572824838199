import React, { Fragment } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import i18n from "utils/i18n"
import { Row, Col } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import MainContainer from "components/PageStructure/MainContainer"
import _ from "lodash"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import Textarea from "components/Form/Textarea"
import FormSeparator from "components/Form/FormSeparator"
import TagHandler from "components/Form/TagHandler"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SharedSwitch from "components/Form/SharedSwitch"

const CustomForm = ({
  data,
  myOrganization,
  organizations,
  userCanEdit,
  errors,
  values,
  loading,
  isNew,
  openConfirmDeleteModalOpen,
  children,
  categories,
  getOrganizationFromList,
  getAllAccessibleBroadcastTags
}) => {
  return (
    <Fragment>
      {userCanEdit && (
        <Row>
          <Col xs={6}>
            <FormSaveButton
              isLoading={loading}
              text={i18n.t("actions.save")}
              type="submit"
              disabled={!_.isEmpty(errors)}
            />
          </Col>
          <Col xs={6}>
            {!isNew && (
              <FormDeleteButton
                text={i18n.t("actions.delete")}
                disabled={false}
                type="button"
                className="form-delete-button-right"
                onClick={() => openConfirmDeleteModalOpen()}
              />
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={5} className="with-right-border">
          <MainContainer title={i18n.t("videos.pageTitle.owner")}>
            {isNew && (
              <Field
                component={SelectAutoComplete}
                required={false}
                name="owner"
                placeholder={i18n.t("form.actions.chooseOrganization")}
                options={generateMultiSelectOptions(
                  [
                    { id: myOrganization.id, name: `${myOrganization.name} (${i18n.t("form.me")})` },
                    ...organizations.list.data.filter(e => e.id !== myOrganization.id)
                  ],
                  "id",
                  "name"
                )}
              />
            )}
            {!isNew && <OrganizationWithLogoDisplayer organization={data.owner} />}
          </MainContainer>
          <MainContainer title={i18n.t("videos.pageTitle.informations")}>
            <Field component={SharedSwitch} name="shared" label={i18n.t("entities.attributes.sharing")} required />
            <Field
              type="text"
              name="title"
              editable={userCanEdit}
              label={i18n.t("entities.attributes.title")}
              component={ClassicInput}
              required
            />
            <Field
              component={Textarea}
              editable={userCanEdit}
              label={i18n.t("entities.attributes.description")}
              required={false}
              name="description"
              placeholder={i18n.t("entities.attributes.description")}
            />

            <Field
              component={TagHandler}
              label={i18n.t("entities.plural.category")}
              required={false}
              editable={userCanEdit}
              name="categories"
              placeholder={i18n.t("form.actions.addCategories")}
              type="local"
              options={generateMultiSelectOptions(categories, "id", "name")}
            />
            <Field
              component={SelectAutoComplete}
              label={i18n.t("entities.singular.group")}
              required={false}
              editable
              name="groups"
              placeholder={i18n.t("form.actions.chooseGroup")}
              options={[{ value: "", label: "Aucun" }].concat(
                generateMultiSelectOptions(myOrganization.localGroups, "id", "name", "WEBCONTENT")
              )}
            />

            <Field
              component={TagHandler}
              label={i18n.t("entities.plural.broadcastTag")}
              required={false}
              editable={userCanEdit}
              name="broadcastTags"
              placeholder={i18n.t("form.actions.addTags")}
              type="local"
              options={generateMultiSelectOptions(!userCanEdit ? [] : getAllAccessibleBroadcastTags(), "id", "name")}
            />
          </MainContainer>
        </Col>
        {children}
      </Row>
    </Fragment>
  )
}

export default CustomForm
