import { toFailure, toSuccess } from "actions"
import {
  API_GET_ALL_VIDEOS,
  API_GET_ONE_VIDEOS,
  API_POST_VIDEOS,
  API_PATCH_VIDEOS,
  API_DELETE_VIDEOS,
  INIT_VIDEOS,
  RESET_FOCUS_VIDEOS,
  GET_ONE_VIDEOS,
  UPLOAD_PROGRESS_VIDEO
} from "actions/videos"
import { toast } from "react-toastify"
import { createReducer } from "@reduxjs/toolkit"
import i18n from "utils/i18n"
import { LOGOUT_USER } from "actions/users"

const initialState = {
  list: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  focus: {
    uploadProgress: 0,
    loading: false,
    error: false,
    data: null
  }
}

const videos = createReducer(initialState, {
  [API_GET_ALL_VIDEOS]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_VIDEOS)]: (state, action) => {
    state.list.loading = false
    state.list.loaded = true
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_VIDEOS)]: state => {
    state.list.loading = false
    state.list.loaded = false
    state.list.error = true
  },

  [API_GET_ONE_VIDEOS]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ONE_VIDEOS)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ONE_VIDEOS)]: state => {
    state.focus.loading = false
    state.focus.error = true
    state.focus.data = null
  },

  [GET_ONE_VIDEOS]: (state, action) => {
    state.focus.data = state.list.data.find(e => e.id === Number(action.payload.id)) || null
  },

  [API_POST_VIDEOS]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_POST_VIDEOS)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
    state.list.data.push(action.payload.data)

    toast.success(i18n.t("videos.api.created"))
  },
  [toFailure(API_POST_VIDEOS)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [UPLOAD_PROGRESS_VIDEO]: (state, action) => {
    state.focus.uploadProgress = action.payload
  },

  [API_PATCH_VIDEOS]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_VIDEOS)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_VIDEOS)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("videos.api.patched"))
  },

  [API_DELETE_VIDEOS]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_VIDEOS)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_VIDEOS)]: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    let { data } = state.list
    state.list.loading = false
    data = data.splice(
      data.findIndex(i => i.id === action.payload.data.id),
      1
    )

    state.focus.data = null
    toast.success(i18n.t("videos.api.deleted"))
  },

  [RESET_FOCUS_VIDEOS]: state => {
    state.focus.data = null
    state.focus.uploadProgress = 0
    state.focus.loading = false
  },

  [INIT_VIDEOS]: state => {
    state.focus.data = {}
    state.focus.loading = false
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default videos
