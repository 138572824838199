import { API_POST_BROADCASTTAG, API_DELETE_BROADCASTTAG, API_PATCH_BROADCASTTAG } from "actions/broadcastTags"

export function apiPostBroadcastTag(_data) {
  return {
    type: API_POST_BROADCASTTAG,
    payload: {
      request: {
        url: `/broadcastTags`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchBroadcastTag(_data) {
  return {
    type: API_PATCH_BROADCASTTAG,
    payload: {
      request: {
        url: `/broadcastTags/${_data.id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteBroadcastTag(id) {
  return {
    type: API_DELETE_BROADCASTTAG,
    payload: {
      request: {
        url: `/broadcastTags/${id}`,
        method: "DELETE"
      }
    }
  }
}
