import React from "react"
import ReactDOM from "react-dom"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

Bugsnag.start({
  apiKey: "3e0f4724d245bfaace3652d787716915",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ["production"]
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

const startApp = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById("root")
  )
}

// @ts-ignore
if (!window.cordova) {
  startApp()
} else {
  document.addEventListener("deviceready", startApp, false)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
