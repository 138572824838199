import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  apiGetPlaylist,
  getPlaylistFromStore,
  apiPostPlaylist,
  apiPatchPlaylist,
  apiPatchPlaylistContent,
  apiDeletePlaylist,
  resetFocusPlaylist
} from "services/playlists"
import { apiGetVideos } from "services/videos"
import { apiGetWebContents } from "services/webcontents"
import { Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import _ from "lodash"
import initFormValues from "utils/formik/initFormValues"
import { Redirect, withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/Playlists/One/Form/validationSchema"
import initialValues from "features/Playlists/One/Form/initialValues"
import PlaylistForm from "features/Playlists/One/Form"
import { Loader, Confirm } from "semantic-ui-react"
import { createJsonDataFromFormik } from "utils/formik/apiSerializer"
import PlaylistHandler from "features/Playlists/One/PlaylistHandler"
import ListLoader from "components/List/ListLoader"
import ReplaceOrAdd from "./PlaylistHandler/ReplaceOrAdd"

export class Playlist extends Component {
  state = {
    isInit: false,
    isNew: true,
    title: "playlists.pageTitle.addTitle",
    redirectToRoot: false,
    playlistHasChanged: false,
    currentSelectedContent: [],
    confirmDeleteModalOpen: false,
    replaceOrAddContentModal: false,
    replaceOrAddContentItemType: null,
    replaceOrAddContentType: null,
    replaceOrAddContentId: null,
    playlistContentNeedReload: false
  }

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
    if (!this.props.videos.loaded) this.props.apiGetVideos()
    if (!this.props.webContents.loaded) this.props.apiGetWebContents()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusPlaylist()
  }

  setPlaylistHasChanged = value => this.setState({ playlistHasChanged: value })

  setCurrentSelectedContent = values => {
    this.setState({ currentSelectedContent: values })
  }

  initComponent = async id => {
    const isNew = id === "new"
    if (!isNew) {
      this.setState({ isNew: false, title: "playlists.pageTitle.editTitle" })
      await this.props.apiGetPlaylist(id)

      if (this.props.playlists.focus.data === null) this.setState({ redirectToRoot: true })
    }

    this.setState({ isInit: true })
  }

  submitForm = async values => {
    const { state } = this
    const data = createJsonDataFromFormik(_.clone(values))

    // New element
    if (state.isNew) {
      const response = await this.props.apiPostPlaylist(data)

      if (response.type === "API_POST_PLAYLIST_SUCCESS") {
        this.props.history.push(`/playlists/${response.payload.data.id}`)
        this.initComponent(response.payload.data.id)
      }
    } else {
      const response = await this.props.apiPatchPlaylist(this.props.match.params.id, data)
      await this.onPlaylistContentUpdate(state.currentSelectedContent)

      if (response.type === "API_PATCH_PLAYLIST_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false, confirmDeleteModalOpen: false })
    await this.props.apiDeletePlaylist(this.props.match.params.id)

    this.setState({ redirectToRoot: true })
  }

  onPlaylistContentUpdate = async values => {
    const data = values.map((item, index) => ({
      order: index,
      data: item.data,
      type: item.type,
      contentId: item.contentId
    }))

    await this.props.apiPatchPlaylistContent(this.props.match.params.id, data)
    this.setPlaylistHasChanged(false)
  }

  replaceContent = (type, id) => {
    this.setState({
      replaceOrAddContentModal: true,
      replaceOrAddContentType: "replace",
      replaceOrAddContentItemType: type,
      replaceOrAddContentId: id
    })
  }

  addAfterContent = (type, id) => {
    this.setState({
      replaceOrAddContentModal: true,
      replaceOrAddContentType: "addAfter",
      replaceOrAddContentItemType: type,
      replaceOrAddContentId: id
    })
  }

  closeReplaceOrAddContentModal = () => {
    this.setState({ replaceOrAddContentModal: false, replaceOrAddContentType: null, replaceOrAddContentId: null })
  }

  playlistContentInitDone = () => {
    this.setState({ playlistContentNeedReload: false })
  }

  reloadPlaylistContent = () => {
    this.setState({ playlistContentNeedReload: true })
  }

  openConfirmDeleteModalOpen = () => this.setState({ confirmDeleteModalOpen: true })

  render() {
    const {
      title,
      isNew,
      redirectToRoot,
      isInit,
      playlistHasChanged,
      replaceOrAddContentModal,
      replaceOrAddContentItemType,
      replaceOrAddContentType,
      replaceOrAddContentId,
      playlistContentNeedReload
    } = this.state
    const { myOrganization, videos, webContents, organizations, me, categories } = this.props

    if (redirectToRoot) {
      return <Redirect to="/playlists" />
    }
    if (!isInit) return <ListLoader />

    const newElement = {
      owner: {
        id: this.props.myOrganization.id,
        name: this.props.myOrganization.name
      }
    }

    const data = isNew ? newElement : this.props.playlists.focus.data

    const userCanEdit =
      me.role === "ADMIN" &&
      (data.owner.id === myOrganization.id || organizations.list.data.some(i => i.id === data.owner.id))

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/playlists" />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors, values }) => {
                  return (
                    <Form>
                      <PlaylistForm
                        organizations={organizations}
                        isNew={isNew}
                        element={data}
                        userCanEdit={userCanEdit}
                        myOrganization={myOrganization}
                        values={values}
                        errors={errors}
                        loading={this.props.playlists.focus.loading}
                        openConfirmDeleteModalOpen={this.openConfirmDeleteModalOpen}
                      />
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          {!isNew && (
            <PlaylistHandler
              videos={videos}
              webContents={webContents}
              content={data.content}
              needReload={playlistContentNeedReload}
              initDone={this.playlistContentInitDone}
              categories={categories}
              organizations={organizations}
              userCanEdit={userCanEdit}
              update={this.onPlaylistContentUpdate}
              setCurrentSelectedContent={this.setCurrentSelectedContent}
              hasChanged={playlistHasChanged}
              setHasChanged={this.setPlaylistHasChanged}
              myOrganization={this.props.myOrganization}
              playlistId={this.props.playlists.focus.data.id}
              ownerId={this.props.playlists.focus.data.owner.id}
              replaceContent={this.replaceContent}
              addAfterContent={this.addAfterContent}
            />
          )}

          <ReplaceOrAdd
            reloadContent={this.reloadPlaylistContent}
            id={replaceOrAddContentId}
            itemType={replaceOrAddContentItemType}
            visible={replaceOrAddContentModal}
            close={this.closeReplaceOrAddContentModal}
            type={replaceOrAddContentType}
          />

          <Confirm
            open={this.state.confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  videos: state.videos.list,
  webContents: state.webContents.list,
  playlists: state.playlists,
  organizations: state.organizations,
  myOrganization: state.organizations.me.data,
  me: state.users.me.data,
  categories: state.categories.list.data
})

const mapDispatchToProps = {
  getPlaylistFromStore,
  apiGetPlaylist,
  apiPostPlaylist,
  apiPatchPlaylist,
  apiPatchPlaylistContent,
  apiDeletePlaylist,
  resetFocusPlaylist,
  apiGetVideos,
  apiGetWebContents
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Playlist))
