import React, { Fragment } from "react"
import { Col, Row, Button } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import getThumbnailSrc from "utils/getThumbnailSrc"
import { Dropdown } from "semantic-ui-react"
import i18n from "utils/i18n"

const GridLink = ({ element, linkActivated, children }) => {
  if (linkActivated)
    return (
      <Link to={`/videos/${element.id}`} className="grid-link">
        {children}
      </Link>
    )

  return <Fragment>{children}</Fragment>
}

const GridItemVideo = ({
  element,
  handlers = {
    userCanDownloadList: [],
    download: id => id,
    copyIntegrationCode: id => id
  },
  linkActivated = true,
  actionsActivated = true,
  smallText = false
}) => {
  return (
    <GridLink element={element} linkActivated={linkActivated}>
      <div className="grid-element-top">
        <img src={getThumbnailSrc(element.thumbnail, "VIDEO")} alt="" className="grid-thumbnail" />
      </div>
      <div className="grid-element-subcontainer">
        <Row>
          <Col xs={12} className="grid-element-firstrow">
            <div
              className="element-title text-ellipsis"
              style={{ letterSpacing: 0, fontSize: smallText ? "0.9em" : "1em" }}
            >
              {element.title}
            </div>
            <div className="video-time">{moment.utc(element.duration * 1000).format("mm:ss")}</div>
          </Col>
        </Row>
        <Row className="grid-element-second-row">
          <Col xs={9} className="grid-element">
            <div className="line-element-info">
              <div className="content">
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
          {actionsActivated && (
            <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dropdown
                icon={null}
                floating
                direction="left"
                onClick={e => {
                  e.preventDefault()
                }}
                trigger={
                  <Button className="pe-7s-more btn-icon-wrapper" size="sm" color="light">
                    {" "}
                  </Button>
                }
              >
                <Dropdown.Menu>
                  {handlers.userCanDownloadList.includes(element.owner.id) && (
                    <Dropdown.Item
                      className="deviceGroup-handler-item"
                      text={i18n.t("actions.download")}
                      onClick={() => handlers.download(element.id)}
                    />
                  )}
                  <Dropdown.Item
                    className="deviceGroup-handler-item"
                    text={i18n.t("actions.copyIntegrationCode")}
                    onClick={() => handlers.copyIntegrationCode(element.id)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          )}
        </Row>
      </div>
    </GridLink>
  )
}

export default GridItemVideo
