import React, { Fragment, Component } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import i18n from "utils/i18n"
import { Row, Col } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import MainContainer from "components/PageStructure/MainContainer"
import _ from "lodash"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SharedSwitch from "components/Form/SharedSwitch"
import { BASE_API_URL } from "config/api"
import IntegrationCodeButton from "components/Buttons/IntegrationCodeButton"

class CustomForm extends Component {
  state = {
    showIntegrationCode: false
  }

  toggleIntegrationCode = () => this.setState(prevState => ({ showIntegrationCode: !prevState.showIntegrationCode }))

  render() {
    const {
      organizations,
      userCanEdit,
      myOrganization,
      element,
      values,
      errors,
      loading,
      isNew,
      openConfirmDeleteModalOpen
    } = this.props
    const { showIntegrationCode } = this.state

    const urlStreamingLink = `${BASE_API_URL}player/${element.owner.id}/streaming/${element.id}`
    const webTvFullscreen = `${BASE_API_URL}player/${element.owner.id}/webTV/${element.id}/fullscreen`
    const webTvList = `${BASE_API_URL}player/${element.owner.id}/webTV/${element.id}/list`

    const getOrganizationFromList = organization => {
      return organizations.list.data.find(i => i.id === organization.value)
    }

    return (
      <Fragment>
        {userCanEdit && (
          <Row>
            <Col xs={6}>
              <FormSaveButton
                isLoading={loading}
                text={i18n.t("actions.save")}
                type="submit"
                disabled={!_.isEmpty(errors)}
              />
            </Col>
            <Col xs={6}>
              {!isNew && (
                <FormDeleteButton
                  text={i18n.t("actions.delete")}
                  disabled={false}
                  type="button"
                  className="form-delete-button-right"
                  onClick={() => openConfirmDeleteModalOpen()}
                />
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} md={12}>
            <MainContainer title={i18n.t("channels.pageTitle.owner")}>
              {isNew && (
                <Field
                  component={SelectAutoComplete}
                  required
                  name="owner"
                  placeholder={i18n.t("form.actions.chooseOrganization")}
                  options={generateMultiSelectOptions(
                    [
                      { id: myOrganization.id, name: `${myOrganization.name} (${i18n.t("form.me")})` },
                      ...organizations.list.data.filter(e => e.id !== myOrganization.id)
                    ],
                    "id",
                    "name"
                  )}
                />
              )}

              {!isNew && <OrganizationWithLogoDisplayer organization={element.owner} style={{ marginTop: 0 }} />}
            </MainContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <MainContainer title={i18n.t("channels.pageTitle.informations")}>
              <Field
                component={SharedSwitch}
                name="shared"
                editable={userCanEdit}
                label={i18n.t("entities.attributes.sharing")}
                required
              />
              <Row>
                <Col xs={6}>
                  <Field
                    type="text"
                    name="title"
                    editable={userCanEdit}
                    label={i18n.t("entities.attributes.title")}
                    component={ClassicInput}
                    required
                  />
                </Col>
                <Col xs={6}>
                  <Field
                    component={SelectAutoComplete}
                    label={i18n.t("entities.singular.group")}
                    required={false}
                    editable
                    name="groups"
                    placeholder={i18n.t("form.actions.chooseGroup")}
                    options={[{ value: "", label: "Aucun" }].concat(
                      generateMultiSelectOptions(myOrganization.localGroups, "id", "name", "CHANNEL")
                    )}
                  />
                </Col>
              </Row>
            </MainContainer>
          </Col>
        </Row>
        {!isNew && (
          <Fragment>
            <Row>
              <Col xs={12}>
                <IntegrationCodeButton
                  disabled={false}
                  type="button"
                  text="Codes d'intégration"
                  icon="link"
                  onClick={() => this.toggleIntegrationCode()}
                  active={showIntegrationCode}
                />
              </Col>
            </Row>

            {showIntegrationCode && (
              <Row style={{ marginTop: "1em" }}>
                <Col xs={4}>
                  <div className="collapsible-element-info">
                    <div className="label" style={{ marginBottom: 3 }}>
                      {i18n.t("entities.attributes.urlStreamingLink")}
                    </div>
                    <div className="link-content">
                      <a href={urlStreamingLink} target="_blank">
                        {urlStreamingLink}
                      </a>
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="collapsible-element-info">
                    <div className="label" style={{ marginBottom: 3 }}>
                      {i18n.t("entities.attributes.webTvFullscreen")}
                    </div>
                    <div className="iframe-content">
                      {`<iframe
                          src="${webTvFullscreen}"
                          width="640"
                          height="365"
                          allowfullscreen="true"
                          frameborder="0"
                        ></iframe>`}
                    </div>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="collapsible-element-info">
                    <div className="label" style={{ marginBottom: 3 }}>
                      {i18n.t("entities.attributes.webTvList")}
                    </div>
                    <div className="iframe-content">
                      {`<iframe
                          src="${webTvList}"
                          width="1100"
                          height="429"
                          allowfullscreen="true"
                          frameborder="0"
                        ></iframe>`}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

export default CustomForm
