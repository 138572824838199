import {
  API_GET_ALL_CHANNEL,
  API_GET_ONE_CHANNEL,
  API_POST_CHANNEL,
  API_DELETE_CHANNEL,
  GET_ONE_CHANNEL,
  INIT_CHANNEL,
  RESET_FOCUS_CHANNEL,
  API_PATCH_CHANNEL,
  API_UPLOAD_IMAGE_CHANNEL,
  API_DELETE_IMAGE_CHANNEL
} from "actions/channels"

export function getChannelFromStore(id) {
  return {
    type: GET_ONE_CHANNEL,
    payload: {
      id
    }
  }
}

export function apiGetChannels() {
  return {
    type: API_GET_ALL_CHANNEL,
    payload: {
      request: {
        url: `/channels`
      }
    }
  }
}

export function apiGetChannel(id) {
  return {
    type: API_GET_ONE_CHANNEL,
    payload: {
      request: {
        url: `/channels/${id}`
      }
    }
  }
}

export function apiPostChannel(_data) {
  return {
    type: API_POST_CHANNEL,
    payload: {
      request: {
        url: `/channels`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchChannel(id, _data) {
  return {
    type: API_PATCH_CHANNEL,
    payload: {
      request: {
        url: `/channels/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteChannel(id) {
  return {
    type: API_DELETE_CHANNEL,
    payload: {
      request: {
        url: `/channels/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function apiUploadChannelDisplayParametersImage(id, _data, savedParameters) {
  return {
    type: API_UPLOAD_IMAGE_CHANNEL,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/channels/${id}/displayParameters/uploadImage`,
        method: "POST",
        data: _data
      },
      savedParameters
    }
  }
}

export function apiDeleteChannelDisplayParametersImage(id, _data, savedParameters) {
  return {
    type: API_DELETE_IMAGE_CHANNEL,
    payload: {
      request: {
        url: `/channels/${id}/displayParameters/deleteImage`,
        method: "POST",
        data: _data
      },
      savedParameters
    }
  }
}

export function initChannel() {
  return {
    type: INIT_CHANNEL
  }
}

export function resetFocusChannel() {
  return {
    type: RESET_FOCUS_CHANNEL
  }
}
