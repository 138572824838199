import React, { Component, Fragment } from "react"
import { Col } from "reactstrap"

export class GridCollapsableElement extends Component {
  render() {
    const { element, collapseId, gridSpace, selectable, selection, select } = this.props
    const isCollapsed = element.id === collapseId
    return (
      <Fragment>
        <Col
          xs={gridSpace}
          className="grid-element-col"
          onClick={() => {
            if (this.props.collapsibleData !== null && !selectable) this.props.collapseElt(element.id)
            if (selectable) select(element.id)
          }}
        >
          <div
            className={`grid-element-container ${isCollapsed ? "collapsed" : ""} ${
              this.props.collapsibleData !== null ? "cursor-pointer" : ""
            } ${selectable && selection.includes(element.id) ? "grid-selected" : ""}`}
          >
            {this.props.lineData}
          </div>
          {this.props.collapsibleData !== null && (
            <div className={`grid-element-content-container ${isCollapsed ? "collapsed" : ""}`}>
              <div className="grid-element-content">{this.props.collapsibleData}</div>
            </div>
          )}
        </Col>
      </Fragment>
    )
  }
}

export default GridCollapsableElement
