import React, { Component } from "react"
import { Modal, Button } from "semantic-ui-react"
import Select from "react-select"

export class ChooseOwner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedId: null
    }
  }

  changeOwner = e => {
    this.setState({ selectedId: e.value })
  }

  render() {
    const { organizations, submit, cancel, visible } = this.props
    const { selectedId } = this.state

    return (
      <Modal size="small" open={visible} onClose={() => cancel()}>
        <Modal.Header>Sélectionnez le propriétaire de la nouvelle playlist</Modal.Header>
        <Modal.Content>
          <Select
            placeholder="Propriétaire"
            classNamePrefix="selectDeviceSource"
            className="selectDeviceSourceContainer"
            isDisabled={false}
            defaultValue={selectedId}
            label="Propriétaire"
            options={organizations
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(i => ({ value: i.id, label: i.name }))}
            onChange={e => this.changeOwner(e)}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => cancel()}>
            Annuler
          </Button>
          <Button positive disabled={selectedId === null} onClick={() => submit(selectedId)}>
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ChooseOwner
