import { API_POST_LOCALGROUP, API_DELETE_LOCALGROUP, API_PATCH_LOCALGROUP } from "actions/localGroups"

export function apiPostLocalGroup(_data) {
  return {
    type: API_POST_LOCALGROUP,
    payload: {
      request: {
        url: `/localGroups`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchLocalGroup(_data) {
  return {
    type: API_PATCH_LOCALGROUP,
    payload: {
      request: {
        url: `/localGroups/${_data.id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteLocalGroup(id) {
  return {
    type: API_DELETE_LOCALGROUP,
    payload: {
      request: {
        url: `/localGroups/${id}`,
        method: "DELETE"
      }
    }
  }
}
