import React, { Fragment } from "react"
import Select from "react-select"
import chroma from "chroma-js"
import MultipleTagDisplayer from "components/Tags/MultipleTagDisplayer"

const TagHandler = ({
  options = [],
  placeholder,
  label = null,
  editable = true,
  required,
  type,
  field: { name, value, onBlur },
  form: { errors, touched, setFieldValue }
}) => {
  const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color)
      return {
        ...styles,
        backgroundColor: (() => {
          if (isDisabled) {
            return null
          }
          if (isSelected) {
            return data.color
          }
          if (isFocused) {
            return color.alpha(0.1).css()
          }
          return null
        })(),
        color: (() => {
          if (isDisabled) return "#ccc"
          else if (isSelected) {
            if (chroma.contrast(color, "white") > 2) {
              return "white"
            }
            return "black"
          }
          return data.color
        })(),
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css())
        }
      }
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color)
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
        border: `1px solid ${data.color}`
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ":hover": {
        backgroundColor: data.color,
        color: "white"
      }
    })
  }

  const _onChange = _value => {
    setFieldValue(name, _value)
  }

  return (
    <div className="form-input-container">
      <div className="form-label">
        {label !== null && <span>{label}</span>}
        {label !== null && required && <span className="form-cross-required">*</span>}
      </div>

      {!editable && (
        <MultipleTagDisplayer tags={value.map(v => ({ name: v.label, id: v.id, color: v.color }))} type={type} />
      )}

      {editable && (
        <Fragment>
          <Select
            value={value}
            onChange={_onChange}
            onBlur={onBlur}
            isMulti
            name={name}
            options={options}
            styles={colourStyles}
            placeholder={placeholder}
            className="basic-multi-select"
            classNamePrefix="tagHandler"
          />
          {touched[name] && errors[name] && <div className="form-error-style">{errors[name]}</div>}
        </Fragment>
      )}
    </div>
  )
}

export default TagHandler
