import React from "react"
import GridItemVideo from "features/Videos/List/Items/GridItem"
import GridItemWebContent from "features/WebContents/List/Items/GridItem"

const GridContentItem = ({ item, provided, style, deleteContent, userCanEdit, index }) => {
  return (
    <div
      className="grid-element-col"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...style, marginTop: "1.5em", position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          top: "-16px",
          left: 5,
          padding: "0 2px",
          backgroundColor: "#cecece",
          color: "#fff"
        }}
      >
        {index}
      </div>
      {userCanEdit && (
        <div style={{ position: "absolute", top: "-20px", right: 0 }}>
          <i
            onClick={() => deleteContent(item, index)}
            className="pe-7s-close btn-icon-wrapper color-red cursor-pointer"
            style={{ float: "left", fontSize: "2em" }}
          />
        </div>
      )}
      {item.type === "VIDEO" && <GridItemVideo element={item.data} linkActivated={false} />}
      {item.type === "WEBCONTENT" && <GridItemWebContent element={item.data} linkActivated={false} />}
    </div>
  )
}

export default GridContentItem
