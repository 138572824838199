import React, { Component, Fragment } from "react"
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import blobToFile from "utils/blobToFile"
import i18n from "utils/i18n"

class ThumbnailHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInit: false,
      importChoice: null,
      src: null,
      dropdownOpened: false
    }
  }

  componentDidMount() {
    document.getElementById("videoPlayer").setAttribute("crossorigin", "anonymous")
  }

  initCapture = () => {
    this.setState({ isInit: true })
  }

  capture = () => {
    const canvas = document.getElementById("canvas")
    const video = document.getElementById("videoPlayer")

    // @ts-ignore
    canvas.width = video.videoWidth
    // @ts-ignore
    canvas.height = video.videoHeight
    // @ts-ignore
    canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
    // @ts-ignore
    canvas.toBlob(blob => {
      const img = new Image()
      img.setAttribute("crossOrigin", "anonymous")
      img.src = URL.createObjectURL(blob)
      this.setState({ isInit: false, importChoice: null })

      this.props.onChange(img.src, blobToFile(blob, "thumbnail.png", "image/png"))
    })
  }

  onInVideoChoice = () => {
    this.setState({ isInit: true, importChoice: "inVideo" })
    this.props.showThumbnailTip()
  }

  thumbnailInputClick = () => {
    document.getElementById("thumbnailInput").click()
  }

  onThumbnailLibraryImport = e => {
    const file = e.target.files[0]
    this.props.onChange(URL.createObjectURL(file), file)
    this.setState({ isInit: false, importChoice: null })
  }

  render() {
    const { isInit, src, dropdownOpened, importChoice } = this.state
    const { isThumbnailUploaded } = this.props
    return (
      <div className="form-input-container" style={{ textAlign: "right" }}>
        <input
          type="file"
          name="thumbnailInput"
          id="thumbnailInput"
          className="form-video-input"
          onChange={this.onThumbnailLibraryImport}
        />
        {!isInit && (
          <Dropdown isOpen={dropdownOpened} toggle={() => this.setState({ dropdownOpened: !dropdownOpened })}>
            <DropdownToggle tag="span" data-toggle="dropdown">
              <Button className="mb-3 btn-icon" color="success" type="button" outline={isThumbnailUploaded} size="md">
                <i className="pe-7s-photo btn-icon-wrapper"> </i>
                {!isThumbnailUploaded ? i18n.t("videos.thumbnail.add") : i18n.t("videos.thumbnail.edit")}
              </Button>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => this.onInVideoChoice()}>
                {i18n.t("videos.thumbnail.chooseInVideo")}
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  this.setState({ isInit: true, importChoice: "fromLibrary" })
                  this.thumbnailInputClick()
                }}
              >
                {i18n.t("videos.thumbnail.import")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
        {isInit && importChoice === "inVideo" && (
          <Fragment>
            <Button className="mb-3 btn-icon" color="success" type="button" size="md" onClick={() => this.capture()}>
              <i className="pe-7s-photo btn-icon-wrapper"> </i>
              {i18n.t("actions.validate")}
            </Button>
          </Fragment>
        )}
        {src !== null && <img src={src} crossOrigin="anonymous" alt="src" />}
      </div>
    )
  }
}

export default ThumbnailHandler
