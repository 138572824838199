import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import ReleaseStatusChip from "components/ReleaseStatusChip"
import moment from "moment"
import React from "react"
import { Col, Row } from "reactstrap"

const Collapsible = ({ element }) => {
  return (
    <div className="collapsible-container">
      <Row className="collapsible-first-row">
        <Col xs={4}>
          <b>Écran</b>
        </Col>
        <Col xs={4}>
          <b>Propriétaire</b>
        </Col>
      </Row>

      {element.releaseStatus?.devices.map(d => (
        <Row style={{ padding: "10px 0", borderTop: "1px solid #efefef" }}>
          <Col xs={4}>{d.device.name}</Col>
          <Col xs={4}>
            <OrganizationWithLogoDisplayer organization={d.device.owner} style={{ height: 20, marginTop: 0 }} />
          </Col>
          <Col xs={2}>
            <ReleaseStatusChip isSynced={d.isSynced} />
          </Col>
          <Col xs={2} style={{ display: "flex", alignItems: "center", fontSize: "0.9em" }}>
            {d.device.lastReleaseStatusUpdate !== null
              ? moment(d.device.lastReleaseStatusUpdate).format("DD/MM/YYYY - HH:mm")
              : ""}
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default Collapsible
