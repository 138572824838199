/* eslint-disable no-loop-func */
import React, { Component, Fragment } from "react"
import { Sidebar } from "semantic-ui-react"
import ListLoader from "components/List/ListLoader"
import { connect } from "react-redux"
import Select from "react-select"
import { Link } from "react-router-dom"

import {
  apiGetDevice,
  apiMagicInfoGetTime,
  apiMagicInfoPowerOnDevice,
  apiMagicInfoPowerOffDevice,
  apiMagicInfoRestartDevice,
  apiMagicInfoClearDiskDevice,
  apiMagicInfoChangeSourceDevice
} from "services/devices"

import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SidebarCloseButton from "components/SidebarCloseButton"
import DeviceStatus from "components/Device/DeviceStatus"
import TizenVersion from "./components/TizenVersion"
import DeviceName from "./components/DeviceName"
import Planning from "./components/Planning"
import CurrentProgram from "./components/CurrentProgram"
import DeviceStorage from "./components/Storage"
import QuickAccessButton from "./components/QuickAccessButton"

class QuickAccess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timeLoading: false,
      basicSource: null,
      sourceLoading: false,
      cannotBeReached: false
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.deviceId !== this.props.deviceId && this.props.deviceId !== null) {
      this.getDevice()
    }
  }

  getDevice = async () => {
    await this.props.apiGetDevice(this.props.deviceId)
    const { data } = this.props.devices.focus
    if (data.status === "DISCONNECTED" || (data.status === "OFF" && data.display.networkStandbyMode === 0)) {
      this.setState({ cannotBeReached: true })
    } else if (data.status !== "UNPAIRED") {
      const { display } = this.props.devices.focus.data
      const source = display.inputSourceList.find(i => i.code === display.basicSource.toString())
      this.setState({
        basicSource: {
          value: display.basicSource.toString(),
          label: source !== undefined ? source.text : "Inconnu"
        }
      })
      this.getDeviceTime()
    }
  }

  getDeviceTime = async () => {
    this.setState({ timeLoading: true })

    await this.props.apiMagicInfoGetTime(this.props.deviceId)

    this.setState({ timeLoading: false })
  }

  onCancel = () => {
    this.props.onCancel()
  }

  powerOn = () => {
    this.props.apiMagicInfoPowerOnDevice(this.props.deviceId)
  }

  powerOff = () => {
    this.props.apiMagicInfoPowerOffDevice(this.props.deviceId)
  }

  restart = () => {
    this.props.apiMagicInfoRestartDevice(this.props.deviceId)
  }

  changeSource = async e => {
    this.setState({ basicSource: e, sourceLoading: true })
    await this.props.apiMagicInfoChangeSourceDevice(this.props.deviceId, { source: e.value })

    this.setState({ sourceLoading: false })
  }

  clearDisk = () => {
    this.props.apiMagicInfoClearDiskDevice(this.props.deviceId)
  }

  render() {
    const { visible, devices, close } = this.props
    const { timeLoading, cannotBeReached, basicSource, sourceLoading } = this.state

    const { data } = devices.focus
    const deviceIsPaired = data.status !== "UNPAIRED"

    return (
      <Sidebar
        as="div"
        animation="overlay"
        direction="right"
        visible={visible}
        className="sidebar-editor"
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        {devices.focus.loading && <ListLoader />}
        {data !== null && !devices.focus.loading && this.props.devices.focus.loaded && (
          <div className="quickaccess-container">
            <SidebarCloseButton onClick={() => close()} style={{ position: "absolute", top: "25px", right: "1em" }} />

            <div className="quickaccess-sidebar-title" style={{ padding: "1em 0", marginBottom: "1em" }}>
              {i18n.t("entities.singular.device")}
              {data.magicInfoId !== null && data.general !== null && data.general !== undefined && (
                <TizenVersion deviceTypeVersion={data.general.deviceTypeVersion} />
              )}
              <DeviceStatus status={data.status} style={{ marginLeft: 5 }} />
            </div>

            <DeviceName name={data.name} />
            <OrganizationWithLogoDisplayer organization={data.owner} style={{ height: 20, marginTop: "0.2em" }} />

            {data.magicInfoId !== null && data.general !== null && (
              <Fragment>
                {deviceIsPaired && data.general !== undefined && (
                  <DeviceStorage
                    diskSpaceAvailable={data.general.diskSpaceAvailable}
                    diskSpaceUsage={data.general.diskSpaceUsage}
                  />
                )}
                {deviceIsPaired && data.time !== undefined && (
                  <Planning cannotBeReached={cannotBeReached} loading={timeLoading} time={data.time} />
                )}

                <br />

                <CurrentProgram program={data.program} />

                {deviceIsPaired && (
                  <div className="quickaccess-power-buttons">
                    <QuickAccessButton
                      text="Marche"
                      className="poweron"
                      onClick={() => this.powerOn()}
                      style={{ marginRight: 5 }}
                      disabled={cannotBeReached}
                    />
                    <QuickAccessButton
                      text="Arrêt"
                      className="poweroff"
                      onClick={() => this.powerOff()}
                      style={{ marginLeft: 5 }}
                      disabled={cannotBeReached}
                    />
                  </div>
                )}
                {deviceIsPaired && data.display !== undefined && basicSource !== null && (
                  <Select
                    placeholder="Source"
                    classNamePrefix="selectDeviceSource"
                    className="selectDeviceSourceContainer"
                    isDisabled={cannotBeReached || sourceLoading}
                    defaultValue={basicSource}
                    label={i18n.t("devices.attributes.source")}
                    options={data.display.inputSourceList.map(i => ({ value: i.code, label: i.text }))}
                    onChange={e => this.changeSource(e)}
                  />
                )}

                {deviceIsPaired && (
                  <Fragment>
                    <QuickAccessButton
                      text="Redémarrer"
                      className="restart"
                      onClick={() => this.restart()}
                      disabled={cannotBeReached}
                    />
                    <QuickAccessButton
                      text="Vider espace disque"
                      className="cleardisk"
                      onClick={() => this.clearDisk()}
                      disabled={cannotBeReached}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            <Link to={`/devices/${data.id}`} onClick={() => close()}>
              <QuickAccessButton text="Paramètres avancés" className="advanced" onClick={() => true} />
            </Link>
          </div>
        )}
      </Sidebar>
    )
  }
}

const mapStateToProps = state => ({
  devices: state.devices,
  organizations: state.organizations.list
})

const mapDispatchToProps = {
  apiGetDevice,
  apiMagicInfoGetTime,
  apiMagicInfoPowerOnDevice,
  apiMagicInfoPowerOffDevice,
  apiMagicInfoRestartDevice,
  apiMagicInfoClearDiskDevice,
  apiMagicInfoChangeSourceDevice
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccess)
