import React from "react"
import TreeView from "react-treeview"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Loader } from "semantic-ui-react"
import OrgaTree from "./OrgaTree"

class Lists extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      collapsed: this.props.data.map(() => true)
    }
  }

  resetFilter = () => {
    this.setState(prevState => ({
      collapsed: prevState.collapsed.map(() => true)
    }))
    this.props.changeListFilter(null, null)
  }

  handleClick = (i, id) => {
    const [...collapsed] = this.state.collapsed
    collapsed[i] = !collapsed[i]
    this.setState({ collapsed })
    this.props.changeListFilter(id, null)
  }

  collapseAll = () => {
    this.setState(prevState => ({
      collapsed: prevState.collapsed.map(() => true)
    }))
  }

  render() {
    const { collapsed } = this.state
    const {
      data,
      onDeviceGroupSubmit,
      onDeviceGroupUpdate,
      onDeviceGroupDelete,
      initialLevel,
      listFilter,
      changeListFilter,
      refreshing
    } = this.props

    return (
      <div className="tree-container">
        <div className="tree-header-buttons">
          <div
            className={`btn-header-device-tree ${listFilter.orga === null ? "active" : ""}`}
            onClick={() => this.resetFilter()}
          >
            Organisations{" "}
            {refreshing && <Loader active inline size="tiny" inverted className="device-refresh-loader" />}
          </div>
        </div>

        <PerfectScrollbar style={{ position: "relative" }} className="scrollbar-extra-right">
          {data !== null &&
            data.map((orga, i) => {
              const orgaLabel = (
                <div className="node text-ellipsis" onClick={() => this.handleClick(i, orga.id)}>
                  <OrganizationWithLogoDisplayer
                    organization={orga}
                    style={{ marginTop: 0, height: 20, width: "90%" }}
                  />{" "}
                  {orga.mine === true && <div className="tree-orga-mine">{i18n.t("organizations.mine")}</div>}
                </div>
              )

              return (
                <TreeView
                  key={`organization-${orga.id}`}
                  nodeLabel={orgaLabel}
                  collapsed={collapsed[i]}
                  treeViewClassName={`tree-sub-orga-${orga.level - initialLevel}`}
                  itemClassName={`tree-view_item orga-tree ${listFilter.orga === orga.id ? "active" : ""}`}
                  onClick={() => this.handleClick(i, orga.id)}
                >
                  <OrgaTree
                    orga={orga}
                    focus={this.props.focus}
                    onDeviceGroupSubmit={onDeviceGroupSubmit}
                    onDeviceGroupUpdate={onDeviceGroupUpdate}
                    onDeviceGroupDelete={onDeviceGroupDelete}
                    listFilter={listFilter}
                    changeListFilter={changeListFilter}
                  />
                </TreeView>
              )
            })}
        </PerfectScrollbar>
      </div>
    )
  }
}

export default Lists
