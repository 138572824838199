import React, { Fragment } from "react"
import { Icon } from "semantic-ui-react"
import InputRange from "react-input-range"
import { Button } from "reactstrap"
import i18n from "utils/i18n"
import ListSearchInput from "./ListSearchInput"

const DeviceFilters = ({
  filters,
  layout,
  onFilterChange,
  onLayoutChange,
  gridSpace,
  administrables,
  onGridSpaceChange,
  multipleLayouts = false
}) => {
  const counter = {
    all: administrables.length,
    ON: administrables.filter(i => i.status === "ON").length,
    OFF: administrables.filter(i => i.status === "OFF").length,
    DISCONNECTED: administrables.filter(i => i.status === "DISCONNECTED").length,
    UNPAIRED: administrables.filter(i => i.status === "UNPAIRED").length
  }

  return (
    <div className="list-filters-container">
      <div className="list-filters-left">
        <div className="filterButton">
          <Button
            className={`btn-icon btn-pill btn ${
              filters.selectSingle[0].value === null ? "btn-dark-blue" : "btn-outline-dark-blue"
            }`}
            size="md"
            color="none"
            onClick={() => onFilterChange("selectSingle", "status", null)}
          >
            <i className="pe-7s-monitor btn-icon-wrapper"> </i>
            {i18n.t("devices.status.all")} &nbsp;<b>{counter.all}</b>
          </Button>
        </div>

        <div className="filterButton">
          <Button
            className="btn-icon btn-pill btn"
            color="success"
            outline={filters.selectSingle[0].value !== "ON"}
            size="md"
            onClick={() => onFilterChange("selectSingle", "status", "ON")}
          >
            {i18n.t("devices.status.on")} &nbsp;<b>{counter.ON}</b>
          </Button>
        </div>

        <div className="filterButton">
          <Button
            className="btn-icon btn-pill btn"
            color="warning"
            outline={filters.selectSingle[0].value !== "OFF"}
            size="md"
            onClick={() => onFilterChange("selectSingle", "status", "OFF")}
          >
            {i18n.t("devices.status.off")} &nbsp;<b>{counter.OFF}</b>
          </Button>
        </div>

        <div className="filterButton">
          <Button
            className="btn-icon btn-pill btn"
            color="danger"
            outline={filters.selectSingle[0].value !== "DISCONNECTED"}
            size="md"
            onClick={() => onFilterChange("selectSingle", "status", "DISCONNECTED")}
          >
            {i18n.t("devices.status.disconnected")} &nbsp;<b>{counter.DISCONNECTED}</b>
          </Button>
        </div>

        <div className="filterButton">
          <Button
            className="btn-icon btn-pill btn"
            color="secondary"
            outline={filters.selectSingle[0].value !== "UNPAIRED"}
            size="md"
            onClick={() => onFilterChange("selectSingle", "status", "UNPAIRED")}
          >
            {i18n.t("devices.status.unpaired")} &nbsp;<b>{counter.UNPAIRED}</b>
          </Button>
        </div>
        <ListSearchInput onChange={value => onFilterChange("search", "", value)} filter={filters.search} />
      </div>
      <div className="filters-layout-choice">
        <Fragment>
          {layout === "grid" && (
            <InputRange
              formatLabel={() => ""}
              maxValue={3}
              minValue={1}
              value={gridSpace}
              onChange={value => onGridSpaceChange(value)}
            />
          )}

          {multipleLayouts && (
            <i
              onClick={() => onLayoutChange("grid")}
              style={{ fontSize: "26px", marginRight: "10px" }}
              className={`pe-7s-keypad btn-icon-wrapper layout-choice-icon ${
                layout === "grid" ? "color-gray-dark" : "color-light-gray"
              }`}
            >
              {" "}
            </i>
          )}
          {multipleLayouts && (
            <i
              onClick={() => onLayoutChange("list")}
              className={`pe-7s-menu btn-icon-wrapper layout-choice-icon ${
                layout === "list" ? "color-gray-dark" : "color-light-gray"
              }`}
            >
              {" "}
            </i>
          )}
        </Fragment>
      </div>
    </div>
  )
}

export default DeviceFilters
