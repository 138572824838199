import React, { Component } from "react"
import { Button } from "reactstrap"
import i18n from "utils/i18n"

class WebContentThumbnailHandler extends Component {
  thumbnailInputClick = () => {
    document.getElementById("thumbnailInput").click()
  }

  onThumbnailLibraryImport = e => {
    const file = e.target.files[0]
    this.props.onChange(URL.createObjectURL(file), file)
  }

  render() {
    const { isThumbnailUploaded } = this.props
    return (
      <div className="form-input-container" style={{ textAlign: "right" }}>
        <input
          type="file"
          name="thumbnailInput"
          id="thumbnailInput"
          className="form-video-input"
          onChange={this.onThumbnailLibraryImport}
        />
        <Button
          className="mb-3 btn-icon"
          color="success"
          type="button"
          outline={isThumbnailUploaded}
          size="md"
          onClick={() => {
            this.thumbnailInputClick()
          }}
        >
          <i className="pe-7s-photo btn-icon-wrapper"> </i>
          {!isThumbnailUploaded ? i18n.t("webContents.thumbnail.add") : i18n.t("webContents.thumbnail.edit")}
        </Button>
      </div>
    )
  }
}

export default WebContentThumbnailHandler
