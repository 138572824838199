import { API_POST_DEVICEGROUP, API_PATCH_DEVICEGROUP, API_DELETE_DEVICEGROUP } from "actions/deviceGroups"

export function apiPostDeviceGroup(_data) {
  return {
    type: API_POST_DEVICEGROUP,
    payload: {
      request: {
        url: `/deviceGroups`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchDeviceGroup(id, _data) {
  return {
    type: API_PATCH_DEVICEGROUP,
    payload: {
      request: {
        url: `/deviceGroups/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteDeviceGroup(id) {
  return {
    type: API_DELETE_DEVICEGROUP,
    payload: {
      request: {
        url: `/deviceGroups/${id}`,
        method: "DELETE"
      }
    }
  }
}
