import { API_POST_SHARINGRULE, API_PATCH_SHARINGRULE, API_DELETE_SHARINGRULE } from "actions/sharingRules"

export function apiPostSharingRule(_data) {
  return {
    type: API_POST_SHARINGRULE,
    payload: {
      request: {
        url: `/sharingRules`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchSharingRule(id, _data) {
  return {
    type: API_PATCH_SHARINGRULE,
    payload: {
      request: {
        url: `/sharingRules/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteSharingRule(id) {
  return {
    type: API_DELETE_SHARINGRULE,
    payload: {
      request: {
        url: `/sharingRules/${id}`,
        method: "DELETE"
      }
    }
  }
}
