import * as React from "react"

function BigScreenFive(props) {
  return (
    <svg id="prefix__Calque_1" x={0} y={0} viewBox="0 0 600 353" xmlSpace="preserve" {...props}>
      <style>
        {".prefix__st3{fill:#fff}.prefix__st4{font-family:inherit;font-weight:600}.prefix__st5{font-size:16px}"}
      </style>
      <path fill="#e8e8e8" d="M0 0h497v280H0z" />
      <path fill="#0184df" d="M0 280h497v73H0z" />
      <path fill="#30d4ee" d="M497 0h103v353H497z" />
      <path
        className="prefix__st3"
        d="M276 140.7c-.5-14.5-12.2-26.3-26.7-26.7-7.9-.3-15.3 2.8-20.8 8.4-5.3 5.4-8 12.6-7.8 20.1.5 14.5 12.2 26.3 26.7 26.7h.9c7.6 0 14.6-3 19.9-8.4 5.2-5.4 8-12.6 7.8-20.1zm-12 16.1c-4.1 4.3-9.7 6.6-15.7 6.6h-.7c-11.5-.4-20.7-9.6-21.1-21.1-.2-6 2-11.6 6.1-15.9 4.1-4.3 9.7-6.6 15.7-6.6h.7c11.5.4 20.7 9.6 21.1 21.1.2 5.9-2 11.6-6.1 15.9z"
      />
      <path
        className="prefix__st3"
        d="M257.4 140l-12.3-8.8c-1.3-.9-3.1 0-3.1 1.6v17.6c0 1.6 1.8 2.5 3.1 1.6l12.3-8.8c1.1-.8 1.1-2.4 0-3.2z"
      />
      <text transform="translate(223.105 323.223)" className="prefix__st3 prefix__st4 prefix__st5">
        BLOC 1
      </text>
      <text transform="translate(524.605 181.223)" className="prefix__st3 prefix__st4 prefix__st5">
        BLOC 2
      </text>
    </svg>
  )
}

const MemoBigScreenFive = React.memo(BigScreenFive)
export default MemoBigScreenFive
