const initialValues = [
  {
    name: "sharingOrganization",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "canDownload",
    type: "boolean"
  },
  {
    name: "onlyShared",
    type: "boolean"
  }
]

export default initialValues
