import React, { Component, Fragment } from "react"
import { Button } from "reactstrap"
import i18n from "utils/i18n"

export class FilterButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false
    }
    this.timeOutId = null
  }

  componentDidUpdate() {
    clearTimeout(this.timeOutId)
  }

  onBlurHandler = e => {
    e.preventDefault()
    this.timeOutId = setTimeout(() => {
      this.setState({
        isMenuOpen: false
      })
    })
  }

  // If a child receives focus, do not close the popover.
  onFocusHandler = () => {
    clearTimeout(this.timeOutId)
  }

  closeOptionsMenu = () => this.setState({ isMenuOpen: false })

  toggleOptionsMenu = () => this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }))

  render() {
    const { filter, onChange, onlyLogo } = this.props
    const { isMenuOpen } = this.state

    return (
      <Fragment>
        {filter !== undefined && (
          <div className="filterButton" onBlur={e => this.onBlurHandler(e)} onFocus={() => this.onFocusHandler()}>
            <Button
              className="btn-icon btn-pill btn btn-outline-dark"
              color="dark"
              outline
              size="md"
              onClick={() => this.toggleOptionsMenu()}
            >
              <i className={`pe-7s-${filter.icon} ${onlyLogo ? "no-margin-right" : ""} btn-icon-wrapper`}> </i>
              {!onlyLogo && filter.name}
            </Button>
            <div className={`optionsMenu custom-scroll ${isMenuOpen ? "active" : ""}`}>
              <div
                className="filterOption first"
                onMouseDown={e => {
                  e.preventDefault()
                  onChange({ value: null })
                }}
              >
                <input type="checkbox" checked={filter.value.length === 0} readOnly />{" "}
                <div className="title">{i18n.t("form.all")}</div>
              </div>

              {filter.options.map(option => (
                <div
                  className="filterOption"
                  onMouseDown={e => {
                    e.preventDefault()
                    onChange(option)
                  }}
                  key={`option-${option.value}-${option.label}-${filter.name}`}
                >
                  <input type="checkbox" checked={filter.value.includes(option.value)} readOnly />{" "}
                  <div className="title text-ellipsis">{option.label}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default FilterButton
