import { toFailure, toSuccess } from "actions"
import {
  API_GET_ALL_PLAYLIST,
  API_GET_ONE_PLAYLIST,
  API_POST_PLAYLIST,
  API_PATCH_PLAYLIST,
  API_DELETE_PLAYLIST,
  INIT_PLAYLIST,
  RESET_FOCUS_PLAYLIST,
  GET_ONE_PLAYLIST,
  API_PATCH_PLAYLIST_CONTENT,
  API_COPY_PLAYLIST,
  API_REPLACE_IN_PLAYLIST,
  API_ADDAFTER_IN_PLAYLIST,
  API_GET_ONE_FOR_COPY_PLAYLIST
} from "actions/playlists"
import { toast } from "react-toastify"
import { createReducer } from "@reduxjs/toolkit"
import i18n from "utils/i18n"
import { LOGOUT_USER } from "actions/users"

const initialState = {
  list: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  focus: {
    loading: false,
    error: false,
    data: null
  },
  toCopy: null
}

const playlists = createReducer(initialState, {
  [API_GET_ALL_PLAYLIST]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_PLAYLIST)]: (state, action) => {
    state.list.loading = false
    state.list.loaded = true
    state.list.error = false
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_PLAYLIST)]: state => {
    state.list.loading = false
    state.list.loaded = false
    state.list.error = true
  },

  [API_GET_ONE_PLAYLIST]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ONE_PLAYLIST)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ONE_PLAYLIST)]: state => {
    state.focus.loading = false
    state.focus.error = true
    state.focus.data = null
  },

  [toSuccess(API_GET_ONE_FOR_COPY_PLAYLIST)]: (state, action) => {
    state.toCopy = action.payload.data
  },

  [GET_ONE_PLAYLIST]: (state, action) => {
    state.focus.data = state.list.data.find(e => e.id === Number(action.payload.id)) || null
  },

  [API_POST_PLAYLIST]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_POST_PLAYLIST)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
    state.list.data.push(action.payload.data)

    toast.success(i18n.t("playlists.api.created"))
  },
  [toFailure(API_POST_PLAYLIST)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_COPY_PLAYLIST]: state => {
    state.list.loading = true
  },
  [toSuccess(API_COPY_PLAYLIST)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.data.push(action.payload.data)

    toast.success(i18n.t("playlists.api.created"))
  },
  [toFailure(API_COPY_PLAYLIST)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_PATCH_PLAYLIST]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_PLAYLIST)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_PLAYLIST)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("playlists.api.patched"))
  },

  [API_PATCH_PLAYLIST_CONTENT]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_PLAYLIST_CONTENT)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_PLAYLIST_CONTENT)]: (state, action) => {
    console.log("data after patch", action.payload.data.content)
    state.focus.loading = false
    state.focus.data.content = action.payload.data.content

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index].content = action.payload.data.content

    toast.success(i18n.t("playlists.api.contentPatched"))
  },

  [API_DELETE_PLAYLIST]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_PLAYLIST)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_PLAYLIST)]: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    let { data } = state.list
    state.list.loading = false
    data = data.splice(
      data.findIndex(i => i.id === action.payload.data.id),
      1
    )

    state.focus.data = null
    toast.success(i18n.t("playlists.api.deleted"))
  },

  /** Replacing and adding after */
  [toSuccess(API_REPLACE_IN_PLAYLIST)]: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    state.focus.data.content = action.payload.data.currentPlaylist.content
  },
  [toSuccess(API_ADDAFTER_IN_PLAYLIST)]: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    state.focus.data.content = action.payload.data.currentPlaylist.content
  },

  [RESET_FOCUS_PLAYLIST]: state => {
    state.focus.data = null
    state.focus.loading = false
  },

  [INIT_PLAYLIST]: state => {
    state.focus.data = {}
    state.focus.loading = false
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default playlists
