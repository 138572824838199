import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  magicinfoServerUrl: Yup.string().required(i18n.t("form.requiredField")),
  urlLauncherTimeout: Yup.number().required(i18n.t("form.requiredField")),
  urlLauncherAddress: Yup.string().required(i18n.t("form.requiredField"))
})

export default validationSchema
