import { toFailure, toSuccess } from "actions"
import {
  API_GET_ALL_CHANNEL,
  API_GET_ONE_CHANNEL,
  API_POST_CHANNEL,
  API_PATCH_CHANNEL,
  API_DELETE_CHANNEL,
  INIT_CHANNEL,
  RESET_FOCUS_CHANNEL,
  GET_ONE_CHANNEL,
  API_UPLOAD_IMAGE_CHANNEL,
  API_DELETE_IMAGE_CHANNEL
} from "actions/channels"
import { toast } from "react-toastify"
import { createReducer } from "@reduxjs/toolkit"
import i18n from "utils/i18n"
import { LOGOUT_USER } from "actions/users"

const initialState = {
  list: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  focus: {
    loading: false,
    error: false,
    data: {
      displayParameters: {}
    }
  }
}

const channels = createReducer(initialState, {
  [API_GET_ALL_CHANNEL]: state => {
    state.list.loading = true
  },
  [toSuccess(API_GET_ALL_CHANNEL)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.loaded = true
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_CHANNEL)]: state => {
    state.list.loading = false
    state.list.loaded = false
    state.list.error = true
  },

  [API_GET_ONE_CHANNEL]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ONE_CHANNEL)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
  },
  [toFailure(API_GET_ONE_CHANNEL)]: state => {
    state.focus.loading = false
    state.focus.error = true
    state.focus.data = null
  },

  [GET_ONE_CHANNEL]: (state, action) => {
    state.focus.data = state.list.data.find(e => e.id === Number(action.payload.id)) || null
  },

  [API_POST_CHANNEL]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_POST_CHANNEL)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data
    state.list.data.push(action.payload.data)

    toast.success(i18n.t("channels.api.created"))
  },
  [toFailure(API_POST_CHANNEL)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_PATCH_CHANNEL]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_CHANNEL)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_CHANNEL)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("channels.api.patched"))
  },

  [API_DELETE_CHANNEL]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_CHANNEL)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_CHANNEL)]: (state, action) => {
    // eslint-disable-next-line no-unused-vars
    let { data } = state.list
    state.list.loading = false
    data = data.splice(
      data.findIndex(i => i.id === action.payload.data.id),
      1
    )

    state.focus.data = null
    toast.success(i18n.t("channels.api.deleted"))
  },

  [toSuccess(API_UPLOAD_IMAGE_CHANNEL)]: (state, action) => {
    const { bloc, channel } = action.payload.data

    // Save current params
    state.focus.data.displayParameters = action.meta.previousAction.payload.savedParameters

    state.focus.data.displayParameters[`${bloc}_img`] = channel.displayParameters[`${bloc}_img`]
    state.focus.data.displayParameters[`${bloc}_imgPosition`] = channel.displayParameters[`${bloc}_imgPosition`]
    state.focus.data.displayParameters[`${bloc}_source`] = null

    const index = state.list.data.findIndex(i => i.id === channel.id)
    // Save current params
    state.list.data[index].displayParameters = action.meta.previousAction.payload.savedParameters

    state.list.data[index].displayParameters[`${bloc}_img`] = channel.displayParameters[`${bloc}_img`]
    state.list.data[index].displayParameters[`${bloc}_imgPosition`] = channel.displayParameters[`${bloc}_imgPosition`]
    state.list.data[index].displayParameters[`${bloc}_source`] = null

    toast.success(i18n.t("channels.api.patched"))
  },

  [toSuccess(API_DELETE_IMAGE_CHANNEL)]: (state, action) => {
    const { bloc, channel } = action.payload.data

    // Save current params
    state.focus.data.displayParameters = action.meta.previousAction.payload.savedParameters

    state.focus.data.displayParameters[`${bloc}_img`] = channel.displayParameters[`${bloc}_img`]
    state.focus.data.displayParameters[`${bloc}_imgPosition`] = channel.displayParameters[`${bloc}_imgPosition`]
    state.focus.data.displayParameters[`${bloc}_source`] = null

    const index = state.list.data.findIndex(i => i.id === channel.id)
    // Save current params
    state.list.data[index].displayParameters = action.meta.previousAction.payload.savedParameters

    state.list.data[index].displayParameters[`${bloc}_img`] = channel.displayParameters[`${bloc}_img`]
    state.list.data[index].displayParameters[`${bloc}_imgPosition`] = channel.displayParameters[`${bloc}_imgPosition`]
    state.list.data[index].displayParameters[`${bloc}_source`] = null

    toast.success(i18n.t("channels.api.patched"))
  },

  [RESET_FOCUS_CHANNEL]: state => {
    state.focus.data = null
    state.focus.loading = false
  },

  [INIT_CHANNEL]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loading = false
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default channels
