import React, { Component } from "react"
import { Droppable, Draggable } from "react-beautiful-dnd"
import i18n from "utils/i18n"
import moment from "moment"
import ValidateButton from "components/Buttons/ValidateButton"
import getContentDuration from "utils/getContentDuration"
import { Row } from "reactstrap"
import LineContentItem from "./LineContentItem"

export class ProgramContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      grid: 8
    }
  }

  getItemStyle = (isDragging, draggableStyle, snapshot) => {
    const style = {
      userSelect: "none",
      padding: this.state.grid * 2,
      margin: `0 10px ${this.state.grid}px 0`,
      width: "25%",

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "grey",

      // styles we need to apply on draggables
      ...draggableStyle
    }
    if (!snapshot.isDragging) return style

    if (!snapshot.isDropAnimating) {
      return style
    }
    return {
      ...style,
      // cannot be 0, but make it super tiny
      transitionDuration: `0.001s`
    }
  }

  render() {
    const { selected, deleteContent, hasChanged, update, editProgramContentDates, userCanEdit } = this.props

    return (
      <Droppable droppableId="programContent">
        {provided => (
          <div ref={provided.innerRef} className="droppable-list-content">
            <div className="droppable-content-header">
              <div className="main-container-title">
                {i18n.t("programs.pageTitle.content")}
                <div className="video-time" style={{ marginLeft: "1em", marginRight: "1em" }}>
                  {moment.utc(getContentDuration(selected)).format("mm:ss")}
                </div>
                {hasChanged && (
                  <ValidateButton
                    disabled={false}
                    text={i18n.t("actions.save")}
                    type="button"
                    onClick={() => update()}
                  />
                )}
              </div>
            </div>
            {selected.length === 0 && (
              <div className="line-element-container-helper">{i18n.t("programs.helpInit")}</div>
            )}

            <div className="main-list-container">
              <Row style={{ marginBottom: 60 }}>
                {selected.map((item, index) => (
                  <Draggable
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.type}-${item.id}-${index}`}
                    draggableId={`${item.id}-programContent-${index}`}
                    index={index}
                    isDragDisabled={!userCanEdit}
                  >
                    {(provided2, snapshot2) => (
                      <LineContentItem
                        item={item}
                        editProgramContentDates={editProgramContentDates}
                        provided={provided2}
                        style={this.getItemStyle(snapshot2.isDragging, provided2.draggableProps.style, snapshot2)}
                        deleteContent={deleteContent}
                        index={index}
                        userCanEdit={userCanEdit}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Row>
            </div>
          </div>
        )}
      </Droppable>
    )
  }
}

export default ProgramContent
