import React from "react"
import { Button } from "reactstrap"

const DeviceActionButton = ({ text, onClick, disabled }) => {
  return (
    <Button
      className="btn-icon btn-pill btn"
      color="dark"
      outline
      size="md"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
      disabled={disabled}
      style={{ marginRight: 5 }}
    >
      {text}
    </Button>
  )
}

export default DeviceActionButton
