import React, { Fragment } from "react"

const ReleaseStatusChip = ({ isSynced = true, style = {} }) => {
  return (
    <Fragment>
      {isSynced && (
        <div className="release-status-chip synced" style={{ ...style }}>
          Publié
        </div>
      )}
      {!isSynced && (
        <div className="release-status-chip unsynced" style={{ ...style }}>
          Non publié
        </div>
      )}
    </Fragment>
  )
}

export default ReleaseStatusChip
