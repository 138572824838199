import React, { Fragment } from "react"
import { DropdownToggle, DropdownMenu, Nav, NavItem, NavLink, UncontrolledButtonDropdown } from "reactstrap"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import i18n from "utils/i18n"
import { Flag, Dropdown } from "semantic-ui-react"
import UserAvatar from "components/Users/UserAvatar"

class UserBox extends React.Component {
  render() {
    const { user, locale, logout, changeLocale } = this.props
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left header-user-info">
                <div className="widget-heading">
                  {user.firstName} {user.lastName}
                </div>
                <div className="widget-subheading">{i18n.t(`users.roles.${user.role}`)}</div>
              </div>
              <div className="widget-content-left ml-3">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0 userBoxButton">
                    <UserAvatar user={user} className="onUserBox" />
                    <FontAwesomeIcon className="opacity-8" icon={faAngleDown} />
                  </DropdownToggle>
                  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                    <Nav vertical>
                      <NavItem className="nav-item-header">{i18n.t("menu.user.myAccount")}</NavItem>
                      <NavItem>
                        <NavLink href="#/me">{i18n.t("menu.user.profile")}</NavLink>
                      </NavItem>
                      <NavItem onClick={() => logout()}>
                        <NavLink>{i18n.t("menu.user.logout")}</NavLink>
                      </NavItem>
                    </Nav>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left ml-3">
                <Dropdown
                  direction="left"
                  className="icon"
                  pointing="top right"
                  icon={null}
                  trigger={<Flag name={locale} className="flag-locale-select" />}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeLocale("fr")}>
                      <Flag name="fr" className="flag-locale-select" />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLocale("gb")}>
                      <Flag name="gb" className="flag-locale-select" />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default UserBox
