import React from "react"
import MainContainer from "components/PageStructure/MainContainer"
import { Row, Col } from "reactstrap"
import { Field } from "formik"
import i18n from "utils/i18n"
import ClassicInput from "components/Form/ClassicInput"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import DeviceStatus from "components/Device/DeviceStatus"
import DevicePairingCode from "components/Device/DevicePairingCode"
import TagHandler from "components/Form/TagHandler"

const Header = ({
  device,
  values,
  myOrganization,
  organizations,
  isNew,
  getOrganizationFromList,
  getAllAccessibleBroadcastTags
}) => {
  return (
    <MainContainer style={{ height: 180 }}>
      <Row style={{ alignItems: "center" }}>
        <Col xs={6} md={6}>
          <MainContainer title={i18n.t("entities.attributes.owner")}>
            {isNew && (
              <Field
                component={SelectAutoComplete}
                required
                name="owner"
                placeholder={i18n.t("form.actions.chooseOrganization")}
                options={generateMultiSelectOptions(
                  [
                    { id: myOrganization.id, name: `${myOrganization.name} (${i18n.t("form.me")})` },
                    ...organizations.list.data.filter(e => e.id !== myOrganization.id)
                  ],
                  "id",
                  "name"
                )}
              />
            )}

            {!isNew && <OrganizationWithLogoDisplayer organization={device.owner} style={{ marginTop: 0 }} />}
          </MainContainer>
        </Col>
        <Col xs={1}>
          <DeviceStatus status={device.status} />
        </Col>
        <Col xs={1}>
          <DevicePairingCode pairingCode={device.pairingCode} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <MainContainer title={i18n.t("channels.pageTitle.informations")}>
            <Row>
              <Col xs={4}>
                <Field
                  type="text"
                  name="name"
                  label={i18n.t("entities.attributes.name")}
                  component={ClassicInput}
                  required
                />
              </Col>
              <Col xs={4}>
                <Field
                  component={SelectAutoComplete}
                  label={i18n.t("entities.singular.group")}
                  required={false}
                  editable
                  name="group"
                  placeholder={i18n.t("form.actions.chooseGroup")}
                  options={[{ value: "", label: "Aucun" }].concat(
                    generateMultiSelectOptions(getOrganizationFromList(values.owner).deviceGroups, "id", "name")
                  )}
                />
              </Col>
              <Col xs={4}>
                <Field
                  component={TagHandler}
                  label={i18n.t("entities.plural.broadcastTag")}
                  required={false}
                  editable
                  name="broadcastTags"
                  placeholder={i18n.t("form.actions.addTags")}
                  type="local"
                  options={generateMultiSelectOptions(getAllAccessibleBroadcastTags(), "id", "name")}
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
      </Row>
    </MainContainer>
  )
}

export default Header
