import * as React from "react"

function ScreenFour(props) {
  return (
    <svg
      id="prefix__Calque_1"
      data-name="Calque 1"
      viewBox="0 0 150 85"
      style={{ width: "100%", height: "100%", cursor: "pointer" }}
      className={props.active ? "screen-display-active" : ""}
    >
      <defs>
        <style>{".prefix__cls-2{fill:#30d4ee}.prefix__cls-4{fill:#fff}"}</style>
      </defs>
      <path fill="#e8e8e8" d="M0 0h125v67.5H0z" />
      <path className="prefix__cls-2" d="M125 42.5h25V85h-25zM125 0h25v42.5h-25z" />
      <path fill="#0184df" d="M0 67.5h150V85H0z" />
      <path
        className="prefix__cls-4"
        d="M67.73 33.34a5.49 5.49 0 00-5.3-5.3 5.48 5.48 0 00-.36 11h.18a5.48 5.48 0 005.48-5.66zm-2.37 3.19a4.29 4.29 0 01-3.11 1.32h-.14a4.33 4.33 0 01.14-8.65h.15a4.32 4.32 0 013 7.33z"
      />
      <path
        className="prefix__cls-4"
        d="M64.05 33.2l-2.43-1.74a.39.39 0 00-.62.32v3.48a.39.39 0 00.62.32l2.43-1.74a.39.39 0 000-.64z"
      />
    </svg>
  )
}

const MemoScreenThree = React.memo(ScreenFour)
export default MemoScreenThree
