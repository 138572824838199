export const createOrganizationSearchFilter = fields => {
  return {
    value: "",
    fields
  }
}

export const createOrganizationSelectFilter = (
  _organization,
  filterName,
  filterIcon,
  organizationFilterField,
  eltField
) => {
  return {
    name: filterName,
    icon: filterIcon,
    eltField,
    value: [],
    options: _organization[organizationFilterField]
      .map(elt => ({
        id: elt.id,
        label: elt.title || elt.name,
        value: elt.id
      }))
      .sort((a, b) => {
        // Owner filter : return first your organization, then others
        if (eltField === "owner") return true
        return a.label.localeCompare(b.label)
      })
  }
}

export const createOrganizationSelectFilterForGroups = (
  _organization,
  filterName,
  organizationFilterField,
  eltField,
  type
) => {
  return {
    name: filterName,
    icon: "folder",
    eltField,
    value: [],
    options: _organization[organizationFilterField]
      .filter(e => e.type === type)
      .map(elt => ({
        id: elt.id,
        label: elt.title || elt.name,
        value: elt.id
      }))
  }
}
