import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  getWebContentFromStore,
  apiGetWebContent,
  apiPostWebContent,
  apiPatchWebContent,
  apiDeleteWebContent,
  resetFocusWebContent
} from "services/webcontents"
import { Row, Col } from "reactstrap"
import { Formik, Form, Field } from "formik"
import _ from "lodash"
import produce from "immer"
import initFormValues from "utils/formik/initFormValues"
import { Redirect, withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/WebContents/One/Form/validationSchema"
import initialValues from "features/WebContents/One/Form/initialValues"
import WebContentForm from "features/WebContents/One/Form"
import { Loader, Confirm } from "semantic-ui-react"
import { createFormDataFromFormik } from "utils/formik/apiSerializer"
import MainContainer from "components/PageStructure/MainContainer"
import WebContentThumbnailHandler from "features/WebContents/One/Form/WebContentThumbnailHandler"
import getThumbnailSrc from "utils/getThumbnailSrc"
import ClassicInput from "components/Form/ClassicInput"
import ListLoader from "components/List/ListLoader"

export class WebContent extends Component {
  state = {
    isInit: false,
    isNew: true,
    title: "webContents.pageTitle.addTitle",
    redirectToRoot: false,
    thumbnail: {
      isNew: false,
      src: null,
      file: null,
      error: null
    },
    confirmDeleteModalOpen: false
  }

  formikRef = React.createRef()

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusWebContent()
  }

  handleThumbnailChange = (src, file) => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.thumbnail.error = null
      })
    )
    const supportedTypes = ["image/png", "image/jpeg"]

    if (!supportedTypes.includes(file.type)) {
      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.thumbnail.error = i18n.t("form.images.formatError")
        })
      )
      return false
    }

    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.thumbnail = { isNew: true, src, file, error: null }
      })
    )
    if (!this.state.isNew) {
      const { values } = this.formikRef.current.state
      const formData = createFormDataFromFormik(values)
      formData.append("thumbnail", file)

      this.props.apiPatchWebContent(this.props.match.params.id, formData)
    }
    return true
  }

  initComponent = async id => {
    const isNew = id === "new"

    if (!isNew) {
      this.setState({ isNew: false, title: "webContents.pageTitle.editTitle" })

      if (this.props.webContents.list.data.some(e => e.id === id)) await this.props.getWebContentFromStore(id)
      else await this.props.apiGetWebContent(id)
      const { data } = this.props.webContents.focus

      if (data === null) this.setState({ redirectToRoot: true })
      else {
        this.setState({
          isInit: true,
          thumbnail: { isNew: false, src: getThumbnailSrc(data.thumbnail, "webContent"), file: null, error: null }
        })
      }
    } else {
      this.setState({ isInit: true })
    }
  }

  submitForm = async values => {
    const { state } = this
    // New element
    if (state.isNew) {
      const formData = createFormDataFromFormik(_.clone(values))
      if (state.thumbnail.file !== null) formData.append("thumbnail", state.thumbnail.file)

      const response = await this.props.apiPostWebContent(formData)

      if (response.type === "API_POST_WEBCONTENT_SUCCESS") {
        this.props.history.push(`/webContents/${response.payload.data.id}`)
        this.initComponent(response.payload.data.id)
      }
    } else {
      const formData = createFormDataFromFormik(_.clone(values))

      if (state.thumbnail.isNew) formData.append("thumbnail", state.thumbnail.file)

      const response = await this.props.apiPatchWebContent(this.props.match.params.id, formData)

      if (response.type === "API_PATCH_WEBCONTENT_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false, confirmDeleteModalOpen: false })
    await this.props.apiDeleteWebContent(this.props.match.params.id)

    this.setState({ redirectToRoot: true })
  }

  openConfirmDeleteModalOpen = () => this.setState({ confirmDeleteModalOpen: true })

  getOrganizationFromList = organization => {
    return this.props.organizations.list.data.find(i => i.id === organization.value)
  }

  getAllAccessibleBroadcastTags = () => {
    const result = this.props.organizations.list.data.reduce((acc, orga) => {
      return acc.concat(orga.broadcastTags)
    }, [])

    return result
  }

  render() {
    const { title, isNew, redirectToRoot, isInit, thumbnail } = this.state
    const { myOrganization, organizations, me, webContents, categories } = this.props

    if (redirectToRoot) {
      return <Redirect to="/webContents" />
    }
    if (!isInit) return <ListLoader />

    const newElement = {
      owner: {
        id: myOrganization.id,
        name: myOrganization.name
      },
      shared: true,
      url: "https://",
      duration: 10
    }
    const data = isNew ? newElement : webContents.focus.data

    const userCanEdit =
      me.role === "ADMIN" &&
      (data.owner.id === myOrganization.id || organizations.list.data.some(i => i.id === data.owner.id))

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/webContents" />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                ref={this.formikRef}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors, values }) => {
                  return (
                    <Form>
                      <WebContentForm
                        data={data}
                        isNew={isNew}
                        myOrganization={myOrganization}
                        organizations={organizations}
                        values={values}
                        errors={errors}
                        userCanEdit={userCanEdit}
                        loading={this.props.webContents.focus.loading}
                        openConfirmDeleteModalOpen={this.openConfirmDeleteModalOpen}
                        categories={categories}
                        getOrganizationFromList={this.getOrganizationFromList}
                        getAllAccessibleBroadcastTags={this.getAllAccessibleBroadcastTags}
                      >
                        <Col xs={12} md={7}>
                          <MainContainer title={i18n.t("webContents.pageTitle.parameters")}>
                            <Row>
                              <Col xs={12} md={12}>
                                <Field
                                  type="text"
                                  name="url"
                                  editable={userCanEdit}
                                  label={i18n.t("entities.attributes.url")}
                                  component={ClassicInput}
                                  required
                                />
                                <Field
                                  type="number"
                                  name="duration"
                                  editable={userCanEdit}
                                  label={`${i18n.t("entities.attributes.duration")} (en secondes)`}
                                  component={ClassicInput}
                                  required
                                />
                              </Col>
                            </Row>
                          </MainContainer>

                          <MainContainer title={i18n.t("webContents.pageTitle.thumbnail")}>
                            {thumbnail.src === null && (
                              <div className="webContent-default-thumbnail">{i18n.t("webContents.thumbnail.none")}</div>
                            )}
                            {thumbnail.src !== null && (
                              <img src={thumbnail.src} className="thumbnail-webcontent" alt="" />
                            )}

                            {thumbnail.error !== null && (
                              <div className="form-error">
                                <div className="form-error-style">{thumbnail.error}</div>
                              </div>
                            )}

                            {userCanEdit && (
                              <div className="form-video-actionbar">
                                <WebContentThumbnailHandler
                                  isThumbnailUploaded={thumbnail.src !== null}
                                  onChange={this.handleThumbnailChange}
                                />
                              </div>
                            )}
                          </MainContainer>
                        </Col>
                      </WebContentForm>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          <Confirm
            open={this.state.confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  webContents: state.webContents,
  myOrganization: state.organizations.me.data,
  organizations: state.organizations,
  me: state.users.me.data,
  categories: state.categories.list.data
})

const mapDispatchToProps = {
  getWebContentFromStore,
  apiGetWebContent,
  apiPostWebContent,
  apiPatchWebContent,
  apiDeleteWebContent,
  resetFocusWebContent
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebContent))
