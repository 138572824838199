import sourceHDMI from "assets/devices-thumbnail/source_hdmi.png"
import sourcePepstream from "assets/devices-thumbnail/source_pepstream.png"
import sourceSamsungHome from "assets/devices-thumbnail/source_samsunghome.png"
import sourceWebBrowser from "assets/devices-thumbnail/source_webbrowser.png"
import sourceDisconnected from "assets/devices-thumbnail/disconnected.png"
import sourceUnpaired from "assets/devices-thumbnail/unpaired.png"
import sourceStandby from "assets/devices-thumbnail/standby.png"

const getDeviceThumbnail = device => {
  let sourceImg
  switch (device.thumbnail) {
    case "HDMI":
      sourceImg = sourceHDMI
      break
    case "PepstreamApp":
      sourceImg = sourcePepstream
      break
    case "SamsungHome":
      sourceImg = sourceSamsungHome
      break
    case "WebBrowser":
      sourceImg = sourceWebBrowser
      break
    case "Disconnected":
      sourceImg = sourceDisconnected
      break
    case "Network Standby":
      sourceImg = sourceStandby
      break
    case "Unpaired":
      sourceImg = sourceUnpaired
      break
    default:
      sourceImg = sourceSamsungHome
  }

  return sourceImg
}
export default getDeviceThumbnail
