import React from "react"
import i18n from "utils/i18n"

const CurrentProgram = ({ program }) => {
  return (
    <div>
      <div className="quickaccess-title">{i18n.t("devices.quickAccess.currentProgram")}</div>
      <div className="program-name-quickaccess">{program === null ? "-" : program.title}</div>
    </div>
  )
}

export default CurrentProgram
