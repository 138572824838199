import React, { Fragment } from "react"

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              <ul className="nav">
                <li className="nav-item">
                  <div className="nav-link">Footer Link 1</div>
                </li>
                <li className="nav-item">
                  <div className="nav-link">Footer Link 2</div>
                </li>
              </ul>
            </div>
            <div className="app-footer-right">
              <ul className="nav">
                <li className="nav-item">
                  <div className="nav-link">Footer Link 3</div>
                </li>
                <li className="nav-item">
                  <div className="nav-link">
                    <div className="badge badge-success mr-1 ml-0">
                      <small>NEW</small>
                    </div>
                    Footer Link 4
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default AppFooter
