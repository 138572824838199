import React, { Fragment, Component } from "react"
import { Row, Col } from "reactstrap"
import DeviceSmallInfo from "components/Device/DeviceSmallInfo"
import i18n from "utils/i18n"
import ListLoader from "components/List/ListLoader"
import humanFileSize from "utils/humanFileSize"
import { Field, Formik } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import initFormValues from "utils/formik/initFormValues"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"
import ListActionButton from "components/Buttons/ListActionButton"
import { SearchBox } from "@mapbox/search-js-react"
import validationSchema from "./validationSchema"
import initialValues from "./initialValues"

class DeviceInformations extends Component {
  constructor(props) {
    super(props)

    this.state = {
      changingAddress: false
    }
  }

  changeAddress = feature => {
    // eslint-disable-next-line camelcase
    const address = feature?.features[0]?.properties?.full_address
    const lat = feature?.features[0]?.geometry.coordinates[1]
    const lng = feature?.features[0]?.geometry.coordinates[0]

    const data = {
      address,
      lat,
      lng
    }

    this.props.onSave(data)
    this.setState({ changingAddress: false })
  }

  toggleChangingAddress = () => {
    this.setState(prevState => ({ changingAddress: !prevState.changingAddress }))
  }

  render() {
    const { device, loading, onSave } = this.props
    const { changingAddress } = this.state

    return (
      <Formik
        enableReinitialize
        initialValues={initFormValues(initialValues, device, false)}
        validationSchema={validationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({ errors, values }) => {
          return (
            <Fragment>
              {device.magicInfoId === null && (
                <div className="list-no-result">{i18n.t("devices.magicInfoPairingNeeded")}</div>
              )}
              {device.magicInfoId !== null && (
                <Row>
                  <Col xs={12}>
                    <FormSaveButton
                      text={i18n.t("actions.save")}
                      type="button"
                      disabled={!_.isEmpty(errors)}
                      onClick={() => onSave(values)}
                    />
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: "2em" }}>
                {device.magicInfoId !== null && loading && <ListLoader />}
                {device.magicInfoId !== null && !loading && device.general !== null && (
                  <Fragment>
                    <Col xs={3}>
                      <DeviceSmallInfo label="MAC" value={device.general.macAddress} />
                      <DeviceSmallInfo label="IP" value={device.general.ipAddress} />
                    </Col>
                    <Col xs={3}>
                      <DeviceSmallInfo
                        label="HDD Remaining"
                        value={humanFileSize(device.general.diskSpaceAvailable * 1000)}
                      />
                      <DeviceSmallInfo label="Used" value={humanFileSize(device.general.diskSpaceUsage * 1000)} />
                    </Col>
                    <Col xs={3}>
                      <DeviceSmallInfo label="FWV" value={device.general.firmwareVersion} />
                      <DeviceSmallInfo label="Pepstream App" value="-" />
                    </Col>
                    <Col xs={3}>
                      <DeviceSmallInfo label="RM" value={device.general.deviceTypeVersion} />
                      <DeviceSmallInfo label="Model" value={device.general.deviceModelName} />
                    </Col>
                  </Fragment>
                )}
              </Row>
              <Row style={{ marginTop: "1em" }}>
                <Col xs={6}>
                  <Field
                    type="text"
                    name="contactName"
                    label={i18n.t("devices.attributes.contactName")}
                    component={ClassicInput}
                  />
                  <Field
                    type="text"
                    name="contactEmail"
                    label={i18n.t("devices.attributes.contactEmail")}
                    component={ClassicInput}
                  />

                  <Field
                    type="text"
                    name="contactPhone"
                    label={i18n.t("devices.attributes.contactPhone")}
                    component={ClassicInput}
                  />
                </Col>
                <Col xs={6}>
                  {device.address !== null && (
                    <Fragment>
                      <div className="form-label">Adresse</div>
                      <div>{device.address}</div>

                      <ListActionButton
                        icon="edit"
                        label={!changingAddress ? i18n.t("actions.edit") : i18n.t("actions.cancel")}
                        onClick={() => this.toggleChangingAddress()}
                        style={{ marginTop: "1em", marginBottom: "1em" }}
                      />
                    </Fragment>
                  )}
                  {(changingAddress || device.address === null) && (
                    <div className="form-input-container">
                      <div className="form-label">Adresse</div>
                      <SearchBox
                        accessToken="pk.eyJ1IjoiZ2FybmllciIsImEiOiJja2VncDh0NWIwMzh3MnptdW9tNjV3cTIzIn0.lCzu4zpdjK85hjdMfSVdyA"
                        options={{
                          language: "fr",
                          types: "address"
                        }}
                        value={device.address}
                        onRetrieve={res => {
                          this.changeAddress(res)
                        }}
                      />

                      {/* <AlgoliaPlaces
                        placeholder="..."
                        options={{
                          appId: "plNNR68UMN84",
                          apiKey: "ce4b68a45ed85bca335666ef284c96ca",
                          language: "fr",
                          countries: ["fr", "lu"],
                          type: "address"
                          // Other options from https://community.algolia.com/places/documentation.html#options
                        }}
                        onChange={({ query, rawAnswer, suggestion, suggestionIndex }) =>
                          this.changeAddress(
                            `${suggestion.name}, ${suggestion.postcode} ${suggestion.city}, ${suggestion.country}`,
                            suggestion.latlng
                          )
                        }
                      /> */}
                    </div>
                  )}
                </Col>
              </Row>
            </Fragment>
          )
        }}
      </Formik>
    )
  }
}

export default DeviceInformations
