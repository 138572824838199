import React from "react"
import { Button } from "reactstrap"
import { Loader } from "semantic-ui-react"

const IntegrationCodeButton = ({
  type = "button",
  text = "",
  icon = "",
  disabled,
  isLoading = false,
  onClick = null,
  active = false
}) => {
  return (
    <Button
      color="grey"
      size="md"
      // @ts-ignore
      onClick={event => {
        if (onClick !== null) onClick(event)
      }}
      // @ts-ignore
      type={type}
      disabled={disabled || isLoading}
      outline={active}
      className="btn-icon"
      style={{ marginRight: 10 }}
    >
      {!isLoading && <i className={`pe-7s-${icon} btn-icon-wrapper`}> </i>}
      {isLoading && <Loader size="mini" className="loader-button" inline active />}
      {text}
    </Button>
  )
}

export default IntegrationCodeButton
