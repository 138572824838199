import React from "react"
import { Image } from "semantic-ui-react"
import getThumbnailSrc from "utils/getThumbnailSrc"

const PlaylistThumbnail = ({ playlist, className = "", style = {} }) => {
  let thumbnail
  let type

  if (playlist.content.length === 0) {
    thumbnail = "default.png"
    type = "PLAYLIST"
  } else {
    const firstContentIndex = playlist.content.findIndex(i => i.order === 0)
    if (firstContentIndex > -1) {
      const firstContent = playlist.content[firstContentIndex]
      thumbnail = firstContent.type === "WEBCONTENT" ? firstContent.webContent.thumbnail : firstContent.video.thumbnail
      type = firstContent.type
    } else {
      thumbnail = "default.png"
      type = "PLAYLIST"
    }
  }

  return <Image src={getThumbnailSrc(thumbnail, type)} className={className} style={{ ...style }} />
}

export default PlaylistThumbnail
