import React, { Component } from "react"
import { Modal, Button } from "semantic-ui-react"

export class ConfirmModal extends Component {
  render() {
    const { submit, cancel, visible, title, content } = this.props

    return (
      <Modal size="small" open={visible} onClose={() => cancel()}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => cancel()}>
            Annuler
          </Button>
          <Button positive onClick={() => submit()}>
            Valider
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default ConfirmModal
