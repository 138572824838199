import {
  LOGOUT_USER,
  AUTH_USER,
  API_GET_ME,
  API_PATCH_ME,
  API_POST_USER,
  API_PATCH_USER,
  API_DELETE_USER
} from "actions/users"
import { toSuccess, toFailure } from "actions"
import { createReducer } from "@reduxjs/toolkit"
import { toast } from "react-toastify"
import i18n from "utils/i18n"

const initialState = {
  focus: {
    data: {},
    loading: false,
    error: null
  },
  list: {
    data: [],
    loading: false,
    error: null
  },
  me: {
    data: {
      id: null,
      role: null,
      name: null
    },
    loading: false,
    error: false
  }
}

const users = createReducer(initialState, {
  [API_GET_ME]: state => {
    state.me.loading = true
  },
  [toSuccess(API_GET_ME)]: (state, action) => {
    state.me.loading = false
    state.me.error = false
    state.me.data = action.payload.data
  },
  [toFailure(API_GET_ME)]: state => {
    state.me.loading = false
    state.me.error = true
  },

  [API_PATCH_ME]: state => {
    state.me.loading = true
  },
  [toFailure(API_PATCH_ME)]: state => {
    state.me.loading = false
  },
  [toSuccess(API_PATCH_ME)]: (state, action) => {
    state.me.loading = false
    state.me.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("users.api.patched"))
  },

  /* All Users */
  [API_POST_USER]: () => {},
  [toSuccess(API_POST_USER)]: () => {},
  [toFailure(API_POST_USER)]: () => {},

  [API_PATCH_USER]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_USER)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_USER)]: state => {
    state.focus.loading = false
  },

  [API_DELETE_USER]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_USER)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_USER)]: () => {},

  /* AUTHENTIFICATION */
  [toSuccess(AUTH_USER)]: (state, action) => {
    state.me.data = action.payload.data.me
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default users
