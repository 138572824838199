import React, { Component } from "react"
import { connect } from "react-redux"
import { Grid, Header, Image, Loader } from "semantic-ui-react"
import { authUser, apiResetPassword } from "services/auth"
import Form from "features/Login/components/Form"
import { Redirect } from "react-router-dom"
import PepstreamLogoHorizontal from "components/Icons/PepstreamLogoHorizontalWhite"
import i18n from "utils/i18n"
import PasswordRecovery from "./components/PasswordRecovery"

const styles = {
  blocWhiteSuccess: {
    color: "#fff"
  },
  blocHeader: {
    padding: 20,
    width: "100%",
    marginBottom: "1em"
  }
}

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      passwordRecoveryOpen: false
    }
  }

  componentDidMount() {}

  submitAuth = values => {
    this.props.authUser(values)
  }

  openPasswordRecoveryForm = () => {
    this.setState({ passwordRecoveryOpen: true })
  }

  cancelPasswordRecoveryForm = () => {
    this.setState({ passwordRecoveryOpen: false })
  }

  submitResetPassword = async values => {
    this.props.apiResetPassword(values)
    this.setState({ passwordRecoveryOpen: false })
  }

  render() {
    const { auth } = this.props
    const { passwordRecoveryOpen } = this.state

    if (auth.isLogged) {
      return <Redirect to="/" />
    }

    return (
      <div className="login-background">
        <Grid textAlign="center" verticalAlign="middle">
          <Grid.Column>
            <div style={styles.blocHeader}>
              <PepstreamLogoHorizontal style={{ width: "40%" }} />
            </div>
            {auth.loading && (
              <div style={styles.blocWhiteError}>
                <Loader inline active size="tiny" />
              </div>
            )}

            <div className="login-box">
              {!passwordRecoveryOpen && (
                <Form
                  submitAuth={this.submitAuth}
                  openPasswordRecoveryForm={this.openPasswordRecoveryForm}
                  authError={auth.error}
                  passwordRecoverySuccess={auth.passwordRecoverySuccess}
                />
              )}
              {passwordRecoveryOpen && (
                <PasswordRecovery
                  submitResetPassword={this.submitResetPassword}
                  cancelPasswordRecoveryForm={this.cancelPasswordRecoveryForm}
                />
              )}
            </div>
            <div className="copyright">© 2020 PEPSTREAM Tous droits réservés</div>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = {
  authUser,
  apiResetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
