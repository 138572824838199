import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  getChannelFromStore,
  apiGetChannel,
  apiPostChannel,
  apiPatchChannel,
  apiDeleteChannel,
  resetFocusChannel,
  apiUploadChannelDisplayParametersImage,
  apiDeleteChannelDisplayParametersImage
} from "services/channels"
import { Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import _ from "lodash"
import initFormValues from "utils/formik/initFormValues"
import { Redirect, withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/Channels/One/Form/validationSchema"
import initialValues from "features/Channels/One/Form/initialValues"
import ChannelForm from "features/Channels/One/Form"
import { Loader, Confirm } from "semantic-ui-react"
import Tabs from "react-responsive-tabs"
import { createJsonDataFromFormik } from "utils/formik/apiSerializer"
import ListLoader from "components/List/ListLoader"
import ChannelContent from "./Form/Content"

export class Channel extends Component {
  state = {
    isInit: false,
    isNew: true,
    title: "channels.pageTitle.addTitle",
    redirectToRoot: false,
    confirmDeleteModalOpen: false
  }

  formikRef = React.createRef()

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusChannel()
  }

  initComponent = async id => {
    const isNew = id === "new"

    if (!isNew) {
      this.setState({ isNew: false, title: "channels.pageTitle.editTitle" })
      if (this.props.channels.list.data.some(e => e.id === Number(id))) await this.props.getChannelFromStore(id)
      else await this.props.apiGetChannel(id)

      if (this.props.channels.focus.data === null) this.setState({ redirectToRoot: true })
    }

    this.setState({ isInit: true })
  }

  onScreenImageUpload = (formData, savedParameters) => {
    this.props.apiUploadChannelDisplayParametersImage(
      this.props.match.params.id,
      formData,
      createJsonDataFromFormik(_.clone(savedParameters))
    )
  }

  onScreenImageDelete = (data, savedParameters) => {
    this.props.apiDeleteChannelDisplayParametersImage(
      this.props.match.params.id,
      data,
      createJsonDataFromFormik(_.clone(savedParameters))
    )
  }

  onTextChange = (value, bloc) => {
    // Auto set the name of the video
    this.formikRef.current.setFieldValue(`displayParameters.${bloc}_text`, value)
  }

  submitForm = async values => {
    const { state } = this
    const data = createJsonDataFromFormik(_.clone(values))

    data.displayParameters = createJsonDataFromFormik(_.clone(values.displayParameters))

    // New element
    if (state.isNew) {
      const response = await this.props.apiPostChannel(data)

      if (response.type === "API_POST_CHANNEL_SUCCESS") {
        this.props.history.push(`/channels/${response.payload.data.id}`)
        this.initComponent(response.payload.data.id)
      }
    } else {
      const response = await this.props.apiPatchChannel(this.props.match.params.id, data)

      if (response.type === "API_PATCH_CHANNEL_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false })
    await this.props.apiDeleteChannel(this.props.match.params.id)

    this.setState({ redirectToRoot: true })
  }

  openConfirmDeleteModalOpen = () => this.setState({ confirmDeleteModalOpen: true })

  render() {
    const { title, isNew, redirectToRoot, isInit } = this.state
    const { myOrganization, organizations, me } = this.props

    if (redirectToRoot) {
      return <Redirect to="/channels" />
    }
    if (!isInit) return <ListLoader />

    const newElement = {
      owner: {
        id: this.props.myOrganization.id,
        name: this.props.myOrganization.name
      },
      pepstreamImageMode: 1,
      displayParameters: {}
    }

    const data = isNew ? newElement : this.props.channels.focus.data

    const userCanEdit =
      me.role === "ADMIN" &&
      (data.owner.id === myOrganization.id || organizations.list.data.some(i => i.id === data.owner.id))

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/channels" />

          <Row className="custom-scroll" style={{ flex: 1, marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                ref={this.formikRef}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors, values, setFieldValue }) => {
                  return (
                    <Form>
                      <ChannelForm
                        isNew={isNew}
                        userCanEdit={userCanEdit}
                        organizations={organizations}
                        myOrganization={myOrganization}
                        element={data}
                        values={values}
                        errors={errors}
                        loading={this.props.channels.focus.loading}
                        openConfirmDeleteModalOpen={this.openConfirmDeleteModalOpen}
                      />
                      {!isNew && (
                        <Tabs
                          tabsWrapperClass="body-tabs body-tabs-layout"
                          transform={false}
                          showInkBar
                          items={[
                            {
                              title: i18n.t("channels.tabs.content"),
                              content: (
                                <ChannelContent
                                  channel={data}
                                  values={values}
                                  onScreenDisplayChange={id => setFieldValue("pepstreamImageMode", id)}
                                  onImageUpload={formData =>
                                    this.onScreenImageUpload(formData, values.displayParameters)
                                  }
                                  onScreenImageDelete={data => this.onScreenImageDelete(data, values.displayParameters)}
                                  onTextChange={this.onTextChange}
                                  isNew={isNew}
                                />
                              ),
                              tabClassName: "draggable-tab"
                            }
                          ]}
                        />
                      )}
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>

          <Confirm
            open={this.state.confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  channels: state.channels,
  programs: state.programs.list,
  organizations: state.organizations,
  myOrganization: state.organizations.me.data,
  me: state.users.me.data
})

const mapDispatchToProps = {
  getChannelFromStore,
  apiGetChannel,
  apiPostChannel,
  apiPatchChannel,
  apiDeleteChannel,
  resetFocusChannel,
  apiDeleteChannelDisplayParametersImage,
  apiUploadChannelDisplayParametersImage
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Channel))
