import Devices from "features/Devices"
import Videos from "features/Videos/List"
import Video from "features/Videos/One"
import Organization from "features/Organization"
import SubOrgas from "features/SubOrgas/List"
import SubOrga from "features/SubOrgas/One"
import Playlists from "features/Playlists/List"
import Playlist from "features/Playlists/One"
import Programs from "features/Programs/List"
import Program from "features/Programs/One"
import Channels from "features/Channels/List"
import Channel from "features/Channels/One"
import WebContents from "features/WebContents/List"
import WebContent from "features/WebContents/One"
import Profile from "features/Profile"
import Dashboard from "features/Dashboard"
import SharingRules from "features/SharingRules"

const routes = [
  {
    name: "menu.categories.head",
    inSidebar: true,
    authorizedRoles: ["ADMIN"],
    elements: [
      {
        path: "/dashboard",
        icon: "pe-7s-rocket",
        exact: true,
        component: Dashboard,
        inSidebar: true,
        label: "menu.dashboard",
        children: []
      }
    ]
  },
  {
    name: "menu.categories.content",
    inSidebar: true,
    authorizedRoles: ["ADMIN", "USER"],
    elements: [
      {
        path: "/videos",
        exact: true,
        component: Videos,
        icon: "pe-7s-video",
        inSidebar: true,
        label: "menu.videos",
        children: []
      },
      {
        path: "/videos/:id",
        exact: true,
        component: Video,
        inSidebar: false,
        children: []
      },
      {
        path: "/webContents",
        exact: true,
        component: WebContents,
        icon: "pe-7s-global",
        inSidebar: true,
        label: "menu.webcontents",
        children: []
      },
      {
        path: "/webContents/:id",
        exact: true,
        component: WebContent,
        icon: "pe-7s-global",
        inSidebar: false,
        label: "menu.webcontents",
        children: []
      }
    ]
  },
  {
    name: "menu.categories.program",
    inSidebar: true,
    authorizedRoles: ["ADMIN", "USER"],
    elements: [
      {
        path: "/playlists",
        icon: "pe-7s-play",
        inSidebar: true,
        exact: true,
        component: Playlists,
        label: "menu.playlists",
        authorizedRoles: ["ADMIN", "USER"],
        children: []
      },
      {
        path: "/playlists/:id",
        icon: "pe-7s-play",
        inSidebar: false,
        exact: true,
        component: Playlist,
        authorizedRoles: ["ADMIN", "USER"],
        children: []
      },
      {
        path: "/programs",
        icon: "pe-7s-film",
        exact: true,
        component: Programs,
        inSidebar: true,
        label: "menu.programs",
        authorizedRoles: ["ADMIN"],
        children: []
      },
      {
        path: "/programs/:id",
        icon: "pe-7s-film",
        inSidebar: false,
        exact: true,
        component: Program,
        authorizedRoles: ["ADMIN"],
        children: []
      }
    ]
  },
  {
    name: "menu.categories.broadcast",
    authorizedRoles: ["ADMIN"],
    inSidebar: true,
    elements: [
      {
        path: "/devices",
        icon: "pe-7s-monitor",
        exact: false,
        inSidebar: true,
        component: Devices,
        label: "menu.devices",
        children: []
      },
      {
        path: "/channels",
        icon: "pe-7s-airplay",
        exact: true,
        component: Channels,
        inSidebar: true,
        label: "menu.channels",
        children: []
      },
      {
        path: "/channels/:id",
        icon: "pe-7s-airplay",
        inSidebar: false,
        exact: true,
        component: Channel,
        children: []
      }
    ]
  },
  {
    name: "menu.categories.settings",
    inSidebar: true,
    authorizedRoles: ["ADMIN"],
    elements: [
      {
        path: "/organization",
        icon: "pe-7s-culture",
        inSidebar: true,
        label: "menu.myOrganization",
        component: Organization,
        exact: false,
        children: []
      },
      {
        path: "/subOrgas",
        icon: "pe-7s-network",
        exact: true,
        component: SubOrgas,
        inSidebar: true,
        label: "menu.suborgas",
        children: []
      },
      {
        path: "/subOrgas/:id",
        exact: true,
        component: SubOrga,
        inSidebar: false,
        children: []
      },
      {
        path: "/sharing",
        icon: "pe-7s-flag",
        exact: true,
        component: SharingRules,
        inSidebar: true,
        label: "menu.sharing",
        children: []
      }
    ]
  },
  {
    name: "menu.categories.admin",
    inSidebar: true,
    isOwnerOnly: true,
    authorizedRoles: ["ADMIN"],
    elements: [
      {
        path: "/analytics",
        icon: "pe-7s-graph2",
        inSidebar: true,
        label: "menu.stats",
        children: []
      },
      {
        path: "/logs",
        icon: "pe-7s-diamond",
        inSidebar: true,
        label: "menu.logs",
        children: []
      }
    ]
  },
  {
    inSidebar: false,
    authorizedRoles: ["ADMIN", "USER"],
    elements: [
      {
        path: "/me",
        component: Profile,
        exact: true,
        inSidebar: false,
        children: []
      }
    ]
  }
]

export default routes
