import * as React from "react"

function ScreenThree(props) {
  return (
    <svg
      id="prefix__Calque_1"
      x={0}
      y={0}
      viewBox="0 0 150 85"
      xmlSpace="preserve"
      style={{ width: "100%", height: "100%", cursor: "pointer" }}
      className={props.active ? "screen-display-active" : ""}
    >
      <style>{".prefix__st4{fill:#fff}"}</style>
      <path fill="#e8e8e8" d="M0 0h125v67.5H0z" />
      <path fill="#06a9f9" d="M125 33.2h25V68h-25z" />
      <path fill="#30d4ee" d="M125 0h25v33.8h-25z" />
      <path fill="#0184df" d="M0 67.5h150V85H0z" />
      <path
        className="prefix__st4"
        d="M67.7 33.3c-.1-2.9-2.4-5.2-5.3-5.3-1.6 0-3 .5-4.1 1.7-1 1.1-1.6 2.5-1.5 4 .1 2.9 2.4 5.2 5.3 5.3h.2c1.5 0 2.9-.6 3.9-1.7 1-1 1.6-2.5 1.5-4zm-2.3 3.2c-.8.8-1.9 1.3-3.1 1.3h-.1c-2.3-.1-4.1-1.9-4.2-4.2 0-1.2.4-2.3 1.2-3.2.8-.8 1.9-1.3 3.1-1.3h.1c2.3.1 4.1 1.9 4.2 4.2 0 1.3-.4 2.4-1.2 3.2z"
      />
      <path
        className="prefix__st4"
        d="M64.1 33.2l-2.4-1.7c-.3-.2-.6 0-.6.3v3.5c0 .3.4.5.6.3l2.4-1.7c.2-.2.2-.5 0-.7z"
      />
    </svg>
  )
}

const MemoScreenTwo = React.memo(ScreenThree)
export default MemoScreenTwo
