import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Button } from "reactstrap"
import { apiGetPlaylists, apiGetPlaylistForCopy, apiCopyPlaylist } from "services/playlists"
import ListContainer from "components/List/ListContainer"
import { Link, Redirect } from "react-router-dom"
import i18n from "utils/i18n"
import ChooseOwner from "./ChooseOwner"

class Playlists extends Component {
  constructor(props) {
    super(props)

    this.state = {
      listLength: null,
      redirectToId: null,
      chooseOwnerVisible: false,
      playlistIdToCopy: null
    }
  }

  componentDidMount() {
    this.props.apiGetPlaylists()
  }

  copyPlaylist = async ownerId => {
    this.setState({ chooseOwnerVisible: false })
    const { playlistIdToCopy } = this.state
    const _data = {
      owner: ownerId,
      playlist: playlistIdToCopy
    }

    const response = await this.props.apiCopyPlaylist(playlistIdToCopy, _data)
    this.setState({ redirectToId: response.payload.data.id, playlistIdToCopy: null })
  }

  openChooseOwner = id => {
    this.setState({ playlistIdToCopy: id, chooseOwnerVisible: true })
  }

  closeChooseOwner = () => {
    this.setState({ playlistIdToCopy: null, chooseOwnerVisible: false })
  }

  render() {
    const { playlists, me, organizations, myOrganization } = this.props
    const { redirectToId, chooseOwnerVisible, listLength } = this.state

    const userCanEdit = contentOwnerId => {
      return (
        me.role === "ADMIN" &&
        (contentOwnerId === myOrganization.id || organizations.list.data.some(i => i.id === contentOwnerId))
      )
    }

    if (redirectToId !== null) return <Redirect to={`/playlists/${redirectToId}`} />

    return (
      <PageContainer>
        <PageTitle
          heading={i18n.t("playlists.pageTitle.heading")}
          icon="pe-7s-play folder-color-PLAYLIST"
          number={listLength}
        />

        <div className="page-top-buttons">
          {me.role === "ADMIN" && (
            <Link to="/playlists/new" className="new-element-button">
              <Button className="btn-icon" color="primary" outline size="md">
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {i18n.t("playlists.actions.add")}
              </Button>
            </Link>
          )}
        </div>

        <ListContainer
          type="PLAYLIST"
          lineHeight="lg"
          elements={{ data: playlists.list.data }}
          sortField="createdAt"
          filtersOn
          filters={{ search: ["title"], select: ["owner", "groups"] }}
          defaultLayout="grid"
          multipleLayouts
          listLengthSetter={nb => this.setState({ listLength: nb })}
          handlers={{ role: me.role, copy: this.openChooseOwner, userCanEdit }}
        />

        <ChooseOwner
          organizations={organizations.list.data}
          visible={chooseOwnerVisible}
          cancel={this.closeChooseOwner}
          submit={this.copyPlaylist}
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    playlists: state.playlists,
    myOrganization: state.organizations.me.data,
    organizations: state.organizations,
    me: state.users.me.data
  }
}

const mapDispatchToProps = {
  apiGetPlaylists: () => apiGetPlaylists(),
  apiGetPlaylistForCopy,
  apiCopyPlaylist
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlists)
