import React from "react"
import { Button } from "reactstrap"
import { Loader } from "semantic-ui-react"

const FormDeleteButton = ({ text, type = "button", disabled, onClick, className = null, isLoading = false }) => {
  return (
    <Button
      color="danger"
      size="md"
      // @ts-ignore
      type={type}
      outline
      disabled={disabled || isLoading}
      className={className !== null && className}
      onClick={() => onClick()}
    >
      {isLoading && <Loader size="mini" className="loader-button" inline active />}
      {text}
    </Button>
  )
}

export default FormDeleteButton
