import React from "react"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import getThumbnailSrc from "utils/getThumbnailSrc"
import moment from "moment"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"

const ItemLine = ({ element }) => {
  return (
    <Link to={`/webContents/${element.id}`} className="row-link">
      <Row className="line-element-row">
        <Col
          xs={1}
          style={{ backgroundImage: `url(${getThumbnailSrc(element.thumbnail, "webContent")})` }}
          className="list-thumbnail"
        >
          &nbsp;
        </Col>
        <Col xs={4}>
          <div className="element-title">{element.title}</div>
          <div className="video-time mt-video-time">{moment.utc(element.duration * 1000).format("mm:ss")}</div>
        </Col>
        <Col xs={3}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.owner")}</div>
            <div className="content">
              <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.uploadedAt")}</div>
            <div className="content">{moment(element.uploadedAt).format("DD/MM/YYYY, HH:mm")}</div>
          </div>
        </Col>
      </Row>
    </Link>
  )
}

export default ItemLine
