import React, { Fragment, Component } from "react"
import { Row, Col, Button } from "reactstrap"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import i18n from "utils/i18n"

class RuleLine extends Component {
  render() {
    const { rule, organization, onDelete, onToggleDownloadRule } = this.props

    return (
      <div className="line-element-container sm">
        <Row className="line-element-row" style={{ position: "relative" }}>
          <Col xs={3} className="list-thumbnail">
            <OrganizationWithLogoDisplayer organization={organization} />
          </Col>
          <Col xs={3}>
            <div className="line-element-info" style={{ flexDirection: "row", alignItems: "center" }}>
              <div className="label success">
                {rule.canDownload && i18n.t("organizations.sharingRules.downloadGranted")}{" "}
              </div>
              <div className="label error">
                {!rule.canDownload && i18n.t("organizations.sharingRules.downloadNotGranted")}
              </div>
              <Button
                className="btn-icon"
                color="light"
                outline
                size="sm"
                style={{ marginLeft: "5px" }}
                onClick={() => onToggleDownloadRule(rule, "canDownload")}
              >
                <i className="pe-7s-switch btn-icon-wrapper" style={{ marginRight: 0 }}>
                  {" "}
                </i>
              </Button>
            </div>
          </Col>
          <Col xs={3}>
            <div className="line-element-info" style={{ flexDirection: "row", alignItems: "center" }}>
              <div className="label error">{rule.onlyShared && i18n.t("organizations.sharingRules.onlyShared")} </div>
              <div className="label success">{!rule.onlyShared && i18n.t("organizations.sharingRules.allContent")}</div>
              <Button
                className="btn-icon"
                color="light"
                outline
                size="sm"
                style={{ marginLeft: "5px" }}
                onClick={() => onToggleDownloadRule(rule, "onlyShared")}
              >
                <i className="pe-7s-switch btn-icon-wrapper" style={{ marginRight: 0 }}>
                  {" "}
                </i>
              </Button>
            </div>
          </Col>
          <Col xs={3}>
            {rule.isEditable && (
              <div className="list-action-menu">
                <Button className="btn-icon" color="danger" outline size="sm" onClick={() => onDelete(rule)}>
                  <i className="pe-7s-trash btn-icon-wrapper"> </i>
                  {i18n.t("actions.delete")}
                </Button>
              </div>
            )}
            {!rule.isEditable && (
              <Fragment>
                {rule.isSubOrga && (
                  <div className="rule-link-explainer">{i18n.t("organizations.sharingRules.isSubOrga")}</div>
                )}
                {rule.isSuperOrga && (
                  <div className="rule-link-explainer">{i18n.t("organizations.sharingRules.isSuperOrga")}</div>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    )
  }
}

export default RuleLine
