import React, { Fragment } from "react"
import i18n from "utils/i18n"
import FormSaveButton from "components/Buttons/FormSaveButton"
import { toast } from "react-toastify"
import DeviceGroupsTree from "./DeviceGroupsTree"

class OrgaTree extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addGroupInputVisible: false,
      newGroupInput: ""
    }
  }

  toggleNewGroupInput = () => {
    this.setState(prevState => ({ addGroupInputVisible: !prevState.addGroupInputVisible }))
  }

  onNewGroupInputChange = event => {
    this.setState({ newGroupInput: event.target.value })
  }

  onNewGroupSubmit = async orga => {
    if (this.state.newGroupInput === "") {
      toast.error(i18n.t("deviceGroups.form.notNull"))
    } else {
      const data = {
        owner: orga.id,
        name: this.state.newGroupInput
      }

      await this.props.onDeviceGroupSubmit(data)

      this.setState({ newGroupInput: "", addGroupInputVisible: false })
    }
  }

  render() {
    const { orga, focus, selected, onElementAdd, onElementDelete, program } = this.props

    return (
      <Fragment>
        {orga.deviceGroups.map(deviceGroup => (
          <DeviceGroupsTree
            focus={focus}
            deviceGroup={deviceGroup}
            key={`deviceGroup-${deviceGroup.id}`}
            selected={selected}
            onElementAdd={onElementAdd}
            onElementDelete={onElementDelete}
            program={program}
          />
        ))}
      </Fragment>
    )
  }
}

export default OrgaTree
