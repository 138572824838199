import { toFailure, toSuccess } from "actions"
import {
  API_GET_ALL_DEVICE,
  API_GET_ONE_DEVICE,
  API_POST_DEVICE,
  API_PATCH_DEVICE,
  API_DELETE_DEVICE,
  INIT_DEVICE,
  RESET_FOCUS_DEVICE,
  GET_ONE_DEVICE,
  API_GET_ORGANIZATION_DEVICE,
  RESET_DEVICES,
  API_SET_ON_DEVICE,
  API_SET_OFF_DEVICE,
  API_CLEAR_STORAGE_DEVICE,
  API_UPLOAD_IMAGE_DEVICE,
  API_DELETE_IMAGE_DEVICE,
  API_POST_SCREEN_TIMER_DEVICE,
  API_DELETE_SCREEN_TIMER_DEVICE,
  API_PATCH_SCREEN_TIMER_DEVICE,
  API_GET_ALL_ADMINISTRABLE_DEVICE,
  API_MAGICINFO_GET_TIME_DEVICE,
  API_MAGICINFO_GET_SETUP_DEVICE,
  API_MAGICINFO_GET_SECURITY_DEVICE,
  API_MAGICINFO_GET_GENERAL_DEVICE,
  API_MAGICINFO_PATCH_TIME_DEVICE,
  API_MAGICINFO_PATCH_SECURITY_DEVICE,
  API_MAGICINFO_PATCH_SETUP_DEVICE,
  API_MAGICINFO_PATCH_DISPLAY_DEVICE,
  API_MAGICINFO_PATCH_GENERAL_DEVICE,
  API_MAGICINFO_POWERON_DEVICE,
  API_MAGICINFO_POWEROFF_DEVICE,
  API_MAGICINFO_RESTART_DEVICE,
  API_MAGICINFO_CHANGESOURCE_DEVICE,
  API_MAGICINFO_CLEARDISK_DEVICE
} from "actions/devices"
import { toast } from "react-toastify"
import { createReducer } from "@reduxjs/toolkit"
import i18n from "utils/i18n"
import { LOGOUT_USER } from "actions/users"
import _ from "lodash"
import { API_PATCH_PROGRAM } from "actions/programs"

const initialState = {
  administrables: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  list: {
    loading: false,
    loaded: false,
    error: false,
    data: []
  },
  focus: {
    loading: false,
    loaded: false,
    error: false,
    data: {
      owner: { programs: [], deviceGroups: [] },
      displayParameters: {},
      broadcastTags: [],
      time: null,
      security: null,
      general: null,
      display: null,
      setup: null,
      magicInfo: null,
      status: null,
      thumbnail: null
    }
  }
}

const devices = createReducer(initialState, {
  [API_GET_ALL_ADMINISTRABLE_DEVICE]: state => {
    state.administrables.loading = true
  },
  [toSuccess(API_GET_ALL_ADMINISTRABLE_DEVICE)]: (state, action) => {
    state.administrables.loading = false
    state.administrables.loaded = true
    state.administrables.error = false
    state.administrables.data = action.payload.data
  },
  [toFailure(API_GET_ALL_ADMINISTRABLE_DEVICE)]: state => {
    state.administrables.loading = false
    state.administrables.loaded = true
    state.administrables.error = true
  },

  [API_GET_ALL_DEVICE]: (state, action) => {
    if (!action.payload.params.silent) state.list.loading = true
  },
  [toSuccess(API_GET_ALL_DEVICE)]: (state, action) => {
    state.list.loading = false
    state.list.error = false
    state.list.loaded = true
    state.list.data = action.payload.data
  },
  [toFailure(API_GET_ALL_DEVICE)]: state => {
    state.list.loading = false
    state.list.error = true
  },

  [API_GET_ONE_DEVICE]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ONE_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.loaded = true
    state.focus.data = _.merge(state.focus.data, action.payload.data)

    // Need to get status and thumbnail from list...
    const inList = state.list.data.find(i => i.id === action.payload.data.id)
    if (inList !== undefined) {
      state.focus.data.status = inList.status
      state.focus.data.thumbnail = inList.thumbnail
    }
  },
  [toFailure(API_GET_ONE_DEVICE)]: state => {
    state.focus.loading = false
    state.focus.error = true
    state.focus.data = initialState.focus.data
  },

  [API_MAGICINFO_GET_GENERAL_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_GET_GENERAL_DEVICE)]: (state, action) => {
    state.focus.data = {
      ...state.focus.data,
      general: action.payload.data.general,
      display: action.payload.data.display,
      time: state.focus.data.time,
      setup: state.focus.data.setup,
      security: state.focus.data.security
    }
  },
  [toFailure(API_MAGICINFO_GET_GENERAL_DEVICE)]: () => {},

  [API_MAGICINFO_GET_TIME_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_GET_TIME_DEVICE)]: (state, action) => {
    state.focus.data.time = action.payload.data
  },
  [toFailure(API_MAGICINFO_GET_TIME_DEVICE)]: state => {
    state.focus.data.time = null
  },

  [API_MAGICINFO_GET_SETUP_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_GET_SETUP_DEVICE)]: (state, action) => {
    state.focus.data.setup = action.payload.data
  },
  [toFailure(API_MAGICINFO_GET_SETUP_DEVICE)]: state => {
    state.focus.data.setup = null
  },

  [API_MAGICINFO_GET_SECURITY_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_GET_SECURITY_DEVICE)]: (state, action) => {
    state.focus.data.security = action.payload.data
  },
  [toFailure(API_MAGICINFO_GET_SECURITY_DEVICE)]: state => {
    state.focus.data.security = null
  },

  [API_MAGICINFO_PATCH_TIME_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_PATCH_TIME_DEVICE)]: (state, action) => {
    state.focus.data.time.timerConfTimer1 = action.payload.data.timerConfTimer1 || state.focus.data.time.timerConfTimer1
    state.focus.data.time.timerConfTimer2 = action.payload.data.timerConfTimer2 || state.focus.data.time.timerConfTimer2
    state.focus.data.time.timerConfTimer3 = action.payload.data.timerConfTimer3 || state.focus.data.time.timerConfTimer3
    state.focus.data.time.timerConfTimer4 = action.payload.data.timerConfTimer4 || state.focus.data.time.timerConfTimer4
    state.focus.data.time.timerConfTimer5 = action.payload.data.timerConfTimer5 || state.focus.data.time.timerConfTimer5
    state.focus.data.time.timerConfTimer6 = action.payload.data.timerConfTimer6 || state.focus.data.time.timerConfTimer6
    state.focus.data.time.timerConfTimer7 = action.payload.data.timerConfTimer7 || state.focus.data.time.timerConfTimer7

    toast.success(i18n.t("devices.api.timerPatched"))
  },
  [toFailure(API_MAGICINFO_PATCH_TIME_DEVICE)]: () => {},

  [API_MAGICINFO_PATCH_GENERAL_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_PATCH_GENERAL_DEVICE)]: (state, action) => {
    state.focus.data.general = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },
  [toFailure(API_MAGICINFO_PATCH_GENERAL_DEVICE)]: () => {},

  [API_MAGICINFO_PATCH_DISPLAY_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_PATCH_DISPLAY_DEVICE)]: (state, action) => {
    state.focus.data.display = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },
  [toFailure(API_MAGICINFO_PATCH_DISPLAY_DEVICE)]: () => {},

  [API_MAGICINFO_PATCH_SETUP_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_PATCH_SETUP_DEVICE)]: (state, action) => {
    state.focus.data.setup = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },
  [toFailure(API_MAGICINFO_PATCH_SETUP_DEVICE)]: () => {},

  [API_MAGICINFO_PATCH_SECURITY_DEVICE]: () => {},
  [toSuccess(API_MAGICINFO_PATCH_SECURITY_DEVICE)]: (state, action) => {
    state.focus.data.security = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },
  [toFailure(API_MAGICINFO_PATCH_SECURITY_DEVICE)]: () => {},

  [API_GET_ORGANIZATION_DEVICE]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_GET_ORGANIZATION_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data.owner = action.payload.data
  },
  [toFailure(API_GET_ORGANIZATION_DEVICE)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [GET_ONE_DEVICE]: (state, action) => {
    state.focus.data = state.list.data.find(e => e.id === Number(action.payload.id)) || null
  },

  [API_POST_DEVICE]: state => {
    state.focus.loading = true
  },
  [toSuccess(API_POST_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.error = false
    state.focus.data = action.payload.data.device
    state.list.data.push(action.payload.data.device)
    state.administrables.data.push(action.payload.data.device)

    toast.success(i18n.t("devices.api.created"))
  },
  [toFailure(API_POST_DEVICE)]: state => {
    state.focus.loading = false
    state.focus.error = true
  },

  [API_PATCH_DEVICE]: state => {
    state.focus.loading = true
  },
  [toFailure(API_PATCH_DEVICE)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_PATCH_DEVICE)]: (state, action) => {
    // Keep status, thumbnail && magicInfo
    const oldData = _.clone(state.focus.data)

    state.focus.loading = false
    state.focus.data = _.merge(state.focus.data, action.payload.data)
    state.focus.data = {
      ...state.focus.data,
      broadcastTags: action.payload.data.broadcastTags || oldData.broadcastTags,
      magicInfo: oldData.magicInfo,
      status: oldData.status,
      thumbnail: oldData.thumbnail
    }

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = state.focus.data

    toast.success(i18n.t("devices.api.patched"))
  },

  [API_DELETE_DEVICE]: state => {
    state.list.loading = true
  },
  [toFailure(API_DELETE_DEVICE)]: state => {
    state.list.loading = false
  },
  [toSuccess(API_DELETE_DEVICE)]: (state, action) => {
    state.list.loading = false
    state.administrables.loading = false
    state.list.data.splice(
      state.list.data.findIndex(i => i.id === Number(action.payload.data.id)),
      1
    )

    state.administrables.data.splice(
      state.administrables.data.findIndex(i => i.id === Number(action.payload.data.id)),
      1
    )

    state.focus.data = initialState.focus.data
    toast.success(i18n.t("devices.api.deleted"))
  },

  /** Device Management */
  [API_SET_ON_DEVICE]: state => {
    state.focus.loading = true
  },
  [toFailure(API_SET_ON_DEVICE)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_SET_ON_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.deviceSetOn"))
  },

  /** Device Management */
  [API_SET_OFF_DEVICE]: state => {
    state.focus.loading = true
  },
  [toFailure(API_SET_OFF_DEVICE)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_SET_OFF_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.deviceSetOff"))
  },

  /** Device Management */
  [API_CLEAR_STORAGE_DEVICE]: state => {
    state.focus.loading = true
  },
  [toFailure(API_CLEAR_STORAGE_DEVICE)]: state => {
    state.focus.loading = false
  },
  [toSuccess(API_CLEAR_STORAGE_DEVICE)]: (state, action) => {
    state.focus.loading = false
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.deviceStorageCleared"))
  },

  [toSuccess(API_UPLOAD_IMAGE_DEVICE)]: (state, action) => {
    const { bloc, device } = action.payload.data

    state.focus.data.displayParameters = action.meta.previousAction.payload.savedParameters
    state.focus.data.displayParameters[`${bloc}_img`] = device.displayParameters[`${bloc}_img`]
    state.focus.data.displayParameters[`${bloc}_imgPosition`] = device.displayParameters[`${bloc}_imgPosition`]
    state.focus.data.displayParameters[`${bloc}_source`] = null

    toast.success(i18n.t("devices.api.patched"))
  },

  [toSuccess(API_DELETE_IMAGE_DEVICE)]: (state, action) => {
    const { bloc, device } = action.payload.data

    state.focus.data.displayParameters = action.meta.previousAction.payload.savedParameters
    state.focus.data.displayParameters[`${bloc}_img`] = device.displayParameters[`${bloc}_img`]
    state.focus.data.displayParameters[`${bloc}_imgPosition`] = device.displayParameters[`${bloc}_imgPosition`]
    state.focus.data.displayParameters[`${bloc}_source`] = null

    toast.success(i18n.t("devices.api.patched"))
  },

  [toSuccess(API_POST_SCREEN_TIMER_DEVICE)]: (state, action) => {
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },

  [toSuccess(API_PATCH_SCREEN_TIMER_DEVICE)]: (state, action) => {
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },

  [toSuccess(API_DELETE_SCREEN_TIMER_DEVICE)]: (state, action) => {
    state.focus.data = action.payload.data

    const index = state.list.data.findIndex(i => i.id === action.payload.data.id)
    state.list.data[index] = action.payload.data

    toast.success(i18n.t("devices.api.patched"))
  },

  [toSuccess(API_PATCH_PROGRAM)]: (state, action) => {
    action.payload.data.devices.forEach(device => {
      const index = state.list.data.findIndex(i => i.id === device.id)
      if (index > -1) state.list.data[index].program = device.program
    })

    // Remove program from devices removed for this program
    const oldDevices = _.clone(state.list.data)
      .filter(i => i.program !== undefined && i.program !== null && i.program.id === Number(action.payload.data.id))
      .map(i => i.id)

    oldDevices.forEach(id => {
      if (!action.payload.data.devices.includes(id)) {
        const index = state.list.data.findIndex(i => i.id === id)
        if (index > -1) state.list.data[index].program = null
      }
    })
  },

  /* QUICK ACCESS */
  [toSuccess(API_MAGICINFO_POWERON_DEVICE)]: (state, action) => {
    const responseStatus = action.payload.data.status
    if (responseStatus === "Fail") {
      toast.warn("Impossible de se connecter à l'écran")
    } else if (responseStatus === "Success") {
      state.focus.data.status = "ON"
    }
  },
  [toSuccess(API_MAGICINFO_POWEROFF_DEVICE)]: (state, action) => {
    const responseStatus = action.payload.data.status
    if (responseStatus === "Fail") {
      toast.warn("Impossible de se connecter à l'écran")
    } else if (responseStatus === "Success") {
      state.focus.data.status = state.focus.data.display.networkStandbyMode === "1" ? "OFF" : "DISCONNECTED"
    }
  },
  [toSuccess(API_MAGICINFO_RESTART_DEVICE)]: (state, action) => {
    const responseStatus = action.payload.data.status
    if (responseStatus === "Fail") {
      toast.warn("Impossible de se connecter à l'écran")
    } else if (responseStatus === "Success") {
      state.focus.data.status = "ON"
    }
  },
  [toSuccess(API_MAGICINFO_CHANGESOURCE_DEVICE)]: () => {
    toast.success("Source mise à jour")
  },
  [toSuccess(API_MAGICINFO_CLEARDISK_DEVICE)]: () => {
    toast.success("Nettoyage mémoire en cours")
  },

  [RESET_FOCUS_DEVICE]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loaded = false
    state.focus.loading = false
    state.focus.error = false
  },

  [INIT_DEVICE]: state => {
    state.focus.data = initialState.focus.data
    state.focus.loaded = false
    state.focus.error = false
    state.focus.loading = false
  },
  [RESET_DEVICES]: state => {
    state.list = initialState.list
    state.focus = initialState.focus
  },

  // eslint-disable-next-line no-unused-vars
  [LOGOUT_USER]: state => {
    // eslint-disable-next-line no-param-reassign
    state = initialState
  }
})

export default devices
