import React, { Fragment } from "react"
import cx from "classnames"

import { connect } from "react-redux"

import ReactCSSTransitionGroup from "react-addons-css-transition-group"

import HeaderLogo from "../AppLogo"

import UserBox from "./Components/UserBox"

class Header extends React.Component {
  render() {
    const {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
      user,
      locale,
      changeLocale,
      logout
    } = this.props
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, { "header-shadow": enableHeaderShadow })}
          transitionName="HeaderAnimation"
          transitionAppear
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />

          <div className={cx("app-header__content", { "header-mobile-open": enableMobileMenuSmall })}>
            <div className="app-header-right">
              {user !== undefined && (
                <UserBox user={user} locale={locale} changeLocale={changeLocale} logout={logout} />
              )}
            </div>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  me: state.users.me.data
})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
