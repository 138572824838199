import React from "react"
import { Loader } from "semantic-ui-react"

const ListLoader = () => {
  return (
    <div className="list-loader">
      <Loader active inline />
    </div>
  )
}

export default ListLoader
