import { API_POST_LOCALTAG, API_DELETE_LOCALTAG, API_PATCH_LOCALTAG } from "actions/localTags"

export function apiPostLocalTag(_data) {
  return {
    type: API_POST_LOCALTAG,
    payload: {
      request: {
        url: `/localTags`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchLocalTag(_data) {
  return {
    type: API_PATCH_LOCALTAG,
    payload: {
      request: {
        url: `/localTags/${_data.id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteLocalTag(id) {
  return {
    type: API_DELETE_LOCALTAG,
    payload: {
      request: {
        url: `/localTags/${id}`,
        method: "DELETE"
      }
    }
  }
}
