import React from "react"
import Radium from "radium"
import { Loader } from "semantic-ui-react"

const mainButton = disabled => ({
  cursor: "pointer",
  display: "inline-block",
  minHeight: "1em",
  outline: 0,
  border: "none",
  verticalAlign: "baseline",
  backgroundColor: disabled ? "#b9b9b9" : "#cccccc",
  color: disabled ? "#a2a2a2" : "#fff",
  fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
  margin: "0 .25em 0 0",
  padding: ".78571429em 1.5em .78571429em",
  textTransform: "none",
  textShadow: "none",
  fontWeight: 700,
  lineHeight: "1em",
  fontStyle: "normal",
  textAlign: "center",
  textDecoration: "none",
  borderRadius: ".28571429rem",
  boxShadow: "0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset",
  transition: "opacity .1s ease,background-color .1s ease,color .1s ease,box-shadow .1s ease,background .1s ease",
  ":hover": {
    backgroundColor: "#c3c2c2",
    boxShadow: "0 0 0 1px transparent inset, 0 0 0 0 rgba(34,36,38,.15) inset",
    color: "#f5f5f5"
  },
  ":active": {
    backgroundColor: "#c6c2c2",
    color: "#e6e6e6"
  }
})

const CancelConnectButton = ({ text, style, disabled, onClick, children, isSubmitting }) => {
  return (
    <button
      style={{ ...mainButton(disabled), ...style }}
      type="button"
      disabled={disabled}
      onClick={e => {
        if (onClick !== undefined) {
          e.preventDefault()
          onClick()
        }
      }}
    >
      {isSubmitting !== undefined && isSubmitting === true ? <Loader inline active size="tiny" /> : text} {children}
    </button>
  )
}

export default Radium(CancelConnectButton)
