import React from "react"

const PS7Icon = ({ icon, style = null, onClick = null, className = "" }) => {
  return (
    <i
      className={`pe-7s-${icon} ${className}`}
      onClick={() => {
        if (onClick !== null) onClick()
      }}
      style={{ ...style }}
      // eslint-disable-next-line react/jsx-closing-tag-location
    ></i>
  )
}

export default PS7Icon
