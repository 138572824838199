import {
  API_POST_USER,
  API_DELETE_USER,
  API_PATCH_USER,
  INIT_USER,
  RESET_FOCUS_USER,
  API_PATCH_ME,
  API_GET_ME
} from "actions/users"

export function apiGetMe() {
  return {
    type: API_GET_ME,
    payload: {
      request: {
        url: `/users/me`,
        method: "GET"
      }
    }
  }
}

export function apiPostUser(user) {
  return {
    type: API_POST_USER,
    payload: {
      request: {
        url: `/users`,
        method: "POST",
        data: user
      }
    }
  }
}

export function apiPatchMe(_data) {
  return {
    type: API_PATCH_ME,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/users/me`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchUser(user) {
  return {
    type: API_PATCH_USER,
    payload: {
      request: {
        url: `/users/${user.id}`,
        method: "PATCH",
        data: user
      }
    }
  }
}

export function apiDeleteUser(id) {
  return {
    type: API_DELETE_USER,
    payload: {
      request: {
        url: `/users/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function initDevice() {
  return {
    type: INIT_USER
  }
}

export function resetFocusDevice() {
  return {
    type: RESET_FOCUS_USER
  }
}
