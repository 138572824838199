import axios from "axios"
import { BASE_API_URL } from "config/api"
import i18n from "utils/i18n"
import { toast } from "react-toastify"

const client = axios.create({
  baseURL: BASE_API_URL,
  responseType: "json"
})

export const middlewareConfig = {
  interceptors: {
    response: [
      {
        // eslint-disable-next-line no-empty-pattern
        success: ({}, res) => {
          if (res.headers["x-token-refresh"] !== undefined)
            client.defaults.headers.common.authorization = `Bearer ${res.headers["x-token-refresh"]}`
          return Promise.resolve(res)
        },
        // eslint-disable-next-line no-empty-pattern
        error: ({ dispatch }, error) => {
          const { errorCode } = error.response.data.data
          const { statusCode } = error.response.data

          if (errorCode !== 101 && statusCode !== 401) toast.error(i18n.t(`api.errors.${errorCode}`))
          if (error.response.data.statusCode === 401) {
            client.defaults.headers.common.authorization = ""
            dispatch({ type: "LOGOUT_USER" })
          }

          return Promise.reject(error)
        }
      }
    ]
  }
}
export default client
