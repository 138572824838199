import React, { Component, Fragment } from "react"
import { withRouter } from "react-router-dom"

import { getSidebarConfig } from "config/routing"
import i18n from "utils/i18n"
import { NavSoloItem } from "./NavSoloItem"
import { NavMultipleItem } from "./NavMultipleItem"

class Nav extends Component {
  state = {
    openedMenu: null
  }

  translateMenu = routes => {
    return routes.map(category => ({
      ...category,
      name: i18n.t(category.name),
      elements: category.elements.map(elt => ({
        ...elt,
        label: i18n.t(elt.label),
        children: elt.children.map(child => ({
          ...child,
          label: i18n.t(child.label)
        }))
      }))
    }))
  }

  setOpenedMenu = _name => {
    if (this.state.openedMenu !== _name) this.setState({ openedMenu: _name })
    else this.setState({ openedMenu: null })
  }

  render() {
    const { user } = this.props
    const { pathname } = this.props.location
    // const { openedMenu } = this.state
    const config = this.translateMenu(getSidebarConfig())

    const categories = config
      .filter(cat => cat.authorizedRoles.includes(user.role))
      .filter(cat => (cat.isOwnerOnly && user.isPepstreamAdmin) || !cat.isOwnerOnly)

    return (
      <Fragment>
        {categories.map(
          category =>
            category.inSidebar && (
              <React.Fragment key={`fragment-${category.name}`}>
                <h5 className="app-sidebar__heading">{category.name}</h5>
                <div className="metismenu vertical-nav-menu">
                  <ul className="metismenu-container">
                    {category.elements
                      .filter(item => {
                        if (item.authorizedRoles !== undefined) return item.authorizedRoles.includes(user.role)
                        return category.authorizedRoles.includes(user.role)
                      })
                      .map(item => (
                        <React.Fragment key={`fragment-item-${item.path}`}>
                          {item.inSidebar && item.children.length === 0 && (
                            <NavSoloItem
                              content={item}
                              active={pathname === item.path}
                              key={`navSoloItem-${item.path}`}
                            />
                          )}
                          {item.inSidebar && item.children.length > 0 && (
                            <NavMultipleItem
                              item={item}
                              key={`navMultipleItem-${item.path}`}
                              pathname={pathname}
                              opened={false /* openedMenu === item.name */}
                              onClick={() => this.setOpenedMenu(item.name)}
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
              </React.Fragment>
            )
        )}
      </Fragment>
    )
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path)
  }
}

export default withRouter(Nav)
