const initialValues = [
  {
    name: "firstName",
    type: "text"
  },
  {
    name: "lastName",
    type: "text"
  },
  {
    name: "email",
    type: "text"
  },
  {
    name: "role",
    type: "text"
  },
  {
    name: "password",
    type: "password"
  }
]

export default initialValues
