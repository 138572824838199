import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { apiPostBroadcastTag, apiPatchBroadcastTag, apiDeleteBroadcastTag } from "services/broadcastTags"
import ListContainer from "components/List/ListContainer"
import { Button } from "reactstrap"
import i18n from "utils/i18n"
import { Formik, Form } from "formik"
import initFormValues from "utils/formik/initFormValues"
import BroadcastTagForm from "features/Organization/BroadcastTags/Form"
import initialValues from "features/Organization/BroadcastTags/Form/initialValues"
import validationSchema from "features/Organization/BroadcastTags/Form/validationSchema"
import ConfirmModal from "components/Modals/ConfirmModal"

export class BroadcastTags extends Component {
  state = {
    form: {
      init: false,
      loading: false
    },
    deleteConfirmModalOpened: false,
    eltDeleted: null
  }

  cancelForm = () => {
    this.setState({ form: { loading: false, init: false } })
  }

  submitForm = async values => {
    this.setState({ form: { loading: true, init: true } })

    // Serializer
    const data = values
    data.owner = this.props.organization.id

    const response = await this.props.apiPostBroadcastTag(data)

    if (response.type === "API_POST_BROADCASTTAG_FAIL") this.setState({ form: { loading: false, init: true } })
    else this.setState({ form: { loading: false, init: false } })
  }

  patch = elt => {
    this.props.apiPatchBroadcastTag(elt)
  }

  openDeleteConfirmModal = id => {
    this.setState({ deleteConfirmModalOpened: true, eltDeleted: id })
  }

  closeDeleteConfirmModal = () => {
    this.setState({ deleteConfirmModalOpened: false, eltDeleted: null })
  }

  delete = async () => {
    this.setState({ deleteConfirmModalOpened: false })
    await this.props.apiDeleteBroadcastTag(this.state.eltDeleted)

    this.setState({ eltDeleted: null })
  }

  render() {
    const { broadcastTags } = this.props.organization
    const { form, deleteConfirmModalOpened } = this.state

    return (
      <Fragment>
        {!form.init && (
          <div className="new-element-button">
            <Button
              className="btn-icon"
              color="primary"
              outline
              size="md"
              onClick={() => this.setState({ form: { init: true, loading: false } })}
            >
              <i className="pe-7s-plus btn-icon-wrapper"> </i>
              {i18n.t("broadcastTags.actions.add")}
            </Button>
          </div>
        )}

        {form.init && (
          <Formik
            enableReinitialize
            initialValues={initFormValues(initialValues, {}, true)}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.submitForm(values)
            }}
          >
            {({ errors }) => {
              return (
                <Form>
                  <BroadcastTagForm errors={errors} loading={form.loading} onCancel={this.cancelForm} />
                </Form>
              )
            }}
          </Formik>
        )}

        <ListContainer
          type="BROADCASTTAG"
          lineHeight="md"
          elements={{ data: broadcastTags, loading: false }}
          handlers={{ delete: id => this.openDeleteConfirmModal(id), patch: this.patch }}
          filtersOn={false}
          filters={null}
          defaultLayout="list"
          multipleLayouts={false}
        />

        <ConfirmModal
          title="Supprimer le tag de diffusion"
          content="Êtes vous sûr de vouloir supprimer ce tag de diffusion ?"
          visible={deleteConfirmModalOpened}
          cancel={this.closeDeleteConfirmModal}
          submit={this.delete}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  apiPostBroadcastTag,
  apiPatchBroadcastTag,
  apiDeleteBroadcastTag
}

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastTags)
