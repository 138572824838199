import React from "react"

const LocalTag = ({ name, color }) => {
  return (
    <div className="tag" style={{ border: `1px solid ${color}`, color }}>
      <i className="pe-7s-ticket btn-icon-wrapper icon-tag"> </i>
      {name}
    </div>
  )
}

export default LocalTag
