import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { apiGetVideoForPlaylistChanges } from "services/videos"
import { apiGetWebContentForPlaylistChanges } from "services/webcontents"
import { apiReplaceInPlaylists, apiAddAfterInPlaylists } from "services/playlists"
import { Modal, Button } from "semantic-ui-react"
import ListContainer from "components/List/ListContainer"
import GridItemVideo from "features/Videos/List/Items/GridItem"
import FormSaveButton from "components/Buttons/FormSaveButton"
import GridItemPlaylist from "features/Playlists/List/Items/GridItem"
import GridItemWebContent from "features/WebContents/List/Items/GridItem"

export class ReplaceOrAdd extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReady: false,
      selectionOpened: true,
      selectionId: null,
      playlistChoiceOption: "onlyThisOne",
      playlistSelection: [],
      isSubmitting: false,
      isCompleted: false
    }
  }

  reinit = () => {
    this.setState({
      isReady: false,
      selectionOpened: true,
      selectionId: null,
      playlistChoiceOption: "onlyThisOne",
      playlistSelection: [],
      isSubmitting: false,
      isCompleted: false
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) {
      if (this.props.itemType === "VIDEO") this.fetchVideo()
      else if (this.props.itemType === "WEBCONTENT") this.fetchWebContent()
    }
    if (prevProps.visible && !this.props.visible) this.reinit()
  }

  async fetchVideo() {
    await this.props.apiGetVideoForPlaylistChanges(this.props.id)
    this.setState({ isReady: true })
  }

  async fetchWebContent() {
    await this.props.apiGetWebContentForPlaylistChanges(this.props.id)
    this.setState({ isReady: true })
  }

  onSelect = id => {
    this.setState({ selectionOpened: false, selectionId: id })
  }

  onPlaylistSelect = id => {
    if (this.state.playlistSelection.includes(id)) {
      this.setState(prevState => {
        prevState.playlistSelection.splice(
          prevState.playlistSelection.findIndex(i => i === id),
          1
        )
        return prevState
      })
    } else {
      this.setState(prevState => {
        prevState.playlistSelection.push(id)
        return prevState
      })
    }
  }

  closeModal = () => {
    this.props.close()
    this.setState({ isReady: false })
  }

  changePlaylistChoiceOptions = type => {
    this.setState({ playlistChoiceOption: type })
  }

  submit = async () => {
    const data = {
      oldId: this.props.id,
      newId: this.state.selectionId,
      itemType: this.props.itemType,
      currentPlaylist: this.props.currentPlaylist.id
    }

    const { playlistChoiceOption } = this.state
    if (playlistChoiceOption === "onlyThisOne") {
      data.playlists = [this.props.currentPlaylist.id]
    } else if (playlistChoiceOption === "some") {
      data.playlists = this.state.playlistSelection
    } else if (playlistChoiceOption === "all") {
      if (this.props.itemType === "VIDEO") data.playlists = this.props.videos.focus.data.playlists.map(i => i.id)
      else if (this.props.itemType === "WEBCONTENT")
        data.playlists = this.props.webContents.focus.data.playlists.map(i => i.id)
    } else {
      console.log("error")
    }

    this.setState({ isSubmitting: true })

    if (this.props.type === "replace") await this.props.apiReplaceInPlaylists(data)
    if (this.props.type === "addAfter") await this.props.apiAddAfterInPlaylists(data)

    this.props.reloadContent()

    this.setState({ isSubmitting: false, isCompleted: true })
  }

  render() {
    const { visible, itemType, type, videos, webContents, id, currentPlaylist } = this.props
    const {
      isReady,
      selectionOpened,
      selectionId,
      playlistChoiceOption,
      playlistSelection,
      isSubmitting,
      isCompleted
    } = this.state
    let oldItem
    let replacementItem

    if (selectionId !== null) {
      if (itemType === "VIDEO") {
        oldItem = this.props.videos.list.data.find(i => i.id === id)
        replacementItem = this.props.videos.list.data.find(i => i.id === selectionId)
      } else if (itemType === "WEBCONTENT") {
        oldItem = this.props.webContents.list.data.find(i => i.id === id)
        replacementItem = this.props.webContents.list.data.find(i => i.id === selectionId)
      }
    }

    return (
      <Modal size="fullscreen" open={visible} onClose={() => this.closeModal()}>
        <Modal.Header>{type === "replace" ? "Remplacer un contenu" : "Ajouter à la suite un contenu"}</Modal.Header>
        <Modal.Content>
          {!isReady && <div className="modal-loader">⏳Chargement en cours...</div>}

          {isSubmitting && <div className="modal-loader">⚙️ Traitement en cours...</div>}

          {isCompleted && (
            <div className="modal-replace-success">
              C&apos;est terminé ! ✅
              <br />
              <Button style={{ marginTop: "1em" }} onClick={() => this.closeModal()}>
                Fermer
              </Button>
            </div>
          )}

          {isReady && !isSubmitting && !isCompleted && (
            <Fragment>
              {selectionOpened && (
                <Fragment>
                  <div className="modal-subtitle" style={{ marginBottom: "1em" }}>
                    1️⃣ Sélectionnez le contenu {type === "replace" ? `de remplacement` : "à ajouter"}
                  </div>
                  {itemType === "VIDEO" && (
                    <ListContainer
                      type="VIDEO"
                      lineHeight="lg"
                      elements={videos.list}
                      sortField="updatedAt"
                      filtersOn
                      filters={{ search: ["title"], select: ["owner", "categories", "groups", "broadcastTags"] }}
                      defaultLayout="grid"
                      selectable
                      onSelect={this.onSelect}
                      handlers={{}}
                    />
                  )}
                  {itemType === "WEBCONTENT" && (
                    <ListContainer
                      type="WEBCONTENT"
                      lineHeight="lg"
                      elements={webContents.list}
                      sortField="updatedAt"
                      filtersOn
                      filters={{ search: ["title"], select: ["owner", "categories", "groups", "broadcastTags"] }}
                      defaultLayout="grid"
                      selectable
                      onSelect={this.onSelect}
                      handlers={{}}
                    />
                  )}
                </Fragment>
              )}

              {!selectionOpened && selectionId !== null && type === "replace" && (
                <Fragment>
                  <div className="modal-subtitle" style={{ fontWeight: 600 }}>
                    1️⃣ Contenu de remplacement sélectionné
                  </div>
                  <div className="replacement-video-summary">
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      {itemType === "VIDEO" && (
                        <GridItemVideo element={oldItem} linkActivated={false} actionsActivated={false} />
                      )}
                      {itemType === "WEBCONTENT" && <GridItemWebContent element={oldItem} linkActivated={false} />}
                    </div>
                    <div className="replace-from-to">
                      <i className="pe-7s-angle-right-circle btn-icon-wrapper" style={{ fontSize: "2em" }}>
                        {" "}
                      </i>
                    </div>
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      {itemType === "VIDEO" && (
                        <GridItemVideo element={replacementItem} linkActivated={false} actionsActivated={false} />
                      )}
                      {itemType === "WEBCONTENT" && (
                        <GridItemWebContent element={replacementItem} linkActivated={false} />
                      )}
                    </div>
                  </div>
                </Fragment>
              )}

              {!selectionOpened && selectionId !== null && type === "addAfter" && (
                <Fragment>
                  <div className="modal-subtitle" style={{ fontWeight: 600 }}>
                    1️⃣ Contenu à ajouter sélectionné
                  </div>
                  <div className="replacement-video-summary">
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      {itemType === "VIDEO" && (
                        <GridItemVideo element={oldItem} linkActivated={false} actionsActivated={false} />
                      )}
                      {itemType === "WEBCONTENT" && <GridItemWebContent element={oldItem} linkActivated={false} />}
                    </div>
                    <div className="replace-from-to">
                      <i className="pe-7s-angle-right-circle btn-icon-wrapper" style={{ fontSize: "2em" }}>
                        {" "}
                      </i>
                    </div>
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      {itemType === "VIDEO" && (
                        <GridItemVideo element={oldItem} linkActivated={false} actionsActivated={false} />
                      )}
                      {itemType === "WEBCONTENT" && <GridItemWebContent element={oldItem} linkActivated={false} />}
                    </div>
                    <div className="replace-from-to">
                      <i className="pe-7s-plus btn-icon-wrapper" style={{ fontSize: "2em" }}>
                        {" "}
                      </i>
                    </div>
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      {itemType === "VIDEO" && (
                        <GridItemVideo element={replacementItem} linkActivated={false} actionsActivated={false} />
                      )}
                      {itemType === "WEBCONTENT" && (
                        <GridItemWebContent element={replacementItem} linkActivated={false} />
                      )}
                    </div>
                  </div>
                </Fragment>
              )}

              {!selectionOpened && selectionId !== null && (
                <div className="playlistChoice">
                  <div className="modal-subtitle">2️⃣ Dans quelle(s) playlist(s) remplacer ce contenu ?</div>
                  <div className="playlistChoice-buttons" style={{ marginBottom: "1em" }}>
                    <FormSaveButton
                      text="Toutes"
                      outline={playlistChoiceOption !== "all"}
                      onClick={() => this.changePlaylistChoiceOptions("all")}
                      style={{ marginRight: 10 }}
                    />
                    <FormSaveButton
                      text="Certaines"
                      outline={playlistChoiceOption !== "some"}
                      onClick={() => this.changePlaylistChoiceOptions("some")}
                      style={{ marginRight: 10 }}
                    />
                    <FormSaveButton
                      text="Uniquement la playlist actuelle"
                      outline={playlistChoiceOption !== "onlyThisOne"}
                      onClick={() => this.changePlaylistChoiceOptions("onlyThisOne")}
                      style={{ marginRight: 10 }}
                    />
                  </div>
                  {playlistChoiceOption === "onlyThisOne" && (
                    <div className="grid-element-col box-shadow" style={{ maxWidth: 200 }}>
                      <GridItemPlaylist
                        element={currentPlaylist}
                        linkActivated={false}
                        handlers={{}}
                        actionsActivated={false}
                      />
                    </div>
                  )}

                  {playlistChoiceOption !== "onlyThisOne" && (
                    <ListContainer
                      type="PLAYLIST"
                      lineHeight="md"
                      elements={{
                        data: itemType === "VIDEO" ? videos.focus.data.playlists : webContents.focus.data.playlists
                      }}
                      sortField="updatedAt"
                      filtersOn
                      filters={{ search: ["title"], select: ["owner"] }}
                      defaultLayout="grid"
                      selectable={playlistChoiceOption === "some"}
                      actionsActivated={false}
                      onSelect={this.onPlaylistSelect}
                      handlers={{}}
                    />
                  )}
                </div>
              )}
            </Fragment>
          )}
        </Modal.Content>
        {!isCompleted && (
          <Modal.Actions>
            <Button negative onClick={() => this.closeModal()}>
              Annuler
            </Button>
            <Button
              positive
              disabled={
                id === null ||
                selectionId === null ||
                (playlistChoiceOption === "some" && playlistSelection.length === 0)
              }
              onClick={() => this.submit()}
            >
              Valider
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  videos: state.videos,
  webContents: state.webContents,
  currentPlaylist: state.playlists.focus.data
})

const mapDispatchToProps = {
  apiGetVideoForPlaylistChanges,
  apiGetWebContentForPlaylistChanges,
  apiReplaceInPlaylists,
  apiAddAfterInPlaylists
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceOrAdd)
