import React from "react"
import { Button } from "reactstrap"
import { Loader } from "semantic-ui-react"

const FormSaveButton = ({
  text,
  type = "button",
  disabled = false,
  isLoading = false,
  onClick = null,
  style = {},
  outline = false
}) => {
  return (
    <Button
      color="primary"
      size="md"
      outline={outline}
      // @ts-ignore
      onClick={event => {
        if (onClick !== null) onClick(event)
      }}
      // @ts-ignore
      type={type}
      disabled={disabled || isLoading}
      style={{ ...style }}
    >
      {isLoading && <Loader size="mini" className="loader-button" inline active />}
      {text}
    </Button>
  )
}

export default FormSaveButton
