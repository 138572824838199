import React, { Component } from "react"
import { connect } from "react-redux"
import "semantic-ui-css/semantic.min.css"
// @ts-ignore
import { Route, Redirect, withRouter } from "react-router-dom"
import { Loader } from "semantic-ui-react"
import { Slide, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { tokenExpired, logOutUser } from "services/auth"
import client from "config/agent"
import * as jwt from "jsonwebtoken"
import Main from "components/AppStructure/Main"
import { initMineOrganization, apiGetOrganizations } from "services/organizations"
import { apiGetAllCategories } from "services/categories"
import { getRoutingConfig } from "config/routing"
import AppHeader from "../components/AppStructure/AppHeader"
import AppSidebar from "../components/AppStructure/AppSidebar"
import AppFooter from "../components/AppStructure/AppFooter"

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tokenSet: false,
      locale: "fr"
    }
  }

  componentDidMount() {
    // Init locale
    const locale = window.localStorage.getItem("locale")
    if (locale === null) {
      window.localStorage.setItem("locale", "fr")
    } else {
      this.setState({ locale })
    }

    const { token } = this.props.auth
    const myOrga = this.props.organizations.me.data

    if (token !== null) {
      const decodedToken = jwt.decode(token, { complete: true })
      const dateNow = new Date()

      // @ts-ignore
      if (decodedToken.payload.exp < dateNow.getTime() / 1000) {
        this.props.tokenExpired()
        this.props.logOutUser()
      } else {
        client.defaults.headers.common.authorization = `Bearer ${token}`
        this.setState({ tokenSet: true })
        if (myOrga === null) {
          this.fetchInitData()
        } else {
          this.fetchInitData()
        }
      }
    }
  }

  fetchInitData = async () => {
    await this.props.initMineOrganization()
    await this.props.apiGetOrganizations()
    await this.props.apiGetAllCategories()
  }

  changeLocale = value => {
    window.localStorage.setItem("locale", value)
    this.setState({ locale: value })
  }

  logout = () => {
    this.props.logOutUser()
  }

  render() {
    const { locale, tokenSet } = this.state
    const { auth, organizations, users } = this.props
    const { me } = users

    const appReady = me.data.id !== null && organizations.me.isInit && tokenSet

    if (!auth.isLogged) {
      return <Redirect to="/login" />
    }

    if (!appReady) {
      return <Loader active />
    }

    return (
      <div style={{ height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
        <Main>
          <AppHeader user={me.data} logout={this.logout} locale={locale} changeLocale={this.changeLocale} />
          <div className="app-main">
            <AppSidebar locale={locale} user={me.data} storage={organizations.me.data.storage} />
            <div className="app-main__outer">
              <div className={`app-main__inner ${this.props.location.pathname === "/dashboard" && "no-padding"}`}>
                {getRoutingConfig()
                  .filter(route => route.authorizedRoles.includes(me.data.role))
                  .filter(route => (route.isOwnerOnly && me.data.isPepstreamAdmin) || !route.isOwnerOnly)
                  .map(route => {
                    return <RouteWithSubRoutes {...route} key={`route${route.path}`} />
                  })}

                {this.props.history.location.pathname === "/" && <Redirect to="/dashboard" />}
              </div>
              <AppFooter />
            </div>
          </div>
        </Main>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          transition={Slide}
          style={{ top: "5rem" }}
        />
      </div>
    )
  }
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      {...route.props}
      render={matchProps => (
        // pass the sub-routes down to keep nesting
        <route.component {...matchProps} routes={route.routes} />
      )}
    />
  )
}

const mapStateToProps = state => {
  return {
    users: state.users,
    auth: state.auth,
    organizations: state.organizations
  }
}

const mapDispatchToProps = {
  logOutUser,
  tokenExpired,
  initMineOrganization,
  apiGetOrganizations,
  apiGetAllCategories
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))
