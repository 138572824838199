import React from "react"
import { Row, Col } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"
import getThumbnailSrc from "utils/getThumbnailSrc"
import i18n from "utils/i18n"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import humanFileSize from "utils/humanFileSize"
import ListActionButton from "components/Buttons/ListActionButton"

const ItemLine = ({
  element,
  handlers = {
    userCanDownloadList: [],
    download: id => id,
    copyIntegrationCode: id => id
  }
}) => {
  return (
    <Link to={`/videos/${element.id}`} className="row-link">
      <Row className="line-element-row">
        <Col
          xs={1}
          style={{ backgroundImage: `url(${getThumbnailSrc(element.thumbnail, "video")})` }}
          className="list-thumbnail"
        >
          &nbsp;
        </Col>
        <Col xs={3}>
          <div className="element-title text-ellipsis" style={{ letterSpacing: 0 }}>
            {element.title}
          </div>
          <div className="video-time mt-video-time">{moment.utc(element.duration * 1000).format("mm:ss")}</div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.owner")}</div>
            <div className="content">
              <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
            </div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.size")}</div>
            <div className="content">{humanFileSize(element.size)}</div>
          </div>
        </Col>
        <Col xs={1}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.uploadedAt")}</div>
            <div className="content">{moment(element.uploadedAt).format("DD/MM/YYYY, HH:mm")}</div>
          </div>
        </Col>
        <Col xs={3}>
          {handlers.userCanDownloadList.includes(element.owner.id) && (
            <ListActionButton
              icon="download"
              label={i18n.t("actions.download")}
              onClick={() => handlers.download(element.id)}
            />
          )}

          <ListActionButton
            icon=""
            label={i18n.t("actions.copyIntegrationCode")}
            onClick={() => handlers.copyIntegrationCode(element.id)}
          />
        </Col>
      </Row>
    </Link>
  )
}

export default ItemLine
