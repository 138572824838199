import React from "react"
import { Button } from "reactstrap"

const QuickAccessButton = ({ text, className, onClick, style = {}, disabled = false }) => {
  return (
    <Button
      className={`quickAccessButton ${className}`}
      style={{ ...style }}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

export default QuickAccessButton
