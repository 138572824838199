import React from "react"
import i18n from "utils/i18n"

const DeviceSmallInfo = ({ label, value }) => {
  return (
    <div className="device-small-info">
      <div className="device-info-label">{label}</div>
      <div className="device-info-value">
        {value === null && <i>{i18n.t("form.none")}</i>}
        {value !== null && value}
      </div>
    </div>
  )
}

export default DeviceSmallInfo
