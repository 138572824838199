import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  title: Yup.string().required(i18n.t("form.requiredField")),
  description: Yup.string(),
  shared: Yup.bool().required(i18n.t("form.requiredField")),
  url: Yup.string().required(i18n.t("form.requiredField")),
  duration: Yup.string().required(i18n.t("form.requiredField")),
  owner: Yup.object().required(i18n.t("form.requiredField"))
})

export default validationSchema
