import {
  API_GET_ALL_PROGRAM,
  API_GET_ONE_PROGRAM,
  API_POST_PROGRAM,
  API_DELETE_PROGRAM,
  GET_ONE_PROGRAM,
  INIT_PROGRAM,
  RESET_FOCUS_PROGRAM,
  API_PATCH_PROGRAM,
  API_PATCH_PROGRAM_CONTENT
} from "actions/programs"

export function getProgramFromStore(id) {
  return {
    type: GET_ONE_PROGRAM,
    payload: {
      id
    }
  }
}

export function apiGetPrograms() {
  return {
    type: API_GET_ALL_PROGRAM,
    payload: {
      request: {
        url: `/programs`
      }
    }
  }
}

export function apiGetProgram(id) {
  return {
    type: API_GET_ONE_PROGRAM,
    payload: {
      request: {
        url: `/programs/${id}`
      }
    }
  }
}

export function apiPostProgram(_data) {
  return {
    type: API_POST_PROGRAM,
    payload: {
      request: {
        url: `/programs`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchProgram(id, _data) {
  return {
    type: API_PATCH_PROGRAM,
    payload: {
      request: {
        url: `/programs/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiPatchProgramContent(id, _data) {
  return {
    type: API_PATCH_PROGRAM_CONTENT,
    payload: {
      request: {
        url: `/programs/content/${id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteProgram(id) {
  return {
    type: API_DELETE_PROGRAM,
    payload: {
      request: {
        url: `/programs/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function initProgram() {
  return {
    type: INIT_PROGRAM
  }
}

export function resetFocusProgram() {
  return {
    type: RESET_FOCUS_PROGRAM
  }
}
