import React from "react"
import { Checkbox as SemCheckbox } from "semantic-ui-react"

// Checkbox input
const Switch = ({
  field: { id, name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  label = null,
  editable = true,
  required = false
}) => {
  const onclick = () => {
    setFieldValue(name, !value)
  }
  return (
    <div className="form-input-container form-switch">
      <div className="label-switch">
        {label !== null && <span>{label}</span>}
        {label !== null && required && <span className="form-cross-required">*</span>}
      </div>

      <SemCheckbox
        toggle
        disabled={!editable}
        name={name}
        type="checkbox"
        id={id}
        checked={value}
        onClick={() => onclick()}
        onChange={onChange}
        onBlur={onBlur}
        style={{ float: "right" }}
      />

      {touched[name] && errors[name] && <div className="form-error-style">{errors[name]}</div>}
    </div>
  )
}

export default Switch
