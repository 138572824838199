import {
  API_GET_ALL_DEVICE,
  GET_ONE_DEVICE,
  API_GET_ONE_DEVICE,
  API_POST_DEVICE,
  API_DELETE_DEVICE,
  INIT_DEVICE,
  RESET_FOCUS_DEVICE,
  API_PATCH_DEVICE,
  RESET_DEVICES,
  API_SET_ON_DEVICE,
  API_CLEAR_STORAGE_DEVICE,
  API_SET_OFF_DEVICE,
  API_UPLOAD_IMAGE_DEVICE,
  API_DELETE_IMAGE_DEVICE,
  API_POST_SCREEN_TIMER_DEVICE,
  API_PATCH_SCREEN_TIMER_DEVICE,
  API_DELETE_SCREEN_TIMER_DEVICE,
  API_GET_ALL_ADMINISTRABLE_DEVICE,
  API_MAGICINFO_GET_TIME_DEVICE,
  API_MAGICINFO_GET_SETUP_DEVICE,
  API_MAGICINFO_GET_SECURITY_DEVICE,
  API_MAGICINFO_GET_GENERAL_DEVICE,
  API_MAGICINFO_PATCH_TIME_DEVICE,
  API_MAGICINFO_PATCH_GENERAL_DEVICE,
  API_MAGICINFO_PATCH_DISPLAY_DEVICE,
  API_MAGICINFO_PATCH_SETUP_DEVICE,
  API_MAGICINFO_PATCH_SECURITY_DEVICE,
  API_MAGICINFO_POWERON_DEVICE,
  API_MAGICINFO_POWEROFF_DEVICE,
  API_MAGICINFO_RESTART_DEVICE,
  API_MAGICINFO_CLEARDISK_DEVICE,
  API_MAGICINFO_CHANGESOURCE_DEVICE
} from "actions/devices"

export function getDeviceFromStore(id) {
  return {
    type: GET_ONE_DEVICE,
    payload: {
      id
    }
  }
}

export function apiGetAdministrableDevices() {
  return {
    type: API_GET_ALL_ADMINISTRABLE_DEVICE,
    payload: {
      request: {
        url: `/devices/administrables`
      }
    }
  }
}

export function apiGetDevices({ silent = false } = {}) {
  return {
    type: API_GET_ALL_DEVICE,
    payload: {
      request: {
        url: `/devices/list`,
        method: "GET"
      },
      params: {
        silent
      }
    }
  }
}

export function apiGetDevice(id) {
  return {
    type: API_GET_ONE_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}`
      }
    }
  }
}

export function apiPostDevice(device) {
  return {
    type: API_POST_DEVICE,
    payload: {
      request: {
        url: `/devices`,
        method: "POST",
        data: device
      }
    }
  }
}

export function apiPatchDevice(id, device) {
  return {
    type: API_PATCH_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}`,
        method: "PATCH",
        data: device
      }
    }
  }
}

export function apiDeleteDevice(id) {
  return {
    type: API_DELETE_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function apiSetDeviceOn(id) {
  return {
    type: API_SET_ON_DEVICE,
    payload: {
      request: {
        url: `/devices/management/${id}/on`,
        method: "POST"
      }
    }
  }
}

export function apiSetDeviceOff(id) {
  return {
    type: API_SET_OFF_DEVICE,
    payload: {
      request: {
        url: `/devices/management/${id}/off`,
        method: "POST"
      }
    }
  }
}

export function apiClearDeviceStorage(id) {
  return {
    type: API_CLEAR_STORAGE_DEVICE,
    payload: {
      request: {
        url: `/devices/management/${id}/clear`,
        method: "POST"
      }
    }
  }
}

export function apiUploadDeviceDisplayParametersImage(id, _data, savedParameters) {
  return {
    type: API_UPLOAD_IMAGE_DEVICE,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/devices/${id}/displayParameters/uploadImage`,
        method: "POST",
        data: _data
      },
      savedParameters
    }
  }
}

export function apiDeleteDeviceDisplayParametersImage(id, _data, savedParameters) {
  return {
    type: API_DELETE_IMAGE_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/displayParameters/deleteImage`,
        method: "POST",
        data: _data
      },
      savedParameters
    }
  }
}

export function apiPostScreenTimer(deviceId, _data) {
  return {
    type: API_POST_SCREEN_TIMER_DEVICE,
    payload: {
      request: {
        url: `/devices/${deviceId}/screenTimers`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchScreenTimer(deviceId, _data) {
  return {
    type: API_PATCH_SCREEN_TIMER_DEVICE,
    payload: {
      request: {
        url: `/devices/${deviceId}/screenTimers/${_data.id}`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiDeleteScreenTimer(deviceId, screenTimerId) {
  return {
    type: API_DELETE_SCREEN_TIMER_DEVICE,
    payload: {
      request: {
        url: `/devices/${deviceId}/screenTimers/${screenTimerId}`,
        method: "DELETE"
      }
    }
  }
}

export function apiMagicInfoGetGeneral(id) {
  return {
    type: API_MAGICINFO_GET_GENERAL_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/general`,
        method: "GET"
      }
    }
  }
}

export function apiMagicInfoGetTime(id) {
  return {
    type: API_MAGICINFO_GET_TIME_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/time`,
        method: "GET"
      }
    }
  }
}

export function apiMagicInfoGetSetup(id) {
  return {
    type: API_MAGICINFO_GET_SETUP_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/setup`,
        method: "GET"
      }
    }
  }
}

export function apiMagicInfoGetSecurity(id) {
  return {
    type: API_MAGICINFO_GET_SECURITY_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/security`,
        method: "GET"
      }
    }
  }
}

export function apiMagicInfoPatchGeneral(id, _data) {
  return {
    type: API_MAGICINFO_PATCH_GENERAL_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/general`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiMagicInfoPatchDisplay(id, _data) {
  return {
    type: API_MAGICINFO_PATCH_DISPLAY_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/display`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiMagicInfoPatchTime(id, _data) {
  return {
    type: API_MAGICINFO_PATCH_TIME_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/time`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiMagicInfoPatchSetup(id, _data) {
  return {
    type: API_MAGICINFO_PATCH_SETUP_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/setup`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiMagicInfoPatchSecurity(id, _data) {
  return {
    type: API_MAGICINFO_PATCH_SECURITY_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/security`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function apiMagicInfoPowerOnDevice(id) {
  return {
    type: API_MAGICINFO_POWERON_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/powerOn`,
        method: "PATCH"
      }
    }
  }
}

export function apiMagicInfoPowerOffDevice(id) {
  return {
    type: API_MAGICINFO_POWEROFF_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/powerOff`,
        method: "PATCH"
      }
    }
  }
}

export function apiMagicInfoRestartDevice(id) {
  return {
    type: API_MAGICINFO_RESTART_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/restart`,
        method: "PATCH"
      }
    }
  }
}

export function apiMagicInfoClearDiskDevice(id) {
  return {
    type: API_MAGICINFO_CLEARDISK_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/clearDisk`,
        method: "PATCH"
      }
    }
  }
}

export function apiMagicInfoChangeSourceDevice(id, _data) {
  return {
    type: API_MAGICINFO_CHANGESOURCE_DEVICE,
    payload: {
      request: {
        url: `/devices/${id}/changeSource`,
        method: "PATCH",
        data: _data
      }
    }
  }
}

export function initDevice() {
  return {
    type: INIT_DEVICE
  }
}

export function resetFocusDevice() {
  return {
    type: RESET_FOCUS_DEVICE
  }
}

export function resetDevicesData() {
  return {
    type: RESET_DEVICES
  }
}
