import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Button } from "reactstrap"
import { apiGetChannels, apiPatchChannel, apiDeleteChannel } from "services/channels"
import { apiGetPrograms } from "services/programs"
import ListContainer from "components/List/ListContainer"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import { createJsonDataFromFormik } from "utils/formik/apiSerializer"
import _ from "lodash"

class Channels extends Component {
  state = {
    listLength: null
  }

  componentDidMount() {
    this.props.apiGetChannels()
    this.props.apiGetPrograms()
  }

  patch = elt => {
    const data = createJsonDataFromFormik(_.clone(elt))

    // Groups workaround
    if (elt.groups.length > 0) data.groups = elt.groups[0].id

    this.props.apiPatchChannel(data.id, data)
  }

  delete = elt => {
    this.props.apiDeleteChannel(elt.id)
  }

  render() {
    const { channels, programs, me } = this.props
    const { listLength } = this.state

    return (
      <PageContainer>
        <PageTitle
          heading={i18n.t("channels.pageTitle.heading")}
          icon="pe-7s-airplay folder-color-CHANNEL"
          number={listLength}
        />
        <div className="page-top-buttons">
          {me.role === "ADMIN" && (
            <Link to="/channels/new" className="new-element-button">
              <Button className="btn-icon" color="primary" outline size="md">
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {i18n.t("channels.actions.add")}
              </Button>
            </Link>
          )}
        </div>

        <ListContainer
          type="CHANNEL"
          lineHeight="lg"
          elements={{ data: channels.list.data }}
          sortField="createdAt"
          filtersOn
          filters={{ search: ["title"], select: ["owner", "groups"] }}
          defaultLayout="list"
          multipleLayouts={false}
          listLengthSetter={nb => this.setState({ listLength: nb })}
          handlers={{ patch: this.patch, delete: this.delete, programs: programs.list }}
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    channels: state.channels,
    programs: state.programs,
    myOrganization: state.organizations.me.data,
    me: state.users.me.data
  }
}

const mapDispatchToProps = {
  apiGetChannels,
  apiPatchChannel,
  apiDeleteChannel,
  apiGetPrograms
}

export default connect(mapStateToProps, mapDispatchToProps)(Channels)
