import * as React from "react";

function PepstreamLogoHorizontal2(props) {
  return (
    <svg viewBox="0 0 596.5 80.3" {...props}>
      <linearGradient
        id="prefix__a"
        gradientUnits="userSpaceOnUse"
        x1={2}
        y1={40.462}
        x2={593}
        y2={40.462}
      >
        <stop offset={0} stopColor="#00d2da" />
        <stop offset={1} stopColor="#0076c1" />
      </linearGradient>
      <path
        d="M277 6.4h-26.6V3.1h57v3.3h-26.7v71.5H277V6.4zm97.8 71.5h-4.6l-24.4-32h-26.5v32h-3.6V3.1h30.9c9.5 0 16.8 2.7 21.4 7.2 3.4 3.5 5.5 8.3 5.5 13.9v.2c0 12.6-9.7 20-23.5 21.2l24.8 32.3zm-5-53.3v-.2c0-11.2-8.7-18-23.4-18h-27v36.2h26.3c15.3 0 24.1-7.5 24.1-18zM516 77.9h-4l-10.2-21.8h-46.3l-10.3 21.8h-3.7L477 2.6h3.4L516 77.9zm-15.7-25.1l-21.6-46-21.5 46h43.1zM523 77.9h3.5V9.4l31.3 44.9h.2l31.3-44.9v68.5h3.6V3.1h-3.4L558 48.6 526.4 3.1H523v74.8zm-96.9-39.2h-42.4V42h42.4v-3.3zm8.4-35.8h-50.8v3.3h50.8V2.9zm.5 71.5h-51.3v3.3H435v-3.3zM31.4 3.1c17.4 0 28.4 9.9 28.4 25v.2c0 16.8-13.4 25.5-29.9 25.5H15.1v24H2V3.1h29.4m15.1 25.3c0-8.7-6.3-13.3-16.1-13.3H15.1V42h15.3c9.9 0 16.1-5.5 16.1-13.3v-.3zm63.8 5.9H68v11.6h42.4V34.3zm8.4-31.2H68v11.7h50.7V3.1zm.5 63H68v11.7h51.2V66.1zm39.7-63c17.4 0 28.4 9.9 28.4 25v.2c0 16.8-13.4 25.5-29.9 25.5h-14.8v24h-13.1V3.1h29.4M174 28.4c0-8.7-6.3-13.3-16.1-13.3h-15.3V42h15.3c9.9 0 16.1-5.5 16.1-13.3v-.3zm15.9 38.5c9 8 19.7 12 31.2 12 15.7 0 26.7-8.3 26.7-22.3v-.2c0-12.4-8.2-18-24-21.9-14.3-3.4-17.7-6-17.7-11.7v-.2c0-4.9 4.5-8.9 12.2-8.9 6.8 0 13.6 2.7 20.3 7.7l7-9.9c-7.6-6.1-16.2-9.4-27.1-9.4-14.8 0-25.5 8.9-25.5 21.7v.2c0 13.8 9 18.5 24.9 22.3 13.9 3.2 16.9 6.1 16.9 11.4v.2c0 5.7-5.1 9.4-13.2 9.4-9.3 0-16.4-3.5-23.6-9.7l-8.1 9.3"
        fill="url(#prefix__a)"
      />
    </svg>
  );
}

const MemoPepstreamLogoHorizontal2 = React.memo(PepstreamLogoHorizontal2);
export default MemoPepstreamLogoHorizontal2;
