import React from "react"
import { Col, Row } from "reactstrap"
import { BASE_PLAYER_CHANNEL_URL, BASE_PLAYER_WEBTV_URL } from "config/api"
import i18n from "utils/i18n"

const Collapsible = ({ element }) => {
  const urlStreamingLink = BASE_PLAYER_CHANNEL_URL + element.id
  const webTvFullscreen = `${BASE_PLAYER_WEBTV_URL + element.id}/fullscreen`
  const webTvList = `${BASE_PLAYER_WEBTV_URL + element.id}/list`

  return (
    <div className="collapsible-container">
      <Row className="collapsible-first-row">
        <Col xs={4}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.urlStreamingLink")}</div>
            <div className="link-content">
              <a href={urlStreamingLink} target="_blank">
                {urlStreamingLink}
              </a>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.webTvFullscreen")}</div>
            <div className="link-content">
              <a href={webTvFullscreen} target="_blank">
                {webTvFullscreen}
              </a>
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="collapsible-element-info">
            <div className="label">{i18n.t("entities.attributes.webTvList")}</div>
            <div className="link-content">
              <a href={webTvList} target="_blank">
                {webTvList}
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Collapsible
