import React from "react"
import bytesToGo from "utils/bytesToGo"
import { Progress } from "reactstrap"
import i18n from "utils/i18n"

const DeviceStorage = ({ diskSpaceAvailable, diskSpaceUsage }) => {
  const diskSpaceTotal = (Number(diskSpaceAvailable) + Number(diskSpaceUsage)) * 1000
  const diskSpaceUsageNumber = Number(diskSpaceUsage) * 1000

  let progressBarColor = ""
  let contentColor = ""
  let capPercentage = 0
  let allowed = 0
  let used = 0

  if (diskSpaceTotal !== null) {
    allowed = Math.round(bytesToGo(diskSpaceTotal) * 100) / 100
    used = Math.round(bytesToGo(diskSpaceUsageNumber) * 100) / 100

    capPercentage = ((diskSpaceTotal - diskSpaceUsageNumber) / diskSpaceTotal) * 100
    capPercentage = Math.round(capPercentage * 100) / 100

    switch (true) {
      case capPercentage >= 50:
        progressBarColor = "storage-info-remaining-high"
        contentColor = "storage-content-remaining-high"
        break
      case capPercentage < 50 && capPercentage > 15:
        progressBarColor = "storage-info-remaining-medium"
        contentColor = "storage-content-remaining-medium"
        break
      default:
        progressBarColor = "storage-info-remaining-low"
        contentColor = "storage-content-remaining-low"
        break
    }
  }
  return (
    <div style={{ marginTop: "2em", marginBottom: "2em" }}>
      {diskSpaceAvailable !== null && diskSpaceUsage !== null && (
        <div className="storage-info-container">
          <div className="storage-info-progressbar">
            <Progress color={progressBarColor} value={capPercentage} className="progress-bar-storage">
              {capPercentage}%
            </Progress>
          </div>
          <div className="storage-info-header mt-1">
            <div className="storage-info-title">
              {i18n.t("entities.singular.storage")} {i18n.t("storage.remaining")}
            </div>
            <div className={`storage-info-content ${contentColor}`}>
              {(Math.sign(allowed - used) * Math.round(Math.abs(allowed - used) * 100)) / 100}Go {i18n.t("storage.on")}{" "}
              {allowed}Go
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeviceStorage
