/* eslint-disable no-loop-func */
import React, { Component, Fragment } from "react"
import { Sidebar } from "semantic-ui-react"
import _ from "lodash"
import i18n from "utils/i18n"
import ValidateButton from "components/Buttons/ValidateButton"
import ListLoader from "components/List/ListLoader"
import SidebarCloseButton from "components/SidebarCloseButton"
import Tree from "./Tree"

const getOrgaDevices = (_orga, _devices) => {
  let result = []

  result = _orga.deviceGroups.map(deviceGroup => ({
    id: deviceGroup.id,
    name: deviceGroup.name,
    devices: _devices.filter(device => device.group !== null && device.group.id === deviceGroup.id)
  }))

  if (_devices.some(device => device.owner.id === _orga.id && device.group === null)) {
    result.unshift({
      id: "-1",
      name: i18n.t("devices.noGroup"),
      devices: _devices.filter(device => device.owner.id === _orga.id && device.group === null)
    })
  }

  return result
}

const formatOrga = (_orga, _devices) => ({
  id: _orga.id,
  name: _orga.name,
  logo: _orga.logo,
  level: _orga.level,
  deviceGroups: getOrgaDevices(_orga, _devices)
})

const initTreeData = (_orgas, _devices, initialLevel) => {
  if (_orgas.length === 0) return null
  const result = []

  let level = initialLevel
  let index

  while (_orgas.some(i => i.level === level)) {
    _orgas
      .filter(i => i.level === level)
      .sort((a, b) => b.name.localeCompare(a.name))
      .forEach(orga => {
        if (level === initialLevel) {
          result.push(formatOrga(orga, _devices))
        } else {
          index = result.findIndex(i => i.id === orga.superOrgaId)
          if (index > -1) result.splice(index + 1, 0, formatOrga(orga, _devices))
        }
      })

    level += 1
  }

  return result
}

export class DevicesSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      initSelected: this.props.selected,
      selected: this.props.selected,
      hasChanged: false
    }
  }

  createDataForAutocomplete = () => {
    const result = this.props.elements
      .filter(device => {
        return this.state.selected.findIndex(elt => elt.id === device.id) <= -1
      })
      .map(i => ({
        label: i.name,
        value: i.name,
        data: i
      }))

    return result
  }

  onElementAdd = elt => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = _.clone(this.state.selected)
    data.push(elt)

    this.setState({ selected: data })

    this.setState({ hasChanged: true })
  }

  onElementDelete = elt => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const data = _.clone(this.state.selected)
    data.splice(
      data.findIndex(i => i.id === elt.id),
      1
    )

    this.setState({ selected: data, hasChanged: true })
  }

  onCancel = () => {
    this.props.onCancel()

    this.setState(prevState => ({
      selected: prevState.initSelected
    }))
  }

  render() {
    const { visible, onSubmit, devices, organizations, myOrganization, program } = this.props
    const { selected, hasChanged } = this.state

    const initialLevel = myOrganization.level
    const data = initTreeData(organizations.list.data, devices.administrables.data, initialLevel)

    return (
      <Sidebar
        as="div"
        animation="overlay"
        direction="right"
        visible={visible}
        className="sidebar-editor"
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        {organizations.loading || (devices.administrables.loading && <ListLoader />)}
        {!organizations.loading && !devices.administrables.loading && (
          <Fragment>
            <div className="quickaccess-container">
              <div className="quickaccess-sidebar-title" style={{ padding: "1em 0", marginBottom: "1em" }}>
                {i18n.t("entities.plural.device")}
              </div>

              <SidebarCloseButton onClick={this.onCancel} style={{ position: "absolute", top: "25px", right: "1em" }} />

              <div
                style={{ display: "flex", justifyContent: "space-between", marginLeft: "-10px", marginBottom: "1em" }}
              >
                {hasChanged && (
                  <ValidateButton text={i18n.t("actions.save")} type="button" onClick={() => onSubmit(selected)} />
                )}
              </div>

              <div className="devices-container">
                <div className="devices-list-container">
                  {(!data === null || devices.administrables === null) && <ListLoader />}
                  {data !== null && devices.administrables !== null && (
                    <Tree
                      devices={devices.administrables}
                      focus={devices.focus.data}
                      data={data}
                      selected={selected}
                      onElementAdd={this.onElementAdd}
                      onElementDelete={this.onElementDelete}
                      initialLevel={initialLevel}
                      program={program}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Sidebar>
    )
  }
}

export default DevicesSidebar
