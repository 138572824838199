import React, { Fragment } from "react"
import { Col, Row } from "reactstrap"
import Status from "components/Status"
import moment from "moment"
import { Link } from "react-router-dom"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import getThumbnailSrc from "utils/getThumbnailSrc"

const GridLink = ({ element, linkActivated, children }) => {
  if (linkActivated)
    return (
      <Link to={`/webContents/${element.id}`} className="grid-link">
        {children}
      </Link>
    )

  return <Fragment>{children}</Fragment>
}

const GridItemWebContent = ({ element, linkActivated = true, smallText = false }) => {
  return (
    <GridLink element={element} linkActivated={linkActivated}>
      <div className="grid-element-top">
        <img src={getThumbnailSrc(element.thumbnail, "WEBCONTENT")} alt="" className="grid-thumbnail" />
      </div>
      <div className="grid-element-subcontainer">
        <Row>
          <Col xs={12} className="grid-element-firstrow">
            <Status status={element.status} className="status" />
            <div
              className="element-title text-ellipsis"
              style={{ letterSpacing: 0, fontSize: smallText ? "0.9em" : "1em" }}
            >
              {element.title}
            </div>
            <div className="video-time">{moment.utc(element.duration * 1000).format("mm:ss")}</div>
          </Col>
        </Row>
        <Row className="grid-element-second-row">
          <Col xs={12} className="grid-element">
            <div className="line-element-info">
              <div className="content">
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </GridLink>
  )
}

export default GridItemWebContent
