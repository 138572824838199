import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import {
  getDeviceFromStore,
  apiGetDevice,
  apiPostDevice,
  apiPatchDevice,
  apiDeleteDevice,
  resetFocusDevice,
  apiUploadDeviceDisplayParametersImage,
  apiDeleteDeviceDisplayParametersImage,
  apiPostScreenTimer,
  apiPatchScreenTimer,
  apiDeleteScreenTimer,
  apiMagicInfoGetTime,
  apiMagicInfoGetSetup,
  apiMagicInfoGetSecurity,
  apiMagicInfoGetGeneral,
  apiMagicInfoPatchTime
} from "services/devices"
import { apiGetDeviceOrganization } from "services/organizations"
import { Row, Col, Button } from "reactstrap"
import { Formik, Form } from "formik"
import _ from "lodash"
import initFormValues from "utils/formik/initFormValues"
// @ts-ignore
import { Redirect, withRouter, Link } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/Devices/One/Form/validationSchema"
import initialValues from "features/Devices/One/Form/initialValues"
import { createJsonDataFromFormik } from "utils/formik/apiSerializer"
import FormSaveButton from "components/Buttons/FormSaveButton"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import Tabs from "react-responsive-tabs"
import { toast } from "react-toastify"
import { Loader, Confirm } from "semantic-ui-react"
import Header from "./Form/Header"
import DeviceDisplay from "./Tabs/Display"
import DevicePlanning from "./Tabs/Planning"
import DeviceManagement from "./Tabs/Management"
import DeviceInformations from "./Tabs/Informations"
import DeviceProgram from "./Tabs/Program"

export class Device extends Component {
  state = {
    isInit: false,
    isNew: true,
    redirectToRoot: false,
    setupLoading: false,
    timeLoading: false,
    securityLoading: false,
    informationsLoading: false
  }

  reloadTimer = null

  formikRef = React.createRef()

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    clearTimeout(this.reloadTimer)
    this.props.resetFocusDevice()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.resetFocusDevice()
      clearTimeout(this.reloadTimer)
      this.initComponent(this.props.match.params.id)
    }
  }

  reloadDevice = () => {
    const { data } = this.props.devices.focus

    if (!this.state.isNew && data.magicInfoId !== null && data.id !== undefined) {
      this.props.apiMagicInfoGetGeneral(data.id)

      this.reloadTimer = setTimeout(() => this.reloadDevice(), 15000)
    }
  }

  initComponent = async id => {
    this.setState({ isInit: false })
    const isNew = id === "new"

    if (!isNew) {
      if (this.props.devices.list.data.some(i => i.id === Number(id))) await this.props.getDeviceFromStore(id)

      await this.props.apiGetDevice(id)

      if (this.props.devices.focus.data.id === undefined) this.setState({ redirectToRoot: true })

      this.setState({ isNew: false })
    } else {
      this.setState({ isNew: true })
    }

    this.setState({ isInit: true })

    // setTimeout(() => this.reloadDevice(), 15000)
  }

  onScreenImageUpload = (formData, savedParameters) => {
    this.props.apiUploadDeviceDisplayParametersImage(
      this.props.match.params.id,
      formData,
      createJsonDataFromFormik(_.clone(savedParameters))
    )
  }

  onScreenImageDelete = (data, savedParameters) => {
    this.props.apiDeleteDeviceDisplayParametersImage(
      this.props.match.params.id,
      data,
      createJsonDataFromFormik(_.clone(savedParameters))
    )
  }

  onTextChange = (value, bloc) => {
    // Auto set the name of the video
    this.formikRef.current.setFieldValue(`displayParameters.${bloc}_text`, value)
  }

  getDeviceTime = async () => {
    this.setState({ timeLoading: true })

    await this.props.apiMagicInfoGetTime(this.props.match.params.id)

    this.setState({ timeLoading: false })
  }

  getDeviceSetup = async () => {
    this.setState({ setupLoading: true })

    await this.props.apiMagicInfoGetSetup(this.props.match.params.id)

    this.setState({ setupLoading: false })
  }

  getDeviceSecurity = async () => {
    this.setState({ securityLoading: true })

    await this.props.apiMagicInfoGetSecurity(this.props.match.params.id)

    this.setState({ securityLoading: false })
  }

  patchDeviceTime = (timerIndex, values) => {
    const deviceData = this.props.devices.focus.data
    let data

    if (!deviceData.general.power) toast.warn(i18n.t("devices.unpowered"))
    else {
      data = {
        inputSourceList: deviceData.time.inputSourceList,
        [`timerConfTimer${timerIndex}`]: createJsonDataFromFormik(values)
      }
    }

    this.props.apiMagicInfoPatchTime(this.props.match.params.id, data)
  }

  patchDeviceSetup = values => {
    const deviceData = this.props.devices.focus.data
    let data

    if (!deviceData.general.power) toast.warn(i18n.t("devices.unpowered"))
    else {
      data = {
        deviceName: deviceData.setup.deviceName,
        ...values
      }
    }

    this.props.apiMagicInfoPatchSetup(this.props.match.params.id, data)
  }

  patchDeviceDisplay = values => {
    const deviceData = this.props.devices.focus.data
    let data

    if (!deviceData.general.power) toast.warn(i18n.t("devices.unpowered"))
    else {
      data = {
        deviceName: deviceData.setup.deviceName,
        ...values
      }
    }

    this.props.apiMagicInfoPatchSetup(this.props.match.params.id, data)
  }

  patchDeviceInformations = _data => {
    const deviceData = this.props.devices.focus.data

    const data = {
      id: deviceData.id,
      ..._data
    }

    this.props.apiPatchDevice(this.props.match.params.id, createJsonDataFromFormik(_.clone(data)))
  }

  updateDeviceAddress = (values, _address) => {
    const data = {
      ...values,
      address: _address.address,
      lat: _address.lat,
      lng: _address.lng
    }

    this.submitForm(data)
  }

  getOrganizationFromList = organization => {
    return this.props.organizations.list.data.find(i => i.id === organization.value)
  }

  getAllAccessibleBroadcastTags = () => {
    const result = this.props.organizations.list.data.reduce((acc, orga) => {
      return acc.concat(orga.broadcastTags)
    }, [])

    return result
  }

  onTabChange = value => {
    const { data } = this.props.devices.focus
    if (!this.state.isNew && data.magicInfoId !== null) {
      if (value === 2 && data.time === undefined) this.getDeviceTime()
      if (value === 3 && data.setup === undefined) this.getDeviceSetup()
      if (value === 4 && data.setup === undefined) this.getDeviceSetup()
    }
  }

  submitForm = async values => {
    const { state } = this
    const data = createJsonDataFromFormik(_.clone(values))

    data.displayParameters = createJsonDataFromFormik(_.clone(values.displayParameters))

    // New element
    if (state.isNew) {
      const response = await this.props.apiPostDevice(data)

      if (response.type === "API_POST_DEVICE_SUCCESS") {
        this.props.history.push(`/devices/${response.payload.data.device.id}`)
        this.initComponent(response.payload.data.device.id)
      }
    } else {
      const resp = await this.props.apiPatchDevice(this.props.match.params.id, data)
      // if (resp.type === "API_PATCH_DEVICE_SUCCESS") this.setState({ redirectToRoot: true })
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false })
    await this.props.apiDeleteDevice(this.props.match.params.id)
    this.setState({ redirectToRoot: true })
    clearTimeout(this.reloadTimer)

    this.props.resetFocusDevice()
  }

  openConfirmDeleteModalOpen = () => this.setState({ confirmDeleteModalOpen: true })

  render() {
    const { myOrganization, organizations, listFilter } = this.props
    const {
      isNew,
      redirectToRoot,
      isInit,
      setupLoading,
      timeLoading,
      informationsLoading,
      confirmDeleteModalOpen
    } = this.state

    if (redirectToRoot) {
      return <Redirect to="/devices" />
    }

    const newElement = {
      owner: {
        id: this.props.myOrganization.id,
        name: this.props.myOrganization.name,
        programs: this.props.myOrganization.programs,
        deviceGroups: this.props.myOrganization.deviceGroups
      },
      broadcastTags: [],
      pepstreamImageMode: 1,
      displayParameters: {},
      time: null,
      security: null,
      general: null,
      display: null,
      setup: null
    }

    if (listFilter.orga !== null) newElement.owner = this.getOrganizationFromList({ value: listFilter.orga })
    if (listFilter.orga !== null && listFilter.group !== null)
      newElement.group = this.getOrganizationFromList({ value: listFilter.orga }).deviceGroups.find(
        i => i.id === listFilter.group
      )

    const data = isNew ? newElement : this.props.devices.focus.data

    if (isInit) {
      return (
        <PageContainer>
          <Formik
            enableReinitialize
            initialValues={initFormValues(initialValues, data, isNew)}
            validationSchema={validationSchema}
            ref={this.formikRef}
            onSubmit={values => {
              this.submitForm(values)
            }}
          >
            {({ errors, values, setFieldValue }) => {
              return (
                <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                  <Row style={{ height: 30 }}>
                    <Col xs={6}>
                      <Link to="/devices">
                        <Button className="btn-icon" color="light" outline size="md" style={{ marginRight: 10 }}>
                          <i className="pe-7s-left-arrow btn-icon-wrapper"> </i>
                          {i18n.t("menu.backButton")}
                        </Button>
                      </Link>
                      <FormSaveButton
                        isLoading={this.props.devices.focus.loading}
                        text={i18n.t("actions.save")}
                        type="submit"
                        disabled={!_.isEmpty(errors)}
                      />
                    </Col>
                    <Col xs={6}>
                      {!isNew && (
                        <FormDeleteButton
                          text={i18n.t("actions.delete")}
                          disabled={false}
                          type="button"
                          className="form-delete-button-right"
                          onClick={() => this.openConfirmDeleteModalOpen()}
                        />
                      )}
                    </Col>
                  </Row>
                  <Header
                    device={data}
                    values={values}
                    myOrganization={myOrganization}
                    organizations={organizations}
                    isNew={isNew}
                    getOrganizationFromList={this.getOrganizationFromList}
                    getAllAccessibleBroadcastTags={this.getAllAccessibleBroadcastTags}
                  />

                  <Tabs
                    tabsWrapperClass="body-tabs body-tabs-layout"
                    transform={false}
                    onChange={this.onTabChange}
                    showInkBar
                    items={[
                      {
                        title: i18n.t("devices.tabs.program"),
                        content: <DeviceProgram device={data} values={values} />,
                        tabClassName: "draggable-tab"
                      },
                      {
                        title: i18n.t("devices.tabs.display"),
                        content: (
                          <DeviceDisplay
                            device={data}
                            values={values}
                            onScreenDisplayChange={id => setFieldValue("pepstreamImageMode", id)}
                            onImageUpload={formData => this.onScreenImageUpload(formData, values.displayParameters)}
                            onScreenImageDelete={data => this.onScreenImageDelete(data, values.displayParameters)}
                            onTextChange={this.onTextChange}
                            isNew={isNew}
                          />
                        ),
                        tabClassName: "draggable-tab"
                      },
                      {
                        title: i18n.t("devices.tabs.planning"),
                        content: (
                          <DevicePlanning
                            device={data}
                            handlers={{
                              onSave: this.patchDeviceTime
                            }}
                            isNew={isNew}
                            loading={timeLoading}
                          />
                        ),
                        tabClassName: "draggable-tab"
                      },
                      {
                        title: i18n.t("devices.tabs.management"),
                        content: (
                          <DeviceManagement device={data} loading={setupLoading} onSave={this.patchDeviceSetup} />
                        ),
                        tabClassName: "draggable-tab"
                      },
                      {
                        title: i18n.t("devices.tabs.informations"),
                        content: (
                          <DeviceInformations
                            device={data}
                            loading={informationsLoading}
                            onSave={addressData => this.updateDeviceAddress(values, addressData)}
                          />
                        ),
                        tabClassName: "draggable-tab"
                      }
                    ]}
                  />
                </Form>
              )
            }}
          </Formik>

          <Confirm
            open={confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  devices: state.devices,
  myOrganization: state.organizations.me.data,
  organizations: state.organizations
})

const mapDispatchToProps = {
  getDeviceFromStore,
  apiGetDevice,
  apiPostDevice,
  apiPatchDevice,
  apiDeleteDevice,
  resetFocusDevice,
  apiGetDeviceOrganization,
  apiUploadDeviceDisplayParametersImage,
  apiDeleteDeviceDisplayParametersImage,
  apiPostScreenTimer,
  apiPatchScreenTimer,
  apiDeleteScreenTimer,
  apiMagicInfoGetTime,
  apiMagicInfoGetSetup,
  apiMagicInfoGetSecurity,
  apiMagicInfoGetGeneral,
  apiMagicInfoPatchTime
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Device))
