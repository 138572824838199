import React, { Fragment, Component } from "react"
import { Row, Col } from "reactstrap"
import i18n from "utils/i18n"
import ScreenDisplay from "features/Devices/One/Tabs/Display/ScreenDisplay"
import DisplayParameters from "./DisplayParameters"

class DeviceDisplay extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  changeTab = tab => this.setState({ activeTab: tab })

  onScreenChange = value => {
    this.changeTab(0)
    this.props.onScreenDisplayChange(value)
  }

  onImageUpload = file => {
    const { activeTab } = this.state

    const formData = new FormData()
    const bloc = activeTab === 0 ? "player" : `bloc${activeTab}`

    formData.append("screen", file)
    formData.append("bloc", bloc)

    this.props.onImageUpload(formData)
  }

  onImageDelete = () => {
    const { activeTab } = this.state
    const bloc = activeTab === 0 ? "player" : `bloc${activeTab}`

    const data = {
      bloc
    }

    this.props.onScreenImageDelete(data)
  }

  onTextChange = value => {
    const { activeTab } = this.state
    const bloc = activeTab === 0 ? "player" : `bloc${activeTab}`

    this.props.onTextChange(value, bloc)
  }

  render() {
    const { values, isNew } = this.props
    const { activeTab } = this.state

    const { pepstreamImageMode } = values

    return (
      <Fragment>
        <Row className="custom-scroll" style={{ flex: 1 }}>
          <Col xs={5} style={{ paddingRight: "2em" }}>
            <Row>
              <Col xs={12}>
                <div className="main-container-title">{i18n.t("devices.pageTitle.display")}</div>
                <ScreenDisplay active={pepstreamImageMode} onChange={this.onScreenChange} />
              </Col>
            </Row>
          </Col>
          {isNew && (
            <Col xs={7} style={{ paddingTop: "7em" }}>
              <div className="list-no-result">{i18n.t("devices.content.createFirst")}</div>
            </Col>
          )}
          {!isNew && (
            <Col xs={7} style={{ paddingTop: "7em" }}>
              <div className="tab-device-content-container">
                <div
                  className={`tab-device-content player ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => this.changeTab(0)}
                >
                  {i18n.t("devices.content.player")}
                </div>
                {pepstreamImageMode > 1 && (
                  <Fragment>
                    <div
                      className={`tab-device-content bloc1 ${activeTab === 1 ? "active" : ""}`}
                      onClick={() => this.changeTab(1)}
                    >
                      {i18n.t("devices.content.bloc1")}
                    </div>
                    <div
                      className={`tab-device-content bloc2 ${activeTab === 2 ? "active" : ""}`}
                      onClick={() => this.changeTab(2)}
                    >
                      {i18n.t("devices.content.bloc2")}
                    </div>
                    {pepstreamImageMode < 4 && (
                      <div
                        className={`tab-device-content bloc3 ${activeTab === 3 ? "active" : ""}`}
                        onClick={() => this.changeTab(3)}
                      >
                        {i18n.t("devices.content.bloc3")}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>

              <DisplayParameters
                activeTab={activeTab}
                values={values}
                onImageUpload={this.onImageUpload}
                onImageDelete={this.onImageDelete}
                onTextChange={this.onTextChange}
              />
            </Col>
          )}
        </Row>
      </Fragment>
    )
  }
}
export default DeviceDisplay
