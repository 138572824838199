/* eslint-disable no-loop-func */
import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import i18n from "utils/i18n"
import { Link, Route } from "react-router-dom"
import { getDevicesRoutingConfig } from "config/routing"
import { apiGetDevices, apiGetAdministrableDevices, resetDevicesData } from "services/devices"
import { apiGetOrganizations } from "services/organizations"
import { apiPostDeviceGroup, apiPatchDeviceGroup, apiDeleteDeviceGroup } from "services/deviceGroups"
import ListLoader from "components/List/ListLoader"
import { Button } from "reactstrap"
import Tree from "./Tree"
import QuickAccess from "./QuickAccess"

const getOrgaDevices = (_orga, _devices) => {
  let result = []

  result = _orga.deviceGroups.map(deviceGroup => ({
    id: deviceGroup.id,
    name: deviceGroup.name,
    devices: _devices.filter(device => device.group !== null && device.group.id === deviceGroup.id)
  }))

  if (_devices.some(device => device.owner.id === _orga.id && device.group === null)) {
    result.unshift({
      id: "-1",
      name: i18n.t("devices.noGroup"),
      devices: _devices.filter(device => device.owner.id === _orga.id && device.group === null)
    })
  }

  return result
}

const formatOrga = (_orga, _devices) => ({
  id: _orga.id,
  name: _orga.name,
  logo: _orga.logo,
  level: _orga.level,
  deviceGroups: getOrgaDevices(_orga, _devices)
})

const initTreeData = (_orgas, _devices, initialLevel) => {
  if (_orgas.length === 0) return null
  const result = []

  let level = initialLevel
  let index

  while (_orgas.some(i => i.level === level)) {
    _orgas
      .filter(i => i.level === level)
      .sort((a, b) => b.name.localeCompare(a.name))
      .forEach(orga => {
        if (level === initialLevel) {
          result.push(formatOrga(orga, _devices))
        } else {
          index = result.findIndex(i => i.id === orga.superOrgaId)
          if (index > -1) result.splice(index + 1, 0, formatOrga(orga, _devices))
        }
      })

    level += 1
  }

  return result
}

class Devices extends Component {
  constructor(props) {
    super(props)

    this.state = {
      listFilter: {
        orga: null,
        group: null
      },
      refreshing: false,
      quickAccessVisible: false,
      quickAccessDeviceId: null
    }
  }

  componentDidMount() {
    if (!this.props.devices.list.loaded) this.props.apiGetDevices()
    if (!this.props.organizations.loaded) this.props.apiGetOrganizations()

    this.setDevicesRefreshTimeout()
  }

  setDevicesRefreshTimeout() {
    setTimeout(async () => {
      this.setState({ refreshing: true })
      await this.props.apiRefreshDevices()
      this.setState({ refreshing: false })

      this.setDevicesRefreshTimeout()
    }, 30000)
  }

  componentWillUnmount() {
    this.props.resetDevicesData()
  }

  onDeviceGroupSubmit = data => {
    this.props.apiPostDeviceGroup(data)
  }

  onDeviceGroupUpdate = data => {
    this.props.apiPatchDeviceGroup(data.id, data)
  }

  onDeviceGroupDelete = id => {
    this.props.apiDeleteDeviceGroup(id)
  }

  changeListFilter = (orga, group) => {
    if (this.props.location.pathname === "/devices") {
      this.setState({ listFilter: { orga, group } })
    }
  }

  openQuickAccess = id => {
    this.setState({ quickAccessDeviceId: id, quickAccessVisible: true })
  }

  closeQuickAccess = () => {
    this.setState({ quickAccessDeviceId: null, quickAccessVisible: false })
  }

  render() {
    const { devices, organizations, myOrganization, me } = this.props
    const { listFilter, quickAccessVisible, quickAccessDeviceId, refreshing } = this.state

    if (organizations.loading || devices.list.loading) return <ListLoader />

    const initialLevel = myOrganization.data.level
    const data = initTreeData(organizations.data, devices.list.data, initialLevel)

    return (
      <PageContainer>
        <PageTitle heading={i18n.t("devices.pageTitle.heading")} icon="pe-7s-monitor folder-color-DEVICE" />

        <div className="page-top-buttons">
          {me.role === "ADMIN" && (
            <Link to="/devices/new" className="new-element-button" style={{ marginLeft: "-10px" }}>
              <Button className="btn-icon" color="primary" outline size="md">
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {i18n.t("devices.actions.add")}
              </Button>
            </Link>
          )}
        </div>

        <div className="devices-container">
          <div className="devices-list-container">
            {(!data === null || devices.list === null) && <ListLoader />}
            {data !== null && devices.list !== null && (
              <Tree
                devices={devices.list}
                focus={devices.focus.data}
                data={data}
                refreshing={refreshing}
                onDeviceGroupSubmit={this.onDeviceGroupSubmit}
                onDeviceGroupUpdate={this.onDeviceGroupUpdate}
                onDeviceGroupDelete={this.onDeviceGroupDelete}
                initialLevel={initialLevel}
                listFilter={listFilter}
                changeListFilter={this.changeListFilter}
              />
            )}
          </div>
          <div className="devices-details-container custom-scroll">
            {getDevicesRoutingConfig().map(route => (
              <RouteWithSubRoutes
                {...route}
                key={`route${route.path}`}
                listFilter={listFilter}
                openQuickAccess={this.openQuickAccess}
              />
            ))}
          </div>
        </div>

        <QuickAccess visible={quickAccessVisible} deviceId={quickAccessDeviceId} close={this.closeQuickAccess} />
      </PageContainer>
    )
  }
}
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      {...route.props}
      render={matchProps => (
        // pass the sub-routes down to keep nesting
        <route.component
          {...matchProps}
          routes={route.routes}
          listFilter={route.listFilter}
          openQuickAccess={route.openQuickAccess}
        />
      )}
    />
  )
}

const mapStateToProps = state => ({
  devices: state.devices,
  myOrganization: state.organizations.me,
  organizations: state.organizations.list,
  me: state.users.me.data
})

const mapDispatchToProps = {
  apiGetDevices: () => apiGetDevices({ silent: false }),
  apiRefreshDevices: () => apiGetDevices({ silent: true }),
  apiGetAdministrableDevices,
  apiGetOrganizations,
  resetDevicesData,
  apiPostDeviceGroup,
  apiPatchDeviceGroup,
  apiDeleteDeviceGroup
}

export default connect(mapStateToProps, mapDispatchToProps)(Devices)
