import React, { Component, Fragment } from "react"
import LineItemVideo from "features/Videos/List/Items/LineItem"
import GridItemVideo from "features/Videos/List/Items/GridItem"
import GridItemPlaylist from "features/Playlists/List/Items/GridItem"
import GridItemWebContent from "features/WebContents/List/Items/GridItem"
import GridItemDevice from "features/Devices/List/Items/GridItem"
import CollapsibleVideo from "features/Videos/List/Items/Collapsible"
import CollapsibleWebContent from "features/WebContents/List/Items/Collapsible"
import CollapsibleChannel from "features/Channels/List/Items/Collapsible"
import CollapsibleProgram from "features/Programs/List/Items/Collapsible"

import { Row } from "reactstrap"
import produce from "immer"
import LineItemUser from "features/Organization/Users/Items/LineItem"
import LineItemLocalGroup from "features/Organization/LocalGroups/Items/LineItem"
import LineItemLocalTag from "features/Organization/LocalTags/Items/LineItem"
import LineItemBroadcastTag from "features/Organization/BroadcastTags/Items/LineItem"
import LineItemCategory from "features/Organization/Categories/Items/LineItem"
import LineItemSubOrga from "features/SubOrgas/List/Items/LineItem"
import LineItemPlaylist from "features/Playlists/List/Items/LineItem"
import LineItemProgram from "features/Programs/List/Items/LineItem"
import LineItemChannel from "features/Channels/List/Items/LineItem"
import LineItemWebContent from "features/WebContents/List/Items/LineItem"
import LineItemDevice from "features/Devices/List/Items/LineItem"

import { connect } from "react-redux"
import {
  createOrganizationSelectFilter,
  createOrganizationSearchFilter,
  createOrganizationSelectFilterForGroups
} from "utils/filters/createFilters"
import i18n from "utils/i18n"
import { apiGetOrganizations } from "services/organizations"
import LineCollapsableElement from "./LineCollapsableElement"
import GridCollapsableElement from "./GridCollapsableElement"
import ListLoader from "./ListLoader"
import Filters from "./Filters/Filters"
import DeviceFilters from "./Filters/DeviceFilters"

class ListContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapseId: null,
      gridSpace: 2,
      filtersLoaded: false,
      filters: {
        search: {
          value: ""
        },
        select: [],
        selectSingle: []
      },
      layout: this.props.defaultLayout || "list",
      selection: []
    }
  }

  componentDidMount() {
    if (this.props.filtersOn) this.initFilters()
  }

  componentDidUpdate() {}

  collapseElt = collapseId => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.collapseId = draft.collapseId === collapseId ? null : collapseId
      })
    )
  }

  changeGridSpace = _space => this.setState({ gridSpace: _space })

  initFilters = async () => {
    const listFilters = this.props.filters
    const { type, organizations } = this.props

    if (!organizations.list.loaded) await this.props.apiGetOrganizations()

    if (listFilters.select === undefined) listFilters.select = []
    if (listFilters.selectSingle === undefined) listFilters.selectSingle = []

    this.setState({
      filters: {
        search: createOrganizationSearchFilter(listFilters.search),
        select: listFilters.select.map(filter => {
          if (filter === "owner")
            return createOrganizationSelectFilter(
              {
                data: [
                  this.props.myOrganization,
                  ...this.props.myOrganization.accessTo
                    .map(rule => ({
                      name:
                        organizations.list.data
                          .concat(organizations.accessible.data)
                          .find(i => i.id === rule.sharingOrganizationId) !== undefined
                          ? organizations.list.data
                              .concat(organizations.accessible.data)
                              .find(i => i.id === rule.sharingOrganizationId).name
                          : "Inconnu",
                      id: rule.sharingOrganizationId
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name))
                ]
              },
              i18n.t("entities.attributes.owner"),
              "culture",
              "data",
              "owner"
            )
          else if (filter === "groups") {
            return createOrganizationSelectFilterForGroups(
              this.props.myOrganization,
              i18n.t("entities.singular.group"),
              "localGroups",
              "groups",
              type
            )
          } else if (filter === "localTags") {
            return createOrganizationSelectFilter(
              this.props.myOrganization,
              i18n.t("entities.singular.tag"),
              "ticket",
              "localTags",
              "localTags"
            )
          } else if (filter === "broadcastTags") {
            return createOrganizationSelectFilter(
              this.props.myOrganization,
              i18n.t("entities.singular.broadcastTag"),
              "display1",
              "broadcastTags",
              "broadcastTags"
            )
          } else if (filter === "categories") {
            return createOrganizationSelectFilter(
              { data: this.props.categories },
              i18n.t("entities.singular.category"),
              "ribbon",
              "data",
              "categories"
            )
          }

          return {}
        }),
        selectSingle: listFilters.selectSingle.map(filter => {
          if (filter === "status") {
            return {
              name: "status",
              eltField: "status",
              value: null
            }
          }

          return []
        })
      },
      filtersLoaded: true
    })

    this.setListLength()
  }

  onFilterChange = (_filterType, _filter, _value) => {
    if (this.props.changeFilter !== undefined) this.props.changeFilter(_filterType, _filter, _value)

    switch (_filterType) {
      case "select":
        this.setState(
          produce(draft => {
            const oldFilter = draft.filters.select.find(filter => filter.name === _filter)
            if (_value === null) {
              oldFilter.value = []
            } else if (oldFilter.value.includes(_value))
              oldFilter.value.splice(
                oldFilter.value.findIndex(i => i === _value),
                1
              )
            else {
              oldFilter.value.push(_value)
            }
          }),
          () => this.setListLength()
        )
        break
      case "selectSingle":
        this.setState(
          produce(draft => {
            const oldFilter = draft.filters.selectSingle.find(filter => filter.name === _filter)
            oldFilter.value = _value
          }),
          () => this.setListLength()
        )
        break
      case "search":
        this.setState(
          produce(draft => {
            // eslint-disable-next-line no-param-reassign
            draft.filters.search.value = _value
          }),
          () => this.setListLength()
        )
        break
      default:
      // console.log("not possible")
    }
  }

  onLayoutChange = _layout => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.layout = _layout
      })
    )
  }

  applyFilters = (filters, elt) => {
    if (filters === null) return true
    let result = true

    // Search filter
    if (filters.search.value !== "") {
      filters.search.fields.forEach(field => {
        if (elt[field] === undefined) result = false
        else if (elt[field].toLowerCase().search(filters.search.value.toLowerCase()) === -1) result = false
      })
    }

    // Select filters
    filters.select.forEach(selectFilter => {
      switch (selectFilter.eltField) {
        case "groups":
          if (selectFilter.value.length !== 0) {
            if (elt[selectFilter.eltField][0] === undefined) result = false
            else if (!selectFilter.value.includes(elt[selectFilter.eltField][0].id)) result = false
          }
          break
        case "owner":
          if (selectFilter.value.length !== 0) {
            if (!selectFilter.value.includes(elt[selectFilter.eltField].id)) result = false
          }
          break
        case "localTags":
          if (selectFilter.value.length !== 0) {
            if (!elt[selectFilter.eltField].some(localTag => selectFilter.value.includes(localTag.id))) result = false
          }
          break
        case "broadcastTags":
          if (selectFilter.value.length !== 0) {
            if (!elt[selectFilter.eltField].some(localTag => selectFilter.value.includes(localTag.id))) result = false
          }
          break
        case "categories":
          if (selectFilter.value.length !== 0) {
            if (!elt[selectFilter.eltField].some(localTag => selectFilter.value.includes(localTag.id))) result = false
          }
          break
        default:
        // Nothing happens
      }
    })

    // Select filters
    filters.selectSingle.forEach(selectFilter => {
      switch (selectFilter.eltField) {
        case "status":
          if (selectFilter.value !== null && elt[selectFilter.eltField] !== selectFilter.value) result = false

          break
        default:
        // Nothing happens
      }
    })

    return result
  }

  applySort = (a, b) => {
    const { sortField, type } = this.props

    if (type === "DEVICE") {
      return a.owner.name.localeCompare(b.owner.name) || a.name.localeCompare(b.name)
    }
    if (type === "PLAYLIST" || type === "PROGRAM") {
      return a.title.localeCompare(b.title) || a.owner.name.localeCompare(b.owner.name)
    }
    // @ts-ignore
    if (sortField === "uploadedAt" || sortField === "createdAt" || sortField === "updatedAt")
      // @ts-ignore
      return new Date(b[sortField]) - new Date(a[sortField])

    return b[sortField] - a[sortField]
  }

  toggleSelectElement = id => {
    if (this.props.onSelect) this.props.onSelect(id)

    if (this.state.selection.includes(id)) {
      this.setState(prevState => {
        prevState.selection.splice(
          prevState.selection.findIndex(i => i === id),
          1
        )
        return prevState
      })
    } else {
      this.setState(prevState => {
        prevState.selection.push(id)
        return prevState
      })
    }
  }

  setListLength = () => {
    if (this.props.listLengthSetter !== null && this.props.listLengthSetter !== undefined) {
      this.props.listLengthSetter(
        this.props.elements.data.filter(elt => this.applyFilters(this.state.filters, elt)).length
      )
    }
  }

  render() {
    const { type, elements, lineHeight, handlers, multipleLayouts, loading, selectable, actionsActivated } = this.props
    const { collapseId, gridSpace, filters, layout, filtersLoaded, selection } = this.state

    const list = elements.data.filter(elt => this.applyFilters(filters, elt)).sort(this.applySort)

    if (elements.loading) return <ListLoader />

    return (
      <Fragment>
        {filtersLoaded && type !== "DEVICE" && (
          <Filters
            filters={filters}
            onFilterChange={this.onFilterChange}
            layout={layout}
            multipleLayouts={multipleLayouts}
            onLayoutChange={this.onLayoutChange}
            gridSpace={gridSpace}
            onGridSpaceChange={this.changeGridSpace}
          />
        )}

        {filtersLoaded && type === "DEVICE" && (
          <DeviceFilters
            filters={filters}
            onFilterChange={this.onFilterChange}
            layout={layout}
            administrables={elements.data}
            multipleLayouts={multipleLayouts}
            onLayoutChange={this.onLayoutChange}
            gridSpace={gridSpace}
            onGridSpaceChange={this.changeGridSpace}
          />
        )}

        {loading && <ListLoader />}

        {!loading && layout === "list" && (
          <div className="main-list-container">
            {list.length === 0 && <div className="list-no-result">Aucun résultat</div>}
            {list.length > 0 &&
              list.map(elt => {
                if (type === "VIDEO") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      selectable={selectable}
                      selection={selection}
                      select={this.toggleSelectElement}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemVideo element={elt} handlers={handlers} />}
                      collapsibleData={<CollapsibleVideo element={elt} />}
                    />
                  )
                }

                if (type === "USER") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemUser element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "LOCALGROUP") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemLocalGroup element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "LOCALTAG") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemLocalTag element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "SUBORGA") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemSubOrga element={elt} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "PLAYLIST") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      selectable={selectable}
                      selection={selection}
                      select={this.toggleSelectElement}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemPlaylist element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "PROGRAM") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={
                        <LineItemProgram
                          element={elt}
                          collapseElt={() => this.collapseElt(elt.id)}
                          isCollapsed={collapseId === elt.id}
                        />
                      }
                      collapsibleData={<CollapsibleProgram element={elt} />}
                    />
                  )
                }

                if (type === "CHANNEL") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemChannel element={elt} handlers={handlers} />}
                      collapsibleData={<CollapsibleChannel element={elt} />}
                    />
                  )
                }

                if (type === "WEBCONTENT") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemWebContent element={elt} />}
                      collapsibleData={<CollapsibleWebContent element={elt} />}
                    />
                  )
                }

                if (type === "DEVICE") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemDevice element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "BROADCASTTAG") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemBroadcastTag element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }

                if (type === "CATEGORY") {
                  return (
                    <LineCollapsableElement
                      key={`collapsible${elt.id}`}
                      collapseId={collapseId}
                      collapseElt={this.collapseElt}
                      element={elt}
                      lineHeight={lineHeight}
                      lineData={<LineItemCategory element={elt} handlers={handlers} />}
                      collapsibleData={null}
                    />
                  )
                }
                return <div>App Error</div>
              })}
          </div>
        )}

        {!loading && layout === "grid" && (
          <div className="main-list-container">
            <Row className="grid-list-row">
              {list.length === 0 && <div className="list-no-result">Aucun résultat</div>}
              {list.length > 0 &&
                list.map(elt => {
                  if (type === "VIDEO") {
                    return (
                      <GridCollapsableElement
                        key={`collapsible${elt.id}`}
                        selectable={selectable}
                        selection={selection}
                        select={this.toggleSelectElement}
                        collapseId={collapseId}
                        collapseElt={this.collapseElt}
                        element={elt}
                        gridSpace={gridSpace}
                        lineData={
                          <GridItemVideo
                            element={elt}
                            handlers={handlers}
                            linkActivated={!selectable}
                            actionsActivated={!selectable}
                          />
                        }
                        collapsibleData={<CollapsibleVideo element={elt} />}
                        type="video"
                      />
                    )
                  }
                  if (type === "PLAYLIST") {
                    let activateActions = !selectable
                    if (actionsActivated !== undefined) {
                      activateActions = !selectable && actionsActivated
                    }
                    return (
                      <GridCollapsableElement
                        key={`collapsible${elt.id}`}
                        selectable={selectable}
                        selection={selection}
                        select={this.toggleSelectElement}
                        collapseId={collapseId}
                        collapseElt={this.collapseElt}
                        element={elt}
                        gridSpace={gridSpace}
                        lineData={
                          <GridItemPlaylist
                            element={elt}
                            handlers={handlers}
                            linkActivated={!selectable}
                            actionsActivated={activateActions}
                          />
                        }
                        collapsibleData={null}
                        type="playlist"
                      />
                    )
                  }
                  if (type === "WEBCONTENT") {
                    return (
                      <GridCollapsableElement
                        key={`collapsible${elt.id}`}
                        selectable={selectable}
                        selection={selection}
                        select={this.toggleSelectElement}
                        collapseId={collapseId}
                        collapseElt={this.collapseElt}
                        element={elt}
                        gridSpace={gridSpace}
                        lineData={<GridItemWebContent element={elt} linkActivated={!selectable} />}
                        collapsibleData={<CollapsibleWebContent element={elt} />}
                        type="webContent"
                      />
                    )
                  }

                  if (type === "DEVICE") {
                    return (
                      <GridCollapsableElement
                        key={`collapsible${elt.id}`}
                        collapseId={collapseId}
                        collapseElt={this.collapseElt}
                        element={elt}
                        gridSpace={gridSpace}
                        lineData={<GridItemDevice element={elt} handlers={handlers} />}
                        collapsibleData={null}
                        type="device"
                      />
                    )
                  }

                  return <div>App Error</div>
                })}
            </Row>
          </div>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    myOrganization: state.organizations.me.data,
    organizations: state.organizations,
    categories: state.categories.list.data
  }
}

const mapDispatchToProps = {
  apiGetOrganizations
}

export default connect(mapStateToProps, mapDispatchToProps)(ListContainer)
