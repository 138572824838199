import React from "react"
import i18n from "utils/i18n"
import { Loader } from "semantic-ui-react"

const Planning = ({ loading, time, cannotBeReached }) => {
  let timers = []

  if (time !== null) {
    timers = [
      time.timerConfTimer1,
      time.timerConfTimer2,
      time.timerConfTimer3,
      time.timerConfTimer4,
      time.timerConfTimer5,
      time.timerConfTimer6,
      time.timerConfTimer7
    ]
  }

  console.log(timers)

  const extractDays = element => {
    return [
      element.monday ? i18n.t("devices.daysShort.monday") : null,
      element.tuesday ? i18n.t("devices.daysShort.tuesday") : null,
      element.wednesday ? i18n.t("devices.daysShort.wednesday") : null,
      element.thursday ? i18n.t("devices.daysShort.thursday") : null,
      element.friday ? i18n.t("devices.daysShort.friday") : null,
      element.saturday ? i18n.t("devices.daysShort.saturday") : null,
      element.sunday ? i18n.t("devices.daysShort.sunday") : null
    ].filter(i => i !== null)
  }

  return (
    <div className="quickaccess-timers-container">
      <div className="quickaccess-title">{i18n.t("devices.quickAccess.planning")}</div>
      {cannotBeReached && <div>L&apos;écran n&apos;est actuellement pas connecté au serveur</div>}
      {!cannotBeReached && loading && (
        <div className="quickaccess-planning-loader">
          <Loader active inline />
        </div>
      )}
      {!loading &&
        !cannotBeReached &&
        time !== null &&
        timers
          .filter(i => i.status === true)
          .map(timer => (
            <div className="quickaccess-timer" key={`timer-${timer.timerSeq}`}>
              <div className="timer-days">{extractDays(timer).join(",")}</div>
              <div className="timer-hours">
                {timer.startHour} - {timer.endHour}
              </div>
            </div>
          ))}
    </div>
  )
}

export default Planning
