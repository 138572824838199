import React, { Fragment } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import i18n from "utils/i18n"
import { Row, Col } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import MainContainer from "components/PageStructure/MainContainer"
import _ from "lodash"
import OrganizationLogoHandler from "components/Organization/OrganizationLogoHandler"

const SubOrgaForm = ({ organization, logoHandler, handleLogoChange, errors, loading, isNew }) => {
  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <FormSaveButton
            isLoading={loading}
            text={i18n.t("actions.save")}
            type="submit"
            disabled={!_.isEmpty(errors)}
          />
        </Col>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <Col xs={6}></Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <MainContainer>
            <Row>
              <Col xs={1}>
                <OrganizationLogoHandler
                  organization={organization}
                  logoHandler={logoHandler}
                  onChange={handleLogoChange}
                />
              </Col>
              <Col xs={11}>
                <Field
                  type="text"
                  name="name"
                  label={i18n.t("entities.attributes.name")}
                  component={ClassicInput}
                  required
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
      </Row>
    </Fragment>
  )
}

export default SubOrgaForm
