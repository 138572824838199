import React from "react"
import { Button } from "reactstrap"
import { Loader } from "semantic-ui-react"
import i18n from "utils/i18n"

const CancelButton = ({ type = "button", disabled = false, isLoading = false, onClick, style = {} }) => {
  return (
    <Button
      color="light"
      size="md"
      // @ts-ignore
      type={type}
      disabled={disabled || isLoading}
      onClick={() => onClick()}
      style={style !== null && { ...style }}
    >
      {isLoading && <Loader size="mini" className="loader-button" inline active />}
      {i18n.t("actions.cancel")}
    </Button>
  )
}

export default CancelButton
