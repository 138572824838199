import React, { Fragment } from "react"
import { Col, Row, Button } from "reactstrap"
import moment from "moment"
import { Link } from "react-router-dom"
import getContentDuration from "utils/getContentDuration"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import getThumbnailSrc from "utils/getThumbnailSrc"
import { BASE_URL } from "config/api"
import PlaylistThumbnail from "components/Users/PlaylistThumbnail"
import { Dropdown } from "semantic-ui-react"
import i18n from "utils/i18n"

const GridLink = ({ element, role, linkActivated, children }) => {
  if (linkActivated) {
    if (role === "ADMIN")
      return (
        <Link to={`/playlists/${element.id}`} className="grid-link">
          {children}
        </Link>
      )

    return (
      <a
        style={{ height: "100%" }}
        href={`${BASE_URL}/player/${element.owner.id}/playlist/${element.id}`}
        target="_blank"
      >
        {children}
      </a>
    )
  }
  return <Fragment>{children}</Fragment>
}

const GridItemPlaylist = ({ element, linkActivated = true, handlers, actionsActivated = true, smallText = false }) => {
  return (
    <GridLink role={handlers.role} element={element} linkActivated={linkActivated}>
      <div className="grid-element-top">
        <PlaylistThumbnail playlist={element} className="grid-thumbnail" />
      </div>
      <div className="grid-element-subcontainer">
        <Row>
          <Col xs={12} className="grid-element-firstrow">
            <div
              className="element-title text-ellipsis"
              style={{ letterSpacing: 0, fontSize: smallText ? "0.9em" : "1em" }}
            >
              {element.title}
            </div>
            {element.content !== undefined && (
              <div className="video-time">{moment.utc(getContentDuration(element.content, true)).format("mm:ss")}</div>
            )}
          </Col>
        </Row>
        <Row className="grid-element-second-row">
          <Col xs={9} className="grid-element">
            <div className="line-element-info">
              <div className="content">
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
          {actionsActivated && handlers.userCanEdit(element.owner.id) && (
            <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Dropdown
                icon={null}
                floating
                direction="left"
                onClick={e => {
                  e.preventDefault()
                }}
                trigger={
                  <Button className="pe-7s-more btn-icon-wrapper" size="sm" color="light">
                    {" "}
                  </Button>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="deviceGroup-handler-item"
                    text={i18n.t("actions.duplicate")}
                    onClick={() => handlers.copy(element.id)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          )}
        </Row>
      </div>
    </GridLink>
  )
}

export default GridItemPlaylist
