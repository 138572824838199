import React from "react"
import { Col, Row } from "reactstrap"
import i18n from "utils/i18n"
import { Formik, Field } from "formik"
import initFormValues from "utils/formik/initFormValues"
import ScreenTimerSwitch from "components/Form/ScreenTimerSwitch"
import ClassicInput from "components/Form/ClassicInput"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import FormSaveButton from "components/Buttons/FormSaveButton"
import _ from "lodash"
import initialValues from "./initialValues"
import validationSchema from "./validationSchema"

const CollapsibleScreenTimer = ({ element, onSave, sourceOptions }) => {
  const elt = _.clone(element)
  console.log(elt)

  // Set URL Launcher by default if it's available in options and timer is not active
  if (!elt.status && sourceOptions.findIndex(i => i.value === "URL Launcher") > -1) {
    console.log("url launcher present")

    elt.source = "URL Launcher"
  }
  console.log(elt)

  return (
    <Formik
      enableReinitialize
      initialValues={initFormValues(initialValues, elt, false)}
      validationSchema={validationSchema}
      onSubmit={values => {
        onSave(values)
      }}
    >
      {({ errors, values }) => {
        return (
          <div className="collapsible-container">
            <Row style={{ marginTop: "0.5em" }}>
              <Field component={ScreenTimerSwitch} name="status" label={i18n.t("devices.planning.active")} />
            </Row>
            <Row style={{ marginTop: "0.5em" }}>
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="monday"
                label={i18n.t("devices.days.monday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="tuesday"
                label={i18n.t("devices.days.tuesday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="wednesday"
                label={i18n.t("devices.days.wednesday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="thursday"
                label={i18n.t("devices.days.thursday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="friday"
                label={i18n.t("devices.days.friday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="saturday"
                label={i18n.t("devices.days.saturday")}
              />
              <Field
                component={ScreenTimerSwitch}
                editable={values.status}
                name="sunday"
                label={i18n.t("devices.days.sunday")}
              />
            </Row>
            <Row>
              <Col xs={3}>
                <Field
                  type="time"
                  name="startHour"
                  label={i18n.t("devices.planning.on")}
                  component={ClassicInput}
                  disabled={!values.status}
                  required
                />
              </Col>
              <Col xs={3}>
                <Field
                  type="time"
                  name="endHour"
                  label={i18n.t("devices.planning.off")}
                  component={ClassicInput}
                  disabled={!values.status}
                  required
                />
              </Col>
              <Col xs={3}>
                <Field
                  component={SelectAutoComplete}
                  required
                  disabled={!values.status}
                  name="source"
                  label={i18n.t("devices.attributes.source")}
                  options={sourceOptions}
                />
              </Col>
              <Col xs={3}>
                <Field
                  type="number"
                  name="volume"
                  disabled={!values.status}
                  label={i18n.t("devices.attributes.volume")}
                  component={ClassicInput}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormSaveButton
                  text={i18n.t("actions.save")}
                  type="button"
                  disabled={!_.isEmpty(errors)}
                  onClick={() => onSave(values)}
                />
              </Col>
            </Row>
          </div>
        )
      }}
    </Formik>
  )
}

export default CollapsibleScreenTimer
