import React, { Fragment } from "react"

const Textarea = ({
  placeholder,
  required = false,
  label = null,
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  editable = true
}) => (
  <div className="form-input-container">
    <div className="form-label">
      {label !== null && <span>{label}</span>}
      {label !== null && required && <span className="form-cross-required">*</span>}
    </div>

    <Fragment>
      <textarea
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={!editable}
        className={`form-textarea ${touched[name] && errors[name] ? "error" : ""}`}
      />
      {touched[name] && errors[name] && <div className="form-error-style">{errors[name]}</div>}
    </Fragment>
  </div>
)

export default Textarea
