const initialValues = [
  {
    name: "name",
    type: "text"
  },
  {
    name: "type",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "value",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "color",
    type: "text"
  }
]

export default initialValues
