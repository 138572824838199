import React, { Component } from "react"
import i18n from "utils/i18n"

class ListSearchInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false || props.filter.value !== ""
    }
    this.input = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) this.input.focus()
  }

  closeInput = () => {
    this.props.onChange("")
    this.setState({ isOpen: false })
  }

  toggleInput = () => {
    this.setState(prevState => {
      return { isOpen: !prevState.isOpen }
    })
  }

  render() {
    const { filter, onChange } = this.props
    const { isOpen } = this.state

    return (
      <div className={`list-input-search ${isOpen ? "active" : ""}`}>
        <div
          className="icon-button"
          onClick={() => {
            if (filter.value === "") this.toggleInput()
          }}
        >
          <i className="pe-7s-search color-gray"></i>
        </div>
        <input
          type="text"
          ref={input => {
            this.input = input
          }}
          className={`inputText ${isOpen ? "active" : ""}`}
          placeholder={i18n.t("form.search")}
          value={filter.value}
          onChange={event => onChange(event.target.value)}
        />
        {filter.value !== "" && (
          <i
            onClick={() => this.closeInput()}
            className="pe-7s-close btn-icon-wrapper color-red cursor-pointer close-button"
          />
        )}
      </div>
    )
  }
}

export default ListSearchInput
