import React, { Component } from "react"
import { DragDropContext } from "react-beautiful-dnd"
import { Col, Row } from "reactstrap"
import i18n from "utils/i18n"
import DraggableListVideo from "./DraggableListVideo"
import DraggableListWebContent from "./DraggableListWebContent"
import PlaylistContent from "./PlaylistContent"

export class PlaylistHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      grid: 8,
      hasChanged: false,
      videos: [],
      webContents: [],
      selected: [],
      activeTab: 0
    }
  }

  componentDidMount() {
    this.initItems()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.videos.data.length !== this.props.videos.data.length ||
      prevProps.webContents.data.length !== this.props.webContents.data.length ||
      prevProps.content.length !== this.props.content.length
    )
      this.initItems()

    if (!prevProps.needReload && this.props.needReload) this.initItems()
  }

  initItems = () => {
    this.setState(
      {
        videos: this.props.videos.data
          .map(video => ({
            type: "VIDEO",
            id: video.id,
            thumbnail: video.thumbnail,
            order: null,
            contentId: null,
            data: { ...video }
          })) // @ts-ignore
          .sort((a, b) => new Date(b.data.uploadedAt) - new Date(a.data.uploadedAt)),
        webContents: this.props.webContents.data
          .map(webContent => ({
            id: webContent.id,
            thumbnail: webContent.thumbnail,
            type: "WEBCONTENT",
            order: null,
            contentId: null,
            data: { ...webContent }
          })) // @ts-ignore
          .sort((a, b) => new Date(b.data.uploadedAt) - new Date(a.data.uploadedAt)),
        selected: this.props.content
          .map(content => ({
            id: content.type === "WEBCONTENT" ? content.webContent.id : content.video.id,
            type: content.type,
            thumbnail: content.type === "WEBCONTENT" ? content.webContent.thumbnail : content.video.thumbnail,
            order: content.order,
            contentId: content.id,
            data: content.type === "WEBCONTENT" ? content.webContent : content.video
          }))
          .sort((a, b) => a.order - b.order)
      },
      () => {
        this.props.setCurrentSelectedContent(this.state.selected)
      }
    )

    this.props.initDone()
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)

    destClone.splice(droppableDestination.index, 0, removed)

    const result = {}
    result[droppableSource.droppableId] = droppableSource.droppableId === "playlistContent" ? sourceClone : source
    result[droppableDestination.droppableId] =
      droppableDestination.droppableId === "playlistContent" ? destClone : destination

    return result
  }

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: this.state.grid,
    width: 250
  })

  id2List = {
    droppableVideos: "videos",
    droppableWebContents: "webContents",
    playlistContent: "selected"
  }

  getList = id => this.state[this.id2List[id]]

  onDoubleClick = (item, index, draggableId) => {
    const result = {
      combine: null,
      destination: {
        index: this.state.selected.length,
        droppableId: "playlistContent"
      },
      draggableId,
      mode: "FLUID",
      reason: "DROP",
      source: { index, droppableId: item.type === "WEBCONTENT" ? "droppableWebContents" : "droppableVideos" },
      type: "DEFAULT"
    }

    this.onDragEnd(result)
  }

  deleteContent = (content, index) => {
    const result = {
      combine: null,
      destination: {
        index: 0,
        droppableId: content.type === "WEBCONTENT" ? "droppableWebContents" : "droppableVideos"
      },
      draggableId: "3-playlistContent",
      mode: "FLUID",
      reason: "DROP",
      source: { index, droppableId: "playlistContent" },
      type: "DEFAULT"
    }

    this.onDragEnd(result)
  }

  onDragEnd = _result => {
    const { source, destination } = _result

    // dropped outside the list
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = this.reorder(this.getList(source.droppableId), source.index, destination.index)

      let tmpState
      tmpState = { items }

      if (source.droppableId === "playlistContent") {
        tmpState = { selected: items }
      }

      this.setState(tmpState)
      this.props.setCurrentSelectedContent(items)
      this.props.setHasChanged(true)
    } else {
      const result = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )

      this.setState(
        prevState => ({
          videos: result.droppableVideos || prevState.videos,
          webContents: result.droppableWebContents || prevState.webContents,
          selected: result.playlistContent || prevState.playlistContent
        }),
        () => {
          this.props.setCurrentSelectedContent(this.state.selected)
        }
      )
      this.props.setHasChanged(true)
    }
  }

  update = async () => {
    this.props.update(this.state.selected)

    this.props.setHasChanged(false)
  }

  _changeActiveTab = index => {
    this.setState({ activeTab: index })
  }

  render() {
    const {
      hasChanged,
      setHasChanged,
      playlistId,
      userCanEdit,
      categories,
      organizations,
      ownerId,
      replaceContent,
      addAfterContent
    } = this.props
    const { videos, webContents, selected, activeTab } = this.state

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {userCanEdit && (
          <Row>
            <Col xs={12}>
              <div className="RRT__tabs body-tabs body-tabs-layout no-margin-right">
                <div
                  className={`RRT__tab draggable-tab RRT__tab--first ${activeTab === 0 ? "RRT__tab--selected" : ""}`}
                  onClick={() => this._changeActiveTab(0)}
                >
                  {i18n.t("entities.plural.video")}
                </div>
                <div
                  className={`RRT__tab draggable-tab ${activeTab === 1 ? "RRT__tab--selected" : ""}`}
                  onClick={() => this._changeActiveTab(1)}
                >
                  {i18n.t("entities.plural.webContent")}
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row
          style={{
            display: "flex",
            overflow: "hidden",
            flex: "1 1",
            height: "100%",
            paddingTop: 20,
            paddingBottom: 10
          }}
        >
          {userCanEdit && (
            <Col
              xs={6}
              style={{
                display: "flex",
                overflow: "hidden",
                flex: "1 1",
                height: "100%",
                flexDirection: "column",
                paddingRight: 10
              }}
            >
              {activeTab === 0 && (
                <DraggableListVideo
                  items={videos}
                  categories={categories}
                  organizations={organizations}
                  dblClickHandler={this.onDoubleClick}
                  myOrganization={this.props.myOrganization}
                />
              )}
              {activeTab === 1 && (
                <DraggableListWebContent
                  items={webContents}
                  categories={categories}
                  organizations={organizations}
                  dblClickHandler={this.onDoubleClick}
                  myOrganization={this.props.myOrganization}
                />
              )}
            </Col>
          )}

          <Col xs={userCanEdit ? 6 : 12} style={{ display: "flex", overflow: "hidden", flex: "1 1", height: "100%" }}>
            <PlaylistContent
              userCanEdit={userCanEdit}
              selected={selected}
              deleteContent={this.deleteContent}
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              update={this.update}
              playlistId={playlistId}
              ownerId={ownerId}
              replaceContent={replaceContent}
              addAfterContent={addAfterContent}
            />
          </Col>
        </Row>
      </DragDropContext>
    )
  }
}

export default PlaylistHandler
