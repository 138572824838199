import React from "react"
import i18n from "utils/i18n"
import { Popup } from "semantic-ui-react"

const DevicePairingCode = ({ pairingCode, style = {} }) => {
  return (
    <Popup
      size="tiny"
      trigger={
        <div className="pairing-code-container" style={{ ...style }}>
          {pairingCode}
        </div>
      }
    >
      {i18n.t("devices.attributes.pairingCode")}
    </Popup>
  )
}

export default DevicePairingCode
