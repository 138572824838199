import * as Yup from "yup"
import i18n from "utils/i18n"

const validationSchema = Yup.object().shape({
  name: Yup.string().required(i18n.t("form.requiredField")),
  color: Yup.string().required(i18n.t("form.requiredField")),
  type: Yup.object().required(i18n.t("form.requiredField"))
})

export default validationSchema
