const initialValues = [
  {
    name: "status",
    type: "boolean"
  },
  {
    name: "monday",
    type: "boolean"
  },
  {
    name: "tuesday",
    type: "boolean"
  },
  {
    name: "wednesday",
    type: "boolean"
  },
  {
    name: "thursday",
    type: "boolean"
  },
  {
    name: "friday",
    type: "boolean"
  },
  {
    name: "saturday",
    type: "boolean"
  },
  {
    name: "sunday",
    type: "boolean"
  },
  {
    name: "startHour",
    type: "text"
  },
  {
    name: "endHour",
    type: "text"
  },
  {
    name: "source",
    type: "select"
  },
  {
    name: "volume",
    type: "number"
  },
  {
    name: "timerSeq",
    type: "number"
  }
]

export default initialValues
