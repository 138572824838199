import React from "react"

const DeviceName = ({ name }) => {
  return (
    <div className="device-name-quickaccess">
      <i
        className="pe-7s-monitor btn-icon-wrapper"
        style={{ marginRight: "0.5em", color: "#394660", fontSize: "1.5em" }}
        // eslint-disable-next-line react/jsx-closing-tag-location
      ></i>
      {name}
    </div>
  )
}

export default DeviceName
