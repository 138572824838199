const getContentDuration = (list, onTable = false) => {
  if (list.length === 0) return 0

  let result
  let duration

  if (onTable) {
    result = list.reduce((acc, i) => {
      switch (i.type) {
        case "VIDEO":
          // eslint-disable-next-line prefer-destructuring
          duration = i.video !== undefined ? i.video.duration : 0
          break
        case "WEBCONTENT":
          // eslint-disable-next-line prefer-destructuring
          duration = i.webContent !== undefined ? i.webContent.duration : 0
          break
        case "PLAYLIST":
          duration = getContentDuration(i.playlist.content, true) / 1000
          break
        default:
          duration = 0
      }
      return acc + duration
    }, 0)
  } else {
    result = list.reduce((acc, i) => {
      switch (i.type) {
        case "VIDEO":
          // eslint-disable-next-line prefer-destructuring
          duration = i.data !== undefined ? i.data.duration : 0
          break
        case "WEBCONTENT":
          // eslint-disable-next-line prefer-destructuring
          duration = i.data !== undefined ? i.data.duration : 0
          break
        case "PLAYLIST":
          duration = i.data !== undefined ? getContentDuration(i.data.content, true) / 1000 : 0

          break
        default:
          duration = 0
      }

      return acc + duration
    }, 0)
  }

  return result * 1000
}

export default getContentDuration
