const initialValues = [
  {
    name: "name",
    type: "text"
  },
  {
    name: "color",
    type: "text"
  }
]

export default initialValues
