import React from "react"
import { Col, Row } from "reactstrap"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import PlaylistThumbnail from "components/Users/PlaylistThumbnail"
import getContentDuration from "utils/getContentDuration"
import moment from "moment"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import { BASE_URL } from "config/api"
import ListActionButton from "components/Buttons/ListActionButton"

const PlaylistLink = ({ role, element, children }) => {
  if (role === "ADMIN") {
    return (
      <Link to={`/playlists/${element.id}`} className="row-link">
        {children}
      </Link>
    )
  }

  return (
    <a
      style={{ height: "100%" }}
      href={`${BASE_URL}/player/${element.owner.id}/playlist/${element.id}`}
      target="_blank"
    >
      {children}
    </a>
  )
}

const ItemLine = ({ element, handlers }) => {
  return (
    <PlaylistLink role={handlers.role} element={element}>
      <Row className="line-element-row">
        <Col xs={1} className="list-thumbnail-playlist">
          <PlaylistThumbnail playlist={element} />
        </Col>
        <Col xs={3}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.name")}</div>
            <div className="content">{element.title}</div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.owner")}</div>
            <div className="content">
              <OrganizationWithLogoDisplayer organization={element.owner} style={{ height: 20, marginTop: "0.2em" }} />
            </div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.nbElements")}</div>
            <div className="content">{element.content.length}</div>
          </div>
        </Col>

        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.duration")}</div>
            <div className="video-time">{moment.utc(getContentDuration(element.content, true)).format("mm:ss")}</div>
          </div>
        </Col>

        <Col xs={2}>
          {handlers.userCanEdit(element.owner.id) && (
            <ListActionButton
              icon="copy-file"
              label={i18n.t("actions.duplicate")}
              onClick={() => handlers.copy(element.id)}
            />
          )}
        </Col>
      </Row>
    </PlaylistLink>
  )
}

export default ItemLine
