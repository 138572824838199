import React, { Fragment } from "react"
import { Progress } from "reactstrap"

const ReleaseStatusProgressBar = ({ nb = 0, total = 0 }) => {
  return (
    <Fragment>
      <div className="releasestatus-progress-info-container">
        <div className="releasestatus-progress-info-progressbar">
          <Progress
            color="releasestatus-progress-info-remaining-high"
            value={(nb / total) * 100}
            className="progress-bar-releasestatus"
          />
        </div>
        <div className="releasestatus-progress-info-header">
          <div className="releasestatus-progress-info-title">
            {nb}/{total} écrans
          </div>
          <div className="releasestatus-progress-info-content"></div>
        </div>
      </div>
    </Fragment>
  )
}

export default ReleaseStatusProgressBar
