export const API_INIT_MINE_ORGANIZATION = "API_INIT_MINE_ORGANIZATION"
export const API_GET_MINE_ORGANIZATION = "API_GET_MINE_ORGANIZATION"
export const API_GET_MINE_USERS_ORGANIZATION = "API_GET_MINE_USERS_ORGANIZATION"
export const API_GET_MINE_SUBORGAS_ORGANIZATION = "API_GET_MINE_SUBORGAS_ORGANIZATION"
export const API_GET_ALL_ORGANIZATION = "API_GET_ALL_ORGANIZATION"
export const API_GET_ONE_ORGANIZATION = "API_GET_ONE_ORGANIZATION"
export const API_POST_ORGANIZATION = "API_POST_ORGANIZATION"
export const API_PATCH_ORGANIZATION = "API_PATCH_ORGANIZATION"
export const API_GET_MY_STORAGE_ORGANIZATION = "API_GET_MY_STORAGE_ORGANIZATION"
export const API_PATCH_LOGO_ORGANIZATION = "API_PATCH_LOGO_ORGANIZATION"
export const API_DELETE_ORGANIZATION = "API_DELETE_ORGANIZATION"
export const GET_ONE_SUBORGA = "GET_ONE_SUBORGA"
export const RESET_FOCUS_SUBORGA = "RESET_FOCUS_SUBORGA"
export const RESET_FOCUS_ORGA = "RESET_FOCUS_ORGA"
export const API_PATCH_SHARING_RULES_ORGANIZATION = "API_PATCH_SHARING_RULES_ORGANIZATION"
export const API_GET_ORGANIZATION_SHARING_RULES = "API_GET_ORGANIZATION_SHARING_RULES"
