import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import cx from "classnames"
import ReactCSSTransitionGroup from "react-addons-css-transition-group"
import PerfectScrollbar from "react-perfect-scrollbar"

import { setEnableMobileMenu } from "reducers/ThemeOptions"

import Storage from "components/Storage"
import HeaderLogo from "../AppLogo"
import Nav from "../AppNav/VerticalNavWrapper"

class AppSidebar extends Component {
  state = {}

  toggleMobileSidebar = () => {
    // eslint-disable-next-line no-shadow
    const { enableMobileMenu, setEnableMobileMenu } = this.props
    setEnableMobileMenu(!enableMobileMenu)
  }

  render() {
    const {
      backgroundColor,
      enableBackgroundImage,
      enableSidebarShadow,
      backgroundImage,
      backgroundImageOpacity,
      user,
      storage
    } = this.props

    return (
      <Fragment>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-sidebar", backgroundColor, { "sidebar-shadow": enableSidebarShadow })}
          transitionName="SidebarAnimation"
          transitionAppear
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />
          <PerfectScrollbar style={{ position: "relative" }}>
            <div className="app-sidebar__inner">
              <Nav user={user} />
            </div>
          </PerfectScrollbar>
          <div className="storage-container">
            <Storage storage={storage} />
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity
})

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar)
