import React, { Component, Fragment } from "react"
import { Button } from "reactstrap"
import i18n from "utils/i18n"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import getMediaSrc from "utils/getMediaSrc"
import ListLoader from "components/List/ListLoader"

class ImageHandler extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  inputClick = () => {
    document.getElementById("inputImage").click()
  }

  onLibraryImport = async e => {
    const file = e.target.files[0]
    this.setState({ loading: true })
    await this.props.onChange(file)

    this.setState({ loading: false })
  }

  onDelete = () => {
    this.props.onImageDelete()
  }

  render() {
    const { loading } = this.state
    const { image } = this.props

    return (
      <div className="form-input-container display-parameters-image-uploader-container" style={{ textAlign: "right" }}>
        <input
          type="file"
          name="inputImage"
          id="inputImage"
          className="form-video-input"
          onChange={this.onLibraryImport}
        />
        {image === "" && (
          <Fragment>
            <div className="form-label" style={{ marginTop: 0, textAlign: "left" }}>
              {i18n.t("devices.content.image")}
            </div>
            <div
              className="display-parameters-image-uploader"
              onClick={() => {
                this.inputClick()
              }}
            >
              {i18n.t("devices.content.chooseImage")}
            </div>
          </Fragment>
        )}

        {loading && <ListLoader />}

        {image !== "" && (
          <div className="display-parameters-image-container">
            <img
              src={getMediaSrc(image, "screen")}
              alt=""
              style={{ maxWidth: 150, width: "100%", alignSelf: "center" }}
            />

            <div className="actions-button">
              <Button
                className="btn-icon"
                color="light"
                outline
                size="md"
                onClick={() => {
                  this.inputClick()
                }}
              >
                {i18n.t("actions.edit")}
              </Button>

              <FormDeleteButton
                text={i18n.t("actions.delete")}
                disabled={false}
                type="button"
                className="form-delete-button-right"
                onClick={() => this.onDelete()}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ImageHandler
