import React, { Fragment } from "react"
import { Col, Row } from "reactstrap"
import ScreenOne from "components/Screens/ScreenOne"
import ScreenTwo from "components/Screens/ScreenTwo"
import BigScreenTwo from "components/Screens/BigScreenTwo"
import ScreenThree from "components/Screens/ScreenThree"
import BigScreenThree from "components/Screens/BigScreenThree"
import ScreenFour from "components/Screens/ScreenFour"
import BigScreenFour from "components/Screens/BigScreenFour"
import ScreenFive from "components/Screens/ScreenFive"
import BigScreenFive from "components/Screens/BigScreenFive"

const ScreenDisplay = ({ active, onChange }) => {
  return (
    <Fragment>
      <Row className="screen-display">
        <Col className="screen-display-element" onClick={() => onChange(1)}>
          <ScreenOne active={active === 1} />
        </Col>
        <Col className="screen-display-element" onClick={() => onChange(2)}>
          <ScreenTwo active={active === 2} />
        </Col>
        <Col className="screen-display-element" onClick={() => onChange(3)}>
          <ScreenThree active={active === 3} />
        </Col>
        <Col className="screen-display-element" onClick={() => onChange(4)}>
          <ScreenFour active={active === 4} />
        </Col>
        <Col className="screen-display-element" onClick={() => onChange(5)}>
          <ScreenFive active={active === 5} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {active === 1 && <ScreenOne />}
          {active === 2 && <BigScreenTwo />}
          {active === 3 && <BigScreenThree />}
          {active === 4 && <BigScreenFour />}
          {active === 5 && <BigScreenFive />}
        </Col>
      </Row>
    </Fragment>
  )
}

export default ScreenDisplay
