export const API_GET_ALL_ADMINISTRABLE_DEVICE = "API_GET_ALL_ADMINISTRABLE_DEVICE"
export const API_GET_ALL_DEVICE = "API_GET_ALL_DEVICE"
export const API_GET_ONE_DEVICE = "API_GET_ONE_DEVICE"
export const API_GET_ORGANIZATION_DEVICE = "API_GET_ORGANIZATION_DEVICE"
export const GET_ONE_DEVICE = "GET_ONE_DEVICE"
export const API_POST_DEVICE = "API_POST_DEVICE"
export const API_PATCH_DEVICE = "API_PATCH_DEVICE"
export const API_DELETE_DEVICE = "API_DELETE_DEVICE"
export const API_SET_ON_DEVICE = "API_SET_ON_DEVICE"
export const API_SET_OFF_DEVICE = "API_SET_OFF_DEVICE"
export const API_CLEAR_STORAGE_DEVICE = "API_CLEAR_STORAGE_DEVICE"
export const RESET_FOCUS_DEVICE = "RESET_FOCUS_DEVICE"
export const API_DELETE_IMAGE_DEVICE = "API_DELETE_IMAGE_DEVICE"
export const API_UPLOAD_IMAGE_DEVICE = "API_UPLOAD_IMAGE_DEVICE"
export const API_POST_SCREEN_TIMER_DEVICE = "API_POST_SCREEN_TIMER_DEVICE"
export const API_PATCH_SCREEN_TIMER_DEVICE = "API_PATCH_SCREEN_TIMER_DEVICE"
export const API_DELETE_SCREEN_TIMER_DEVICE = "API_DELETE_SCREEN_TIMER_DEVICE"
export const INIT_DEVICE = "INIT_DEVICE"
export const RESET_DEVICES = "RESET_DEVICES"

export const API_MAGICINFO_GET_GENERAL_DEVICE = "API_MAGICINFO_GET_GENERAL_DEVICE"
export const API_MAGICINFO_GET_TIME_DEVICE = "API_MAGICINFO_GET_TIME_DEVICE"
export const API_MAGICINFO_GET_SETUP_DEVICE = "API_MAGICINFO_GET_SETUP_DEVICE"
export const API_MAGICINFO_GET_SECURITY_DEVICE = "API_MAGICINFO_GET_SECURITY_DEVICE"

export const API_MAGICINFO_PATCH_TIME_DEVICE = "API_MAGICINFO_PATCH_TIME_DEVICE"
export const API_MAGICINFO_PATCH_GENERAL_DEVICE = "API_MAGICINFO_PATCH_GENERAL_DEVICE"
export const API_MAGICINFO_PATCH_DISPLAY_DEVICE = "API_MAGICINFO_PATCH_DISPLAY_DEVICE"
export const API_MAGICINFO_PATCH_SETUP_DEVICE = "API_MAGICINFO_PATCH_SETUP_DEVICE"
export const API_MAGICINFO_PATCH_SECURITY_DEVICE = "API_MAGICINFO_PATCH_SECURITY_DEVICE"
export const API_MAGICINFO_POWERON_DEVICE = "API_MAGICINFO_POWERON_DEVICE"
export const API_MAGICINFO_POWEROFF_DEVICE = "API_MAGICINFO_POWEROFF_DEVICE"
export const API_MAGICINFO_RESTART_DEVICE = "API_MAGICINFO_RESTART_DEVICE"
export const API_MAGICINFO_CHANGESOURCE_DEVICE = "API_MAGICINFO_CHANGESOURCE_DEVICE"
export const API_MAGICINFO_CLEARDISK_DEVICE = "API_MAGICINFO_CLEARDISK_DEVICE"
