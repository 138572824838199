import { LOGOUT_USER, AUTH_USER, API_RESET_PASSWORD } from "actions/users"
import { toSuccess, toFailure } from "actions"
import { createReducer } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  error: false,
  token: null,
  isLogged: false,
  passwordRecoverySuccess: false
}

const auth = createReducer(initialState, {
  /* Authentification */
  [AUTH_USER]: state => {
    state.loading = true
    state.error = false
    state.passwordRecoverySuccess = false
  },
  [toSuccess(AUTH_USER)]: (state, action) => {
    state.loading = false
    state.error = false

    state.token = action.payload.data.token
    state.isLogged = true
  },
  [toFailure(AUTH_USER)]: (state, action) => {
    state.loading = false
    state.error = action.payload.data.errorCode

    state.isLogged = false
  },
  [toSuccess(API_RESET_PASSWORD)]: (state, action) => {
    state.passwordRecoverySuccess = true
  },

  [LOGOUT_USER]: state => {
    state.loading = false
    state.error = false
    state.token = null
    state.isLogged = false
  }
})

export default auth
