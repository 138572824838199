import {
  BASE_MEDIAS_VIDEO_THUMBNAIL_URL,
  BASE_MEDIAS_PLAYLIST_THUMBNAIL_URL,
  BASE_MEDIAS_WEBCONTENT_THUMBNAIL_URL,
  BASE_API_URL
} from "config/api"

const getThumbnailSrc = (thumbnail, type) => {
  if (type === "video" || type === "VIDEO") return BASE_MEDIAS_VIDEO_THUMBNAIL_URL + thumbnail
  if (type === "playlist" || type === "PLAYLIST") return BASE_MEDIAS_PLAYLIST_THUMBNAIL_URL + thumbnail
  if (type === "webContent" || type === "WEBCONTENT") return BASE_MEDIAS_WEBCONTENT_THUMBNAIL_URL + thumbnail

  return BASE_API_URL
}
export default getThumbnailSrc
