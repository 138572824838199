const initialValues = [
  {
    name: "id",
    type: "number"
  },
  {
    name: "name",
    type: "text"
  }
]

export default initialValues
