import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  getSubOrgaFromStore,
  apiGetOrganization,
  apiPostOrganization,
  apiPatchOrganization,
  apiPatchLogoOrganization,
  apiDeleteOrganization,
  resetFocusSubOrga
} from "services/organizations"
import { Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import _ from "lodash"
import produce from "immer"
import initFormValues from "utils/formik/initFormValues"
import { createFormDataFromFormik } from "utils/formik/apiSerializer"
import { Redirect, withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/SubOrgas/One/Form/validationSchema"
import initialValues from "features/SubOrgas/One/Form/initialValues"
import SubOrgaForm from "features/SubOrgas/One/Form"
import Users from "features/Organization/Users"
import { Loader } from "semantic-ui-react"
import getOrganizationLogoSrc from "utils/getOrganizationLogoSrc"
import Tabs from "react-responsive-tabs"
import ListContainer from "components/List/ListContainer"
import ListLoader from "components/List/ListLoader"
import BroadcastTags from "features/Organization/BroadcastTags"

export class SubOrga extends Component {
  state = {
    isInit: false,
    isNew: true,
    title: "subOrgas.pageTitle.addTitle",
    redirectToRoot: false,
    logoHandler: {
      src: null,
      file: null,
      error: null
    }
  }

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusSubOrga()
  }

  initComponent = async id => {
    const isNew = id === "new"

    if (!isNew) {
      if (this.props.organizations.me.data.id === id) await this.props.getSubOrgaFromStore(id)
      else await this.props.apiGetOrganization(id)

      const { data } = this.props.organizations.focus

      if (data === null) this.setState({ redirectToRoot: true })

      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.isNew = false
          // eslint-disable-next-line no-param-reassign
          draft.title = "subOrgas.pageTitle.editTitle"
          // eslint-disable-next-line no-param-reassign
          draft.logoHandler = {
            error: null,
            src: getOrganizationLogoSrc(data)
          }
        })
      )
    }

    this.setState({ isInit: true })
  }

  handleLogoChange = async (src, file) => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.logoHandler.error = null
      })
    )
    const supportedTypes = ["image/png", "image/jpeg"]

    if (!supportedTypes.includes(file.type)) {
      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.logoHandler.error = i18n.t("form.image.formatError")
        })
      )
    } else {
      if (!this.state.isNew) {
        const formData = new FormData()
        formData.append("logo", file)

        await this.props.apiPatchLogoOrganization(this.props.match.params.id, formData)
      }

      this.setState(prevState => ({ ...prevState, logoHandler: { src, file, error: null } }))
    }
  }

  submitForm = async values => {
    const { state } = this
    const data = _.clone(values)
    data.superOrga = this.props.organizations.me.data.id
    // New element
    if (state.isNew) {
      const formData = createFormDataFromFormik(data)
      formData.append("logo", state.logoHandler.file)

      const response = await this.props.apiPostOrganization(formData)

      if (response.type === "API_POST_ORGANIZATION_SUCCESS") {
        this.props.history.push(`/subOrgas/${response.payload.data.id}`)
        this.initComponent(response.payload.data.id)
      }
    } else {
      this.props.apiPatchOrganization(this.props.match.params.id, values)
    }

    return true
  }

  deleteElt = async () => {
    await this.props.apiDeleteOrganization(this.props.match.params.id)

    this.setState({ redirectToRoot: true })
  }

  render() {
    const { title, isNew, redirectToRoot, isInit, logoHandler } = this.state

    const data = isNew ? {} : this.props.organizations.focus.data

    if (redirectToRoot) {
      return <Redirect to="/subOrgas" />
    }

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/subOrgas" />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors }) => {
                  return (
                    <Form>
                      <SubOrgaForm
                        organization={data}
                        isNew={isNew}
                        logoHandler={logoHandler}
                        handleLogoChange={this.handleLogoChange}
                        errors={errors}
                        loading={this.props.organizations.focus.loading}
                      />
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          {!isNew && (
            <Tabs
              tabsWrapperClass="body-tabs body-tabs-layout"
              transform={false}
              showInkBar
              items={[
                {
                  title: i18n.t("organizations.tabs.members"),
                  content: <Users organization={data} />,
                  tabClassName: "draggable-tab"
                },
                {
                  title: i18n.t("entities.plural.broadcastTag"),
                  content: <BroadcastTags organization={data} />,
                  tabClassName: "draggable-tab"
                },
                {
                  title: i18n.t("organizations.tabs.subOrgas"),
                  content: (
                    <ListContainer
                      type="SUBORGA"
                      lineHeight="lg"
                      elements={{ data: data.subOrgas }}
                      filtersOn={false}
                      filters={null}
                      defaultLayout="list"
                      multipleLayouts={false}
                    />
                  ),
                  tabClassName: "draggable-tab"
                }
              ]}
            />
          )}
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  organizations: state.organizations
})

const mapDispatchToProps = {
  getSubOrgaFromStore,
  apiGetOrganization,
  apiPostOrganization,
  apiPatchOrganization,
  apiPatchLogoOrganization,
  apiDeleteOrganization,
  resetFocusSubOrga
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubOrga))
