/* eslint-disable no-else-return */
import _ from "lodash"

export const getIDValueFromObject = object => object.value
export const getIDValuesFromArray = array => array.map(elt => elt.value)

export const createFormDataFromFormik = values => {
  const formData = new FormData()

  Object.keys(values).forEach(key => {
    // eslint-disable-next-line prefer-const
    let value = values[key]

    if (Array.isArray(value)) {
      if (value.length > 0) {
        formData.append(key, getIDValuesFromArray(value))
      }

      // @ts-ignore
    } else if (_.isObject(value) && !_.isEmpty(value)) {
      // @ts-ignore
      const res = value.value === "" ? null : value.value
      formData.append(key, res)
    } else if (value !== null && !_.isObject(value)) formData.append(key, value)
  })

  return formData
}

export const createJsonDataFromFormik = values => {
  const result = {}

  Object.keys(values).forEach(key => {
    // eslint-disable-next-line prefer-const
    let value = values[key]

    if (Array.isArray(value)) {
      if (value.length > 0) {
        result[key] = getIDValuesFromArray(value)
      }
      // @ts-ignore
    } else if (_.isObject(value) && !_.isEmpty(value)) {
      console.log(key, value)
      // @ts-ignore
      const res = value.value === "" ? null : value.value

      result[key] = res
    } else if (value === "") result[key] = null
    else if (value !== null && !_.isObject(value)) result[key] = value
  })

  return result
}
