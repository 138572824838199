import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  getVideoFromStore,
  apiGetVideo,
  resetFocusVideo,
  apiPostVideo,
  apiDeleteVideo,
  apiPatchVideo
} from "services/videos"
import { Row, Col, Button } from "reactstrap"
import MainContainer from "components/PageStructure/MainContainer"
import { Formik, Form, Field } from "formik"
import TagHandler from "components/Form/TagHandler"
import _ from "lodash"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import FormSeparator from "components/Form/FormSeparator"
import VideoPlayerInline from "components/VideoPlayer/VideoPlayerInline"
import { BASE_MEDIAS_VIDEO_URL, BASE_MEDIAS_VIDEO_DOWNLOAD_URL, BASE_PLAYER_IFRAME_URL } from "config/api"
import produce from "immer"
import FormSaveButton from "components/Buttons/FormSaveButton"
import initFormValues from "utils/formik/initFormValues"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import { createFormDataFromFormik } from "utils/formik/apiSerializer"
import { Redirect, withRouter } from "react-router-dom"
import FormDeleteButton from "components/Buttons/FormDeleteButton"
import i18n from "utils/i18n"
import getThumbnailSrc from "utils/getThumbnailSrc"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"
import SharedSwitch from "components/Form/SharedSwitch"
import { Confirm } from "semantic-ui-react"
import ListLoader from "components/List/ListLoader"
import blobToFile from "utils/blobToFile"
import validationSchema from "./Form/validationSchema"
import initialValues from "./Form/initialValues"
import GeneralForm from "./Form/GeneralForm"
import VideoThumbnailHandler from "./Form/VideoThumbnailHandler"
import DropzoneVideo from "./Form/DropzoneVideo"
import UploadProgressBar from "./Form/UploadProgressBar"
import IntegrationCodeButton from "components/Buttons/IntegrationCodeButton"
import { toast } from "react-toastify"

export class Video extends Component {
  state = {
    isNew: true,
    title: "videos.pageTitle.addTitle",
    redirectToRoot: false,
    isUploading: false,
    isReplacing: false,
    thumbnailTipActive: false,
    video: {
      isNew: false,
      src: null,
      file: null,
      error: null
    },
    thumbnail: {
      isNew: false,
      src: null,
      file: null,
      error: null
    },
    isInit: false,
    confirmDeleteModalOpen: false
  }

  formikRef = React.createRef()

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusVideo()
  }

  handleVideoChange = async e => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.video.error = null
      })
    )
    const { files } = e.target
    const supportedTypes = ["video/mp4"]
    if (files.length === 1) {
      const file = files[0]
      const name = file.name.split(".").slice(0, -1).join(".")
      const utf8name = decodeURI(name.normalize("NFC"))

      if (!supportedTypes.includes(file.type)) {
        this.setState(
          produce(draft => {
            // eslint-disable-next-line no-param-reassign
            draft.video.error = i18n.t("videos.video.formatError")
          })
        )
        return false
      }

      await this.setState({ video: { isNew: true, src: URL.createObjectURL(file), file, error: null } })

      if (this.state.isNew) {
        // Auto set the name of the video
        this.formikRef.current.setFieldValue("title", utf8name)

        setTimeout(() => this.captureDefaultThumbnail(), 500)
      }

      if (!this.state.isNew) {
        const { values } = this.formikRef.current.state
        const formData = createFormDataFromFormik(values)
        formData.append("video", file)

        this.setState({ isUploading: true, isReplacing: false })

        await this.props.apiPatchVideo(this.props.match.params.id, formData)

        this.setState({ isUploading: false })
      }

      return true
    }

    return false
  }

  handleThumbnailChange = (src, file) => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.thumbnail.error = null
      })
    )
    const supportedTypes = ["image/png", "image/jpeg"]

    if (!supportedTypes.includes(file.type)) {
      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.thumbnail.error = i18n.t("videos.thumbnail.formatError")
        })
      )
      return false
    }

    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.thumbnail = { isNew: true, src, file, error: null }
      })
    )
    if (!this.state.isNew) {
      const { values } = this.formikRef.current.state
      const formData = createFormDataFromFormik(values)
      formData.append("thumbnail", file)

      this.props.apiPatchVideo(this.props.match.params.id, formData)
    }
    return true
  }

  initComponent = async id => {
    const isNew = id === "new"

    if (!isNew) {
      this.setState({ isNew: false, title: "videos.pageTitle.editTitle" })

      if (this.props.videos.list.data.some(e => e.id === id)) await this.props.getVideoFromStore(id)
      else await this.props.apiGetVideo(id)

      const { data } = this.props.videos.focus

      if (data === null) this.setState({ redirectToRoot: true })
      else {
        this.setState({
          video: { isNew: false, src: `${BASE_MEDIAS_VIDEO_URL + data.id}`, file: null, error: null },
          thumbnail: { isNew: false, src: getThumbnailSrc(data.thumbnail, "VIDEO"), file: null, error: null }
        })
      }
    }

    this.setState({ isInit: true })
  }

  captureDefaultThumbnail = () => {
    document.getElementById("videoPlayer").setAttribute("crossorigin", "anonymous")
    const canvas = document.getElementById("canvas")
    const video = document.getElementById("videoPlayer")
    // @ts-ignore
    video.load()
    // @ts-ignore
    video.currentTime = 5

    setTimeout(() => {
      // @ts-ignore
      canvas.width = video.videoWidth
      // @ts-ignore
      canvas.height = video.videoHeight
      // @ts-ignore
      canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

      // @ts-ignore
      canvas.toBlob(blob => {
        const img = new Image()
        img.setAttribute("crossOrigin", "anonymous")
        img.src = URL.createObjectURL(blob)

        video.currentTime = 0

        this.handleThumbnailChange(img.src, blobToFile(blob, "thumbnail.png", "image/png"))
      })
    }, 1000)
  }

  submitForm = async values => {
    const { state } = this
    // New element
    if (state.isNew) {
      if (state.video.file === null) return false // Error, pas de nouvelle video

      const formData = createFormDataFromFormik(values)
      formData.append("video", state.video.file)
      if (state.thumbnail.isNew) formData.append("thumbnail", state.thumbnail.file)

      // Begin uploading
      this.setState({ isUploading: true })

      const response = await this.props.apiPostVideo(formData)

      this.setState({ isUploading: false })

      if (response.type === "API_POST_VIDEOS_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    } else {
      const formData = createFormDataFromFormik(values)

      const response = await this.props.apiPatchVideo(this.props.match.params.id, formData)
      if (response.type === "API_PATCH_VIDEOS_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false, confirmDeleteModalOpen: false })
    const response = await this.props.apiDeleteVideo(this.props.match.params.id)

    if (response.type === "API_DELETE_VIDEOS_SUCCESS") {
      this.setState({ redirectToRoot: true })
    } else {
      this.setState({ isInit: true })
    }
  }

  openConfirmDeleteModalOpen = () => this.setState({ confirmDeleteModalOpen: true })

  showThumbnailTip = () => this.setState({ thumbnailTipActive: true })

  hideThumbnailTip = () => this.setState({ thumbnailTipActive: false })

  getOrganizationFromList = organization => {
    return this.props.organizations.list.data.find(i => i.id === organization.value)
  }

  getAllAccessibleBroadcastTags = () => {
    const result = this.props.organizations.list.data.reduce((acc, orga) => {
      return acc.concat(orga.broadcastTags)
    }, [])

    return result
  }

  downloadVideo = id => {
    window.open(BASE_MEDIAS_VIDEO_DOWNLOAD_URL + id, "_blank")
  }

  copyIntegrationCode = id => {
    const integrationCode = `<iframe src="${BASE_PLAYER_IFRAME_URL}${id}" width="640" height="360" allowfullscreen="true" frameborder="0"></iframe>`
    navigator.clipboard.writeText(integrationCode)

    toast.success("Code d'intégration copié")
  }

  render() {
    const {
      title,
      isNew,
      video,
      thumbnail,
      redirectToRoot,
      isUploading,
      isInit,
      isReplacing,
      thumbnailTipActive
    } = this.state
    const { myOrganization, organizations, uploadProgress, me, videos, categories } = this.props

    if (redirectToRoot) {
      return <Redirect to="/videos" />
    }
    if (!isInit) return <ListLoader />

    const newElement = {
      owner: {
        id: myOrganization.id,
        name: myOrganization.name
      },
      shared: true
    }
    const data = isNew ? newElement : videos.focus.data

    const userCanEdit =
      me.role === "ADMIN" &&
      (data.owner.id === myOrganization.id || organizations.list.data.some(i => i.id === data.owner.id))

    const canDownloadOrganiationIDs = myOrganization.accessTo
      .filter(i => i.canDownload === true)
      .map(i => i.sharingOrganizationId)

    canDownloadOrganiationIDs.push(myOrganization.id)

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/videos" />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                ref={this.formikRef}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors, values }) => {
                  return (
                    <Form>
                      {userCanEdit && (
                        <Row>
                          <Col xs={6}>
                            <FormSaveButton
                              text={i18n.t("actions.save")}
                              type="submit"
                              isLoading={isUploading}
                              disabled={!_.isEmpty(errors) || video.src === null || isUploading}
                            />
                          </Col>
                          <Col xs={6}>
                            {!isNew && (
                              <FormDeleteButton
                                text={i18n.t("actions.delete")}
                                disabled={false}
                                type="button"
                                className="form-delete-button-right"
                                onClick={() => this.openConfirmDeleteModalOpen()}
                              />
                            )}
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col xs={12} md={5} className="with-right-border">
                          <MainContainer title={i18n.t("videos.pageTitle.owner")}>
                            {isNew && (
                              <Field
                                component={SelectAutoComplete}
                                required
                                name="owner"
                                placeholder={i18n.t("form.actions.chooseOrganization")}
                                options={generateMultiSelectOptions(
                                  [
                                    { id: myOrganization.id, name: `${myOrganization.name} (${i18n.t("form.me")})` },
                                    ...organizations.list.data.filter(e => e.id !== myOrganization.id)
                                  ],
                                  "id",
                                  "name"
                                )}
                              />
                            )}

                            {!isNew && <OrganizationWithLogoDisplayer organization={data.owner} />}
                          </MainContainer>
                          <MainContainer title={i18n.t("videos.pageTitle.informations")}>
                            <Field
                              component={SharedSwitch}
                              name="shared"
                              editable={userCanEdit}
                              label={i18n.t("entities.attributes.sharing")}
                              required
                            />
                            <GeneralForm userCanEdit={userCanEdit} />
                            <Field
                              component={TagHandler}
                              label={i18n.t("entities.plural.category")}
                              required={false}
                              editable={userCanEdit}
                              name="categories"
                              placeholder={i18n.t("form.actions.addCategories")}
                              type="local"
                              options={generateMultiSelectOptions(categories, "id", "name")}
                            />
                            <Field
                              component={SelectAutoComplete}
                              label={i18n.t("entities.singular.group")}
                              required={false}
                              editable
                              name="groups"
                              placeholder={i18n.t("form.actions.chooseGroup")}
                              options={[{ value: "", label: "Aucun" }].concat(
                                generateMultiSelectOptions(myOrganization.localGroups, "id", "name", "VIDEO")
                              )}
                            />

                            <Field
                              component={TagHandler}
                              label={i18n.t("entities.plural.broadcastTag")}
                              required={false}
                              editable={userCanEdit}
                              name="broadcastTags"
                              placeholder={i18n.t("form.actions.addTags")}
                              type="local"
                              options={generateMultiSelectOptions(
                                !userCanEdit ? [] : this.getAllAccessibleBroadcastTags(),
                                "id",
                                "name"
                              )}
                            />

                            {!isNew && (
                              <div className="actions-button-video">
                                <IntegrationCodeButton
                                  disabled={false}
                                  type="button"
                                  text="Copier le code d'intégration"
                                  icon="link"
                                  onClick={() => this.copyIntegrationCode(data.id)}
                                  active
                                />
                                {canDownloadOrganiationIDs.includes(data.owner.id) && (
                                  <IntegrationCodeButton
                                    disabled={false}
                                    type="button"
                                    text="Télécharger la vidéo"
                                    icon="cloud-download"
                                    onClick={() => this.downloadVideo(data.id)}
                                    active
                                  />
                                )}
                              </div>
                            )}
                          </MainContainer>
                        </Col>
                        <Col xs={12} md={7}>
                          <MainContainer title={i18n.t("videos.pageTitle.video")}>
                            {isUploading && <UploadProgressBar percentage={uploadProgress} />}

                            {!isUploading && (
                              <Fragment>
                                {video.src !== null && !isReplacing && (
                                  <VideoPlayerInline
                                    video={video}
                                    thumbnail={thumbnail}
                                    thumbnailTipActive={thumbnailTipActive}
                                    hideThumbnailTip={this.hideThumbnailTip}
                                  />
                                )}

                                {(video.src === null || isReplacing) && (
                                  <DropzoneVideo
                                    onChange={value => this.handleVideoChange({ target: { files: value } })}
                                  />
                                )}

                                {video.error !== null && (
                                  <div className="form-error">
                                    <div className="form-error-style">{video.error}</div>
                                  </div>
                                )}
                                {thumbnail.error !== null && (
                                  <div className="form-error">
                                    <div className="form-error-style">{thumbnail.error}</div>
                                  </div>
                                )}

                                {userCanEdit && !isReplacing && !isUploading && video.src !== null && (
                                  <div className="form-video-actionbar">
                                    <div className="form-input-container">
                                      <Button
                                        className="mb-3 btn-icon"
                                        color="warning"
                                        type="button"
                                        onClick={() => this.setState({ isReplacing: true })}
                                        outline={video.src !== null}
                                        size="md"
                                      >
                                        <i className="pe-7s-plus btn-icon-wrapper"> </i>
                                        {i18n.t("videos.video.edit")}
                                      </Button>
                                    </div>

                                    <VideoThumbnailHandler
                                      isThumbnailUploaded={thumbnail.src !== null}
                                      onChange={this.handleThumbnailChange}
                                      showThumbnailTip={this.showThumbnailTip}
                                      hideThumbnailTip={this.hideThumbnailTip}
                                    />
                                  </div>
                                )}
                              </Fragment>
                            )}
                          </MainContainer>
                        </Col>
                      </Row>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          <Confirm
            open={this.state.confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <div>App Error</div>
  }
}

const mapStateToProps = state => ({
  videos: state.videos,
  uploadProgress: state.videos.focus.uploadProgress,
  organizations: state.organizations,
  myOrganization: state.organizations.me.data,
  me: state.users.me.data,
  categories: state.categories.list.data
})

const mapDispatchToProps = dispatch => ({
  getVideoFromStore: params => dispatch(getVideoFromStore(params)),
  apiGetVideo: params => dispatch(apiGetVideo(params)),
  apiPostVideo: params => dispatch(apiPostVideo(params, dispatch)),
  apiPatchVideo: (params, data) => dispatch(apiPatchVideo(params, data, dispatch)),
  apiDeleteVideo: params => dispatch(apiDeleteVideo(params)),
  resetFocusVideo: () => dispatch(resetFocusVideo())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video))
