import * as Yup from "yup"

const validationSchema = Yup.object().shape({
  contactName: Yup.string(),
  contactEmail: Yup.string(),
  contactPhone: Yup.string(),
  address: Yup.string(),
  lat: Yup.number(),
  lng: Yup.number()
})

export default validationSchema
