import { AUTH_USER, LOGOUT_USER, TOKEN_EXPIRED, API_RESET_PASSWORD } from "actions/users"
import client from "config/agent"

export function authUser(credentials) {
  return {
    type: AUTH_USER,
    payload: {
      request: {
        url: `/auth/login`,
        method: "POST",
        data: credentials
      },
      options: {
        async onSuccess({ dispatch, response }) {
          client.defaults.headers.common.authorization = `Bearer ${response.data.token}`

          dispatch({ type: `${AUTH_USER}_SUCCESS`, payload: response.data })
        },
        onError({ dispatch, error }) {
          dispatch({ type: `${AUTH_USER}_FAIL`, payload: error.response.data })
        }
      }
    }
  }
}

export function apiResetPassword(_data) {
  return {
    type: API_RESET_PASSWORD,
    payload: {
      request: {
        url: `/auth/resetPassword`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function logOutUser() {
  client.defaults.headers.common.authorization = ""

  return {
    type: LOGOUT_USER
  }
}

export function tokenExpired() {
  client.defaults.headers.common.authorization = ""

  return {
    type: TOKEN_EXPIRED
  }
}
