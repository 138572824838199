import React, { Component } from "react"
import ReactQuill, { Quill } from "react-quill"
import "react-quill/dist/quill.snow.css"

const Font = Quill.import("formats/font")
Font.whitelist = ["Arial", "OpenSans", "HelveticaNeue"]
Quill.register(Font, true)

class TextEditor extends Component {
  modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      ["bold", "italic"],
      ["clean"],
      [{ header: [false, 3, 2, 1] }],
      [{ color: [] }] // dropdown with defaults from theme
    ]
  }

  formats = ["header", "font", "bold", "italic", "color"]

  render() {
    const { value, onChange } = this.props

    return (
      <div className="text-editor">
        <ReactQuill
          theme="snow"
          modules={this.modules}
          formats={this.formats}
          value={value}
          onChange={_value => onChange(_value)}
        />
      </div>
    )
  }
}

export default TextEditor
