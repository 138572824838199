import React, { Fragment } from "react"
import { Field } from "formik"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import { Row, Col } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import i18n from "utils/i18n"
import _ from "lodash"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import CancelButton from "components/Buttons/CancelButton"
import MainContainer from "components/PageStructure/MainContainer"

const index = ({ possibleOrganizations, errors, loading, onCancel }) => {
  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <CancelButton onClick={() => onCancel()} style={{ marginLeft: 5 }} />
        </Col>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <Col xs={6}></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <MainContainer withBorder>
            <Row style={{ position: "relative" }}>
              <Col xs={3}>
                <Field
                  component={SelectAutoComplete}
                  required
                  name="sharingOrganization"
                  placeholder={i18n.t("entities.singular.organization")}
                  options={generateMultiSelectOptions(
                    possibleOrganizations.map(e => ({ value: e.id, name: e.name })),
                    "value",
                    "name"
                  )}
                />
              </Col>
              <Col xs={3}>
                <Field
                  component={SelectAutoComplete}
                  required
                  name="canDownload"
                  placeholder={i18n.t("organizations.sharingRules.download")}
                  options={generateMultiSelectOptions(
                    [
                      { value: true, name: i18n.t("organizations.sharingRules.downloadGranted") },
                      { value: false, name: i18n.t("organizations.sharingRules.downloadNotGranted") }
                    ],
                    "value",
                    "name"
                  )}
                />
              </Col>
              <Col xs={3}>
                <Field
                  component={SelectAutoComplete}
                  required
                  name="onlyShared"
                  placeholder={i18n.t("organizations.sharingRules.access")}
                  options={generateMultiSelectOptions(
                    [
                      { value: true, name: i18n.t("organizations.sharingRules.onlyShared") },
                      { value: false, name: i18n.t("organizations.sharingRules.allContent") }
                    ],
                    "value",
                    "name"
                  )}
                />
              </Col>
              <Col xs={3} style={{ display: "flex", alignItems: "center" }}>
                <FormSaveButton
                  isLoading={loading}
                  text={i18n.t("actions.save")}
                  type="submit"
                  disabled={!_.isEmpty(errors)}
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
      </Row>
    </Fragment>
  )
}

export default index
