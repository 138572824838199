const initialValues = [
  {
    name: "id",
    type: "number"
  },
  {
    name: "title",
    type: "text"
  },
  {
    name: "description",
    type: "text"
  },
  {
    name: "shared",
    type: "boolean"
  },
  {
    name: "url",
    type: "text"
  },
  {
    name: "duration",
    type: "number"
  },
  {
    name: "owner",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "categories",
    type: "multiSelect",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: true
    }
  },
  {
    name: "broadcastTags",
    type: "multiSelect",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "groups",
    type: "selectAutoComplete-Multi",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  }
]

export default initialValues
