import React, { Fragment } from "react"
import bytesToGo from "utils/bytesToGo"
import i18n from "utils/i18n"
import { Progress } from "reactstrap"

const Storage = ({ storage = null }) => {
  let progressBarColor = ""
  let contentColor = ""
  let capPercentage = 0
  let allowed = 0
  let used = 0
  let capacities = {}

  if (storage !== null) {
    capacities = storage.videos // Only applied for videos now
    allowed = Math.round(bytesToGo(capacities.allowed) * 100) / 100
    used = Math.round(bytesToGo(capacities.used) * 100) / 100

    capPercentage = ((allowed - used) / allowed) * 100
    capPercentage = Math.round(capPercentage * 100) / 100

    switch (true) {
      case capPercentage >= 50:
        progressBarColor = "storage-info-remaining-high"
        contentColor = "storage-content-remaining-high"
        break
      case capPercentage < 50 && capPercentage > 15:
        progressBarColor = "storage-info-remaining-medium"
        contentColor = "storage-content-remaining-medium"
        break
      default:
        progressBarColor = "storage-info-remaining-low"
        contentColor = "storage-content-remaining-low"
        break
    }
  }
  return (
    <Fragment>
      {storage !== null && (
        <div className="storage-info-container">
          <div className="storage-info-progressbar">
            <Progress color={progressBarColor} value={capPercentage} className="progress-bar-storage">
              {capPercentage}%
            </Progress>
          </div>
          <div className="storage-info-header mt-1">
            <div className="storage-info-title">
              {i18n.t("entities.singular.storage")} {i18n.t("storage.remaining")}
            </div>
            <div className={`storage-info-content ${contentColor}`}>
              {(Math.sign(allowed - used) * Math.round(Math.abs(allowed - used) * 100)) / 100}Go {i18n.t("storage.on")}{" "}
              {allowed}Go
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default Storage
