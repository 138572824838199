import React, { Fragment } from "react"
import i18n from "utils/i18n"
import GlobalTag from "./GlobalTag"
import LocalTag from "./LocalTag"

const MultipleTagDisplayer = ({ tags, type }) => {
  return (
    <Fragment>
      {tags.length === 0 && <div style={{ fontSize: "0.9em", fontStyle: "italic" }}>{i18n.t("form.none")}</div>}
      {type === "global" && tags.map(tag => <GlobalTag name={tag.name} key={`globaltag-display-${tag.id}`} />)}
      {type === "local" &&
        tags.map(tag => <LocalTag name={tag.name} color={tag.color} key={`localtag-display-${tag.id}`} />)}
    </Fragment>
  )
}

export default MultipleTagDisplayer
