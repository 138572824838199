const generateMultiSelectOptions = (_options, valueField, labelField, type = null) => {
  let data = _options
  if (type !== null) {
    data = _options.filter(e => e.type === type)
  }
  return data
    .map(opt => ({
      value: opt[valueField],
      label: opt[labelField],
      color: opt.color || "#000"
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

export default generateMultiSelectOptions
