export const API_GET_ALL_PLAYLIST = "API_GET_ALL_PLAYLIST"
export const API_GET_ONE_PLAYLIST = "API_GET_ONE_PLAYLIST"
export const API_POST_PLAYLIST = "API_POST_PLAYLIST"
export const API_PATCH_PLAYLIST = "API_PATCH_PLAYLIST"
export const API_PATCH_PLAYLIST_CONTENT = "API_PATCH_PLAYLIST_CONTENT"
export const API_DELETE_PLAYLIST = "API_DELETE_PLAYLIST"
export const GET_ONE_PLAYLIST = "GET_ONE_PLAYLIST"
export const RESET_FOCUS_PLAYLIST = "RESET_FOCUS_PLAYLIST"
export const INIT_PLAYLIST = "INIT_PLAYLIST"
export const API_COPY_PLAYLIST = "API_COPY_PLAYLIST"
export const API_REPLACE_IN_PLAYLIST = "API_REPLACE_IN_PLAYLIST"
export const API_ADDAFTER_IN_PLAYLIST = "API_ADDAFTER_IN_PLAYLIST"
export const API_GET_ONE_FOR_COPY_PLAYLIST = "API_GET_ONE_FOR_COPY_PLAYLIST"
