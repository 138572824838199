import React, { Component } from "react"
import { Col, Row } from "reactstrap"
import Status from "components/Status"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import { Loader } from "semantic-ui-react"
import Select from "react-select"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import _ from "lodash"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"

class ItemLine extends Component {
  state = {
    onProgramEdit: false,
    loading: false
  }

  toggleProgramEdit = () => {
    this.setState(prevState => ({ onProgramEdit: !prevState.onProgramEdit }))
  }

  onProgramChange = async value => {
    const { element, handlers } = this.props
    this.setState({ onProgramEdit: false, loading: true })

    const data = _.clone(element)
    data.program = value.value
    data.owner = data.owner.id

    await handlers.patch(data)

    this.setState({ loading: false })
  }

  render() {
    const { element, handlers } = this.props
    const { onProgramEdit, loading } = this.state

    return (
      <Link to={`/channels/${element.id}`} className="row-link">
        <Row className="line-element-row">
          <Col xs={1}>
            <i className="pe-7s-airplay folder-color-CHANNEL" style={{ fontSize: "3em" }}></i>
          </Col>
          <Col xs={3}>
            <div className="line-element-info">
              <div className="label">{i18n.t("entities.attributes.name")}</div>
              <div className="content">{element.title}</div>
            </div>
          </Col>
          <Col xs={2}>
            <div className="line-element-info">
              <div className="label">{i18n.t("entities.attributes.owner")}</div>
              <div className="content">
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
          <Col xs={2}>
            <div className="line-element-info">
              <div className="label">{i18n.t("entities.singular.program")}</div>
              <div className="content">
                {element.program === null && i18n.t("form.none")} {element.program !== null && element.program.title}
              </div>

              {onProgramEdit && (
                <Select
                  value={{
                    value: element.program.id,
                    label: element.program.title
                  }}
                  disabled={false}
                  onChange={this.onProgramChange}
                  name="title"
                  options={generateMultiSelectOptions(handlers.programs.data, "id", "title")}
                  placeholder={i18n.t("form.actions.chooseProgram")}
                  className="basic-multi-select"
                  classNamePrefix="tagHandler"
                />
              )}
            </div>
          </Col>

          <Col xs={2}>
            <Status status={element.status} />
          </Col>
        </Row>
      </Link>
    )
  }
}

export default ItemLine
