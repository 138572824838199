import React, { Component } from "react"
import { Row, Col, Button } from "reactstrap"
import i18n from "utils/i18n"
import moment from "moment"
import UserAvatar from "components/Users/UserAvatar"
import Select from "react-select"
import { Loader } from "semantic-ui-react"
import _ from "lodash"

class ItemLine extends Component {
  state = {
    onEdit: false,
    loading: false
  }

  toggleEdit = () => {
    this.setState(prevState => ({ onEdit: !prevState.onEdit }))
  }

  onChange = async value => {
    const { element, handlers } = this.props
    this.setState({ onEdit: false, loading: true })

    const data = _.clone(element)
    data.role = value.value

    await handlers.patch(data)

    this.setState({ loading: false })
  }

  render() {
    const { element, handlers } = this.props
    const { onEdit, loading } = this.state

    return (
      <Row className="line-element-row">
        <Col xs={1} className="list-user-avatar">
          <UserAvatar user={element} />
        </Col>
        <Col xs={3}>
          <div className="element-title">
            {element.firstName} {element.lastName}
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.email")}</div>
            <div className="content">{element.email}</div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info-editable">
            {!onEdit && (
              <div className="line-editable-content">
                <div className="label">{i18n.t("entities.attributes.role")}</div>
                <div className="content">{i18n.t(`users.roles.${element.role}`)}</div>
              </div>
            )}

            {onEdit && (
              <Select
                value={{
                  value: element.role,
                  label: element.role === "ADMIN" ? i18n.t("users.roles.admin") : i18n.t("users.roles.user")
                }}
                disabled={false}
                onChange={this.onChange}
                name="role"
                options={[
                  { value: "ADMIN", label: i18n.t("users.roles.admin") },
                  { value: "USER", label: i18n.t("users.roles.user") }
                ]}
                placeholder={i18n.t("form.actions.chooseOrganization")}
                className="basic-multi-select"
                classNamePrefix="tagHandler"
              />
            )}

            {loading && <Loader size="mini" active inline />}
            <div className="line-editable-actions">
              <i className="pe-7s-edit icon-only btn-icon-wrapper edit-line-item" onClick={() => this.toggleEdit()}></i>
            </div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="line-element-info">
            <div className="label">{i18n.t("entities.attributes.userCreatedAt")}</div>
            <div className="content">{moment(element.uploadedAt).format("DD/MM/YYYY, HH:mm")}</div>
          </div>
        </Col>
        <Col xs={2}>
          <div className="list-action-menu">
            <Button className="btn-icon" color="danger" outline size="sm" onClick={() => handlers.delete(element.id)}>
              <i className="pe-7s-trash btn-icon-wrapper"> </i>
              {i18n.t("actions.delete")}
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default ItemLine
