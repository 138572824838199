import React from "react"
import { Form as SemForm } from "semantic-ui-react"

const ClassicInput = ({
  field,
  label = null,
  required = false,
  editable = true,
  form: { touched, errors },
  ...props
}) => (
  <div className="form-input-container">
    <div className="form-label">
      {label !== null && <span>{label}</span>}
      {label !== null && required && editable && <span className="form-cross-required">*</span>}
    </div>

    <SemForm.Input
      {...field}
      {...props}
      fluid
      placeholder={props.placeholder}
      style={{ marginTop: 1 }}
      error={touched[field.name] && errors[field.name]}
      required={props.required}
      className="inputText"
      disabled={!editable}
    />

    {touched[field.name] && errors[field.name] && <div className="form-error-style">{errors[field.name]}</div>}
  </div>
)

export default ClassicInput
