import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import {
  getProgramFromStore,
  apiGetProgram,
  apiPostProgram,
  apiPatchProgram,
  apiPatchProgramContent,
  apiDeleteProgram,
  resetFocusProgram
} from "services/programs"
import { apiGetVideos } from "services/videos"
import { apiGetPlaylists } from "services/playlists"
import { apiGetAdministrableDevices } from "services/devices"
import { apiGetChannels } from "services/channels"
import { Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import _ from "lodash"
import initFormValues from "utils/formik/initFormValues"
import { Redirect, withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/Programs/One/Form/validationSchema"
import initialValues from "features/Programs/One/Form/initialValues"
import ProgramForm from "features/Programs/One/Form"
import { Loader, Confirm } from "semantic-ui-react"
import { createJsonDataFromFormik } from "utils/formik/apiSerializer"
import ProgramHandler from "features/Programs/One/ProgramHandler"
import ListLoader from "components/List/ListLoader"
import { toast } from "react-toastify"
import DevicesSidebar from "./Sidebar/DevicesSidebar"
import ChannelsSidebar from "./Sidebar/ChannelsSidebar"

export class Program extends Component {
  state = {
    isInit: false,
    isNew: true,
    programHasChanged: false,
    title: "programs.pageTitle.addTitle",
    redirectToRoot: false,
    currentSelectedContent: [],

    isDevicesSidebarVisible: false,
    isChannelsSidebarVisible: false,
    confirmDeleteModalOpen: false
  }

  componentDidMount() {
    this.initComponent(this.props.match.params.id)
    if (!this.props.channels.loaded) this.props.apiGetChannels()
    if (!this.props.videos.loaded) this.props.apiGetVideos()
    if (!this.props.playlists.loaded) this.props.apiGetPlaylists()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) this.initComponent(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.resetFocusProgram()
  }

  setProgramHasChanged = value => this.setState({ programHasChanged: value })

  setCurrentSelectedContent = values => {
    this.setState({ currentSelectedContent: values })
  }

  initComponent = async id => {
    const isNew = id === "new"

    if (!isNew) {
      this.setState({ isNew: false, title: "programs.pageTitle.editTitle" })

      if (this.props.programs.list.data.some(e => e.id === id)) await this.props.getProgramFromStore(id)
      else await this.props.apiGetProgram(id)

      if (this.props.programs.focus.data === null) this.setState({ redirectToRoot: true })
    }

    this.setState({ isInit: true })
  }

  submitForm = async values => {
    const { state } = this
    const data = createJsonDataFromFormik(_.clone(values))

    // New element
    if (state.isNew) {
      const response = await this.props.apiPostProgram(data)

      if (response.type === "API_POST_PROGRAM_SUCCESS") {
        this.props.history.push(`/programs/${response.payload.data.id}`)
        this.initComponent(response.payload.data.id)
      }
    } else {
      const response = await this.props.apiPatchProgram(this.props.match.params.id, data)
      await this.onProgramContentUpdate(state.currentSelectedContent)

      if (response.type === "API_PATCH_PROGRAM_SUCCESS") {
        this.setState({ redirectToRoot: true })
      }
    }

    return true
  }

  deleteElt = async () => {
    this.setState({ isInit: false })
    await this.props.apiDeleteProgram(this.props.match.params.id)

    this.setState({ redirectToRoot: true })
  }

  onProgramContentUpdate = async values => {
    const data = values.map((item, index) => ({
      order: index,
      data: item.data,
      startDate: item.startDate,
      endDate: item.endDate,
      type: item.type,
      contentId: item.contentId
    }))

    await this.props.apiPatchProgramContent(this.props.match.params.id, data)
    this.setProgramHasChanged(false)
  }

  /* Devices Sidebar */
  toggleDevicesSidebar = () => {
    if (this.state.isDevicesSidebarVisible === false) this.props.apiGetAdministrableDevices()
    this.setState(prevState => ({ isDevicesSidebarVisible: !prevState.isDevicesSidebarVisible }))
  }

  onDevicesSidebarSubmit = _data => {
    const data = createJsonDataFromFormik(initFormValues(initialValues, this.props.programs.focus.data, false))
    data.devices = _data.map(device => device.id)

    this.props.apiPatchProgram(this.props.match.params.id, data)
    this.toggleDevicesSidebar()
  }

  /* Channels Sidebar */
  toggleChannelsSidebar = () => {
    this.setState(prevState => ({ isChannelsSidebarVisible: !prevState.isChannelsSidebarVisible }))
  }

  onChannelsSidebarSubmit = _data => {
    const data = createJsonDataFromFormik(initFormValues(initialValues, this.props.programs.focus.data, false))
    data.channels = _data.map(channel => channel.id)

    this.props.apiPatchProgram(this.props.match.params.id, data)
    this.toggleChannelsSidebar()
  }

  openConfirmDeleteModalOpen = () => {
    if (this.props.programs.focus.data.devices.length > 0) {
      toast.error(i18n.t("programs.devicesUsingThisProgram"))
    } else {
      this.setState({ confirmDeleteModalOpen: true })
    }
  }

  render() {
    const {
      title,
      isNew,
      redirectToRoot,
      isInit,
      programHasChanged,
      isDevicesSidebarVisible,
      isChannelsSidebarVisible
    } = this.state
    const { myOrganization, videos, playlists, devices, channels, organizations, me, categories } = this.props

    if (redirectToRoot) {
      return <Redirect to="/programs" />
    }
    if (!isInit) return <ListLoader />

    const newElement = {
      owner: {
        id: this.props.myOrganization.id,
        name: this.props.myOrganization.name
      }
    }

    const data = isNew ? newElement : this.props.programs.focus.data

    const userCanEdit =
      me.role === "ADMIN" &&
      (data.owner.id === myOrganization.id || organizations.list.data.some(i => i.id === data.owner.id))

    if (isInit) {
      return (
        <PageContainer>
          <MiniPageTitle heading={i18n.t(title)} backButton="/programs" />

          <Row style={{ marginTop: "1em" }}>
            <Col xs={12}>
              <Formik
                enableReinitialize
                initialValues={initFormValues(initialValues, data, isNew)}
                validationSchema={validationSchema}
                onSubmit={values => {
                  this.submitForm(values)
                }}
              >
                {({ errors }) => {
                  return (
                    <Form>
                      <ProgramForm
                        organizations={organizations}
                        userCanEdit={userCanEdit}
                        isNew={isNew}
                        element={data}
                        myOrganization={myOrganization}
                        errors={errors}
                        loading={this.props.programs.focus.loading}
                        openConfirmDeleteModalOpen={this.openConfirmDeleteModalOpen}
                        openDevicesSidebar={this.toggleDevicesSidebar}
                        openChannelsSidebar={this.toggleChannelsSidebar}
                      />
                    </Form>
                  )
                }}
              </Formik>
            </Col>
          </Row>
          {!isNew && (
            <Fragment>
              <ProgramHandler
                videos={videos}
                playlists={playlists}
                userCanEdit={userCanEdit}
                content={data.content}
                categories={categories}
                organizations={organizations}
                update={this.onProgramContentUpdate}
                setCurrentSelectedContent={this.setCurrentSelectedContent}
                hasChanged={programHasChanged}
                setHasChanged={this.setProgramHasChanged}
                myOrganization={this.props.myOrganization}
              />

              <DevicesSidebar
                devices={devices}
                organizations={organizations}
                myOrganization={myOrganization}
                selected={data.devices}
                program={data}
                visible={isDevicesSidebarVisible}
                onSubmit={this.onDevicesSidebarSubmit}
                onCancel={this.toggleDevicesSidebar}
              />

              <ChannelsSidebar
                elements={channels.data}
                selected={data.channels}
                visible={isChannelsSidebarVisible}
                onSubmit={this.onChannelsSidebarSubmit}
                onCancel={this.toggleChannelsSidebar}
              />
            </Fragment>
          )}
          <Confirm
            open={this.state.confirmDeleteModalOpen}
            content={i18n.t("form.confirmDelete")}
            cancelButton={i18n.t("actions.cancel")}
            confirmButton={i18n.t("actions.validate")}
            onCancel={() => this.setState({ confirmDeleteModalOpen: false })}
            onConfirm={this.deleteElt}
          />
        </PageContainer>
      )
    }

    return <Loader active inline />
  }
}

const mapStateToProps = state => ({
  videos: state.videos.list,
  playlists: state.playlists.list,
  programs: state.programs,
  devices: state.devices,
  channels: state.channels.list,
  myOrganization: state.organizations.me.data,
  organizations: state.organizations,
  me: state.users.me.data,
  categories: state.categories.list.data
})

const mapDispatchToProps = {
  getProgramFromStore,
  apiGetProgram,
  apiPostProgram,
  apiPatchProgram,
  apiPatchProgramContent,
  apiDeleteProgram,
  resetFocusProgram,
  apiGetVideos,
  apiGetPlaylists,
  apiGetAdministrableDevices,
  apiGetChannels
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Program))
