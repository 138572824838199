import React, { Fragment } from "react"
import { Field } from "formik"
import ClassicInput from "components/Form/ClassicInput"
import i18n from "utils/i18n"
import { Row, Col, Button } from "reactstrap"
import FormSaveButton from "components/Buttons/FormSaveButton"
import MainContainer from "components/PageStructure/MainContainer"
import _ from "lodash"
import UserAvatarHandler from "components/Users/UserAvatarHandler"
import CancelButton from "components/Buttons/CancelButton"

const ProfileForm = ({
  user,
  avatarHandler,
  handleAvatarChange,
  errors,
  loading,
  toggleEditPassword,
  editPassword
}) => {
  return (
    <Fragment>
      <Row>
        <Col xs={6}>
          <FormSaveButton
            isLoading={loading}
            text={i18n.t("actions.save")}
            type="submit"
            disabled={!_.isEmpty(errors)}
          />
        </Col>
        {/* eslint-disable-next-line react/self-closing-comp */}
        <Col xs={6}></Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <MainContainer>
            <Row>
              <Col xs={1}>
                <UserAvatarHandler user={user} avatarHandler={avatarHandler} onChange={handleAvatarChange} />
              </Col>
              <Col xs={5}>
                <Field
                  type="text"
                  name="firstName"
                  label={i18n.t("entities.attributes.firstName")}
                  component={ClassicInput}
                  required
                />
              </Col>
              <Col xs={5}>
                <Field
                  type="text"
                  name="lastName"
                  label={i18n.t("entities.attributes.lastName")}
                  component={ClassicInput}
                  required
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <MainContainer>
            <Row>
              <Col xs={12}>
                <Field
                  type="text"
                  name="email"
                  label={i18n.t("entities.attributes.email")}
                  component={ClassicInput}
                  required
                />
              </Col>
            </Row>
          </MainContainer>
        </Col>
        <Col xs={12} md={6}>
          <MainContainer>
            <Row>
              {!editPassword && (
                <Col xs={12}>
                  <Button color="light" outline size="sm" onClick={() => toggleEditPassword()}>
                    {i18n.t("users.editPassword")}
                  </Button>
                </Col>
              )}
              {editPassword && (
                <Fragment>
                  <Col xs={12}>
                    <FormSaveButton
                      isLoading={loading}
                      text={i18n.t("actions.save")}
                      type="submit"
                      disabled={!_.isEmpty(errors)}
                    />
                    <CancelButton onClick={() => toggleEditPassword()} style={{ marginLeft: 5 }} />
                  </Col>

                  <Col xs={12}>
                    <Field
                      type="password"
                      name="oldPassword"
                      label={i18n.t("entities.attributes.oldPassword")}
                      component={ClassicInput}
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      type="password"
                      name="password"
                      label={i18n.t("entities.attributes.password")}
                      component={ClassicInput}
                      required
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      type="password"
                      name="repassword"
                      label={i18n.t("entities.attributes.repassword")}
                      component={ClassicInput}
                      required
                    />
                  </Col>
                </Fragment>
              )}
            </Row>
          </MainContainer>
        </Col>
      </Row>
    </Fragment>
  )
}

export default ProfileForm
