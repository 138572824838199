const initialValues = [
  {
    name: "id",
    type: "number"
  },
  {
    name: "name",
    type: "text"
  },
  {
    name: "power",
    type: "boolean"
  },
  {
    name: "source",
    type: "select"
  },
  {
    name: "webBrowserURL",
    type: "text"
  },
  {
    name: "volume",
    type: "number"
  },
  {
    name: "pepstreamImageMode",
    type: "number"
  },
  {
    name: "isInitSecurity",
    type: "boolean"
  },
  {
    name: "broadcastTags",
    type: "multiSelect",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "owner",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "group",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "name",
      withColor: false
    }
  },
  {
    name: "program",
    type: "selectAutoComplete-Single",
    options: {
      valueField: "id",
      labelField: "title",
      withColor: false
    }
  },
  {
    name: "displayParameters",
    type: "nestedObject",
    elements: [
      {
        name: "player_img",
        type: "text"
      },
      {
        name: "player_imgPosition",
        type: "select"
      },
      {
        name: "player_text",
        type: "text"
      },
      {
        name: "player_text_defilement",
        type: "select"
      },
      {
        name: "player_text_with_background",
        type: "boolean"
      },
      {
        name: "bloc1_img",
        type: "text"
      },
      {
        name: "bloc1_imgPosition",
        type: "select"
      },
      {
        name: "bloc1_text",
        type: "text"
      },
      {
        name: "bloc1_text_defilement",
        type: "select"
      },
      {
        name: "bloc1_backgroundColor",
        type: "text"
      },
      {
        name: "bloc1_source",
        type: "select"
      },
      {
        name: "bloc1_url",
        type: "text"
      },
      {
        name: "bloc2_img",
        type: "text"
      },
      {
        name: "bloc2_imgPosition",
        type: "select"
      },
      {
        name: "bloc2_text",
        type: "text"
      },
      {
        name: "bloc2_text_defilement",
        type: "select"
      },
      {
        name: "bloc2_backgroundColor",
        type: "text"
      },
      {
        name: "bloc2_source",
        type: "select"
      },
      {
        name: "bloc2_url",
        type: "text"
      },
      {
        name: "bloc3_img",
        type: "text"
      },
      {
        name: "bloc3_imgPosition",
        type: "select"
      },
      {
        name: "bloc3_text",
        type: "text"
      },
      {
        name: "bloc3_text_defilement",
        type: "select"
      },
      {
        name: "bloc3_backgroundColor",
        type: "text"
      },
      {
        name: "bloc3_source",
        type: "select"
      },
      {
        name: "bloc3_url",
        type: "text"
      }
    ]
  }
]

export default initialValues
