import React, { Fragment } from "react"
import { Icon } from "semantic-ui-react"
import InputRange from "react-input-range"
import ListSearchInput from "./ListSearchInput"
import FilterButton from "./FilterButton"

const Filters = ({
  filters,
  layout,
  onFilterChange,
  onLayoutChange,
  gridSpace,
  onGridSpaceChange,
  multipleLayouts = false,
  onlyLogo = false
}) => {
  return (
    <div className="list-filters-container">
      <div className="list-filters-left">
        {filters.select.map(filter => (
          <FilterButton
            icon="culture"
            key={`filter${filter.name}`}
            filter={filter}
            onlyLogo={onlyLogo}
            onChange={option => onFilterChange("select", filter.name, option.value)}
          />
        ))}
        <ListSearchInput onChange={value => onFilterChange("search", "", value)} filter={filters.search} />
      </div>
      <div className="filters-layout-choice">
        <Fragment>
          {layout === "grid" && (
            <InputRange
              formatLabel={() => ""}
              maxValue={3}
              minValue={1}
              value={gridSpace}
              onChange={value => onGridSpaceChange(value)}
            />
          )}

          {multipleLayouts && (
            <i
              onClick={() => onLayoutChange("grid")}
              style={{ fontSize: "26px", marginRight: "10px" }}
              className={`pe-7s-keypad btn-icon-wrapper layout-choice-icon ${
                layout === "grid" ? "color-gray-dark" : "color-light-gray"
              }`}
            >
              {" "}
            </i>
          )}
          {multipleLayouts && (
            <i
              onClick={() => onLayoutChange("list")}
              className={`pe-7s-menu btn-icon-wrapper layout-choice-icon ${
                layout === "list" ? "color-gray-dark" : "color-light-gray"
              }`}
            >
              {" "}
            </i>
          )}
        </Fragment>
      </div>
    </div>
  )
}

export default Filters
