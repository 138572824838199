import React from "react"

const SidebarCloseButton = ({ onClick, style }) => {
  return (
    <div className="sidebar-close-button" style={{ ...style }} onClick={() => onClick()}>
      <i
        className="pe-7s-close btn-icon-wrapper"
        style={{ color: "#30b1ff", fontSize: "2em" }}
        // eslint-disable-next-line react/jsx-closing-tag-location
      ></i>
    </div>
  )
}

export default SidebarCloseButton
