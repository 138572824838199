import React, { Component, Fragment } from "react"
import { Row, Col } from "reactstrap"
import getThumbnailSrc from "utils/getThumbnailSrc"
import moment from "moment"
import DroppableContentLabel from "components/DroppableContentLabel"
import getContentDuration from "utils/getContentDuration"
import FormSaveButton from "components/Buttons/FormSaveButton"
import PlaylistThumbnail from "components/Users/PlaylistThumbnail"
import OrganizationWithLogoDisplayer from "components/Organization/OrganizationWithLogoDisplayer"

class LineContentItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      startDate: this.props.item.startDate,
      endDate: this.props.item.endDate,
      editActive: false
    }
  }

  toggleEditActive = () => this.setState(prevState => ({ editActive: !prevState.editActive }))

  submitDatesChange = () => {
    this.props.editProgramContentDates(this.props.item.id, this.state.startDate, this.state.endDate)
    this.toggleEditActive()
  }

  changeStartDate = event => this.setState({ startDate: event.target.value })

  changeEndDate = event => this.setState({ endDate: event.target.value })

  render() {
    const { item, provided, style, deleteContent, index, userCanEdit } = this.props
    const { startDate, endDate, editActive } = this.state
    const element = item.data

    return (
      <div
        className="content-line lg"
        style={{ ...style }}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <Row className="line-element-row">
          <Col xs={5} style={{ padding: 0, height: "100%", display: "flex", alignItems: "center" }}>
            {userCanEdit && (
              <i
                onClick={() => deleteContent(item, index)}
                className="pe-7s-close btn-icon-wrapper color-red cursor-pointer"
                style={{ float: "left", fontSize: "2.5em" }}
              />
            )}

            {item.type === "VIDEO" && (
              <img
                src={getThumbnailSrc(item.thumbnail, item.type)}
                style={{ height: "100%", marginRight: 10 }}
                alt=""
              />
            )}
            {item.type === "PLAYLIST" && (
              <PlaylistThumbnail playlist={item.data} style={{ height: "100%", marginRight: 5 }} />
            )}

            <div className="line-element-info" style={{ overflow: "hidden" }}>
              <div className="content">
                <div className="element-title text-ellipsis">{element.title}</div>
                <OrganizationWithLogoDisplayer
                  organization={element.owner}
                  style={{ height: 20, marginTop: "0.2em" }}
                />
              </div>
            </div>
          </Col>
          <Col xs={4} style={{ padding: 0, display: "flex", alignItems: "center" }}>
            {!editActive && (
              <Fragment>
                <div
                  className={`program-dates-container ${userCanEdit ? "cursor-pointer" : ""} ${
                    startDate !== null && endDate !== null ? "lineContentProgramDatesOK" : ""
                  }`}
                  onClick={() => {
                    if (userCanEdit) this.toggleEditActive()
                  }}
                >
                  {startDate !== null &&
                    endDate !== null &&
                    `${moment.utc(startDate).format("DD/MM/YY")} - ${moment(endDate).format("DD/MM/YY")}`}
                  {startDate === null && endDate === null && <div>À programmer</div>}
                </div>
              </Fragment>
            )}
            {editActive && (
              <Fragment>
                <div className="program-dates-container">
                  <input
                    type="date"
                    className="input-date"
                    id="startDate"
                    name="startDate"
                    placeholder="Début"
                    value={moment.utc(startDate).format("YYYY-MM-DD")}
                    max={moment.utc(endDate).format("YYYY-MM-DD")}
                    onChange={this.changeStartDate}
                  />
                  -
                  <input
                    type="date"
                    className="input-date"
                    id="endDate"
                    name="endDate"
                    min={moment.utc(startDate).format("YYYY-MM-DD")}
                    placeholder="Fin"
                    value={moment.utc(endDate).format("YYYY-MM-DD")}
                    onChange={this.changeEndDate}
                  />
                </div>
                <FormSaveButton
                  type="button"
                  disabled={false}
                  onClick={() => {
                    if (startDate !== null && endDate !== null) this.submitDatesChange()
                    else this.toggleEditActive()
                  }}
                  text="OK"
                />
              </Fragment>
            )}
          </Col>

          <Col xs={3} style={{ display: "flex", justifyContent: "flex-end" }}>
            <DroppableContentLabel content={item} />
            <div className="video-time" style={{ float: "right", marginLeft: 5 }}>
              {item.type === "VIDEO" && moment.utc(element.duration * 1000).format("mm:ss")}
              {item.type === "PLAYLIST" && moment.utc(getContentDuration(element.content, true)).format("mm:ss")}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default LineContentItem
