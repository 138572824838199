import api from "utils/i18n/fr/api.json"
import actions from "utils/i18n/fr/actions.json"
import entities from "utils/i18n/fr/entities.json"
import menu from "utils/i18n/fr/menu.json"
import misc from "utils/i18n/fr/misc.json"
import form from "utils/i18n/fr/form.json"
import formats from "utils/i18n/fr/formats.json"

import users from "utils/i18n/fr/users.json"
import videos from "utils/i18n/fr/videos.json"
import storage from "utils/i18n/fr/storage.json"
import organizations from "utils/i18n/fr/organizations.json"
import localGroups from "utils/i18n/fr/localGroups.json"
import localTags from "utils/i18n/fr/localTags.json"
import subOrgas from "utils/i18n/fr/subOrgas.json"
import channels from "utils/i18n/fr/channels.json"
import playlists from "utils/i18n/fr/playlists.json"
import programs from "utils/i18n/fr/programs.json"
import webContents from "utils/i18n/fr/webcontents.json"
import devices from "utils/i18n/fr/devices.json"
import deviceGroups from "utils/i18n/fr/deviceGroups.json"
import broadcastTags from "utils/i18n/fr/broadcastTags.json"
import categories from "utils/i18n/fr/categories.json"

export default {
  api,
  actions,
  entities,
  menu,
  misc,
  form,
  formats,
  users,
  videos,
  storage,
  organizations,
  localGroups,
  localTags,
  subOrgas,
  channels,
  playlists,
  programs,
  webContents,
  devices,
  deviceGroups,
  broadcastTags,
  categories,
  timeUtils: (date, moment) => {
    const dateFormat = options => new Intl.DateTimeFormat("fr-FR", options).format(new Date(date))

    const minimalOptions = {
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }

    const dateNow = new Date()
    const isAtLeastOneWeekBefore = moment(new Date(date)).isSameOrBefore(moment(dateNow).subtract(1, "week"))
    const isOnSameTimeZone = new Date(date).getTimezoneOffset() === dateNow.getTimezoneOffset()
    const isThisYear = moment(new Date(date)).isSame(moment(dateNow), "year")

    if (!isOnSameTimeZone) minimalOptions.timeZoneName = "short"
    if (!isThisYear) minimalOptions.year = "numeric"
    if (!isAtLeastOneWeekBefore) minimalOptions.weekday = "short"

    return dateFormat(minimalOptions)
  }
}
