import React, { Fragment } from "react"
import { connect } from "react-redux"

import Hamburger from "react-hamburgers"

import { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } from "reducers/ThemeOptions"
import PepstreamLogoHorizontal2 from "components/Icons/PepstreamLogoHorizontal2"
import AppMobileMenu from "../AppMobileMenu"

class HeaderLogo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleEnableClosedSidebar = () => {
    const { enableClosedSidebar, setEnableClosedSidebarProps } = this.props
    setEnableClosedSidebarProps(!enableClosedSidebar)
  }

  render() {
    const { enableClosedSidebar } = this.props
    const { active } = this.state

    return (
      <Fragment>
        <div className="app-header__logo">
          <PepstreamLogoHorizontal2 style={{ width: "60%" }} className="app-header__logo_svg" />
          <div className="header__pane ml-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Hamburger
                active={enableClosedSidebar}
                type="elastic"
                // @ts-ignore
                onClick={() => this.setState({ active: !active })}
              />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
})

const mapDispatchToProps = dispatch => ({
  setEnableClosedSidebarProps: enable => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo)
