import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Button } from "reactstrap"
import ListContainer from "components/List/ListContainer"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"

class SubOrgas extends Component {
  state = {
    listLength: null
  }

  componentDidMount() {}

  render() {
    const { organizations } = this.props
    const { listLength } = this.state

    const mySubOrgas = organizations.list.data
      .filter(i => i.superOrgaId === organizations.me.data.id)
      .sort((a, b) => a.name.localeCompare(b.name))

    return (
      <PageContainer>
        <PageTitle
          heading={i18n.t("subOrgas.pageTitle.heading")}
          icon="pe-7s-network folder-color-ORGANIZATION"
          number={listLength}
        />

        <div className="page-top-buttons">
          <Link to="/subOrgas/new" className="new-element-button">
            <Button className="btn-icon" color="primary" outline size="md">
              <i className="pe-7s-plus btn-icon-wrapper"> </i>
              {i18n.t("subOrgas.actions.add")}
            </Button>
          </Link>
        </div>

        <ListContainer
          type="SUBORGA"
          lineHeight="lg"
          elements={{ data: mySubOrgas }}
          filtersOn
          filters={{ search: ["name"], select: [] }}
          defaultLayout="list"
          listLengthSetter={nb => this.setState({ listLength: nb })}
          multipleLayouts={false}
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    organizations: state.organizations
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SubOrgas)
