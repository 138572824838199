import React, { Fragment, Component } from "react"
import { Row, Col } from "reactstrap"
import i18n from "utils/i18n"
import { Field } from "formik"
import SelectAutoComplete from "components/Form/SelectAutoComplete"
import generateMultiSelectOptions from "utils/formik/generateSelectOptions"
import ReleaseStatusChip from "components/ReleaseStatusChip"
import DroppableContentLabel from "components/DroppableContentLabel"
import moment from "moment"

class DeviceProgram extends Component {
  render() {
    const { device, values } = this.props

    const programOptions = [{ value: "", label: i18n.t("form.none") }].concat(
      generateMultiSelectOptions(device.owner.programs, "id", "title")
    )

    return (
      <Fragment>
        <Row className="custom-scroll" style={{ flex: 1 }}>
          <Col xs={12} style={{ paddingRight: "2em" }}>
            <Row>
              <Col xs={6} style={{ marginBottom: "1em" }}>
                <div className="main-container-title">
                  {i18n.t("entities.singular.program")}{" "}
                  <ReleaseStatusChip isSynced={device.releaseStatus?.isSynced} style={{ marginLeft: 10 }} />
                  <div style={{ marginLeft: 10, fontWeight: 400, fontSize: "0.9em" }}>
                    {device.lastReleaseStatusUpdate !== null
                      ? `(Dernière mise à jour : ${moment(device.lastReleaseStatusUpdate).format(
                          "DD/MM/YYYY - HH:mm"
                        )})`
                      : ""}
                  </div>
                </div>

                <Field
                  component={SelectAutoComplete}
                  required={false}
                  disabled={values.owner.value === ""}
                  name="program"
                  placeholder={i18n.t("form.actions.chooseProgram")}
                  options={programOptions}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} style={{ marginBottom: "1em" }}>
                <b>Détail du programme</b>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {device.releaseStatus?.content.map(c => (
                  <Row style={{ padding: "10px 0", borderTop: "1px solid #efefef" }}>
                    <Col xs={5}>
                      <DroppableContentLabel content={c} style={{ float: "left", marginRight: 10 }} /> {c.data.title}
                    </Col>
                    <Col xs={4}>
                      <div
                        className={`program-dates-container ${
                          c.contentData.startDate !== null && c.contentData.endDate !== null
                            ? "lineContentProgramDatesOK"
                            : ""
                        }`}
                      >
                        {c.contentData.startDate !== null &&
                          c.contentData.endDate !== null &&
                          `${moment.utc(c.contentData.startDate).format("DD/MM/YY")} - ${moment(
                            c.contentData.endDate
                          ).format("DD/MM/YY")}`}
                        {c.contentData.startDate === null && c.contentData.endDate === null && <div>À programmer</div>}
                      </div>
                    </Col>
                    <Col xs={3}>
                      <ReleaseStatusChip isSynced={c.isSynced} style={{ float: "right" }} />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    )
  }
}
export default DeviceProgram
