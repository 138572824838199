import React from "react"
import i18n from "utils/i18n"

const GroupDisplayer = ({ groups }) => {
  if (groups.length === 0)
    return (
      <div>
        <i>{i18n.t("form.none")}</i>
      </div>
    )

  return <div>{groups[0].name}</div>
}

export default GroupDisplayer
