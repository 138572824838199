import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { apiPostLocalTag, apiPatchLocalTag, apiDeleteLocalTag } from "services/localTags"
import ListContainer from "components/List/ListContainer"
import { Button } from "reactstrap"
import i18n from "utils/i18n"
import { Formik, Form } from "formik"
import initFormValues from "utils/formik/initFormValues"
import LocalTagForm from "features/Organization/LocalTags/Form"
import initialValues from "features/Organization/LocalTags/Form/initialValues"
import validationSchema from "features/Organization/LocalTags/Form/validationSchema"

export class LocalTags extends Component {
  state = {
    form: {
      init: false,
      loading: false
    }
  }

  cancelForm = () => {
    this.setState({ form: { loading: false, init: false } })
  }

  submitForm = async values => {
    this.setState({ form: { loading: true, init: true } })

    // Serializer
    const data = values
    data.owner = this.props.organization.id
    data.type = values.type.value

    const response = await this.props.apiPostLocalTag(data)

    if (response.type === "API_POST_LOCALTAG_FAIL") this.setState({ form: { loading: false, init: true } })
    else this.setState({ form: { loading: false, init: false } })
  }

  patch = elt => {
    this.props.apiPatchLocalTag(elt)
  }

  delete = elt => {
    this.props.apiDeleteLocalTag(elt.id)
  }

  render() {
    const { localTags } = this.props.organization
    const { form } = this.state

    return (
      <Fragment>
        {!form.init && (
          <div className="new-element-button">
            <Button
              className="btn-icon"
              color="primary"
              outline
              size="md"
              onClick={() => this.setState({ form: { init: true, loading: false } })}
            >
              <i className="pe-7s-plus btn-icon-wrapper"> </i>
              {i18n.t("organizations.localTags.add")}
            </Button>
          </div>
        )}

        {form.init && (
          <Formik
            enableReinitialize
            initialValues={initFormValues(initialValues, {}, true)}
            validationSchema={validationSchema}
            onSubmit={values => {
              this.submitForm(values)
            }}
          >
            {({ errors }) => {
              return (
                <Form>
                  <LocalTagForm errors={errors} loading={form.loading} onCancel={this.cancelForm} />
                </Form>
              )
            }}
          </Formik>
        )}

        <ListContainer
          type="LOCALTAG"
          lineHeight="md"
          elements={{ data: localTags, loading: false }}
          handlers={{ delete: this.delete, patch: this.patch }}
          filtersOn={false}
          filters={null}
          defaultLayout="list"
          multipleLayouts={false}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  apiPostLocalTag,
  apiPatchLocalTag,
  apiDeleteLocalTag
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalTags)
