/* Current user */
export const API_GET_ME = "API_GET_ME"
export const API_PATCH_ME = "API_PATCH_ME"

/* Authentification */
export const AUTH_USER = "AUTH_USER"
export const TOKEN_EXPIRED = "TOKEN_EXPIRED"
export const LOGOUT_USER = "LOGOUT_USER"
export const API_RESET_PASSWORD = "API_RESET_PASSWORD"

/* All Users */
export const API_GET_ALL_USER = "API_GET_ALL_USER"
export const API_GET_ONE_USER = "API_GET_ONE_USER"
export const API_POST_USER = "API_POST_USER"
export const API_PATCH_USER = "API_PATCH_USER"
export const API_DELETE_USER = "API_DELETE_USER"
export const INIT_USER = "INIT_USER"
export const RESET_FOCUS_USER = "RESET_FOCUS_USER"
