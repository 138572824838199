import React, { Fragment } from "react"
import i18n from "utils/i18n"

const Status = ({ status, className = "" }) => {
  return (
    <Fragment>
      {status === "ACTIVE" && (
        <div className={`status-label success ${className}`}>{i18n.t("entities.attributesValues.status.online")}</div>
      )}
      {status === "PROCESSING" && (
        <div className={`status-label info ${className}`}>{i18n.t("entities.attributesValues.status.processing")}</div>
      )}
      {status === "INACTIVE" && (
        <div className={`status-label error ${className}`}>{i18n.t("entities.attributesValues.status.offline")}</div>
      )}
    </Fragment>
  )
}

export default Status
