import {
  API_GET_ALL_WEBCONTENT,
  API_GET_ONE_WEBCONTENT,
  API_POST_WEBCONTENT,
  API_DELETE_WEBCONTENT,
  GET_ONE_WEBCONTENT,
  INIT_WEBCONTENT,
  RESET_FOCUS_WEBCONTENT,
  API_PATCH_WEBCONTENT
} from "actions/webcontents"

export function getWebContentFromStore(id) {
  return {
    type: GET_ONE_WEBCONTENT,
    payload: {
      id
    }
  }
}

export function apiGetWebContents() {
  return {
    type: API_GET_ALL_WEBCONTENT,
    payload: {
      request: {
        url: `/webContents`
      }
    }
  }
}

export function apiGetWebContent(id) {
  return {
    type: API_GET_ONE_WEBCONTENT,
    payload: {
      request: {
        url: `/webContents/${id}`
      }
    }
  }
}

export function apiGetWebContentForPlaylistChanges(id) {
  return {
    type: API_GET_ONE_WEBCONTENT,
    payload: {
      request: {
        url: `/webContents/${id}/playlists`
      }
    }
  }
}

export function apiPostWebContent(_data) {
  return {
    type: API_POST_WEBCONTENT,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/webContents`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiPatchWebContent(id, _data) {
  return {
    type: API_PATCH_WEBCONTENT,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/webContents/${id}`,
        method: "POST",
        data: _data
      }
    }
  }
}

export function apiDeleteWebContent(id) {
  return {
    type: API_DELETE_WEBCONTENT,
    payload: {
      request: {
        url: `/webContents/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function initWebContent() {
  return {
    type: INIT_WEBCONTENT
  }
}

export function resetFocusWebContent() {
  return {
    type: RESET_FOCUS_WEBCONTENT
  }
}
