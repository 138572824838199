const initialValues = [
  {
    name: "contactName",
    type: "text"
  },
  {
    name: "contactEmail",
    type: "text"
  },
  {
    name: "contactPhone",
    type: "text"
  },
  {
    name: "address",
    type: "text"
  },
  {
    name: "lat",
    type: "number"
  },
  {
    name: "lng",
    type: "number"
  }
]

export default initialValues
