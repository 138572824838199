import {
  API_GET_ALL_VIDEOS,
  API_GET_ONE_VIDEOS,
  API_POST_VIDEOS,
  API_DELETE_VIDEOS,
  GET_ONE_VIDEOS,
  INIT_VIDEOS,
  RESET_FOCUS_VIDEOS,
  API_PATCH_VIDEOS,
  UPLOAD_PROGRESS_VIDEO
} from "actions/videos"

export function getVideoFromStore(id) {
  return {
    type: GET_ONE_VIDEOS,
    payload: {
      id
    }
  }
}

export function apiGetVideos() {
  return {
    type: API_GET_ALL_VIDEOS,
    payload: {
      request: {
        url: `/videos`
      }
    }
  }
}

export function apiGetVideo(id) {
  return {
    type: API_GET_ONE_VIDEOS,
    payload: {
      request: {
        url: `/videos/${id}`
      }
    }
  }
}

export function apiGetVideoForPlaylistChanges(id) {
  return {
    type: API_GET_ONE_VIDEOS,
    payload: {
      request: {
        url: `/videos/${id}/playlists`
      }
    }
  }
}

export function apiPostVideo(video, dispatch) {
  return {
    type: API_POST_VIDEOS,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/videos`,
        method: "POST",
        data: video,
        onUploadProgress: ev => {
          const progress = (ev.loaded / ev.total) * 100
          dispatch({ type: UPLOAD_PROGRESS_VIDEO, payload: Math.round(progress) })
        }
      }
    }
  }
}

export function uploadUploadProgress(progress) {
  return {
    type: UPLOAD_PROGRESS_VIDEO,
    payload: progress
  }
}

export function apiPatchVideo(id, _data, dispatch) {
  return {
    type: API_PATCH_VIDEOS,
    payload: {
      request: {
        config: { headers: { "Content-Type": "multipart/form-data" } },
        url: `/videos/${id}`,
        method: "POST",
        data: _data,
        onUploadProgress: ev => {
          const progress = (ev.loaded / ev.total) * 100
          dispatch({ type: UPLOAD_PROGRESS_VIDEO, payload: Math.round(progress) })
        }
      }
    }
  }
}

export function apiDeleteVideo(id) {
  return {
    type: API_DELETE_VIDEOS,
    payload: {
      request: {
        url: `/videos/${id}`,
        method: "DELETE"
      }
    }
  }
}

export function initVideo() {
  return {
    type: INIT_VIDEOS
  }
}

export function resetFocusVideo() {
  return {
    type: RESET_FOCUS_VIDEOS
  }
}
