import React from "react"
import { Formik, Field, Form } from "formik"
import { Form as SemForm } from "semantic-ui-react"
import MainButton from "components/Buttons/MainButton"
import i18n from "utils/i18n"
import ConnectButton from "components/Buttons/ConnectButton"
import CancelConnectButton from "components/Buttons/CancelConnectButton"

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = i18n.t("users.login.errors.emailRequired")
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = i18n.t("users.login.errors.invalidEmail")
  }
  return errors
}

const errorStyle = {
  marginBottom: 10,
  color: "#dc0000",
  fontSize: 10
}

const CustomInputTextComponent = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <SemForm.Input {...field} {...props} fluid placeholder={i18n.t("users.login.email")} style={{ marginBottom: 20 }} />
    {touched[field.name] && errors[field.name] && <div style={errorStyle}>{errors[field.name]}</div>}
  </div>
)

const PasswordRecovery = ({ submitResetPassword, cancelPasswordRecoveryForm }) => (
  <div>
    <Formik
      initialValues={{ email: "" }}
      validate={values => validate(values)}
      onSubmit={values => submitResetPassword(values)}
    >
      {() => (
        <Form className="login-box-form">
          <div className="login-box-top">
            <Field type="email" name="email" component={CustomInputTextComponent} />
          </div>
          <div className="login-box-footer">
            <CancelConnectButton text={i18n.t("actions.cancel")} onClick={() => cancelPasswordRecoveryForm()} />
            <ConnectButton text={i18n.t("users.login.validate")} />
          </div>
        </Form>
      )}
    </Formik>
  </div>
)

export default PasswordRecovery
