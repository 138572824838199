import React from "react"

const GlobalTag = ({ name }) => {
  return (
    <div className="tag global">
      <i className="pe-7s-ticket btn-icon-wrapper icon-tag"> </i>
      <div className="text">{name}</div>
    </div>
  )
}

export default GlobalTag
