import React, { Component } from "react"
import { connect } from "react-redux"
import PageTitle from "components/PageStructure/PageTitle"
import { PageContainer } from "components/PageStructure/PageContainer"
import { Button } from "reactstrap"
import { apiGetVideos } from "services/videos"
import { Link } from "react-router-dom"
import i18n from "utils/i18n"
import ListContainer from "components/List/ListContainer"
import { BASE_MEDIAS_VIDEO_DOWNLOAD_URL, BASE_PLAYER_IFRAME_URL } from "config/api"
import { toast } from "react-toastify"

class Videos extends Component {
  state = {
    listLength: null
  }

  componentDidMount() {
    this.props.apiGetVideos()
  }

  downloadVideo = id => {
    window.open(BASE_MEDIAS_VIDEO_DOWNLOAD_URL + id, "_blank")
  }

  copyIntegrationCode = id => {
    const integrationCode = `<iframe src="${BASE_PLAYER_IFRAME_URL}${id}" width="640" height="360" allowfullscreen="true" frameborder="0"></iframe>`
    navigator.clipboard.writeText(integrationCode)

    toast.success("Code d'intégration copié")
  }

  listLengthSetter = nb => {
    this.setState({ listLength: nb })
  }

  render() {
    const { videos, me, myOrganization } = this.props
    const { listLength } = this.state

    const canDownloadOrganiationIDs = myOrganization.accessTo
      .filter(i => i.canDownload === true)
      .map(i => i.sharingOrganizationId)

    canDownloadOrganiationIDs.push(myOrganization.id)

    return (
      <PageContainer>
        <PageTitle
          heading={i18n.t("videos.pageTitle.heading")}
          icon="pe-7s-video folder-color-VIDEO"
          number={listLength}
        />
        <div className="page-top-buttons">
          {me.role === "ADMIN" && (
            <Link to="/videos/new" className="new-element-button">
              <Button className="btn-icon" color="primary" outline size="md">
                <i className="pe-7s-plus btn-icon-wrapper"> </i>
                {i18n.t("videos.actions.add")}
              </Button>
            </Link>
          )}
        </div>

        <ListContainer
          type="VIDEO"
          lineHeight="lg"
          elements={videos.list}
          sortField="updatedAt"
          filtersOn
          filters={{ search: ["title"], select: ["owner", "categories", "groups", "broadcastTags"] }}
          defaultLayout="grid"
          handlers={{
            userCanDownloadList: canDownloadOrganiationIDs,
            download: this.downloadVideo,
            copyIntegrationCode: this.copyIntegrationCode
          }}
          listLengthSetter={this.listLengthSetter}
          multipleLayouts
        />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    videos: state.videos,
    myOrganization: state.organizations.me.data,
    me: state.users.me.data
  }
}

const mapDispatchToProps = {
  apiGetVideos: () => apiGetVideos()
}

export default connect(mapStateToProps, mapDispatchToProps)(Videos)
