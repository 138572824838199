import React, { Component } from "react"
import { connect } from "react-redux"
import { PageContainer } from "components/PageStructure/PageContainer"
import MiniPageTitle from "components/PageStructure/MiniPageTitle"
import { apiGetMe, apiPatchMe } from "services/users"
import { Row, Col } from "reactstrap"
import { Formik, Form } from "formik"
import initFormValues from "utils/formik/initFormValues"
import { withRouter } from "react-router-dom"
import i18n from "utils/i18n"
import validationSchema from "features/Profile/Form/validationSchema"
import initialValues from "features/Profile/Form/initialValues"
import ProfileForm from "features/Profile/Form"
import { createFormDataFromFormik } from "utils/formik/apiSerializer"
import getUserAvatarSrc from "utils/getUserAvatarSrc"
import produce from "immer"
import _ from "lodash"
import { toast } from "react-toastify"

export class Profile extends Component {
  state = {
    avatarHandler: {
      isNew: false,
      error: null,
      file: null,
      src: getUserAvatarSrc(this.props.users.me.data)
    },
    editPassword: false
  }

  handleAvatarChange = async (src, file) => {
    this.setState(
      produce(draft => {
        // eslint-disable-next-line no-param-reassign
        draft.avatarHandler.error = null
      })
    )
    const supportedTypes = ["image/png", "image/jpeg"]

    if (!supportedTypes.includes(file.type)) {
      this.setState(
        produce(draft => {
          // eslint-disable-next-line no-param-reassign
          draft.avatarHandler.error = i18n.t("form.image.formatError")
        })
      )
    } else {
      this.setState(prevState => ({ ...prevState, avatarHandler: { isNew: true, src, file, error: null } }))
    }
  }

  submitForm = async values => {
    const data = _.clone(values)
    let passwordHasChanged = false

    if (this.state.editPassword) {
      if (data.oldPassword === "" || data.password === "" || data.repassword === "") {
        toast.error(i18n.t("form.passwordLength"))
        return
      }

      if (data.password !== data.repassword) {
        toast.error(i18n.t("form.passwordNotEquals"))
        return
      }

      passwordHasChanged = true
    } else {
      delete data.oldPassword
      delete data.password
      delete data.repassword
    }

    const formData = createFormDataFromFormik(data)

    if (this.state.avatarHandler.isNew) formData.append("avatar", this.state.avatarHandler.file)

    this.props.apiPatchMe(formData)
    if (passwordHasChanged) this.setState({ editPassword: false })
  }

  toggleEditPassword = () => {
    this.setState(prevState => ({ editPassword: !prevState.editPassword }))
  }

  render() {
    const { data } = this.props.users.me
    const { avatarHandler, editPassword } = this.state

    return (
      <PageContainer>
        <MiniPageTitle heading={i18n.t("users.pageTitle.myProfile")} />

        <Row>
          <Col xs={12}>
            <Formik
              enableReinitialize
              initialValues={initFormValues(initialValues, data, false)}
              validationSchema={validationSchema}
              onSubmit={values => {
                this.submitForm(values)
              }}
            >
              {({ errors }) => {
                return (
                  <Form>
                    <ProfileForm
                      user={data}
                      errors={errors}
                      avatarHandler={avatarHandler}
                      handleAvatarChange={this.handleAvatarChange}
                      loading={this.props.users.me.loading}
                      toggleEditPassword={this.toggleEditPassword}
                      editPassword={editPassword}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Col>
        </Row>
      </PageContainer>
    )
  }
}

const mapStateToProps = state => ({
  users: state.users
})

const mapDispatchToProps = {
  apiGetMe,
  apiPatchMe
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
